// ##### 24-02-16 Komarov: файл с перечнем страниц, которые рендерятся на сервере

// TODO: Если страница отключена в Смарт, то закомментировать её и здесь.
// TODO: Сделать автоматическое определение страниц, которые нужно сюда добавить, и которые нужно закомментировать.
// ##### 24-02-21 Komarov
module.exports = {
  // Main page
  Main: "",
  // About Us page
  // ##### 25-01-09 Komarov: раскомментировал страницу: чтобы она была в SSR, иначе при обновлении вкладки приложение перенаправляет на главную страницу
  "About Us": "/sitepages/?name=About-Us&id=2",
  // Mice page
  Mice: "/sitepages/?name=MICE&id=18",
  // Gallery page
  Gallery: "/sitepages/?name=Gallery&id=21",
  // Contact page
  // ##### 25-01-09 Komarov: раскомментировал страницу: чтобы она была в SSR, иначе при обновлении вкладки приложение перенаправляет на главную страницу
  Contact: "/sitepages/?name=Contact&id=41",
  // ##### 24-09-04 Komarov
  // Contacts page
  Contacts: "/sitepages/?name=contacts&id=5",
  // Clients page
  Clients: "/sitepages/?name=Clients&id=42",
  // ##### 24-09-04 Komarov
  // Suppliers page
  Suppliers: "/sitepages/?name=Suppliers&id=167",
  // ##### 24-09-04 Komarov
  // Group page
  Group: "/sitepages/?name=Group%20Request&id=187",
  // War in Ukraine page
  "War in Ukraine": "/sitepages/?name=warukrainespec&id=210",
  // About Us page (link from Footer)
  "About Us Footer": "/sitepages/?name=about-us&id=1",
  // ##### 23-07-14 Komarov
  // Міста: Дніпро, Україна
  // ##### 24-07-22 Komarov
  // "Dnipro city": "/city_overview/?name=dnipro&id=23&sptid=56",
  "Dnipro city": "/city_overview/?name=dnipro&id=185&sptid=66",
  // Міста: Харків
  // ##### 24-07-22 Komarov
  // "Kharkiv city": "/city_overview/?name=kharkiv&id=28&sptid=56",
  "Kharkiv city": "/city_overview/?name=kharkiv&id=186&sptid=66",
  // Міста: Київ, Україна
  // ##### 24-07-22 Komarov
  // "Kyiv city": "/city_overview/?name=kyiv&id=17&sptid=56",
  "Kyiv city": "/city_overview/?name=kyiv&id=184&sptid=66",
  // Міста: Вінниця, Україна
  // ##### 24-07-22 Komarov
  // "Vinnitsa city": "/city_overview/?name=vinnitsa&id=50&sptid=56",
  "Vinnitsa city": "/city_overview/?name=vinnitsa&id=189&sptid=66",
  // Міста: Запоріжжя
  // ##### 24-07-22 Komarov
  // "Zaporozhie city": "/city_overview/?name=zaporozhie&id=51&sptid=56",
  "Zaporozhie city": "/city_overview/?name=zaporozhie&id=190&sptid=66",
  // Міста: Одеса, Україна
  // ##### 24-07-22 Komarov
  // "Odessa city": "/city_overview/?name=odessa&id=42&sptid=56",
  "Odessa city": "/city_overview/?name=odessa&id=188&sptid=66",
  // Міста: Львів, Україна
  // ##### 24-07-22 Komarov
  // "Lviv city": "/city_overview/?name=lviv&id=38&sptid=56",
  "Lviv city": "/city_overview/?name=lviv&id=187&sptid=66",
  // ##### 25-03-26 Komarov: раскомментировал для того, чтобы при обновлении страницы сайт не переходил на Главную
  // Інші Міста
  "Other Cities": "/city_overview/?name=others&id=191&sptid=36",
  // ##### 25-03-26 Komarov: раскомментировал для того, чтобы при обновлении страницы сайт не переходил на Главную
  // Інші Готелі
  "Other Hotels": "/city_overview/?name=others&id=365&sptid=36",
  // Визначні місця: Курортне, Одеська область, Україна
  "Kurortnoe sight": "/city_overview/?name=kurortnoe&id=106&sptid=4",
  // Визначні місця: Грибівка, Одеська область, Україна
  "Grybivka sight": "/city_overview/?name=grybivka&id=3&sptid=4",
  // Визначні місця: Генічеськ, Україна
  "Genichesk sight": "/city_overview/?name=genichesk&id=4&sptid=4",
  // Визначні місця: Бердянськ, Україна
  "Berdyansk sight": "/city_overview/?name=berdyansk&id=19&sptid=4",
  // Визначні місця: Маріуполь, Україна
  "Mariupol sight": "/city_overview/?name=mariupol&id=39&sptid=4",
  // Визначні місця: Буковель Лижний Курорт, Україна
  "Bukovel sight": "/city_overview/?name=bukovel&id=11&sptid=4",
  // Визначні місця: Яремче Курорт, Україна
  "Yaremche sight": "/city_overview/?name=yaremche&id=58&sptid=4",
  // ##### 24-08-23 Komarov
  // nadiya-hotel
  "nadiya-hotel":
    "/hotel_overview/?id=173&city_id=40&hotel_name=nadiya-hotel&hotel_id=173",
  // ##### 24-09-04 Komarov
  // bank-hotel
  "bank-hotel":
    "/hotel_overview/?id=6331&city_id=51&hotel_name=bank-hotel&hotel_id=6331",
  // ##### 24-10-03 Komarov
  // grand-hotel-lviv
  "grand-hotel-lviv":
    "/hotel_overview/?id=188&city_id=51&hotel_name=grand-hotel-lviv&hotel_id=188",
  // grand-hotel-dnipro
  "grand-hotel-dnipro":
    "/hotel_overview/?id=227&city_id=36&hotel_name=grand-hotel-dnipro&hotel_id=227",
  // ##### 24-09-04 Komarov
  // dnipro-kyiv
  "dnipro-kyiv":
    "/hotel_overview/?id=199&city_id=19&hotel_name=dnipro-kyiv&hotel_id=199",
  // ##### 24-09-04 Komarov
  // cityhotel
  cityhotel:
    "/hotel_overview/?id=802&city_id=19&hotel_name=cityhotel&hotel_id=802",
};
