import axios from "../helpers/public.axios";

import {
  GET_HOTEL_CONTENT_ERR,
  GET_HOTEL_CONTENT_REQ,
  GET_HOTEL_CONTENT_RES,
  // ##### 25-02-12 Komarov
  GET_HOTEL_CONTENT_ERR2,
  GET_HOTEL_CONTENT_REQ2,
  GET_HOTEL_CONTENT_RES2,
  // ##### 25-02-15 Komarov
  GET_HOTEL_CONTENT_ERR3,
  GET_HOTEL_CONTENT_REQ3,
  GET_HOTEL_CONTENT_RES3,
  GET_HOTEL_CONTENT_ERR4,
  GET_HOTEL_CONTENT_REQ4,
  GET_HOTEL_CONTENT_RES4,
} from "../constants";
// ##### 25-02-12 Komarov
import { getUrl, getPostObject } from "../../helpers/crypto";
// ##### 25-03-28 Komarov
import { postSavingDataClient } from "../../helpers/saveData";

//action creator
export const hotelContentResponse = (res) => ({
  type: GET_HOTEL_CONTENT_RES,
  payload: res,
});

// ##### 25-03-26 Komarov
// TODO: Проверить и если надо, заменить на получение контента отеля через контроллер Xmlsubj
export const getHotelContent = (id, lang, user_id) => async (dispatch) => {
  // ##### 23-03-06 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/hotelcontent.js:getHotelContent");

  dispatch({ type: GET_HOTEL_CONTENT_REQ });

  const url = getUrl(
    "hotelcontent",
    `id=${id}&language=${lang}&hotelview=1&user_id=${user_id}`,
  );
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/hotelcontent.js:getHotelContent, url:",
      url,
    );
  // ##### 25-03-26 Komarov
  await axios
    .get(url)
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      const filtered_res = await res.data.filter(
        (item, index, array) =>
          array.findIndex((t) => t.content_name === item.content_name) ===
          index,
      );

      dispatch(hotelContentResponse(filtered_res));
      // console.log('[REDUX_HOTEL_CONTENT]:' , res.data)
    })

    .catch((err) => {
      dispatch({ type: GET_HOTEL_CONTENT_ERR, error: err });
    });
};

// ##### 25-02-25 Komarov
export const getHotelContent2 =
  ({
    hotel_id = 0,
    city_id,
    language,
    user_id,
    refpartner,
    sale,
    promocode,
    deviceType,
    company_cipher,
    content_size = 0,
    summary = 0,
  }) =>
  async (dispatch) => {
    // ##### 23-03-06 Komarov
    process.env.NODE_ENV === "development" &&
      console.log("axios:src/Redux/actions/hotelcontent.js:getHotelContent2");

    dispatch({ type: GET_HOTEL_CONTENT_REQ2 });
    const data = {
      sale_id: (sale && sale) || null,
      promocode,
      deviceType,
      company_cipher: company_cipher || "",
      city_id,
      hotel_id,
      content_size,
      summary,
    };

    const postObject = getPostObject({
      action: "GetHotelContentAlphaRQ",
      user_id,
      refpartner,
      language,
      sale: (sale && sale) || null,
      data,
    });

    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/SecondPageofSearchModule/Hotels/HotelItems.js:useEffect, url:",
        postObject.url,
      );

    // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
    await postSavingDataClient({
      postObject,
      typeRes: GET_HOTEL_CONTENT_RES2,
      typeErr: GET_HOTEL_CONTENT_ERR2,
      dispatch,
    });
  };

// ##### 25-02-15 Komarov
export const getHotelContent3 =
  ({
    hotel_id,
    city_id,
    language,
    user_id,
    refpartner,
    sale,
    promocode,
    deviceType,
    company_cipher,
    content_size = 1,
    summary = 0,
  }) =>
  async (dispatch) => {
    // ##### 23-03-06 Komarov
    process.env.NODE_ENV === "development" &&
      console.log("axios:src/Redux/actions/hotelcontent.js:getHotelContent3");

    dispatch({ type: GET_HOTEL_CONTENT_REQ3 });
    const data = {
      sale_id: (sale && sale) || null,
      promocode,
      deviceType,
      company_cipher: company_cipher || "",
      city_id,
      hotel_id: hotel_id || 0,
      content_size,
      summary,
    };

    const postObject = getPostObject({
      action: "GetHotelContentAlphaRQ",
      user_id,
      refpartner,
      language,
      sale: (sale && sale) || null,
      data,
    });

    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/SecondPageofSearchModule/Hotels/HotelItems.js:useEffect, url:",
        postObject.url,
      );

    // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
    await postSavingDataClient({
      postObject,
      typeRes: GET_HOTEL_CONTENT_RES3,
      typeErr: GET_HOTEL_CONTENT_ERR3,
      dispatch,
    });
  };

// ##### 25-02-12 Komarov
export const getHotelContent4 =
  ({
    hotel_id,
    city_id,
    language,
    user_id,
    refpartner,
    sale,
    promocode,
    deviceType,
    company_cipher,
    content_size = 1,
    summary = 1,
  }) =>
  async (dispatch) => {
    // ##### 23-03-06 Komarov
    process.env.NODE_ENV === "development" &&
      console.log("axios:src/Redux/actions/hotelcontent.js:getHotelContent4");

    dispatch({ type: GET_HOTEL_CONTENT_REQ4 });
    const data = {
      sale_id: (sale && sale) || null,
      promocode,
      deviceType,
      company_cipher: company_cipher || "",
      city_id,
      hotel_id: hotel_id || 0,
      content_size,
      summary,
    };

    const postObject = getPostObject({
      action: "GetHotelContentAlphaRQ",
      user_id,
      refpartner,
      language,
      sale: (sale && sale) || null,
      data,
    });

    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/SecondPageofSearchModule/Hotels/HotelItems.js:useEffect, url:",
        postObject.url,
      );

    // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
    await postSavingDataClient({
      postObject,
      typeRes: GET_HOTEL_CONTENT_RES4,
      typeErr: GET_HOTEL_CONTENT_ERR4,
      dispatch,
    });
  };
