// ##### 24-06-14 Komarov
import React, { useContext } from "react";
import { ItemContent } from "../ItemContent/ItemContent";
import { ItemObj } from "../ItemObj/ItemObj";
import { GuestItemContext } from "./GuestItem";

// ##### 23-03-01 Komarov
export const UlTag = ({ ulCLassName, width, divClassName }) => {
  const { filtered, rate, search_data, searchReturnedNoResults } =
    useContext(GuestItemContext);
  return (
    <ul
      data-component="UlTag"
      className={ulCLassName}
      style={{
        width: `${width}px`,
      }}
    >
      <>
        {filtered.length > 0 && filtered ? (
          filtered.map((tour) => {
            return (
              <li key={tour.tour_id} className="descriptionLi">
                {rate ? (
                  rate.map((tariff) => {
                    if (parseInt(tour.tour_id) === parseInt(tariff.tour_id)) {
                      return (
                        <>
                          <h3
                            style={{
                              fontSize: "27px",
                              color: "#001959",
                            }}
                          >
                            {tour.tour_name}
                          </h3>
                          {/* ##### 23-02-22 Komarov */}
                          <div className={divClassName}>
                            <ItemContent tour={tour} />

                            <ItemObj
                              key={parseInt(tariff.tour_id)}
                              tariff={tariff}
                              searchResults={search_data}
                              history={history}
                              tour_name={tour.tour_name}
                              pickedCurrency={search_data.selected_currency}
                            />
                          </div>
                        </>
                      );
                    }
                  })
                ) : (
                  // ##### 24-03-21 Komarov
                  <button type="button" className="onrequestButton">
                    Sold out
                  </button>
                )}
                {/* </div> */}
              </li>
            );
          })
        ) : (
          <div className="noResultSearch">
            {/* ##### 23-09-07 Komarov */}
            {searchReturnedNoResults}
          </div>
        )}
      </>
    </ul>
  );
};
