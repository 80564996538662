// ##### 24-05-16 Komarov
import React from "react";
// ##### 24-05-16 Komarov
import { useDispatch } from "react-redux";
import { AutocompleteDiv } from "./AutocompleteDiv";
// ##### 24-05-16 Komarov
import { saveHotelsContext } from "../../../../../Redux/actions";
// ##### 24-05-22 Komarov
import { useMessages } from "../../../../../helpers/messages";
import { AvailableNowSign } from "../../../../Library/Icons/availableNowSign";
import { OnRequestSign } from "../../../../Library/Icons/onRequestSign";
import parse from "html-react-parser";

// ##### 24-05-22 Komarov: компонент с элементом для отображения условных обозначений точек на иконках в элементах Autocomplete
const HotelsCitiesAvailabilityHint = () => {
  const availableNow = useMessages("Available_Now");
  const onRequest = useMessages("Available_OnRequest");
  const space = parse("&nbsp;");
  return (
    <>
      <div style={{ display: "flex" }}>
        <AvailableNowSign /> {space} {availableNow}
      </div>
      <div style={{ display: "flex" }}>
        <OnRequestSign /> {space} {onRequest}
      </div>
    </>
  );
};

// ##### 24-05-15 Komarov
export const AutocompleteOuterDiv = ({ transform, cityHotelInput }) => {
  // ##### 24-05-16 Komarov
  const dispatch = useDispatch();
  // @@@@@ 22-09-23 Komarov: Функция для обработки клика по элементу Autocomplete: он отображается в увеличенном масштабе, при этом его собственная область клика остаётся оригинального (маленького) размера, и оказывается смещённой влево. А я добавил оборачивающий его div, для которого подобрал высоту и ширину, и по клику по нему в данной функции устанавливаю фокус ввода на Autocomplete, и получается, что нажали как бы на него
  const makeSelection = (e) => {
    dispatch(
      saveHotelsContext({
        cityOrHotelNameOpened: false,
        autocompleteDivOpened: false,
      })
    );
    e.stopPropagation(); // Вызываем эту функцию, чтобы событие не отлавливалось дочерними компонентами, чтобы избежать его срабатвыания два раза подряд: и на div'е, и на целевом компоненте (в данном случае - Autocomplete).
  };
  // ##### 24-05-22 Komarov
  return (
    <>
      <HotelsCitiesAvailabilityHint />
      <div
        data-component="AutocompleteOuterDiv"
        style={{
          transform,
          gridColumn: "1",
          display: "flex",
          // flexDirection: "row-reverse",
          justifyContent: "space-around",
        }}
      >
        <AutocompleteDiv cityHotelInput={cityHotelInput} />
        {/* ##### 24-05-16 Komarov: Кнопка для закрытия div`а с содержимым Autocomplete */}
        <button
          type="button"
          onClick={makeSelection}
          style={{
            // ##### 22-11-07 Komarov
            // minWidth: '90%',
            backgroundColor: "#005AEB",
            color: "white",
            // fontSize: '32px',
            fontFamily: "Arial, Helvetica, sans-serif",
            borderRadius: "1rem",
            // padding: '1vh 33vw,
            // padding: "6.75px 33vw",
            padding: "6.75px 3vw",
            fontWeight: "bold",
          }}
        >
          OK
        </button>
      </div>
    </>
  );
};
