export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const SET_ORDER_ID = "SET_ORDER_ID";
export const SET_ITEM_INFO = "SET_ITEM_INFO";
export const SET_PAYMENT_INFO = "SET_PAYMENT_INFO";
export const GET_PAYMENT_INFO = "GET_PAYMENT_INFO";
export const FETCH_PAYMENT_INFO_REQUEST = "FETCH_PAYMENT_INFO_REQUEST";
export const FETCH_SET_PAYMENT_INFO_SUCCESS = "FETCH_SET_PAYMENT_INFO_SUCCESS";
export const FETCH_SET_PAYMENT_INFO_ERROR = "FETCH_SET_PAYMENT_INFO_ERROR";
export const FETCH_PAYMENT_DATA_SUCCESS = "FETCH_PAYMENT_DATA_SUCCESS";
export const FETCH_PAYMENT_DATA_ERROR = "FETCH_PAYMENT_DATA_ERROR";
// ##### 23-12-22 Komarov
export const FETCH_BOOKING_DATA_SUCCESS = "FETCH_BOOKING_DATA_SUCCESS";
export const FETCH_BOOKING_DATA_ERROR = "FETCH_BOOKING_DATA_ERROR";
export const FETCH_PAYMENT_VOUCHER_SUCCESS = "FETCH_PAYMENT_VOUCHER_SUCCESS";
export const FETCH_PAYMENT_VOUCHER_ERROR = "FETCH_PAYMENT_VOUCHER_ERROR";

export const FETCH_USERS = "FETCH_USERS";
