import { SET_DEVICE_TYPE } from "../constants";

export default (state = "desktop", action) => {
  // ##### 23-03-06 Komarov
  // process.env.NODE_ENV == "development" && console.log('src/Redux/reducers/setDeviceType.js:action', action);
  switch (action.type) {
    case SET_DEVICE_TYPE:
      return action.payload;
    default:
      return state;
  }
};
