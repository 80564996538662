// ##### 24-09-09 Komarov
import React, { useState, useContext, createContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
// ##### 24-02-22 Komarov
import { useHistory, useLocation } from "react-router-dom";

// ##### 23-03-29 Komarov
import { getPickedCurrencyResponse } from "../../../../Redux/actions/currency";
// ##### 23-10-18 Komarov
import { Media } from "../../../../helpers/MediaConfig";
// ##### 24-02-22 Komarov
import { ValidateQuery } from "../../Helpers/helper";

// ##### 23-10-10 Komarov
const CurrencySelect = ({ dropdownStyle = null }) => {
  // ##### 24-05-10 Komarov
  const { path } = useContext(CurrencySelectionToolContext);
  const deviceType = useSelector((state) => state.deviceType);

  // ##### 24-02-22 Komarov
  const location = useLocation();
  const search_data = ValidateQuery(location);

  // ##### 22-08-29 Komarov: Добавил значение валюты по умолчанию, если оно отсутствует в path.selected_currency
  // ##### 24-02-22 Komarov
  const [currency, setCurrency] = useState(
    search_data.selected_currency || path.selected_currency || "UAH"
  );
  const { Option } = Select;
  // ##### 24-02-22 Komarov
  useEffect(() => {
    localStorage.setItem("current_currency", currency);
    // ##### 24-07-30 Komarov
    dispatch(getPickedCurrencyResponse(currency));
  }, [currency]);

  // const pickedCurrency = useSelector(state=>state.currency.pickedCurrency)
  // const currencies = useSelector(state => state.currency.currencies)
  // ##### 24-07-25 Komarov
  const currenciesFromState = useSelector(state => state.currency.currencies);
  const currencies = (currenciesFromState.length > 0 && currenciesFromState)
    || JSON.parse(localStorage.getItem("currency")).currencies;

  // ##### 24-02-22 Komarov
  const dispatch = useDispatch();
  const history = useHistory();

  // ##### 23-02-14 Komarov
  const SelectCurrency = (value) => {
    setCurrency(value);
    // ##### 24-02-21 Komarov
    // history.push(`/${locale}/?selected_currency=${value}`)
    history.push(
      `${location.pathname}${location.search.replace(
        search_data.selected_currency,
        value
      )}`
    );
    // ##### 24-02-22 Komarov
    localStorage.setItem("current_currency", value);
    dispatch(getPickedCurrencyResponse(value));
  };

  return (
    <Select
      defaultValue={currency}
      dropdownStyle={dropdownStyle}
      onChange={SelectCurrency}
      bordered={true}
      // ##### 24-03-21 Komarov
      size={deviceType !== "tablet" ? "small" : "middle"}
    >
      <>
        {currencies?.map((item) => {
          // ##### 22-11-14 Komarov
          return (
            <Option value={item.name} key={item.id}>
              <OptionContent item={item} />
            </Option>
          );
        })}
      </>
    </Select>
  );
};
// ##### 23-10-10 Komarov
const OptionContent = ({ item }) => {
  const { deviceType } = useContext(CurrencySelectionToolContext);
  // ##### 23-10-31 Komarov
  return (
    <>
      <Media lessThan="Laptop-1024-1440">
        {
          // ##### 24-03-21 Komarov
          deviceType === "tablet" ? (
            <span style={{ fontSize: "150%", lineHeight: "175%" }}>
              {item.name}
            </span>
          ) : (
            item.name
          )
        }
      </Media>
      <Media greaterThanOrEqual="Laptop-1024-1440">{item.name}</Media>
    </>
  );
};

const CurrencySelectionToolContext = createContext();

// ##### 23-04-24 Komarov
export const CurrencySelectionTool = ({ path }) => {
  // console.log('[A_PATH]',path)

  // dispatch(getPickedCurrencyResponse(currency))
  // ##### 24-09-11 Komarov
  const currencySelectionToolContextValue = useMemo(() => ({ path }), [path]);

  return (
    <CurrencySelectionToolContext.Provider
      value={currencySelectionToolContextValue}
    >
      <div style={{ marginRight: "1vw" }}>
        {/* ##### 23-11-02 Komarov */}
        <Media lessThan="Mobile-L-425-768">
          <CurrencySelect
            dropdownStyle={{
              scale: "none",
              transformOrigin: "top left",
            }}
          />
        </Media>
        <Media between={["Mobile-L-425-768", "Laptop-1024-1440"]}>
          <CurrencySelect
            dropdownStyle={{
              scale: "1.1",
              transformOrigin: "top left",
            }}
          />
        </Media>
        <Media greaterThanOrEqual="Laptop-1024-1440">
          <CurrencySelect />
        </Media>
      </div>
    </CurrencySelectionToolContext.Provider>
  );
};
