// ##### 24-06-13 Komarov
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
// ##### 23-03-29 Komarov
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// ##### 23-12-18 Komarov
import { ConfigProvider } from "antd";
import en from "antd/es/locale/en_US";
import uk from "antd/es/locale/uk_UA";
import tr from "antd/es/locale/tr_TR";
import fr from "antd/es/locale/fr_FR";
import ru from "antd/es/locale/ru_RU";
import dateFormats from "../../../../../helpers/dateFormats";
import Autocomplete from "react-autocomplete";
import { DatePicker, Space } from "antd";
import { HotelsPaxChoice } from "../HotelsPaxChoice/HotelsPaxChoice";
import { getPages } from "../../../../../Redux/actions/pages";
import { changeLang } from "../../../../../Redux/actions/locale";
import { PromoCode } from "../PromoCode";

import {
  getHotels,
  getGeneralHotels,
  getTranslatedHotels,
} from "../../../../../Redux/actions/hotels";
import { PlaceHolderStrings } from "../../../../Library/Localization/placeholders";
import { ClearInput } from "../../../../Library/PageDevices/ClearInput";
// ##### 23-12-11 Komarov
import { Alert } from "antd";
// ##### 24-04-05 Komarov
import { makeArray } from "../../../../../../server/helpers/isArrayObject";
import getIcon from "../helpers/getIcon";
import config from "../../../../../Redux/config";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../../helpers/messages";
// ##### 23-11-02 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
// ##### 24-02-02 Komarov
import OutsideClickHandler from "react-outside-click-handler";
// ##### 24-04-05 Komarov
import uniqueId from "lodash.uniqueid";
import { setItemInfo } from "../../../../../Redux/actions/order";
// ##### 24-04-17 Komarov
import { makeParamsString } from "../../../Helpers/helper";
// ##### 24-05-22 Komarov
import { HotelsCitiesAvailabilityHint } from "./HotelsCitiesAvailabilityHint";

moment.locale(config.defaultLang);
// moment.locale('uk')
// ##### 23-12-18 Komarov
const dateLocales = {
  en,
  uk,
  tr,
  fr,
  ru,
};

// ##### 23-11-10 Komarov
export const HotelsAutocomplete = ({
  formClass,
  datepickerClass,
  // ##### 24-09-10 Komarov
  // onSubmit,
  props,
  // GeneralListFunction,
  searchProps,
}) => {
  // ##### 24-05-21 Komarov
  const hotelsCitiesAvailabilityHintRef = useRef();

  // ##### 23-10-05 Komarov: Устанавливаем положение календарика с выбором даты в зависимости от положения прокрутки окна
  const [datePickerPlacement, setDatePickerPlacement] = useState("topLeft");

  // ##### 24-06-13 Komarov: Управление положением календарика на экране
  useEffect(() => {
    if (window.scrollY > 250) {
      setDatePickerPlacement("bottomLeft");
    } else setDatePickerPlacement("topLeft");
  // ##### 24-07-25 Komarov
  }, []);

  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  // console.log('REGEX',lettersOnlyString(searchProps.title))

  const [stayDates, setStayDates] = useState(
    searchProps ? [searchProps.start, searchProps.end] : []
  );
  const [pickedStartDate, setPickedStartDate] = useState(
    searchProps ? true : false
  );
  const [pickedEndDate, setPickedEndDate] = useState(
    searchProps ? true : false
  );
  const [list, setList] = useState([]);
  // ##### 24-04-29 Komarov
  const [hotelsvalue, setHotelsValue] = useState(
    searchProps ? decodeURIComponent(searchProps.title) : ""
  );
  // ##### 24-04-05 Komarov
  const selectedItemInfoInitialState = undefined;
  const [selectedItemInfo, setSelectedItemInfo] = useState(
    selectedItemInfoInitialState
  );
  const [open, setOpen] = useState(false);

  // ##### 24-05-21 Komarov
  useEffect(() => {
    open &&
      hotelsCitiesAvailabilityHintRef.current &&
      hotelsCitiesAvailabilityHintRef.current.classList.add("show");
    !open &&
      hotelsCitiesAvailabilityHintRef.current &&
      hotelsCitiesAvailabilityHintRef.current.classList.remove("show");
  }, [open]);
  const [paxListOpen, setPaxListOpen] = useState(false);
  const [crossVisible, setCrossVisible] = useState(false);
  const [pickedHotelValue, setPickedHotelValue] = useState(
    searchProps ? true : false
  );
  // ##### 24-02-02 Komarov
  const [autocompleteFocused, setAutocompleteFocused] = useState(false);

  const history = useHistory();

  // ##### 24-02-22 Komarov
  const pickedcurrencyFromState = useSelector(
    (state) => state.currency.pickedcurrency
  );
  // ##### 24-07-25 Komarov
  const pickedcurrency = pickedcurrencyFromState || JSON.parse(localStorage.getItem("currency")).pickedcurrency;

  const dispatch = useDispatch();
  // ##### 23-04-12 Komarov
  const hotelsTransFromState = useSelector((state) => state.hotels.hotelstrans);
  const generalHotelsFromState = useSelector(
    (state) => state.hotels.general_hotels
  );

  // ##### 24-07-25 Komarov
  const smart_translated_hotels = makeArray(hotelsTransFromState[locale]).sort((a, b) => a.city - b.city);
  // const general_smart_hotels = useSelector(state => state.hotels.general_hotels)
  // ##### 24-07-25 Komarov
  const general_smart_hotels = makeArray(generalHotelsFromState);
  // ##### 24-09-10 Komarov
  // const totalPaxRedux = useSelector((state) => state.paxchoice.pax);
  const totalPaxReduxAdults = useSelector((state) => state.paxchoice.pax.adults);
  const totalPaxReduxChildren = useSelector((state) => state.paxchoice.pax.children);
  const totalPaxReduxRooms = useSelector((state) => state.paxchoice.pax.rooms);
  const promoCode = useSelector((state) => state.promocode.promocode);
  // console.log('TOTALPAXREDUX',totalPaxRedux)

  useEffect(() => {
    dispatch(changeLang());
    // ##### 23-04-12 Komarov
  }, [dispatch]);

  // ##### 23-10-10 Komarov
  // const pagesAllLanguages = useSelector(state => state.pages.pagesAllLanguages);
  // ##### 24-04-23 Komarov
  const pagesFromLocalStorage = useMessages();

  useEffect(
    () => {
      if (Object.values(pagesFromLocalStorage).length > 0) {
        return;
      }
      // ##### 23-04-17 Komarov
      console.log(
        "axios:src/Components/Pages/FirstPageofSearchModule/HotelsAutocompleteBlock/HotelsAutocomplete.js:getPages(lang)"
      );
      //  dispatch (getPages (lang));
      // ##### 23-12-27 Komarov
      dispatch(getPages(locale));
    },
    //  ##### 23-04-17 Komarov
    //  [lang, pagesFromLocalStorage, dispatch]
    //  [contextLanguage, pagesFromLocalStorage, dispatch]
    // ##### 24-01-05 Komarov
    [locale, dispatch, pagesFromLocalStorage]
  );

  const placeholder = PlaceHolderStrings();

  // console.log('TESTING', name.sort((a,b)=>a.city-b.city))

  // console.log('PLACEHOLDER',placeholder)

  // console.log('TOTALPAX', totalPaxRedux)

  const { RangePicker } = DatePicker;

  // ##### 24-07-25 Komarov
  const hotels = useSelector((state) => state.hotels.hotels);

  useEffect(() => {
    // ##### 24-07-25 Komarov
    // dispatch (getHotels ());
    if (hotels.length > 0) {
      return;
    }
    // ##### 24-02-02 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/FirstPageofSearchModule/HotelsAutocompleteBlock/HotelsAutocomplete.js:getHotels()"
      );
    dispatch(getHotels());
    // ##### 23-04-12 Komarov
  }, [dispatch, hotels.length]);
  // console.log('[SMART_HOTELS] : ' , smart_hotels)

  // ##### 24-07-25 Komarov
  const hotelstrans = useSelector((state) => state.hotels.hotelstrans);
  // ##### 24-04-05 Komarov
  useEffect(() => {
    // ##### 24-07-25 Komarov
    if (hotelstrans.length > 0) {
      return;
    }
    // ##### 24-02-02 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/FirstPageofSearchModule/HotelsAutocompleteBlock/HotelsAutocomplete.js:getTranslatedHotels(locale)"
      );
    // ##### 24-04-05 Komarov
    // dispatch(getTranslatedHotels(locale, hotels));
    dispatch(getTranslatedHotels(hotels));
    // ##### 24-07-25 Komarov
  }, [hotels, dispatch, hotelstrans.length]);

  const general_hotels = useSelector(state => state.hotels.general_hotels);

  useEffect(() => {
    // ##### 24-07-25 Komarov
    //  dispatch (getGeneralHotels ());
    if (general_hotels.length > 0) {
      return;
    }
    // ##### 24-02-02 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/FirstPageofSearchModule/HotelsAutocompleteBlock/HotelsAutocomplete.js:getGeneralHotels()"
      );
    dispatch(getGeneralHotels());
    // ##### 24-07-25 Komarov
  }, [dispatch, general_hotels.length]);
  // console.log('[SMART_GENERAL_HOTELS] : ' , general_smart_hotels)

  // ##### 24-03-21 Komarov
  function onChange(_dates, dateStrings) {
    // ##### 24-05-27 Komarov
    if (dateStrings[0] && dateStrings[1]) {
      setStayDates(dateStrings);
      setPickedStartDate(true);
      setPickedEndDate(true);
      return;
    }
    setSearchClicked(false);
    setPickedStartDate(false);
    setPickedEndDate(false);
  }

  const optionChecker = (e) => {
    // console.log('[VALUE] : ', e.target.value)

    setHotelsValue(e.target.value);
    // ##### 23-04-12 Komarov
    if (hotelsvalue.length > 0) {
      setOpen(true);
    }
  };

  // ##### 24-04-05 Komarov
  useEffect(() => {
    dispatch(setItemInfo(selectedItemInfo));
  }, [selectedItemInfo, dispatch]);

  // ##### 24-03-21 Komarov
  // const dateFormat = 'YYYY-MM-DD';

  ////FOR DATEPICKER (RANGEDATEPICKER) TO DISABLE CHOICE OF DATES THAT ARE BEFORE TODAY

  const disabledDate = (current) => {
    // Can not select days before today
    // return current < moment().endOf('day');
    // console.log('CURRENT',current)
    return current < moment().subtract(1, "days");
  };
  // ##### 24-04-23 Komarov
  const chooseYourHotel =
    useMessages("ChooseYourHotel") || "Please choose your hotel";
  const pickUpYourDatesOfStay =
    useMessages("PickUpYourDatesOfStay") || "Please pick up your dates of stay";
  // ##### 23-10-04 Komarov
  const Search = useMessages("Search");

  // ##### 23-12-11 Komarov
  const [searchClicked, setSearchClicked] = useState(false);
  const [formFilled, setFormFilled] = useState(false);

  useEffect(() => {
    setFormFilled(
      pickedHotelValue !== false &&
      hotelsvalue !== "" &&
      pickedStartDate !== false &&
      pickedEndDate !== false
    );
  }, [pickedHotelValue, hotelsvalue, pickedStartDate, pickedEndDate]);
  // ##### 24-03-01 Komarov
  const whitelabel = useSelector((state) => state.whitelabel);
  const isWhiteLabelSet = whitelabel.set;
  const company_cipher = whitelabel.company_cipher;

  // ##### 24-04-11 Komarov
  const filteredHotels = smart_translated_hotels.filter(
    (item) => item.name === hotelsvalue
  );

  const filtered_hotelcity_id =
    general_smart_hotels.filter((item) => {
      if (parseInt(item.hotel_id) === parseInt(filteredHotels[0]?.id)) {
        return parseInt(item.city_id);
      }
      return parseInt(item.city_id) === parseInt(filteredHotels[0]?.id);
    })[0]?.city_id ||
    selectedItemInfo?.city_id ||
    localStorage.getItem("city_id");

  // console.log('FILTERED_HOTEL_ID', filtered_hotelcity_id )

  const addToList = () => {
    // ##### 23-12-11 Komarov
    setSearchClicked(true);

    if (!formFilled) {
      return;
    }

    // ##### 24-06-06 Komarov
    // dispatch(setHotelsRatesContentLoaded(false));

    // ##### 24-04-11 Komarov
    const hotelNewList = {
      id: filteredHotels[0].id,
      title: hotelsvalue,
      date: stayDates,
    };

    setList([...list, hotelNewList]);

    const params_string = makeParamsString({
      selected_currency:
        localStorage.getItem("current_currency") || pickedcurrency,
      title: hotelsvalue,
      start:
        (moment(stayDates[0], dateFormats[locale]).isValid() &&
          moment(stayDates[0], dateFormats[locale]).format(dateFormats.en)) ||
        moment(stayDates[0], dateFormats.en).format(dateFormats.en),
      end:
        (moment(stayDates[1], dateFormats[locale]).isValid() &&
          moment(stayDates[1], dateFormats[locale]).format(dateFormats.en)) ||
        moment(stayDates[1], dateFormats.en).format(dateFormats.en),
      id: filteredHotels[0].id,
      city_id: filtered_hotelcity_id,
      adults: totalPaxReduxAdults,
      children: totalPaxReduxChildren,
      rooms: totalPaxReduxRooms,
    });

    // ##### 24-04-05 Komarov
    localStorage.setItem("selectedItemInfo", JSON.stringify(selectedItemInfo));
    // ##### 24-02-29 Komarov: TODO: Убрать промокод из URL
    if (isWhiteLabelSet) {
      const route_query = `?${params_string}`;

      history.push(
        `/${locale}/ref${company_cipher}/search_results_hotels/${route_query}`,
        [...list, hotelNewList]
      );
    } else if (promoCode) {
      const route_query = `?${params_string}`;

      history.push(`/${locale}/search_results_hotels/${route_query}`, [
        ...list,
        hotelNewList,
      ]);
    } else {
      const route_query = `?${params_string}`;

      history.push(`/${locale}/search_results_hotels/${route_query}`, [
        ...list,
        hotelNewList,
      ]);
    }
  };

  // +++++
  const MakeVisible = (value) => {
    value === false || value === true
      ? setPaxListOpen(value)
      : setPaxListOpen(!paxListOpen);
  };

  const VisibleCross = () => {
    if (hotelsvalue !== "") {
      setCrossVisible(true);
    }
  };

  const NotVisibleCross = () => {
    setCrossVisible(false);
  };

  const clearInput = () => {
    // ##### 24-04-11 Komarov
    localStorage.removeItem("city_id");
    setHotelsValue("");
    // ##### 24-04-05 Komarov
    setSelectedItemInfo(selectedItemInfoInitialState);
    // ##### 24-05-27 Komarov
    setSearchClicked(false);
  };

  // ##### 24-02-02 Komarov
  const autocomplete = useRef();

  useEffect(() => {
    if (autocompleteFocused) {
      autocomplete.current.focus();
    }
  }, [autocompleteFocused]);

  return (
    <div data-component="HotelsAutocomplete">
      <form 
        className={formClass} 
        // ##### 24-09-10 Komarov
        // onSubmit={onSubmit}
        onSubmit={e => e.preventDefault()}
      >
        {/* <div style={{width:`${width*0.8/4}px`}}> */}
        {/* ##### 23-12-11 Komarov */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <OutsideClickHandler
            onOutsideClick={() => {
              setAutocompleteFocused(false);
              if (hotelsvalue === "") {
                setPickedHotelValue(false);
              }
            }}
          >
            <div
              style={{
                gridColumn: "1",
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "space-around",
              }}
              onMouseEnter={VisibleCross}
              onMouseLeave={NotVisibleCross}
              // ##### 24-02-02 Komarov
              onClick={() => setAutocompleteFocused(true)}
              onKeyDown={(event) => {
                if (event.key === "Enter" || event.key === " ") {
                  setAutocompleteFocused(true);
                }
              }}
              id="HotelsAutocomplete"
            >
              {(autocompleteFocused || pickedHotelValue) && (
                <ClearInput
                  makevisible={crossVisible}
                  clearInput={clearInput}
                />
              )}

              {((autocompleteFocused || pickedHotelValue) && (
                <Autocomplete
                  {...props}
                  ref={autocomplete}
                  value={hotelsvalue}
                  inputProps={{
                    style: {
                      // width: `${width*0.8/4}px`,
                      height: "45px",
                      // ##### 23-03-29 Komarov
                      // fontFamily: 'Tahoma',
                      fontFamily: "Arial",
                      fontSize: "16px",
                      border: "none",
                      marginTop: "0.2vw",
                      textAlign: "center",
                      position: "relative",
                    },

                    // placeholder: placeholder.placeHolderString,
                  }}
                  menuStyle={{
                    fontFamily: "Arial Narrow",
                    fontWeight: "bold",
                    fontSize: "18px",
                    color: "darkslategrey",
                    borderRadius: "3px",
                    boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                    background: "rgba(255, 255, 255)",
                    padding: "3px",
                    // ##### 24-06-28 Komarov
                    // overflow: "scroll",
                    overflowY: "auto",
                    maxHeight: "30vh",
                    zIndex: "2000",
                    border: "2px solid grey",
                    position: "absolute",
                    display: "block",
                    // left:'160px',
                    top: "609",
                    // left:'20%',
                    // top:'50%'
                  }}
                  items={smart_translated_hotels}
                  // items={smart_hotels}

                  getItemValue={(item) => item.name}
                  onChange={optionChecker}
                  // ##### 24-04-11 Komarov
                  // onSelect={(value, item) => setHotelsValue(value) + setOpen(false) + setPickedHotelValue(true) + setSelectedItemInfo(item)}
                  onSelect={(value, item) => {
                    localStorage.setItem("city_id", item.city_id);
                    setHotelsValue(value);
                    setOpen(false);
                    setPickedHotelValue(true);
                    setSelectedItemInfo(item);
                  }}
                  renderItem={(item, highlighted) => (
                    <div
                      // ##### 24-04-05 Komarov
                      key={uniqueId()}
                      style={{
                        backgroundColor: highlighted
                          ? "lightblue"
                          : "transparent",
                      }}
                    >
                      {getIcon(item)} {item.name}{" "}
                      {item.city === 0 ? " city" : null}
                    </div>
                  )}
                  shouldItemRender={(item, value) =>
                    value !== ""
                      ? item.name.toLowerCase().includes(value.toLowerCase())
                      : null
                  }
                  open={open}
                  // ##### 24-03-21 Komarov
                  onMenuVisibilityChange={() => setOpen(false)}
                />
              )) || (
                  <div
                    style={{
                      height: "45px",
                      fontFamily: "Tahoma",
                      fontSize: "14px",
                      color: "rgb(75, 73, 73)",
                      border: "none",
                      marginTop: "0.2vw",
                      textAlign: "center",
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {placeholder.placeHolderString}
                  </div>
                )}
            </div>
          </OutsideClickHandler>
          {/* ##### 23-12-11 Komarov */}
          {searchClicked &&
            (pickedHotelValue === false || hotelsvalue === "") && (
              <Alert message={chooseYourHotel} type="error" />
            )}
        </div>

        {/* ##### 23-12-11 Komarov */}
        <div
          style={{
            gridColumn: "2",
            borderLeft: "0.5px solid grey",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
            // ##### 24-02-02 Komarov
            id="RangePicker"
          >
            {/* ##### 24-01-03 Komarov */}
            <Space direction="horizontal">
              {/* ##### 23-12-18 Komarov */}
              <ConfigProvider locale={dateLocales[locale]}>
                <RangePicker
                  size={"middle"}
                  disabledDate={disabledDate}
                  allowClear={true}
                  onChange={onChange}
                  /* defaultValue={[
                      moment(searchProps.start, dateFormats[locale]),
                      moment(searchProps.end, dateFormats[locale]),
                    ]} */
                  defaultValue={
                    searchProps && [
                      moment(searchProps.start, dateFormats.en),
                      moment(searchProps.end, dateFormats.en),
                    ]
                  }
                  format={dateFormats[locale]}
                  bordered={false}
                  className={datepickerClass}
                  placeholder={[
                    placeholder.placeHolderStartDate,
                    placeholder.placeHolderEndDate,
                  ]}
                  allowEmpty={searchProps && [false, false]}
                  placement={datePickerPlacement}
                />
              </ConfigProvider>
            </Space>
          </div>
          {/* ##### 23-12-11 Komarov */}
          {searchClicked &&
            pickedStartDate === false &&
            pickedEndDate === false && (
              <Alert message={pickUpYourDatesOfStay} type="error" />
            )}
        </div>

        {/* ##### 23-11-02 Komarov */}
        <Media
          at="Tablet-768-1024"
          style={{
            gridColumn: "3",
            borderLeft: "0.5px solid grey",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <HotelsPaxChoice
            MakeVisible={MakeVisible}
            paxListOpen={paxListOpen}
            searchProps={searchProps}
            pages={useMessages}
          />
        </Media>
        <Media
          lessThan="Tablet-768-1024"
          style={{
            gridColumn: "3",
            borderLeft: "0.5px solid grey",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <HotelsPaxChoice
            MakeVisible={MakeVisible}
            paxListOpen={paxListOpen}
            searchProps={searchProps}
            pages={useMessages}
          />
        </Media>
        <Media
          greaterThan="Tablet-768-1024"
          style={{
            gridColumn: "3",
            borderLeft: "0.5px solid grey",
            display: "flex",
            alignItems: "center",
            // ##### 24-01-30 Komarov
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <HotelsPaxChoice
            MakeVisible={MakeVisible}
            paxListOpen={paxListOpen}
            searchProps={searchProps}
            pages={useMessages}
          />
        </Media>

        <div
          style={{
            gridColumn: "4",
            borderLeft: "0.5px solid grey",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // ##### 23-05-08 Komarov
            position: "relative",
          }}
        >
          {/* ##### 24-08-05 Komarov */}
          {/* TODO: Исправить код в соответствии с разницей понятий refpartner и promoCode */}
          <PromoCode /* refpartner={searchProps ? searchProps.refpartner : ""} */ />
        </div>

        {/* <div className='borderInnerWrapper2' style={{width:`${width*0.8/4}px`}}> */}
        <button style={{ gridColumn: "5" }} type="submit" onClick={addToList}>
          {
            // ##### 23-10-04 Komarov
            Search
          }
        </button>

        {/* </div> */}
      </form>
      {/* ##### 24-05-21 Komarov */}
      <HotelsCitiesAvailabilityHint
        hotelsCitiesAvailabilityHintRef={hotelsCitiesAvailabilityHintRef}
      />
    </div>
  );
};
