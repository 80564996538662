import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import reducer from "../reducers";
import { loggerMiddleware } from "./middlewares/logger";
import { promise } from "./middlewares/promise";

const composeEnhancers =
  process.env.NODE_ENV !== "production" &&
  typeof window !== "undefined" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

// ##### 23-02-21 Komarov
const middlewares = applyMiddleware(
  loggerMiddleware,
  thunk,
  promise,
);

const store = createStore(
  reducer,
  window.INITIAL_STATE ? window.INITIAL_STATE : {},
  composeEnhancers(middlewares),
);

export default store;
