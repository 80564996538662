// ##### 24-12-26 Komarov
import { decryptString } from "../../../helpers/crypto";

const getSplittingSymbol = (stringToSplit) => {
  if (stringToSplit.includes("&")) return "&";
  return ",";
};

// ##### 24-12-26 Komarov: По сути убираем знак вопроса перед строкой параметров (если он есть в URL) и возвращаем строку без знака вопроса
// ##### 25-01-17 Komarov
const getUrlParamsString = (location, isEncrypted, key) => {
  let locationString;
  if (typeof location === "string" && !isEncrypted)
    locationString = location.replace("?", "");
  else if (isEncrypted && typeof location.indexOf === "function")
    locationString = location.slice(location.indexOf("?") + 1);
  else locationString = location.search.replace("?", "");
  // ##### 24-12-26 Komarov: Если установлен параметр "шифровать", то прежде расшифровываем параметры URL
  // ##### 25-01-17 Komarov
  let decryptedLocationString =
    (isEncrypted && decryptString(locationString, key)) || locationString;
  return decryptedLocationString.split(
    getSplittingSymbol(decryptedLocationString),
  );
};

// ##### 24-12-27 Komarov
const makeParamsObject = (urlParamsString) => {
  const result = {};
  if (typeof urlParamsString.map === "function") {
    urlParamsString.map((item) => {
      const name = item.match(/\w+(?==)/);
      // ##### 24-09-23 Komarov
      const value = item.match(/=.+/);

      // ##### 23-04-05 Komarov: поставил учёт случаев, когда name оказывается null, когда отсутствует location
      if (name && value) {
        result[name[0]] = decodeURI(value[0].replace("=", ""));
      }
    });
  }
  return result;
};

// ##### 25-01-17 Komarov
export function ValidateQuery(location, isEncrypted = false, key = undefined) {
  // ##### 25-01-17 Komarov
  return makeParamsObject(getUrlParamsString(location, isEncrypted, key));
}

// ##### 24-04-17 Komarov
export function makeParamsString(paramsObject) {
  if (typeof paramsObject === "string") return paramsObject;
  let resultWithAmpersand = "";
  for (const key in paramsObject) {
    if (paramsObject[key] === "") {
      process.env.NODE_ENV === "development" &&
        console.warn(`Value of ${key} is empty`);
      continue;
    }
    if (paramsObject[key] === "Not set") {
      process.env.NODE_ENV === "development" &&
        console.warn(`Value of ${key} is not set`);
      continue;
    }
    resultWithAmpersand += `${key}=${encodeURIComponent(paramsObject[key])}&`;
  }
  return resultWithAmpersand.slice(0, -1);
}
