// ##### 24-09-13 Komarov
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
// ##### 25-03-26 Komarov
import { useDispatch, useSelector } from "react-redux";
import { ValidateQuery } from "../../Pages/Helpers/helper";
// ##### 25-03-26 Komarov
import { getPurePageSsr } from "../../../Redux/actions";
// ##### 23-09-14 Komarov

export const SeoMetaDescription = () => {
  const browserUrl = `${location.pathname}${location.search}`.substring(3);
  const shouldCypherUrlParams = useSelector(
    (state) => !!parseInt(state.siteCypherCodeData.data.value),
  );
  // ##### 25-02-28 Komarov
  const cypherKey = useSelector(
    (state) => state.siteCypherCodeData.data.description,
  );
  const urlToObject = ValidateQuery(location, shouldCypherUrlParams, cypherKey);
  const page_id_from_url = urlToObject.id ? urlToObject.id : 209;
  const dispatch = useDispatch();
  const purePageSsrLoaded = useSelector(
    (state) => state.pages.purepages_loaded[page_id_from_url],
  );
  const purePageSsrDefaultLoaded = useSelector(
    (state) => state.pages.purepages_loaded[305],
  );
  const deviceType = useSelector((state) => state.deviceType);
  const language = useSelector((state) => state.language);
  useEffect(() => {
    !!!purePageSsrLoaded &&
      dispatch(getPurePageSsr(page_id_from_url, deviceType, language));
  }, [purePageSsrLoaded]);
  useEffect(() => {
    !!!purePageSsrDefaultLoaded &&
      dispatch(getPurePageSsr(305, deviceType, language));
  }, [purePageSsrDefaultLoaded]);
  // ##### 25-03-21 Komarov
  const purePageSsr =
    useSelector((state) => state.pages.purepages[page_id_from_url]) ||
    window.INITIAL_STATE.pages.purepages[page_id_from_url];
  // ##### 24-08-22 Komarov
  const purePageSsrDefault =
    useSelector((state) => state.pages.purepages[305]) ||
    window.INITIAL_STATE.pages.purepages[305];
  // ##### 24-08-20 Komarov
  const purepage = useSelector((state) => state.pages.purepage);
  // ##### 24-08-21 Komarov
  const hotelcontent = useSelector((state) => state.hotelcontent.hotelcontent);
  // ##### 24-08-23 Komarov
  const currentPurePage =
    ((browserUrl.includes("hotel_details") ||
      browserUrl.includes("hotel_overview")) &&
      hotelcontent) ||
    ((!urlToObject.id ||
      (browserUrl.includes("sitepages") &&
        ["id=2", "id=210"].includes(`id=${urlToObject.id}`))) &&
      purePageSsr) ||
    (purepage.length > 0 && purepage) ||
    purePageSsrDefault;
  // ##### 24-09-13 Komarov
  const metaDescriptionObject =
    currentPurePage.length > 0 &&
    currentPurePage.find((item) => item.content_name === "Meta-Description");
  const metaDescriptionText =
    metaDescriptionObject?.text ||
    purePageSsrDefault.find((item) => item.content_name === "Meta-Description")
      .text;

  // Удаляем теги meta, которые пришли с сервера (потому что на клиенте они не заменяются, а к ним добавляются ещё и клиентские meta-теги)
  const metaTags = document.getElementsByTagName("meta");
  // ##### 24-03-21 Komarov
  const clearMetaTags = (metaTags) => {
    for (let i = 0; i < metaTags.length; i++) {
      const metaTag = metaTags[i];
      const name = metaTag.getAttribute("name");

      if (!metaTag.hasAttribute("data-rh") && name === "description") {
        // Удаляем тег meta без атрибута data-rh
        metaTag.remove();
      }
    }
  };
  // ##### 24-03-21 Komarov
  useEffect(() => {
    // Проходим по всем тегам meta и удаляем теги без атрибута data-rh
    clearMetaTags(metaTags);
  }, [clearMetaTags, metaTags]);

  return (
    <Helmet>
      {/* ##### 24-09-13 Komarov */}
      <meta name="description" content={metaDescriptionText} />
    </Helmet>
  );
};
