import { SET_LANGUAGE } from "../constants";

// ##### 23-03-28 Komarov: Функция для добавления в Redux store поля language
export default (state = "none", action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
};
