// ##### 25-02-15 Komarov
import React, { useState } from "react";
import { getDomain } from "../../../Helpers/urls";
import { CupOfCoffee } from "../../../../Library/PageDevices/Animation/CupOfCoffee";

function ImageComponent({ src, alt, width, height, hotel_id }) {
  const [imageSrc, setImageSrc] = useState(src);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const currentWebProtocol = process.env.REACT_APP_PROTOCOL || "https://";
  const handleImageError = () => {
    setImageError(true);
    // Set a fallback image source:
    console.warn("There's no main image for hotel with id", hotel_id);
    setImageSrc(
      `${currentWebProtocol}/${getDomain(process.env.REACT_APP_SMART_URL)}/images_symlink/hotel/0/Hotel_when_no_image.jpeg`,
    );
  };

  return (
    <div>
      {!imageLoaded && !imageError && (
        <div className="placeholder">
          <CupOfCoffee />
        </div>
      )}{" "}
      {/* Placeholder */}
      {imageError ? (
        <img
          src={imageSrc}
          alt="Fallback Hotel Image"
          style={{
            width,
            height,
            borderRadius: "5px",
          }}
        />
      ) : (
        <img
          src={imageSrc}
          alt={alt}
          onLoad={handleImageLoad}
          onError={handleImageError}
          style={{
            width,
            height,
            borderRadius: "5px",
            display: imageLoaded ? "block" : "none", // Hide until loaded
          }}
        />
      )}
    </div>
  );
}

export default ImageComponent;
