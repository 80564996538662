// ##### 25-02-05 Komarov
import { Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

// ##### 25-03-26 Komarov
import {
  getLangResponse,
  getPages,
  getPurePageSsr,
} from "../../../../Redux/actions";
// ##### 25-01-22 Komarov
import { setLanguage } from "../../../../Redux/actions";
// ##### 24-08-23 Komarov
import { getHotelContent, getPurePage } from "../../../../Redux/actions";
import { ValidateQuery } from "../../../Pages/Helpers/helper";
// ##### 25-03-20 Komarov
import config from "../../../../Redux/config";
// ##### 23-11-02 Komarov

// ##### 23-10-10 Komarov
export const LangSelect = ({ dropdownStyle }) => {
  // ##### 24-05-10 Komarov
  const deviceType = useSelector((state) => state.deviceType);
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  const location = useLocation();

  // ##### 25-03-20 Komarov
  const { supportedLangs } = config;
  const history = useHistory();
  const { Option } = Select;

  const currentUrl = useSelector((state) => state.currentUrl);

  // ##### 25-01-15 Komarov
  // const search_data = ValidateQuery(location);
  const shouldCypherUrlParams = useSelector(
    (state) => !!parseInt(state.siteCypherCodeData.data.value),
  );
  // ##### 25-02-28 Komarov
  const cypherKey = useSelector(
    (state) => state.siteCypherCodeData.data.description,
  );
  const search_data = ValidateQuery(location, shouldCypherUrlParams, cypherKey);
  // ##### 24-09-16 Komarov
  const { user: currentUser } = useSelector((state) => state.auth);
  const { user_id } = currentUser;

  const dispatch = useDispatch();

  // ##### 25-03-26 Komarov
  const selectLanguage = (value) => {
    history.push(`/${value + currentUrl}`);
    // ##### 24-08-23 Komarov
    if (search_data.id) {
      if (currentUrl.includes("city_overview")) {
        dispatch(
          getPurePage({
            id: search_data.id,
            lang: value,
            deviceType,
            pageType: "sitepagecitycontent",
            user_id,
          }),
        );
      } else if (currentUrl.includes("hotel_overview")) {
        // ##### 25-02-25 Komarov
        dispatch(getHotelContent(search_data.hotel_id, value, user_id));
      }
    }
    // ##### 25-03-26 Komarov
    dispatch(getPages(value));
    dispatch(getPurePageSsr(search_data.id || 209, deviceType, value));
    dispatch(getPurePageSsr(210, deviceType, value));
    // ##### 23-12-28 Komarov: Вроде, запрос getPurePageSsr здесь не нужен
    // search_data.id && dispatch(getPurePageSsr(value, deviceType, [search_data.id]));
    // ##### 23-04-07 Komarov
    // Устанавливаем выбранный язык в Redux, в объект locale
    dispatch(getLangResponse(value));
    // ##### 23-05-04 Komarov
    // Устанавливаем выбранный язык в Redux, в поле language
    dispatch(setLanguage(value));
  };

  return (
    <Select
      // ##### 24-07-24 Komarov: использование value позволяет изменить выбранное значение языка при смене locale (defaultValue не позволяло)
      // defaultValue={locale}
      value={locale}
      // ##### 22-11-14 Komarov
      dropdownStyle={dropdownStyle}
      // ##### 25-03-26 Komarov
      onChange={selectLanguage}
      bordered={true}
      // ##### 23-03-24 Komarov
      // size="small"
      // ##### 24-08-16 Komarov
      size={deviceType === "tablet" ? "middle" : "small"}
    >
      <>
        {supportedLangs?.map((item) => (
          <Option value={item} key={item}>
            {/* ##### 22-11-10 Komarov */}
            {item}
          </Option>
        ))}
      </>
    </Select>
  );
};
