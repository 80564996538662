// ##### 23-06-22 Komarov
import React from "react";

import coffeeGif from "./caffeine-rush-coffee-1.gif";
// ##### 23-09-14 Komarov
// import "./CupOfCoffee.css";

export const CupOfCoffee = () => {
  return (
    <div className="pre-loader" data-component="CupOfCoffee">
      {/* <h1>Animated GIF Example</h1> */}
      <img src={coffeeGif} alt="Animated GIF" />
    </div>
  );
};
