import { SET_WHITELABEL } from "../constants";
import axios from "axios";
import config from "../config";

// ##### 24-08-05 Komarov
export const setWhitelabel = ({company_cipher, promocode}) => (dispatch) => {
  if (company_cipher) {
    // ##### 24-02-26 Komarov: Делаем запрос к Смарту на данные для WhiteLabel
    // ##### 24-02-28 Komarov: TODO: Какой user_id указывать при обращении к Смарт за данными для режима White Label?
    // ##### 24-03-21 Komarov
    const ActionRQ = {
      username: process.env.REACT_APP_SMART_USERNAME,
      password: process.env.REACT_APP_SMART_PASSWORD,
      user_id: 1497,
      action: "GetWhitelabelImagesRQ",
      data: {
        company_cipher,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_SMART_URL}interface/xmlsubj/`,
        JSON.stringify({ ActionRQ })
      )
      .then(async (res) => {
        if (await res.data !== "Company not found") {
          dispatch({
            type: SET_WHITELABEL,
            payload: {
              set: true,
              // ##### 24-03-14 Komarov
              // isDemo: company_cipher === '*E3*D8*A7*A6*60v*83*B9',
              isDemo:
                company_cipher ===
                config.whiteLabelCodes.find((code) => code.isDemo).company_cipher,
              // ##### 24-03-01 Komarov
              company_cipher,
              // ##### 24-11-01 Komarov
              data: await res.data,
            },
          });
        }
      });
  }
  if (promocode) {
    // ##### 24-02-26 Komarov: Делаем запрос к Смарту на данные для WhiteLabel
    // ##### 24-02-28 Komarov: TODO: Какой user_id указывать при обращении к Смарт за данными для режима White Label?
    // ##### 24-03-21 Komarov
    const ActionRQ = {
      username: process.env.REACT_APP_SMART_USERNAME,
      password: process.env.REACT_APP_SMART_PASSWORD,
      user_id: 1497,
      action: "GetWhitelabelImagesRQ",
      data: {
        promocode,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_SMART_URL}interface/xmlsubj/`,
        JSON.stringify({ ActionRQ })
      )
      .then(async (res) => {
        const company_cipher = res.company_cipher;
        if (await res.data !== "Company not found") {
          dispatch({
            type: SET_WHITELABEL,
            payload: {
              set: true,
              // ##### 24-03-14 Komarov
              // isDemo: company_cipher === '*E3*D8*A7*A6*60v*83*B9',
              isDemo:
                company_cipher ===
                config.whiteLabelCodes.find((code) => code.isDemo).company_cipher,
              // ##### 24-03-01 Komarov
              company_cipher,
              // ##### 24-11-01 Komarov
              data: await res.data,
            },
          });
        }
      });
  }
};
