// ##### 24-09-13 Komarov
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { ValidateQuery } from "../../Pages/Helpers/helper";
// ##### 23-09-14 Komarov

export const SeoMetaKeywords = () => {
  const browserUrl = `${location.pathname}${location.search}`.substring(3);
  const urlToObject = ValidateQuery(browserUrl);
  const page_id_from_url = urlToObject.id ? urlToObject.id : 209;
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  // ##### 24-07-25 Komarov
  // const purePagesSsr = useSelector((state) => state.purePagesSsr);
  // const purePageSsr = purePagesSsr[page_id_fromurl] || purePagesSsr[209];
  // ##### 24-03-21 Komarov
  const purePageSsr = useSelector((state) => state.pages.purePagesSsr[page_id_from_url]?.[locale]) 
  || 
  window.INITIAL_STATE.pages.purePagesSsr[page_id_from_url]?.[locale];
  // ##### 24-08-22 Komarov
  const purePageSsrDefault = useSelector((state) => state.pages.purePagesSsr[304]?.[locale]) 
  || 
  window.INITIAL_STATE.pages.purePagesSsr[304]?.[locale];
  // ##### 24-08-20 Komarov
  const purepage = useSelector((state) => state.pages.purepage);
  // const currentPurePage = (purepage.length > 0 && purepage) || purePageSsr;
  // ##### 24-08-21 Komarov
  const hotelcontent = useSelector((state) => state.hotelcontent.hotelcontent);
  // ##### 24-08-23 Komarov
  const currentPurePage = ((browserUrl.includes('hotel_details') || browserUrl.includes('hotel_overview')) && hotelcontent) || ((!urlToObject.id || (browserUrl.includes('sitepages') && ['id=2', 'id=210'].includes(`id=${urlToObject.id}`))) && purePageSsr) || (purepage.length > 0 && purepage) || purePageSsrDefault;
  // ##### 24-09-13 Komarov
  const metaKeywordsObject = currentPurePage.length > 0 && currentPurePage.find((item) => item.content_name === "Meta-Keywords");
  const metaKeywordsText = metaKeywordsObject?.text || purePageSsrDefault.find((item) => item.content_name === "Meta-Keywords").text;

  // ##### 24-03-21 Komarov
  const clearMetaTags = (metaTags) => {
    // Проходим по всем тегам meta и удаляем теги без атрибута data-rh
    for (let i = 0; i < metaTags.length; i++) {
      const metaTag = metaTags[i];
      const name = metaTag.getAttribute("name");

      if (!metaTag.hasAttribute("data-rh") && name === "keywords") {
        // Удаляем тег meta без атрибута data-rh
        metaTag.remove();
      }
    }
  };
  // Удаляем теги meta, которые пришли с сервера (потому что на клиенте они не заменяются, а к ним добавляются ещё и клиентские meta-теги)
  const metaTags = document.getElementsByTagName("meta");
  useEffect(() => {
    clearMetaTags(metaTags);
  }, [clearMetaTags, metaTags]);

  return (
    <Helmet>
      {/* ##### 24-09-13 Komarov */}
      <meta name="keywords" content={metaKeywordsText} />
    </Helmet>
  );
};
