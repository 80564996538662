// ##### 25-03-06 Komarov
import {
  GET_CURRENCY_ERR,
  GET_CURRENCY_REQ,
  GET_CURRENCY_RES,
  GET_PICKEDCURRENCY_REQ,
  GET_PICKEDCURRENCY_RES,
} from "../constants";
// ##### 25-01-09 Komarov
import { getUrl } from "../../helpers/crypto";
// ##### 25-03-28 Komarov
import { getSavingDataClient } from "../../helpers/saveData";

// ##### 25-03-26 Komarov
export const getCurrency = () => async (dispatch) => {
  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/currency.js:getCurrency");

  dispatch({ type: GET_CURRENCY_REQ });

  // ##### 25-01-09 Komarov
  const url = getUrl("classifier", "classifier=currency");
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/currency.js:getCurrency, url:", url);

  // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
  await getSavingDataClient({
    smartUrl: url,
    typeRes: GET_CURRENCY_RES,
    typeErr: GET_CURRENCY_ERR,
    dispatch,
  });
};

export const pickedCurrencyResponse = (res) => ({
  type: GET_PICKEDCURRENCY_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
export const getPickedCurrencyResponse = (item) => (dispatch) => {
  dispatch({ type: GET_PICKEDCURRENCY_REQ });

  dispatch(pickedCurrencyResponse(item));
};
