// ##### 24-06-14 Komarov
import parse from "html-react-parser";
import uniqueId from "lodash.uniqueid";
import React from "react";
import { Star } from "../../../../Library/Icons/star";
import { CupOfCoffee } from "../../../../Library/PageDevices/Animation/CupOfCoffee";
import { CartGallery } from "../../../../Library/PageDevices/CartGallery/CartGallery";
// ##### 25-02-15 Komarov
import { useSelector } from "react-redux";
import { useMessages } from "../../../../../helpers/messages";
// ##### 23-09-14 Komarov
// import './HotelDetailsCSS.css'

// ##### 25-02-15 Komarov
export const ContentDetails = ({ className }) => {
  const HotelAddress = useMessages("HotelAddress");
  const hotelcontent_loaded3 = useSelector(
    (state) => state.hotelcontent.hotelcontent_loaded3,
  );
  const hotelId = Object.keys(
    useSelector((state) => state.hotelcontent.hotelcontent3.data.hotels),
  )[0];
  const hotelData = useSelector(
    (state) => state.hotelcontent.hotelcontent3.data.hotels[hotelId],
  );
  return (
    <div className={className} data-component="ContentBlock">
      {!hotelcontent_loaded3 ? (
        <div
          style={{
            // textAlign: 'center',
            minHeight: "10vw",
            // margin:'auto'
            display: "flex",
            gridColumn: "1/3",
            // justifyContent:'center'
            // background:'#FFE5CC'
          }}
        >
          <CupOfCoffee />
        </div>
      ) : (
        <>
          <div key={uniqueId()} style={{ gridColumn: "1 / 3" }}>
            {Array.from(hotelData.star_name).map(() => (
              <span
                key={uniqueId()}
                style={{
                  marginLeft: "0.1vw",
                  marginRight: "0.1vw",
                }}
              >
                <Star />
              </span>
            ))}
            <div
              style={{
                fontFamily: "Arial",
                color: "blue",
              }}
            >
              {/* ##### 25-03-26 Komarov */}
              <span style={{ fontWeight: "bold" }}>{parse(HotelAddress)}</span>
              <span>{hotelData.address}</span>
            </div>
          </div>
          <div
            key={uniqueId()}
            className="ContentBlockHotels"
            style={{ height: "55vh" }}
          >
            {parse(hotelData.body)}
          </div>
          <div key={uniqueId()} style={{ gridColumn: "1", gridRow: "2" }}>
            <CartGallery photos={hotelData.image} />
          </div>
        </>
      )}
    </div>
  );
};
