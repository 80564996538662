// ##### 24-09-09 Komarov
import React, { useState, useContext, createContext, useMemo } from "react";
import { IconHint } from "./iconHint";
// ##### 23-10-31 Komarov
import { Media } from "../../../helpers/MediaConfig";
// ##### 23-10-10 Komarov
const SvgTag = ({ style = null }) => {
  const { color, setIsHintShown, setY } = useContext(PhoneIconContext);

  // ##### 24-08-16 Komarov
  const [crossColor, setCrossColor] = useState(color ? color : "");

  const onMouseEnter = (e) => {
    if (!color) {
      return;
    }
    setCrossColor("yellow");
    setIsHintShown(true);
    setY(e.clientY + 10);
  };

  // ##### 24-08-16 Komarov
  const onMouseLeave = () => {
    if (!color) {
      return;
    }
    setCrossColor("white");
    setIsHintShown(false);
    setY(undefined);
  };

  // ##### 24-05-31 Komarov
  return (
    <a href="tel:+380933266802">
      <svg
        aria-hidden="true"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        color={crossColor}
        // ##### 23-03-06 Komarov
        style={style}
        focusable="false"
        data-prefix="fas"
        data-icon="phone-square"
        // ##### 23-10-31 Komarov
        className="svg-inline--fa fa-phone-square fa-w-14"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM94 416c-7.033 0-13.057-4.873-14.616-11.627l-14.998-65a15 15 0 0 1 8.707-17.16l69.998-29.999a15 15 0 0 1 17.518 4.289l30.997 37.885c48.944-22.963 88.297-62.858 110.781-110.78l-37.886-30.997a15.001 15.001 0 0 1-4.289-17.518l30-69.998a15 15 0 0 1 17.16-8.707l65 14.998A14.997 14.997 0 0 1 384 126c0 160.292-129.945 290-290 290z"
        />
      </svg>
    </a>
  );
};
// ##### 23-10-10 Komarov
const PhoneIconContext = createContext();

// ##### 23-04-26 Komarov
export const PhoneIcon = ({ color, hintText }) => {
  // ##### 23-03-15 Komarov
  const [isHintShown, setIsHintShown] = useState(false);
  const [y, setY] = useState(undefined);

  // ##### 24-09-09 Komarov
  const phoneIconContextValue = useMemo(() => ({
    color,
    setIsHintShown,
    setY,
  }), [
    // ##### 24-09-11 Komarov
    color,
    setIsHintShown,
    setY,
  ]); // value is cached by useMemo

  return (
    <div
      data-component="PhoneIcon"
      style={{
        position: "relative",
        zIndex: 2001,
      }}
    >
      {/* ##### 24-09-09 Komarov */}
      <PhoneIconContext.Provider
        value={phoneIconContextValue}
      >
        {/* ##### 23-10-31 Komarov */}
        <Media lessThan="Mobile-L-425-768">
          <SvgTag style={{ width: "7.5vw" }} />
        </Media>
        <Media at="Mobile-L-425-768">
          <SvgTag style={{ height: "2.33em" }} />
        </Media>
        <Media at="Tablet-768-1024">
          <SvgTag style={{ height: "2em" }} />
        </Media>
        <Media greaterThanOrEqual="Laptop-1024-1440">
          <SvgTag style={{ width: "3vw" }} />
        </Media>
      </PhoneIconContext.Provider>
      <IconHint isHintShown={isHintShown} hintText={hintText} y={y} />
    </div>
  );
};
