// ##### 25-02-05 Komarov
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { LoadingMessage } from "../../../Library/PageDevices/LoadingMessage";
import uniqueId from "lodash.uniqueid";
import { HotelContent } from "../../SecondPageofSearchModule/Hotels/HotelContent/HotelContent";
import { HotelBookButton } from "../HotelBookButton/HotelBookButton";
import { CityHotelsContext } from "./CityHotels";

export const CityHotelsDiv = ({ className1, className2 }) => {
  const { filteredCities, citiesList, See_Availability, filteredLinkCityName } =
    useContext(CityHotelsContext);
  // ##### 24-05-29 Komarov: Берём название страницы исходя из текста ссылки в Footer, по которой кликнули.
  const footerPagesInfo = useSelector((state) => state.sitePageListSection);
  const [footerPageTitle, setFooterPageTitle] = useState();
  const location = useLocation();
  // ##### 24-05-31 Komarov
  const pageTitle = footerPagesInfo.find((pageInfo) =>
    `${location.pathname}${location.search}`.includes(pageInfo.urlPart),
  )?.title;
  useEffect(() => {
    // ##### 24-05-31 Komarov
    pageTitle && setFooterPageTitle(pageTitle);
  }, [pageTitle]);
  return (
    <div
      data-component="CityHotelsDiv"
      className={className1}
      // ##### 23-04-10 Komarov: Подвинул выше блок с сообщением о загрузке, чтобы он не накладывался на Footer
      // ##### 23-06-22 Komarov
      style={
        !filteredCities || filteredCities.length === 0
          ? { height: "250px" }
          : null
      }
    >
      {!citiesList || citiesList.length === 0 ? (
        // ##### 23-09-08 Komarov
        <LoadingMessage loadingMessageClass="RateLoading" />
      ) : (
        <>
          <h2>{footerPageTitle && footerPageTitle.toUpperCase()}</h2>
          <>
            {/* ##### 24-05-31 Komarov */}
            {filteredCities && filteredCities.length > 0 ? (
              filteredCities.map((filtered_hotel) => {
                return (
                  // ##### 23-04-12 Komarov
                  <div key={uniqueId()} className={className2}>
                    {/* ##### 25-02-12 Komarov TODO: Привести в соответствие с актуальными Компонентами HotelContent и HotelBookButton */}
                    <HotelContent />
                    <HotelBookButton
                      innerText={
                        // ##### 23-10-04 Komarov
                        See_Availability
                      }
                    />
                  </div>
                );
              })
            ) : (
              // ##### 23-10-10 Komarov: TODO: Взять перевод из Смарта
              <h3>
                Sorry, we do not have hotels available in{" "}
                {filteredLinkCityName.toUpperCase()} at the moment
              </h3>
            )}
          </>
        </>
      )}
    </div>
  );
};
