// ##### 24-06-14 Komarov
import React, { useContext } from "react";
import { Star } from "../../../../Library/Icons/star";
import parse from "html-react-parser";
import uniqueId from "lodash.uniqueid";
import { CartGallery } from "../../../../Library/PageDevices/CartGallery/CartGallery";
import { CupOfCoffee } from "../../../../Library/PageDevices/Animation/CupOfCoffee";
import { ContentBlockContext } from "./ContentBlock";

// ##### 23-09-14 Komarov
// import './HotelDetailsCSS.css'
// ##### 23-03-01 Komarov
export const DivTag = ({ className }) => {
  const { show, hotelcontents, HotelAddress, search_data, photoHeight } = useContext(ContentBlockContext);
  return (
    <div className={className} data-component="ContentBlock">
      {show === true ? (
        <div
          style={{
            // textAlign: 'center',
            minHeight: "10vw",
            // margin:'auto'
            display: "flex",
            gridColumn: "1/3",
            // justifyContent:'center'
            // background:'#FFE5CC'
          }}
        >
          {/* <LoadingMessage loadingMessageClass='RateLoadingNoBackground' noBackground noTextMessage/> */}
          {/* ##### 23-06-22 Komarov */}
          {/* <CupCoffeeLoader /> */}
          <CupOfCoffee />
        </div>
      ) : (
        <>
          {hotelcontents.length > 0
            ? hotelcontents.map((trip) => {
              // ##### 24-03-21 Komarov
              for (const key in trip) {
                if (key === "hotel_parameters") {
                  return (
                    // ##### 24-03-21 Komarov
                    <div key={uniqueId()} style={{ gridColumn: "1 / 3" }}>
                      {trip[key].category &&
                        Array.from(trip[key].category).includes("*")
                        ? Array.from(trip[key].category).map(() => (
                          // ##### 23-04-12 Komarov
                          <span
                            key={uniqueId()}
                            style={{
                              marginLeft: "0.1vw",
                              marginRight: "0.1vw",
                            }}
                          >
                            <Star />
                          </span>
                        ))
                        : null}
                      <div
                        style={{
                          fontFamily: "Arial",
                          color: "blue",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {
                            // ##### 23-10-04 Komarov
                            HotelAddress}
                        </span>
                        <span>{trip[key].address}</span>
                      </div>
                    </div>
                  );
                }
              }

              // ##### 23-11-06 Komarov
              if (trip.text && trip.content_name === "Body") {
                if (parseInt(trip.id) === parseInt(search_data.id)) {
                  return (
                    // <div className='Li_HotelContent'>
                    // ##### 23-06-22 Komarov
                    <div
                      key={uniqueId()}
                      className="ContentBlockHotels"
                      style={{ height: `${photoHeight}` }}
                    >
                      {parse(trip.text)}
                    </div>
                  );
                }
              }
              // ##### 23-11-06 Komarov
              if (trip.text && trip.content_name === "Image") {
                // console.log('testtest',parseInt(search_data.id),trip.id)
                if (parseInt(trip.id) === parseInt(search_data.id)) {
                  return (
                    // ##### 23-04-12 Komarov
                    <div
                      key={uniqueId()}
                      style={{ gridColumn: "1", gridRow: "2" }}
                    >
                      <CartGallery
                        photos={trip}
                        search_data_id={parseInt(search_data.id)}
                        photoHeight={photoHeight}
                        mainphoto={trip.text[0]} />
                    </div>
                  );
                }
              }
              // }
            })
            : null}
        </>
      )}
    </div>
  );
};
