import {
  GET_CONTENT_ERR,
  GET_CONTENT_REQ,
  GET_CONTENT_RES,
} from "../constants";
// ##### 25-01-10 Komarov
import { getUrl } from "../../helpers/crypto";
// ##### 25-03-28 Komarov
import { getSavingDataClient } from "../../helpers/saveData";

// console.log('[CONTENTRESPONCE]' , contentResponse)

// ##### 25-03-26 Komarov
export const getContent = (id, locale) => async (dispatch) => {
  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/content.js:getContent");

  // console.log('GET_STATE:', getState())
  dispatch({ type: GET_CONTENT_REQ });

  // console.log('[GET_POSTS]' , id)
  // ##### 25-01-10 Komarov
  const url = getUrl("content", `id=${id}&language=${locale}`);
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/content.js:getContent, url:", url);

  // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
  await getSavingDataClient({
    smartUrl: url,
    typeRes: GET_CONTENT_RES,
    typeErr: GET_CONTENT_ERR,
    dispatch,
  });
};
