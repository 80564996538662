import React from "react";
import { HotelDatesComponent } from "./HotelDatesComponent";

// ##### 24-05-15 Komarov
export function HotelDatesModalDiv() {
  return (
    <div
      data-component="HotelDatesModalDiv"
      style={{
        // flexDirection: 'column',
        width: "100%",
        // ##### 24-05-15 Komarov
        height: "100%",
        backgroundColor: "white",
        // border: '#196fbb',
        border: "#e59700",
        borderStyle: "solid",
        // ##### 23-07-19 Komarov
        // borderRadius: '1rem',
        position: "absolute",
        // position: 'fixed',
        // inset: '80px 0px 40px',
        // inset: '0px 0px',
        // top: '0px',
        top: window.scrollY,
        left: "0px",
        zIndex: 2001, // ##### 22-11-11 Komarov
        // alignItems: 'center'
      }}
      id="HotelDatesModalDiv"
    >
      {/* ##### 22-11-02 Komarov: div с содержимым HotelDates */}
      <HotelDatesComponent />
    </div>
  );
}
