// ##### 24-08-10 Komarov
import React from "react";
import { useMessages } from "../../../../../helpers/messages";

export const TopHotelsTitle = ({ fontSize }) => {
  const sloganText = useMessages("ArkturTopHotels");
  return (
    <h2
      data-component="TopHotelsTitle"
      style={{
        padding: "0.2em",
        margin: "auto",
        color: "rgb(122,6,8)",
        fontSize,
        fontFamily: "Arial",
        fontWeight: "bold",
      }}
    >
      {sloganText}
    </h2>
  );
};
