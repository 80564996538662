// ##### 24-05-15 Komarov
import React, { useEffect, useRef } from "react";
// ##### 24-05-10 Komarov
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  getHotels,
  getGeneralHotels,
} from "../../../../../Redux/actions/hotels";
// ##### 23-05-08 Komarov

import config from "../../../../../Redux/config";
// ##### 23-11-02 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
// ##### 24-04-17 Komarov
import { ValidateQuery } from "../../../Helpers/helper";
// ##### 24-05-17 Komarov
import { useHistory, useLocation } from "react-router-dom";
// ##### 24-04-05 Komarov
import { makeArray } from "../../../../../../server/helpers/isArrayObject";
import { setItemInfo } from "../../../../../Redux/actions/order";
import { AutocompleteOuterDiv } from "./AutocompleteOuterDiv";
import { BorderInnerWrapper2DivTag } from "./BorderInnerWrapper2DivTag";
import { ClickableLayer_01_CityOrHotel } from "./ClickableLayer_01_CityOrHotel";
import { ClickableLayer_01_CityOrHotel_Alert } from "./ClickableLayer_01_CityOrHotel_Alert";
import { ClickableLayer_02_StartEndDates } from "./ClickableLayer_02_StartEndDates";
import { ClickableLayer_02_StartEndDates_Alert } from "./ClickableLayer_02_StartEndDates_Alert";
import { ClickableLayer_03_PaxChoice } from "./ClickableLayer_03_PaxChoice";
import { PaxChoice } from "./PaxChoice";
import { HotelDatesModalDiv } from "./HotelDatesModalDiv";
// ##### 24-05-10 Komarov
import {
  saveHotelsContext,
  setStayStartDate,
  setStayEndDate,
  setPickedHotelValue,
  setFormFilled,
  // ##### 24-05-13 Komarov
  setPickedStartDate,
  setPickedEndDate,
  setCounterAdultsOuter,
  setCounterChildOuter,
  setCounterRoomsOuter,
  setPaxChoiceDivOpened,
} from "../../../../../Redux/actions";

// ##### 24-03-15 Komarov

moment.locale(config.defaultLang);

export const HotelsAutocompleteSmallScreen = ({
  wrapper,
  formClass,
  onSubmit,
  props,
  searchProps,
}) => {
  // ##### 24-05-15 Komarov
  const cityHotelInput = useRef();
  const locale = useSelector((state) => state.language);
  const dispatch = useDispatch();
  // ##### 23-11-23 Komarov
  const query = ValidateQuery(useLocation());
  useEffect(() => {
    if (query?.start) {
      dispatch(setStayStartDate(query.start));
      dispatch(setPickedStartDate(true));
    }
    if (query?.end) {
      dispatch(setStayEndDate(query.end));
      dispatch(setPickedEndDate(true));
    }
  }, []);
  useEffect(() => {
    if (query?.title || searchProps) {
      dispatch(setPickedHotelValue(true));
    }
  }, []);

  const stayStartDate = useSelector(
    (state) => state.hotelsContext.stayStartDate
  );
  const stayEndDate = useSelector((state) => state.hotelsContext.stayEndDate);
  const hotelsvalue = useSelector((state) => state.hotelsContext.hotelsvalue);
  const selectedItemInfo = useSelector(
    (state) => state.hotelsContext.selectedItemInfo
  );

  // ##### 24-05-17 Komarov
  const history = useHistory();

  useEffect(() => {
    const selectedItemInfo =
      localStorage.getItem("selectedItemInfo") !== "undefined" &&
      JSON.parse(localStorage.getItem("selectedItemInfo"));
    if (
      selectedItemInfo &&
      history.location.pathname.includes("search_results_hotels")
    ) {
      dispatch(setItemInfo(selectedItemInfo));
      localStorage.removeItem("selectedItemInfo");
    }
  }, [history.location.pathname, dispatch]);

  const pickedHotelValue = useSelector(
    (state) => state.hotelsContext.pickedHotelValue
  );
  const hotelDatesDivOpened = useSelector(
    (state) => state.hotelsContext.hotelDatesDivOpened
  );
  // ##### 24-05-13 Komarov
  const pickedStartDate = useSelector(
    (state) => state.hotelsContext.pickedStartDate
  );
  const pickedEndDate = useSelector(
    (state) => state.hotelsContext.pickedEndDate
  );
  const autocompleteDivOpened = useSelector(
    (state) => state.hotelsContext.autocompleteDivOpened
  );
  const paxChoiceDivOpened = useSelector(
    (state) => state.hotelsContext.paxChoiceDivOpened
  );

  // @@@@@ 23-03-03 Komarov
  const translatedHotelsFromState = useSelector(
    (state) => state.hotels.hotelstrans
  );

  // ##### 24-07-25 Komarov
  const smart_translated_hotels = makeArray(translatedHotelsFromState[locale]).sort((a, b) => a.city - b.city);

  // ##### 24-05-13 Komarov
  useEffect(() => {
    const adults = query?.adults || searchProps?.adults;
    if (adults) {
      dispatch(setCounterAdultsOuter(adults));
    }
  }, []);

  useEffect(() => {
    const children = query?.children || searchProps?.children;
    if (children) {
      dispatch(setCounterChildOuter(children));
    }
  }, []);

  useEffect(() => {
    const rooms = query?.rooms || searchProps?.rooms;
    if (rooms) {
      dispatch(setCounterRoomsOuter(rooms));
    }
  }, []);

  // ##### 24-07-25 Komarov
  const hotels = useSelector(state => state.hotels.hotels);

  useEffect(() => {
    // ##### 23-03-28 Komarov
    // dispatch(getHotels());
    if (hotels.length > 0) {
      return;
    }
    // ##### 23-03-06 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/FirstPageofSearchModule/HotelsAutocompleteBlock/HotelsAutocompleteSmallScreen.js:getHotels()"
      );
    dispatch(getHotels());
    // ##### 23-04-12 Komarov
  }, [dispatch]);
  // console.log('[SMART_HOTELS] : ' , smart_hotels)

  // ##### 24-07-25 Komarov
  const general_hotels = useSelector(state => state.hotels.general_hotels);

  useEffect(() => {
    // ##### 24-07-25 Komarov
    if (general_hotels.length > 0) {
      return;
    }
    // ##### 23-03-06 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/FirstPageofSearchModule/HotelsAutocompleteBlock/HotelsAutocompleteSmallScreen.js:getGeneralHotels()"
      );
    dispatch(getGeneralHotels());
    // ##### 23-04-12 Komarov
  }, [dispatch]);
  // console.log('[SMART_GENERAL_HOTELS] : ' , general_smart_hotels)

  // ##### 24-04-05 Komarov
  useEffect(() => {
    dispatch(setItemInfo(selectedItemInfo));
  }, [selectedItemInfo, dispatch]);

  // ##### 24-05-10 Komarov
  const searchClicked = useSelector(
    (state) => state.hotelsContext.searchClicked
  );

  useEffect(() => {
    // ##### 24-05-10 Komarov
    dispatch(
      setFormFilled(
        pickedHotelValue !== false &&
        hotelsvalue !== "" &&
        pickedStartDate !== false &&
        pickedEndDate !== false
      )
    );
  }, [pickedHotelValue, hotelsvalue, pickedStartDate, pickedEndDate]);

  // @@@@@ 22-09-23 Komarov: Функция для обработки клика по элементу Autocomplete: он отображается в увеличенном масштабе, при этом его собственная область клика остаётся оригинального (маленького) размера, и оказывается смещённой влево. А я добавил оборачивающий его div, для которого подобрал высоту и ширину, и по клику по нему в данной функции устанавливаю фокус ввода на Autocomplete, и получается, что нажали как бы на него
  const makeSelection = (e) => {
    dispatch(
      saveHotelsContext({
        cityOrHotelNameOpened: false,
        autocompleteDivOpened: false,
      })
    );
    e.stopPropagation(); // Вызываем эту функцию, чтобы событие не отлавливалось дочерними компонентами, чтобы избежать его срабатвыания два раза подряд: и на div'е, и на целевом компоненте (в данном случае - Autocomplete).
  };

  // ##### 24-05-13 Komarov
  const onPaxChoiceDivClick = (e) => {
    if (e.target.tagName === "BUTTON") {
      dispatch(setPaxChoiceDivOpened(false));
    } else {
      dispatch(setPaxChoiceDivOpened(true));
    }
    e.stopPropagation(); // Вызываем эту функцию, чтобы событие не отлавливалось дочерними компонентами, чтобы избежать его срабатвыания два раза подряд: и на div'е, и на целевом компоненте (в данном случае - Autocomplete).
  };

  // ##### 24-05-09 Komarov: Сохраняем значения текущего состояния в Redux
  useEffect(() => {
    dispatch(
      saveHotelsContext({
        stayStartDate,
        stayEndDate,
        hotelsvalue,
        hotelDatesDivOpened,
        smart_translated_hotels,
        props,
      })
    );
  }, []);

  return (
    <div className={wrapper} data-component="HotelsAutocompleteSmallScreen">
      {/* ##### 22-11-02 Komarov: div-обёртка (модальное окно) для div`а с содержимым Autocomplete */}
      <>
        {autocompleteDivOpened && (
          <div
            style={{
              // display: autocompleteDivOpened ? "inline-flex" : "none",
              display: "inline-flex",
              flexDirection: "column",
              width: "100%",
              // ##### 23-09-06 Komarov
              height: "100%",
              backgroundColor: "white",
              // border: '#196fbb',
              border: "#e59700",
              borderStyle: "solid",
              borderRadius: "1rem",
              position: "absolute",
              // ##### 23-09-06 Komarov
              // inset: '80px 0px 40px',
              zIndex: 2001,
              top: window.scrollY,
              left: "0px",
              // ##### 22-11-11 Komarov
              alignItems: "center",
            }}
          >
            {/* ##### 22-11-02 Komarov: div с содержимым Autocomplete */}
            <Media greaterThanOrEqual="Mobile-L-425-768">
              <AutocompleteOuterDiv
                // ##### 23-11-10 Komarov
                // transform='translateY(-105px)'
                // ##### 24-06-06 Komarov
                // transform="translateY(33px)"
                cityHotelInput={cityHotelInput}
              />
            </Media>
            <Media lessThan="Mobile-L-425-768">
              <AutocompleteOuterDiv
                // ##### 24-05-22 Komarov
                // transform="translateY(25px)"
                cityHotelInput={cityHotelInput}
              />
            </Media>
            {/* ##### 24-05-16 Komarov: div с кнопкой для закрытия div`а с содержимым Autocomplete */}
            {/* <div
              style={{
                // ##### 23-07-19 Komarov
                position: "absolute",
                bottom: "5%",
                // width: '0',
              }}
            >
              <button
                type="button"
                onClick={makeSelection}
                style={{
                  // ##### 22-11-07 Komarov
                  // minWidth: '90%',
                  backgroundColor: "#005AEB",
                  color: "white",
                  // fontSize: '32px',
                  fontFamily: "Arial, Helvetica, sans-serif",
                  borderRadius: "1rem",
                  // padding: '1vh 33vw,
                  padding: "6.75px 33vw",
                  fontWeight: "bold",
                }}
              >
                OK
              </button>
            </div> */}
          </div>
        )}
        {/* ##### 24-05-15 Komarov: div-обёртка (модальное окно) для div`а с содержимым HotelDates */}
        {hotelDatesDivOpened && <HotelDatesModalDiv />}
        {/* ##### 22-11-02 Komarov: div-обёртка (модальное окно) для div`а с содержимым PaxChoice */}
        {paxChoiceDivOpened && (
          <div
            style={{
              // display: paxChoiceDivOpened ? "inline-flex" : "none",
              display: "inline-flex",
              flexDirection: "column",
              width: "100%",
              // ##### 23-09-06 Komarov
              height: "100%",
              backgroundColor: "white",
              // border: '#196fbb',
              border: "#e59700",
              borderStyle: "solid",
              borderRadius: "1rem",
              position: "absolute",
              // ##### 23-09-06 Komarov
              // inset: '80px 0px 40px',
              top: window.scrollY,
              left: "0px",
              zIndex: 2001,
              // ##### 22-11-11 Komarov
              alignItems: "center",
            }}
          >
            {/* ##### 22-11-02 Komarov: div с содержимым PaxChoice */}
            <Media greaterThanOrEqual="Mobile-L-425-768">
              <PaxChoice
                // ##### 24-02-08 Komarov
                // transform='translateY(-105px)'
                transform="translate3d(22px, -65px, 0)"
              />
            </Media>
            <Media lessThan="Mobile-L-425-768">
              <PaxChoice transform="translate3d(0.5vw, 176px, 0px)" />
            </Media>

            {/* ##### 22-11-02 Komarov: div с кнопкой для закрытия div`а с содержимым PaxChoice */}
            {/* ##### 24-05-16 Komarov */}
            {/* <div
              style={{
                // ##### 23-07-19 Komarov
                position: "absolute",
                bottom: "5%",
              }}
            >
              <button
                type="button"
                onClick={onPaxChoiceDivClick}
                style={{
                  // minWidth: '90%',
                  backgroundColor: "#005AEB",
                  color: "white",
                  // fontSize: '32px',
                  fontFamily: "Arial, Helvetica, sans-serif",
                  borderRadius: "1rem",
                  padding: "6.75px 33vw",
                  fontWeight: "bold",
                }}
              >
                OK
              </button>
            </div> */}
          </div>
        )}

        {/* ##### 22-11-02 Komarov: форма поиска */}
        <form className={formClass} onSubmit={onSubmit}>
          {/* ##### 22-10-25 Komarov - start */}
          {/* ##### 22-11-02 Komarov: внешний div для формы поиска для клика для открытия div`а-обёртки (модального окна) для div`а с содержимым Autocomplete */}
          <Media lessThan="Mobile-L-425-768">
            <ClickableLayer_01_CityOrHotel
              width="77vw"
              height="2.5em"
              cityHotelInput={cityHotelInput}
            />
            {/* ##### 23-12-11 Komarov */}
            {searchClicked &&
              (pickedHotelValue === false || hotelsvalue === "") && (
                <ClickableLayer_01_CityOrHotel_Alert
                  width="77vw"
                  height="2.5em"
                />
              )}
          </Media>
          <Media greaterThanOrEqual="Mobile-L-425-768">
            <ClickableLayer_01_CityOrHotel width="68vw" height="2.5em" />
            {/* ##### 23-12-11 Komarov */}
            {searchClicked &&
              (pickedHotelValue === false || hotelsvalue === "") && (
                <ClickableLayer_01_CityOrHotel_Alert
                  width="68vw"
                  height="2.5em"
                />
              )}
          </Media>
          <Media lessThan="Mobile-L-425-768">
            <ClickableLayer_02_StartEndDates width="77vw" height="2.5em" />
            {/* ##### 23-12-11 Komarov */}
            {searchClicked &&
              pickedStartDate === false &&
              pickedEndDate === false && (
                <ClickableLayer_02_StartEndDates_Alert
                  width="77vw"
                  height="2.5em"
                />
              )}
          </Media>
          <Media greaterThanOrEqual="Mobile-L-425-768">
            <ClickableLayer_02_StartEndDates width="68vw" height="2.5em" />
            {/* ##### 23-12-11 Komarov */}
            {searchClicked &&
              pickedStartDate === false &&
              pickedEndDate === false && (
                <ClickableLayer_02_StartEndDates_Alert
                  width="68vw"
                  height="2.5em"
                />
              )}
          </Media>
          <Media lessThan="Mobile-L-425-768">
            <ClickableLayer_03_PaxChoice width="77vw" height="2.5em" />
          </Media>
          <Media greaterThanOrEqual="Mobile-L-425-768">
            <ClickableLayer_03_PaxChoice width="68vw" height="2.5em" />
          </Media>
          {/* ##### 23-05-08 Komarov */}
          <Media lessThan="Mobile-L-425-768">
            <BorderInnerWrapper2DivTag width="77vw" />
          </Media>
          <Media greaterThanOrEqual="Mobile-L-425-768">
            <BorderInnerWrapper2DivTag width="68vw" />
          </Media>
        </form>
      </>
    </div>
  );
};
