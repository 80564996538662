import { GET_PAYMENT_INFO, SET_PAYMENT_INFO } from "./types";

export const setPaymenInfo = (payload) => {
  // console.log("work_with_payment: action ");
  return { type: SET_PAYMENT_INFO, payload };
};

export const requestPaymenInfo = () => {
  // console.log("work_with_payment: action ");
  return { type: GET_PAYMENT_INFO };
};

// ##### 23-12-22 Komarov
const bookingDataLoaded = (paymentData) => {
  // console.log("work_with_payment: action ");
  return {
    type: "FETCH_BOOKING_DATA_SUCCESS",
    payload: paymentData,
  };
};

const bookingDataError = (error) => {
  // console.log("work_with_payment: action ");
  return {
    type: "FETCH_BOOKING_DATA_ERROR",
    payload: error,
  };
};

const paymentDataLoaded = (paymentData) => {
  // console.log("work_with_payment: action ");
  return {
    type: "FETCH_PAYMENT_DATA_SUCCESS",
    payload: paymentData,
  };
};

const paymentDataError = (error) => {
  // console.log("work_with_payment: action ");
  return {
    type: "FETCH_PAYMENT_DATA_ERROR",
    payload: error,
  };
};

// ##### 23-12-22 Komarov
export const bookingFromCart = (paymentService, dispatch) => () => {
  // console.log("work_with_payment: action ");
  // dispatch(requestPaymenInfo);
  // ##### 23-12-22 Komarov
  paymentService
    .bookingFromCart()
    .then((data) => dispatch(bookingDataLoaded(data)))
    .catch((err) => dispatch(bookingDataError(err)));
};

// ##### 23-12-22 Komarov
export const getPaymentInfo = (paymentService, dispatch) => () => {
  // console.log("work_with_payment: action ");
  // dispatch(requestPaymenInfo);
  paymentService
    .getPaymentInfo()
    .then((data) => dispatch(paymentDataLoaded(data)))
    .catch((err) => dispatch(paymentDataError(err)));
};
