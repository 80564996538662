// ##### 23-12-27 Komarov
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// ##### 23-12-18 Komarov
import { ConfigProvider } from "antd";
import en from "antd/es/locale/en_US";
import uk from "antd/es/locale/uk_UA";
import tr from "antd/es/locale/tr_TR";
import fr from "antd/es/locale/fr_FR";
import ru from "antd/es/locale/ru_RU";
import { month } from "../../../../helpers/dateFormats";
import Autocomplete from "react-autocomplete";
import { DatePicker, Space } from "antd";
// ##### 23-03-29 Komarov
import { getGeo, getGeneralGeo } from "../../../../Redux/actions/cities";
// ##### 23-03-29 Komarov
import { PlaceHolderStrings } from "../../../Library/Localization/placeholders";

// ##### 23-09-14 Komarov
// import '../SearchResizersAndSwitchers/Search.css';
// import '../SearchResizersAndSwitchers/SwitcherFront.css';
// import 'antd/dist/antd.css';
import config from "../../../../Redux/config";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages";
// ##### 23-12-12 Komarov
import { Alert } from "antd";
// ##### 24-04-05 Komarov
import uniqueId from "lodash.uniqueid";
// ##### 24-04-17 Komarov
import { makeParamsString } from "../../Helpers/helper";

moment.locale(config.defaultLang);
// ##### 23-12-18 Komarov
const dateLocales = {
  en,
  uk,
  tr,
  fr,
  ru,
};

// ##### 23-11-10 Komarov
export const ToursAutocomplete = ({
  formClass,
  datepickerClass,
  onSubmit,
  props,
  // ##### 23-12-12 Komarov
  searchProps,
  // GeneralListFunction
}) => {
  // console.log('[PROPS] : ', props)
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  //   const [date, setDate] = useState('');
  // ##### 23-12-12 Komarov
  const [tourDate, setTourDate] = useState("");
  const [list, setList] = useState([]);
  // ##### 23-12-12 Komarov
  const [tourValue, setTourValue] = useState("");
  const [open, setOpen] = useState(false);
  //   const [isOpen, setIsOpen] = useState(false);
  //   const [align, setAlign] = useState('TOURS');
  //   const [myID, setmyID] = useState('');
  //   const [loading,setLoading]=useState(false)
  // ##### 23-12-12 Komarov
  const [pickedTourValue, setPickedTourValue] = useState(
    searchProps ? true : false
  );
  const [pickedTourMonth, setPickedTourMonth] = useState(
    searchProps ? true : false
  );
  // ##### 23-12-12 Komarov
  const [searchClicked, setSearchClicked] = useState(false);
  const [formFilled, setFormFilled] = useState(false);

  // ##### 23-12-18 Komarov
  useEffect(() => {
    setFormFilled(
      pickedTourValue !== false && tourValue !== "" && pickedTourMonth !== false
    );
  }, [pickedTourValue, tourValue, pickedTourMonth]);

  // ##### 24-02-22 Komarov
  const pickedcurrencyFromState = useSelector(
    (state) => state.currency.pickedcurrency
  );
  // ##### 24-07-25 Komarov
  const pickedcurrency = pickedcurrencyFromState || JSON.parse(localStorage.getItem("currency")).pickedcurrency;

  const history = useHistory();

  const dispatch = useDispatch();
  const geoFromState = useSelector((state) => state.cities.locs);
  // ##### 24-07-25 Komarov
  const geo = geoFromState;
  // const geoGeneral = useSelector(state => state.cities.gen_locs)
  // ##### 23-04-12 Komarov
  const geoGeneralFromState = useSelector((state) => state.cities.gen_locs);
  // ##### 24-07-25 Komarov
  const geoGeneral = geoGeneralFromState;
  //   const topTours = useSelector(state => state.toptours.toptours)

  // const dateFormat = 'DD-MM-YYYY'
  const placeholder = PlaceHolderStrings();

  useEffect(() => {
    // ##### 24-07-25 Komarov
    (process.env.NODE_ENV === "development" &&
      geoFromState.length > 0) ||
      console.log(
        "axios:src/Components/Pages/FirstPageofSearchModule/ToursAutocompleteBlock/ToursAutocomplete.js:getGeo()"
      );
    //  dispatch (getGeo ());
    // ##### 24-07-25 Komarov
    geoFromState.length > 0 || dispatch(getGeo());
    // ##### 23-04-12 Komarov
  }, [dispatch]);
  // console.log('[GEO] : ' , geo)

  useEffect(() => {
    // ##### 24-07-25 Komarov
    (process.env.NODE_ENV === "development" &&
      geoGeneralFromState.length > 0) ||
      console.log(
        "axios:src/Components/Pages/FirstPageofSearchModule/ToursAutocompleteBlock/ToursAutocomplete.js:getGeneralGeo()"
      );
      // ##### 24-07-25 Komarov
    // dispatch (getGeneralGeo ());
    geoGeneralFromState.length > 0 ||
      dispatch(getGeneralGeo());
    // ##### 23-04-12 Komarov
  }, [dispatch]);

  // ##### 24-03-21 Komarov
  function onChange(_date, dateString) {
    // ##### 23-12-12 Komarov
    setTourDate(dateString);
    setPickedTourMonth(true);
  }

  const optionChecker = (e) => {
    // console.log('[VALUE] : ', e.target.value)
    //  console.log('[ETARGET]',e.target)

    // ##### 23-12-12 Komarov
    setTourValue(e.target.value);
    // ##### 23-12-12 Komarov
    if (tourValue.length > 0) {
      setOpen(true);
    }
    // else if (value === ''){
    // setOpen(false)
    // }
  };

  // ##### 24-04-23 Komarov
  const chooseYourDestination =
    useMessages("ChooseYourDestination") || "Please choose your destination";
  // ##### 23-12-12 Komarov
  const chooseMonth = useMessages("ChooseMonth");
  // ##### 23-10-04 Komarov
  const Search = useMessages("Search");
  // ##### 24-03-01 Komarov
  const whitelabel = useSelector((state) => state.whitelabel);
  const isWhiteLabelSet = whitelabel.set;
  const company_cipher = whitelabel.company_cipher;
  const promoCode = useSelector((state) => state.promocode.promocode);

  const addToList = () => {
    // ##### 23-12-12 Komarov
    setSearchClicked(true);
    if (!formFilled) {
      return;
    }
    const filtered = geo.filter((item) => item.name === tourValue);

    // ##### 24-03-21 Komarov
    const filtered_city_id = geoGeneral.filter((item) => {
      if (parseInt(item.tour_id) === parseInt(filtered[0].id)) {
        return parseInt(item.city_id);
      }
      return parseInt(item.city_id) === parseInt(filtered[0].id);
    });

    // console.log('FILTERED_CITY_ID', filtered_city_id )

    // ##### 23-12-12 Komarov
    const newList = {
      id: filtered[0].id,
      title: tourValue,
      date: tourDate,
    };

    setList([...list, newList]);
    setTourValue("");

    const params_string = makeParamsString({
      selected_currency: pickedcurrency,
      title: tourValue,
      date:
        (moment(tourDate, month[locale]).format(month.en).isValid() &&
          moment(tourDate, month[locale]).format(month.en)) ||
        moment(tourDate, month.en).format(month.en),
      id: filtered[0].id,
      city_id: filtered_city_id[0].city_id,
    });

    // ##### 24-02-29 Komarov: TODO: Убрать промокод из URL
    if (isWhiteLabelSet) {
      const route_query = `?${params_string}`;

      history.push(
        `/${locale}/ref${company_cipher}/search_results_tours/${route_query}`,
        [...list, newList]
      );
    } else if (promoCode) {
      const route_query = `?${params_string}`;

      history.push(`/${locale}/search_results_tours/${route_query}`, [
        ...list,
        newList,
      ]);
    } else {
      const route_query = `?${params_string}`;

      history.push(`/${locale}/search_results_tours/${route_query}`, [
        ...list,
        newList,
      ]);
    }
  };

  ///not to pick up old date. This dunction is for DatePicker

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < moment().endOf("day");
  };

  return (
    <div data-component="ToursAutocomplete">
      <form className={formClass} onSubmit={onSubmit}>
        <div
          // ##### 23-12-12 Komarov
          style={{
            display: "flex",
            flexDirection: "column",
            gridColumn: "1/3",
          }}
        >
          <div
          // style={{ gridColumn: "1/3" }}
          >
            <Autocomplete
              {...props}
              value={tourValue}
              inputProps={{
                style: {
                  width: "30vw",
                  height: "45px",
                  // ##### 23-03-29 Komarov
                  // fontFamily: 'Tahoma',
                  fontFamily: "Arial",
                  fontSize: "16px",
                  borderTop: "none",
                  borderBottom: "none",
                  borderLeft: "none",
                  marginTop: "0.2vw",
                  textAlign: "center",
                  position: "relative",
                },

                placeholder: placeholder.placeHolderTour,
              }}
              menuStyle={{
                fontFamily: "Arial Narrow",
                fontWeight: "bold",
                fontSize: "18px",
                color: "darkslategrey",
                borderRadius: "3px",
                boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                background: "rgba(255, 255, 255)",
                padding: "3px",
                border: "2px solid grey",
                position: "absolute",
                overflow: "scroll",
                maxHeight: "30vh",
                zIndex: "2000",
                left: "10",
                top: "5",
              }}
              items={geo}
              getItemValue={(item) => item.name}
              onChange={optionChecker}
              onSelect={(value) =>
                setTourValue(value) +
                // ##### 23-12-12 Komarov
                setOpen(false) +
                setPickedTourValue(true)
              }
              renderItem={(item, highlighted) => (
                <div
                  // ##### 24-04-05 Komarov
                  key={uniqueId()}
                  style={{
                    backgroundColor: highlighted ? "lightblue" : "transparent",
                  }}
                >
                  {item.name}
                </div>
              )}
              shouldItemRender={(item, value) =>
                value !== ""
                  ? item.name.toLowerCase().includes(value.toLowerCase())
                  : null
              }
              open={open}
              // ##### 24-03-21 Komarov
              onMenuVisibilityChange={() => setOpen(false)}
            />
          </div>
          {/* ##### 23-12-12 Komarov */}
          {searchClicked && (pickedTourValue === false || tourValue === "") && (
            <Alert message={chooseYourDestination} type="error" />
          )}
        </div>
        {/* </Col>             */}

        {/* <Col md={1}> */}
        <div className="MonthPicker">
          {/* <DatePicker /> */}

          {/* ##### 23-12-12 Komarov */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Space direction="vertical">
              {/* ##### 23-12-18 Komarov */}
              <ConfigProvider locale={dateLocales[locale]}>
                <DatePicker
                  size="small"
                  onChange={onChange}
                  picker="month"
                  //  format={dateFormat}
                  format={month[locale]}
                  placeholder={placeholder.placeHolderMonth}
                  bordered={false}
                  className={datepickerClass}
                  disabledDate={disabledDate}
                />
              </ConfigProvider>
            </Space>
            {/* ##### 23-12-12 Komarov */}
            {searchClicked && pickedTourMonth === false && (
              <Alert message={chooseMonth} type="error" />
            )}
          </div>
        </div>
        <div className="borderInnerWrapper2">
          <button type="submit" onClick={addToList}>
            {
              // ##### 23-10-04 Komarov
              Search
            }
          </button>
        </div>
      </form>
    </div>
  );
};
