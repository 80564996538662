// ##### 23-10-24 Komarov
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
// ##### 23-10-04 Komarov
import { Select, Input, Alert } from "antd";
import parse from "html-react-parser";

import { PlaceHolderStrings } from "../../../Localization/placeholders.js";

import { SendForm } from "../SendForm";
import LodgingTypes from "../../../StaticJsonData/LodgingTypes.json";
// ##### 23-10-24 Komarov
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../../helpers/messages.js";

export const PartnershipForm = ({ text }) => {
  const placeholder = PlaceHolderStrings();

  // ##### 23-10-02 Komarov: сохраняю начальные значения переменных для обязательных полей в переменные, чтобы сравнивать значения обязательных полей и так определять, изменились ли они.
  const countryInitialState = "";
  // ##### 23-10-25 Komarov
  const cityInitialState = "";
  const propertyNameInitialState = undefined;
  const ownerNameInitialState = undefined;
  const positionInitialState = "";
  const telInputInitialState = "";
  const emailInitialState = "";

  // ##### 23-10-04 Komarov
  const [country, setCountry] = useState(countryInitialState);
  const [city, setCity] = useState(cityInitialState);
  const [propertyName, setPropertyName] = useState(propertyNameInitialState);
  const [ownerName, setOwnerName] = useState(ownerNameInitialState);
  const [position, setPosition] = useState(positionInitialState);
  const [telInput, setTelInput] = useState(telInputInitialState);
  const [email, setEmail] = useState(emailInitialState);
  const [adrressInput, setAddressInput] = useState("");
  const [website, setWebSite] = useState("");
  const [comments, setComments] = useState();
  // ##### 23-10-24 Komarov
  const [captchaNotSolved, setCaptchaNotSolved] = useState(false);
  const captchaRef = useRef(null);
  const { user: currentUser } = useSelector((state) => state.auth);

  // ##### 23-09-30 Komarov: отметки заполненности обязательных полей
  const [isCountrySet, setIsCountrySet] = useState("first rendered");
  const [isCitySet, setIsCitySet] = useState("first rendered");
  const [isPropertyNameSet, setIsPropertyNameSet] = useState("first rendered");
  const [isOwnerNameSet, setIsOwnerNameSet] = useState("first rendered");
  const [isPositionSet, setIsPositionSet] = useState("first rendered");
  const [isTelInputSet, setIsTelInputSet] = useState("first rendered");
  const [isEmailSet, setIsEmailSet] = useState("first rendered");

  // ##### 23-10-03 Komarov: Функции для проверки правильности значения поля: если оно не отличается от начального значения, - поле заполнено неправильно и его значение отмечается ниже (в useEffect) не установленным
  // ##### 24-03-21 Komarov
  const isCountryRight = () => country !== countryInitialState;
  const isCityRight = () => city !== cityInitialState;
  const isPropertyNameRight = () => propertyName !== propertyNameInitialState;
  const isOwnerNameRight = () => ownerName !== ownerNameInitialState;
  const isPositionRight = () => position !== positionInitialState;
  const isTelInputRight = () => telInput !== telInputInitialState;
  const isEmailRight = () => email !== emailInitialState;

  // ##### 23-09-30 Komarov: при изменении поля на значение, отличное от начального, устанавливаем отметку заполненности для соответствующего поля
  useEffect(() => {
    isCountryRight() && setIsCountrySet(true);
  }, [isCountryRight]);
  useEffect(() => {
    isCityRight() && setIsCitySet(true);
  }, [isCityRight]);
  useEffect(() => {
    isPropertyNameRight() && setIsPropertyNameSet(true);
  }, [isPropertyNameRight]);
  useEffect(() => {
    isOwnerNameRight() && setIsOwnerNameSet(true);
  }, [isOwnerNameRight]);
  useEffect(() => {
    isPositionRight() && setIsPositionSet(true);
  }, [isPositionRight]);
  useEffect(() => {
    isTelInputRight() && setIsTelInputSet(true);
  }, [isTelInputRight]);
  useEffect(() => {
    isEmailRight() && setIsEmailSet(true);
  }, [isEmailRight]);

  // ##### 23-10-04 Komarov: функция для помечания полей обязательными для заполнения (она добавляет звёздочку к названию поля)
  const markReuired = (labelText) => `${labelText} *`;

  const [list, setList] = useState();
  const [clicked, setClicked] = useState(false);

  // ##### 23-10-02 Komarov: вынес вычисление значений для рендеринга (вызовы функций) в код перед рендерингом, иначе возникают ошибки при рендеринге компонентов, скрытых до нажатия кнопки.
  // ##### 24-04-23 Komarov
  const SupplierForm_PleaseChooseYourLocation = useMessages(
    "SupplierForm_PleaseChooseYourLocation"
  );
  const companyName = useMessages("Company name");
  const SupplierForm_ContactName = useMessages("SupplierForm_ContactName");
  const SupplierForm_ContactPosition = useMessages(
    "SupplierForm_ContactPosition"
  );
  const SupplierTel = useMessages("SupplierTel");
  const SupplierForm_Email = useMessages("SupplierForm_Email");
  const SupplierForm_PropertyWebsite = useMessages(
    "SupplierForm_PropertyWebsite"
  );
  const SupplierForm_Address = useMessages("SupplierForm_Address");
  const Comments = useMessages("Comments");
  const Submit = useMessages("Submit");
  // ##### 24-04-23 Komarov
  const Country = useMessages("SupplierForm_Country");
  const City = useMessages("SupplierForm_city");

  const locale = useSelector((state) => state.language);
  // ##### 23-09-26 Komarov
  const { Option } = Select;
  const { TextArea } = Input;

  // ##### 23-10-25 Komarov
  const selectCountry = (e) => {
    setCountry(e.target.value);
  };

  // ##### 23-10-25 Komarov
  const onChangeCity = (e) => {
    setCity(e.target.value);
  };

  const SelNameProperty = (e) => {
    setPropertyName(e.target.value);
  };

  const SelOwnerName = (e) => {
    setOwnerName(e.target.value);
  };

  const selectPostion = (value) => {
    setPosition(value);
  };

  const SelTelephone = (e) => {
    setTelInput(e.target.value);
  };

  const SelAddress = (e) => {
    setAddressInput(e.target.value);
  };

  const InputEmail = (e) => {
    setEmail(e.target.value);
  };

  const SelWebSite = (e) => {
    setWebSite(e.target.value);
  };

  const AddComments = (e) => {
    setComments(e.target.value);
  };

  const Complete = () => {
    // ##### 23-09-29 Komarov: при нажатии на кнопку "Submit" проверяем значения обязательных полей и, если значение не отличается от начального, устанавливаем признак заполненности этого поля в false, чтобы использовать этот признак для установки статуса соотв. поля на странице в error (поле становится обведено красной полоской). При этом функцию отправки электронного письма не выполняем, а выходим из неё (return).
    if (!isCountryRight()) {
      setIsCountrySet(false);
      return;
    }
    if (!isCityRight()) {
      setIsCitySet(false);
      return;
    }
    if (!isPropertyNameRight()) {
      setIsPropertyNameSet(false);
      return;
    }
    if (!isOwnerNameRight()) {
      setIsOwnerNameSet(false);
      return;
    }
    if (!isPositionRight()) {
      setIsPositionSet(false);
      return;
    }
    if (!isTelInputRight()) {
      setIsTelInputSet(false);
      return;
    }
    if (!isEmailRight()) {
      setIsEmailSet(false);
      return;
    }

    // ##### 24-03-21 Komarov: Если приложение работает онлайн, установлены соотв. переменные окружения, тогда проверяем успешное прохождение CAPTCHA, иначе действуем без учёта прохождения CAPTCHA
    if (
      process.env.REACT_APP_IS_RECAPTCHA_ONLINE &&
      process.env.REACT_APP_IS_RECAPTCHA_ONLINE === "true" &&
      process.env.REACT_APP_RECAPTCHA_SITE &&
      process.env.REACT_APP_RECAPTCHA_SERVER
    ) {
      // ##### 23-10-24 Komarov
      const token = captchaRef.current.getValue();
      captchaRef.current.reset();
      setCaptchaNotSolved(true);
      axios
        .post(
          `${process.env.REACT_APP_URL}/token_check`,
          { token },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          if (res.data === "Human 👨 👩") {
            setCaptchaNotSolved(false);
            const newList = {
              country: country,
              city: city,
              property_name: propertyName,
              owner_name: ownerName,
              website: website,
              position: position,
              tel: telInput,
              email: email,
              address: adrressInput,
              comments: comments,
            };
            // ##### 23-10-04 Komarov
            setList(newList);
            setClicked(true);
          } else {
            setCaptchaNotSolved(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const newList = {
        country: country,
        city: city,
        property_name: propertyName,
        owner_name: ownerName,
        website: website,
        position: position,
        tel: telInput,
        email: email,
        address: adrressInput,
        comments: comments,
      };
      setList(newList);
      setClicked(true);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form
      className="supplierWrapper"
      onSubmit={onSubmit}
      data-component="PartnershipForm"
    >
      <h3>{parse(text)}</h3>
      {/* ##### 23-10-25 Komarov */}
      <div className="blocks_3">
        <span>
          {
            // ##### 23-10-25 Komarov
            markReuired(Country)
          }
        </span>
        {/* ##### 23-10-25 Komarov */}
        <TextArea
          rows={1}
          // ##### 23-10-16 Komarov
          // style={{ marginTop: '1vh', marginBottom: '1vh' }}
          style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
          onChange={selectCountry}
          value={country}
          // ##### 23-10-02 Komarov: если поле не заполнено, оно обводится красной полоской
          status={!isCountrySet && "error"}
        />
        {/* ##### 23-10-02 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {!isCountrySet && (
          <Alert
            message={SupplierForm_PleaseChooseYourLocation}
            type="error"
            showIcon
            closable
            onClose={() => setIsCountrySet("first rendered")}
          />
        )}

        <span>
          {
            // ##### 23-10-04 Komarov
            markReuired(City)
          }
        </span>
        {/* ##### 23-10-25 Komarov */}
        <TextArea
          rows={1}
          // ##### 23-10-16 Komarov
          // style={{ marginTop: '1vh', marginBottom: '1vh' }}
          style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
          onChange={onChangeCity}
          value={city}
          // ##### 23-10-02 Komarov: если поле не заполнено, оно обводится красной полоской
          status={!isCitySet && "error"}
        />
        {/* ##### 23-10-02 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {!isCitySet && (
          <Alert
            message={SupplierForm_PleaseChooseYourLocation}
            type="error"
            showIcon
            closable
            onClose={() => setIsCitySet("first rendered")}
          />
        )}
      </div>

      <div className="blocks_3">
        <span>
          {
            // ##### 23-10-04 Komarov
            markReuired(companyName)
          }
        </span>
        <TextArea
          rows={1}
          // ##### 23-10-16 Komarov
          // style={{ marginTop: '1vh', marginBottom: '1vh' }}
          style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
          onChange={SelNameProperty}
          value={propertyName}
          // ##### 23-10-02 Komarov: если поле не заполнено, оно обводится красной полоской
          status={!isPropertyNameSet && "error"}
        />
        {/* ##### 23-10-02 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {!isPropertyNameSet && (
          <Alert
            message={companyName}
            type="error"
            showIcon
            closable
            onClose={() => setIsPropertyNameSet("first rendered")}
          />
        )}

        <span>
          {
            // ##### 23-10-04 Komarov
            markReuired(SupplierForm_ContactName)
          }
        </span>
        <TextArea
          rows={1}
          // ##### 23-10-16 Komarov
          // style={{ marginTop: '1vh', marginBottom: '1vh' }}
          style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
          onChange={SelOwnerName}
          value={ownerName}
          // ##### 23-10-02 Komarov: если поле не заполнено, оно обводится красной полоской
          status={!isOwnerNameSet && "error"}
        />
        {/* ##### 23-10-02 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {!isOwnerNameSet && (
          <Alert
            message={SupplierForm_ContactName}
            type="error"
            showIcon
            closable
            onClose={() => setIsOwnerNameSet("first rendered")}
          />
        )}
      </div>

      <div className="blocks_5">
        <span>
          {
            // ##### 23-10-04 Komarov
            markReuired(SupplierForm_ContactPosition)
          }
        </span>
        <Select
          onChange={selectPostion}
          bordered={true}
          placeholder={placeholder.placeHolderSelect}
          // ##### 23-10-02 Komarov: если поле не заполнено, оно обводится красной полоской
          status={!isPositionSet && "error"}
          style={{ width: "13vw" }}
        >
          <>
            {
              // ##### 24-03-21 Komarov
              LodgingTypes?.map((item) => {
                if (item.lang === locale) {
                  return item.ownership_types.map((item1) => {
                    return (
                      <Option value={item1} key={item1}>
                        {item1}
                      </Option>
                    );
                  });
                }
              })
            }
          </>
        </Select>
        {/* ##### 23-10-02 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {!isPositionSet && (
          <Alert
            message={SupplierForm_ContactPosition}
            type="error"
            showIcon
            closable
            onClose={() => setIsPositionSet("first rendered")}
          />
        )}

        <span>
          {
            // ##### 23-10-04 Komarov
            SupplierForm_PropertyWebsite
          }
        </span>
        <Input
          style={{ width: "80%", background: "white" }}
          onChange={SelWebSite}
          value={website}
        />

        <span>
          {
            // ##### 23-10-04 Komarov
            markReuired(SupplierForm_Email)
          }
        </span>
        <Input
          style={{ width: "80%", background: "white" }}
          onChange={InputEmail}
          value={email}
          // ##### 23-10-02 Komarov: если поле не заполнено, оно обводится красной полоской
          status={!isEmailSet && "error"}
        />
        {/* ##### 23-10-02 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {!isEmailSet && (
          <Alert
            message={SupplierForm_Email}
            type="error"
            showIcon
            closable
            onClose={() => setIsEmailSet("first rendered")}
          />
        )}
      </div>

      <div className="blocks_3">
        <span>
          {
            // ##### 23-10-04 Komarov
            SupplierForm_Address
          }
        </span>
        <TextArea
          // ##### 23-09-06 Komarov
          // rows={3}
          rows={1}
          // ##### 23-10-16 Komarov
          // style={{ marginTop: '1vh', marginBottom: '1vh' }}
          style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
          onChange={SelAddress}
          value={adrressInput}
        />

        <span>
          {
            // ##### 23-10-04 Komarov
            markReuired(SupplierTel)
          }
        </span>
        <Input
          style={{ width: "100%", background: "white" }}
          onChange={SelTelephone}
          value={telInput}
          // ##### 23-10-02 Komarov: если поле не заполнено, оно обводится красной полоской
          status={!isTelInputSet && "error"}
        />
        {/* ##### 23-10-02 Komarov: оповещение пользователя о необходимости заполнить это поле */}
        {!isTelInputSet && (
          <Alert
            message={SupplierTel}
            type="error"
            showIcon
            closable
            onClose={() => setIsTelInputSet("first rendered")}
          />
        )}
      </div>

      {/* ##### 23-10-16 Komarov */}
      {/* <div className='groupTravelblocks_3'> */}
      <div className="groupTravelFormComments">
        <span>
          {
            // ##### 23-10-04 Komarov
            Comments
          }
        </span>
        <TextArea
          // ##### 23-09-06 Komarov
          // rows={3}
          rows={1}
          // ##### 23-10-16 Komarov
          // style={{ marginTop: '1vh', marginBottom: '1vh' }}
          style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
          onChange={AddComments}
          value={comments}
        />
      </div>
      {/* ##### 23-10-24 Komarov: TODO: взять перевод из Смарта */}
      {/* ##### 23-10-24 Komarov: Если пользователь не зарегистрирован, и в переменных окружения есть переменная REACT_APP_IS_RECAPTCHA_ONLINE=true, то отображаем тест reCAPTCHA */}
      {!currentUser ||
        (currentUser &&
          // ##### 24-03-21 Komarov
          parseInt(currentUser.user_id) === 1426 &&
          process.env.REACT_APP_IS_RECAPTCHA_ONLINE &&
          process.env.REACT_APP_IS_RECAPTCHA_ONLINE === "true" &&
          process.env.REACT_APP_RECAPTCHA_SITE &&
          process.env.REACT_APP_RECAPTCHA_SERVER && (
            <div className="groupTravelFormComments">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE}
                // onChange={handleRecaptchaChange}
                ref={captchaRef}
              />
              {/* ##### 23-10-24 Komarov: TODO: взять перевод из Смарта */}
              {captchaNotSolved && (
                <Alert variant={"danger"}>Please solve a CAPTCHA!</Alert>
              )}
            </div>
          ))}
      {/* ##### 24-03-21 Komarov */}
      <button type="submit" className="SupplierSubmitButton" onClick={Complete}>
        {
          // ##### 23-10-04 Komarov
          Submit
        }
      </button>
      {clicked === true ? <SendForm list={list} /> : null}
    </form>
  );
};
