// ##### 23-03-29 Komarov
import React from "react";
// ##### 23-04-12 Komarov
import uniqueId from "lodash.uniqueid";
import { Pax } from "../../../../Library/Icons/pax";
import { AvailableOptions } from "./AvailableOptions";
// ##### 24-08-05 Komarov
import { useSelector } from "react-redux";

// ##### 23-09-14 Komarov
// import './RatesBlockCSS.css'

// ##### 23-11-08 Komarov
export const SmallScreenRatesBlock = ({
  hotelratesRatesBlock,
  search_data,
}) => {
  // console.log('PICKEDHOTELROOMS',pickedHotelRooms)

  // ##### 23-03-29 Komarov
  /* for (let key in OccupancyTypes){
    // console.log('KEY_TEST', key)
  } */

  // ##### 24-04-05 Komarov
  const available_now_hotel = !!hotelratesRatesBlock[0].available_now;
  // ##### 24-04-13 Komarov
  const available_now_hotel_dates_closed =
    !available_now_hotel && hotelratesRatesBlock[0].dates;

  let tariff_policy;
  // ##### 24-03-21 Komarov
  const empty_array = [];

  // ##### 24-05-30 Komarov
  const getGridTemplateColumns = (itemsCount) => {
    if (itemsCount === 1) {
      return "100%";
    }
    return "50% 50%";
  };

  // ##### 24-08-05 Komarov
  const company_id = useSelector(state => state.auth.user.company_id);

  return (
    // ##### 23-11-08 Komarov
    <div data-component="SmallScreenRatesBlock">
      <ul>
        {/* ##### 24-05-31 Komarov */}
        {hotelratesRatesBlock &&
          hotelratesRatesBlock.length > 0 &&
          parseInt(hotelratesRatesBlock[0].hotel_id) === parseInt(search_data.id)
          ? hotelratesRatesBlock.map((hotelrate) => {
            // ##### 24-04-05 Komarov
            if (hotelrate.dates) {
              return hotelrate.room_rates.map((room_rate) => {
                return hotelrate.dates.map(
                  (hotelrate_date, indexRoomType) => {
                    if (hotelrate_date.date === search_data.start) {
                      return hotelrate_date.rooms.map(
                        (hotelrate_date_room) => {
                          if (
                            parseInt(hotelrate_date_room.room_id) ===
                            parseInt(room_rate.room_id)
                          ) {
                            return (
                              // ##### 23-04-12 Komarov
                              <li
                                key={uniqueId()}
                                className="DetailsSmallScreen"
                                // ##### 24-05-30 Komarov
                                style={{
                                  gridTemplateColumns: getGridTemplateColumns(
                                    hotelrate_date_room.tariffs[0].prices
                                      .length
                                  ),
                                }}
                              >
                                <h5 className="RoomTypeColumnSmallScreen">
                                  {/* ##### 24-01-25 Komarov */}
                                  {/* {item.room_name === '0' ? 'Regular' : item.room_name} {item.room_type_name} */}
                                  {room_rate.room_name === "0"
                                    ? "Regular"
                                    : room_rate.room_name}
                                  {/* {item.room_id} */}
                                </h5>
                                {hotelrate_date_room.tariffs.map(
                                  (hotelrate_date_room_tariff) => {
                                    // ##### 24-04-17 Komarov
                                    const tariff_type_name =
                                      room_rate.tariffs.find(
                                        (tariff) =>
                                          tariff.tariff_id ===
                                          hotelrate_date_room_tariff.tariff_id
                                      )?.tariff_type_name;
                                    const available_now = !!parseInt(
                                      hotelrate_date_room_tariff.available_now
                                    );
                                    return hotelrate_date_room_tariff.prices.map(
                                      (item4, indexPrices) => {
                                        empty_array.length = item4.pax;
                                        return (
                                          // ##### 23-04-12 Komarov
                                          <div key={uniqueId()}>
                                            <h5>
                                              {
                                                // ##### 24-03-21 Komarov
                                                empty_array
                                                  .fill(null)
                                                  .map(() => {
                                                    return (
                                                      // ##### 23-04-12 Komarov
                                                      <Pax key={uniqueId()} />
                                                    );
                                                  })
                                              }
                                            </h5>

                                            {/* ##### 23-07-03 Komarov */}
                                            {
                                              // ##### 24-04-05 Komarov
                                              room_rate.tariffs?.map(
                                                (tariff) => {
                                                  if (
                                                    parseInt(
                                                      tariff.tariff_id
                                                    ) ===
                                                    parseInt(
                                                      hotelrate_date_room_tariff.tariff_id
                                                    )
                                                  ) {
                                                    tariff_policy =
                                                      tariff.tariff_nrf;
                                                    return (
                                                      // ##### 23-04-12 Komarov
                                                      <h5
                                                        key={uniqueId()}
                                                        style={{
                                                          fontSize: "17px",
                                                          color: "blue",
                                                          fontFamily: "Arial",
                                                          fontWeight: "bold",
                                                          display: "flex",
                                                          flexDirection:
                                                            "row",
                                                          justifyContent:
                                                            "center",
                                                          alignItems:
                                                            "center",
                                                        }}
                                                      >
                                                        <span>
                                                          {
                                                            tariff.tariff_type_name
                                                          }
                                                        </span>
                                                        <span
                                                          style={{
                                                            color: "darkred",
                                                            marginLeft: "5px",
                                                            fontStyle:
                                                              "italic",
                                                          }}
                                                        >
                                                          {parseInt(
                                                            tariff.tariff_nrf
                                                          ) === parseInt("1")
                                                            ? "Non Refundable"
                                                            : null}
                                                        </span>
                                                      </h5>
                                                    );
                                                  }
                                                }
                                              )
                                            }
                                            {/* ##### 23-07-03 Komarov */}
                                            <div className="WrapperAvailableOptionsSmallScreen">
                                              <AvailableOptions
                                                index={[
                                                  indexRoomType,
                                                  indexPrices,
                                                ].join("")}
                                                currency={hotelrate.currency}
                                                contract_id={
                                                  hotelrate.contract_id
                                                }
                                                rooms={search_data.rooms}
                                                room_id={room_rate.room_id}
                                                room_name={
                                                  room_rate.room_name
                                                }
                                                room_type_id={
                                                  room_rate.room_type_id
                                                }
                                                room_type_name={
                                                  room_rate.room_type_name
                                                }
                                                // ##### 24-04-17 Komarov
                                                tariff_type_name={
                                                  tariff_type_name
                                                }
                                                // room_subcategory_id={item.room_subcategory_id}
                                                // room_subcategory_name={item.room_subcategory_name}
                                                occupancy={item4.pax}
                                                sum={item4.sum}
                                                tariff_id={
                                                  hotelrate_date_room_tariff.tariff_id
                                                }
                                                availability={
                                                  hotelrate_date_room_tariff.availability
                                                }
                                                pickedCurrency={
                                                  search_data.selected_currency
                                                }
                                                // ##### 24-08-05 Komarov: TODO: Исправить код в соответствии с задачей убрать Промокод из URL
                                                refpartner={
                                                  /* search_data.refpartner
                                                    ? search_data.refpartner
                                                    : */ company_id
                                                }
                                                tariff_policy={tariff_policy}
                                                start={search_data.start}
                                                end={search_data.end}
                                                available_now={available_now}
                                                // ##### 24-04-13 Komarov
                                                available_now_hotel={
                                                  available_now_hotel ||
                                                  available_now_hotel_dates_closed
                                                }
                                              />
                                              {/* ##### 23-07-03 Komarov */}
                                            </div>
                                          </div>
                                        );
                                      }
                                    );
                                  }
                                )}
                              </li>
                            );
                          }
                        }
                      );
                    }
                  }
                );
              });
            } else {
              return hotelrate.room_rates.map((room_rate) => {
                return (
                  <li
                    key={uniqueId()}
                    className="DetailsSmallScreen"
                    // ##### 24-05-30 Komarov
                    style={{
                      gridTemplateColumns: getGridTemplateColumns(
                        room_rate.tariffsToShow.length
                      ),
                    }}
                  >
                    <h5 className="RoomTypeColumnSmallScreen">
                      {room_rate.room_name === "0"
                        ? "Regular"
                        : room_rate.room_name}
                    </h5>
                    {/* ##### 24-04-17 Komarov */}
                    {room_rate.tariffsToShow.map((room_rate_tariff) => {
                      const tariff_type_name = room_rate.tariffs.find(
                        (tariff) =>
                          tariff.tariff_id === room_rate_tariff.tariff_id
                      )?.tariff_type_name;
                      return (
                        <div key={uniqueId()}>
                          {/* ##### 24-04-17 Komarov */}
                          {room_rate.tariffsToShow?.map((tariff) => {
                            if (
                              parseInt(tariff.tariff_id) ===
                              parseInt(room_rate_tariff.tariff_id)
                            ) {
                              tariff_policy = tariff.tariff_nrf;
                              return (
                                <h5
                                  key={uniqueId()}
                                  style={{
                                    fontSize: "17px",
                                    color: "blue",
                                    fontFamily: "Arial",
                                    fontWeight: "bold",
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <span>{tariff.tariff_type_name}</span>
                                  <span
                                    style={{
                                      color: "darkred",
                                      marginLeft: "5px",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    {parseInt(tariff.tariff_nrf) ===
                                      parseInt("1")
                                      ? "Non Refundable"
                                      : null}
                                  </span>
                                </h5>
                              );
                            }
                          })}
                          <div className="WrapperAvailableOptionsSmallScreen">
                            <AvailableOptions
                              currency={hotelrate.currency}
                              contract_id={hotelrate.contract_id}
                              rooms={search_data.rooms}
                              room_id={room_rate.room_id}
                              room_name={room_rate.room_name}
                              room_type_id={room_rate.room_type_id}
                              room_type_name={room_rate.room_type_name}
                              // ##### 24-04-17 Komarov
                              tariff_type_name={tariff_type_name}
                              tariff_id={room_rate_tariff.tariff_id}
                              availability={room_rate_tariff.availability}
                              pickedCurrency={search_data.selected_currency}
                              // ##### 24-08-05 Komarov: TODO: Исправить код в соответствии с задачей убрать Промокод из URL
                              refpartner={
                                /* search_data.refpartner
                                  ? search_data.refpartner
                                  : */ company_id
                              }
                              tariff_policy={tariff_policy}
                              start={search_data.start}
                              end={search_data.end}
                              // ##### 24-04-05 Komarov: Так как в эту ветку кода попадают целиком отели, у которых нет доступных на продажу с аллотмента номеров, то здесь и указываем значение "available_now_hotel"
                              available_now={available_now_hotel}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </li>
                );
              });
            }
          })
          : null}
      </ul>
    </div>
  );
};
