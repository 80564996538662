// ##### 24-06-12 Komarov
import parse from "html-react-parser";
import uniqueId from "lodash.uniqueid";
import React from "react";
import { Media } from "../../../../../helpers/MediaConfig";
import { useMessages } from "../../../../../helpers/messages";
import { HomeLinkTag } from "../../../../Library/Common/HomeLinkTag";
import { LogInLinkTag } from "../../../../Library/Common/LogInLinkTag";
import { EmailIcon } from "../../../../Library/Icons/email";
import { Operations } from "../../../../Library/Icons/operations";
import { PhoneIcon } from "../../../../Library/Icons/phoneicon";
import { LocalizationNavLink } from "../../../../Library/Localization/LocalizationNavLink";

// ##### 23-04-24 Komarov
export const TopMenuRightDivPartForNotDesktop = () => {
  // ##### 25-02-21 Komarov
  const Operations_icon = useMessages("Operations_icon");
  const Mail_icon = useMessages("Mail_icon");
  const Phone_icon = useMessages("Phone_icon");
  return (
    <div
      data-component="TopMenuRightDivPartForNotDesktop"
      className="topMenu_right_mobile"
    >
      <Media lessThan="Laptop-1024-1440">
        {
          // ##### 24-04-23 Komarov
          Object.values(useMessages()).length > 0 ? (
            <EmailIcon key={uniqueId()} hintText={parse(Mail_icon)} />
          ) : (
            <EmailIcon hintText={"Email"} />
          )
        }
      </Media>
      <Media lessThan="Laptop-1024-1440">
        {
          // ##### 24-04-23 Komarov
          Object.values(useMessages()).length > 0 ? (
            <PhoneIcon key={uniqueId()} hintText={parse(Phone_icon)} />
          ) : (
            <PhoneIcon hintText={"Phone"} />
          )
        }
      </Media>

      {/* ##### 23-04-05 Komarov: элемент Home */}
      <HomeLinkTag />

      {/* ##### 23-04-05 Komarov: элемент Operations */}
      {
        // ##### 24-04-23 Komarov
        Object.values(useMessages()).length > 0 ? (
          <LocalizationNavLink key={uniqueId()} exact to={"/mybooking"}>
            <Operations key={uniqueId()} hintText={parse(Operations_icon)} />
            {/* ##### 24-03-21 Komarov */}
          </LocalizationNavLink>
        ) : (
          <LocalizationNavLink exact to={"/mybooking"}>
            <Operations hintText={"Operations"} />
          </LocalizationNavLink>
        )
      }

      {/* ##### 23-11-01 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <LogInLinkTag right="9rem" />
      </Media>
    </div>
  );
};
