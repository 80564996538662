// ##### 23-03-29 Komarov
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getHotelRates } from "../../../../../Redux/actions";
import { LargeScreenRatesBlock } from "./LargeScreenRatesBlock";
import { SmallScreenRatesBlock } from "./SmallScreenRatesBlock";
// ##### 23-11-08 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
// ##### 24-06-10 Komarov
import { CupOfCoffee } from "../../../../Library/PageDevices/Animation/CupOfCoffee";

// ##### 23-09-14 Komarov
// import './RatesBlockCSS.css'

export const RatesBlock = ({ search_data }) => {
  // ##### 23-03-29 Komarov
  // const location = useLocation();

  // const redux_search_data = useSelector(state=>state.hotelsearchdata.hotel_search_data)

  const dispatch = useDispatch();
  const hotelratesRatesBlock = useSelector(
    (state) => state.hotelrates.hotelrates
  );
  // const pickedHotelRooms = useSelector(state=>state.availabilitychoice.avail_rooms)
  const { user: currentUser } = useSelector((state) => state.auth);
  const userId = currentUser.user_id;
  // ##### 23-11-07 Komarov
  const sale =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).sale;
  // ##### 23-12-28 Komarov
  // const deviceType = useSelector(state => state.deviceType);

  // useEffect(()=>{
  //   dispatch(getHotelSearch(search_data))
  // },[])

  // console.log('PICKEDHOTELROOMS',pickedHotelRooms)

  // const storedPromoCode = localStorage.getItem('promocode')

  // for (let key in OccupancyTypes){
  //   console.log('KEY_TEST', key)
  // }

  // let empty_array = [];
  // ##### 24-03-01 Komarov
  const company_cipher = useSelector(
    (state) => state.whitelabel.company_cipher
  );

  // ##### 24-05-24 Komarov
  const deviceType = useSelector((state) => state.deviceType);
  // ##### 24-08-05 Komarov
  const company_id = useSelector(state => state.auth.user.company_id);
  const promocode = useSelector(state => state.promocode.promocode);

  useEffect(() => {
    // ##### 23-06-30 Komarov: Убрал запрос цен в Смарте при отсутствии даты заезда в объекте search_data
    // ##### 24-08-05 Komarov
    search_data.start &&
      dispatch(
        getHotelRates({search_data, userId, sale, company_cipher, deviceType, refpartner: company_id, promocode})
      );
    // ##### 24-05-24 Komarov
  }, [search_data, dispatch, userId, sale, company_cipher, deviceType]);

  // ##### 23-03-01 Komarov

  return (
    <div data-component="RatesBlock">
      {/* ##### 23-11-08 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        {/* ##### 24-06-10 Komarov */}
        {(hotelratesRatesBlock.length > 0 && (
          <LargeScreenRatesBlock
            hotelratesRatesBlock={hotelratesRatesBlock}
            search_data={search_data}
          />
        )) || <CupOfCoffee />}
      </Media>
      <Media lessThan="Laptop-1024-1440">
        {/* ##### 24-04-05 Komarov */}
        {(hotelratesRatesBlock.length > 0 && (
          <SmallScreenRatesBlock
            hotelratesRatesBlock={hotelratesRatesBlock}
            search_data={search_data}
          />
        )) || <CupOfCoffee />}
      </Media>
    </div>
  );
};
