// ##### 23-12-27 Komarov
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPurePage } from "../../../Redux/actions";
import parse from "html-react-parser";
import { useWindowWidthAndHeight } from "../Helpers/WindowResizeHook";
import { ClearInput } from "../../Library/PageDevices/ClearInput";

// ##### 23-09-14 Komarov
// import './TermsConditionsCSS.css'

export const TermsConditions = ({ readTerms, closeTermsConditions }) => {
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  const [width, height] = useWindowWidthAndHeight();
  const dispatch = useDispatch();

  // const [makeVisible, setMakeVisible] = useState(true)

  const purePage = useSelector((state) => state.pages.purepage);

  useEffect(() => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/BookingForm/TermsConditions.js:getPurePage('19',locale)"
      );
    // ##### 23-08-18 Komarov
    // dispatch (getPurePage ('19',locale));
    dispatch(
      getPurePage({
        id: 19,
        lang: locale,
      })
    );
    // ##### 23-04-12 Komarov
  }, [dispatch, locale]);

  // console.log('[PURE_PAGE]', purePage)

  if (!purePage) {
    return <div> Loading...</div>;
  }

  return (
    <div
      data-component="TermsConditions"
      className={`${readTerms === false ? "TermsNotVisible" : "TermsVisible"}`}
      style={{
        maxWidth: width,
        maxHeight: height / 2,
      }}
    >
      {
        // ##### 24-03-21 Komarov
        purePage?.map((page) => {
          if (page.content_name === "Body") {
            return (
              <>
                <ClearInput
                  makevisible={true}
                  clearInput={closeTermsConditions}
                />
                <div>{parse(page.text)}</div>
              </>
            );
          }
        })
      }
    </div>
  );
};
