// ##### 24-09-09 Komarov
import React, { createContext, useMemo } from "react";
import { Media } from "../../../../../helpers/MediaConfig";
import { LargeScreenTopHotelsDiv } from "./LargeScreenTopHotelsDiv";

// ##### 24-09-09 Komarov
export const LargeScreenTopHotels = ({ TopHotelContents, GetHotelDetails }) => {
  const largeScreenTopHotelsContextValue = useMemo(() => ({
    TopHotelContents,
    GetHotelDetails,
  }), [
    // ##### 24-09-11 Komarov
    TopHotelContents,
    GetHotelDetails,
  ]);
  return (
    <LargeScreenTopHotelsContext.Provider
      value={largeScreenTopHotelsContextValue}
    >
      {/* ##### 23-11-08 Komarov */}
      <Media at="Tablet-768-1024">
        <LargeScreenTopHotelsDiv width={1180} />
      </Media>
      <Media greaterThan="Tablet-768-1024">
        <LargeScreenTopHotelsDiv width={1280} />
      </Media>
    </LargeScreenTopHotelsContext.Provider>
  )
};// ##### 23-10-10 Komarov

export const LargeScreenTopHotelsContext = createContext();

