// ##### 25-02-15 Komarov
import React from "react";
// ##### 23-10-30 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
import { ContentDetails } from "./ContentDetails";

// ##### 25-02-15 Komarov
export const ContentBlock = () => {
  return (
    <>
      <Media lessThan="Tablet-768-1024">
        <ContentDetails className="ContentDetailsSmallScreen" />
      </Media>
      <Media greaterThanOrEqual="Tablet-768-1024">
        <ContentDetails className="ContentDetails" />
      </Media>
    </>
  );
};
