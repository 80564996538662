// ##### 23-09-26 Komarov: Вынес получение messages в отдельную функцию, чтобы этим управлять из одного места
import { useSelector } from "react-redux";
// ##### 23-10-11 Komarov: вспомогательный объект с сообщениями на случай временного отсутствия некоторых переводов в Смарте
import { staticMessages } from "../Components/Library/StaticJsonData/staticMessages";
// ##### 23-09-26 Komarov: Вынес получение messages в отдельную функцию, чтобы этим управлять из одного места
// ##### 25-03-26 Komarov
export const useMessages = (name) => {
  const language = useSelector((state) => state.language);
  const pages = useSelector((state) => state.pages.pages);
  const pagesObj = {};
  for (const sitePage of pages) {
    if (!pagesObj[sitePage.name]) {
      pagesObj[sitePage.name] = sitePage;
    }
  }

  // ##### 23-09-29 Komarov: добавил проверку на отсутствие перевода для конкретного message
  if (name) {
    // ##### 23-10-11 Komarov: Если нет такого перевода в Смарте, или он не пришёл оттуда
    // ##### 25-03-26 Komarov
    if (!pagesObj[name]) {
      // ##### 23-10-11 Komarov: сообщаем в Консоли об ошибке (в категории Warnings, чтобы не забивать канал сообщений системы об ошибках)
      console.warn(
        `No translations for ${name} message in Smart or it didn't accepted properly`,
      );
      // ##### 23-10-11 Komarov: берём перевод из вспомогательного объекта
      if (!staticMessages[language][name]) {
        // ##### 23-10-11 Komarov: если перевода нет и во вспомогательном объекте,
        // то сообщаем в Консоли об ошибке (в категории Warnings, чтобы не забивать канал сообщений системы об ошибках)
        console.warn(`No translations for ${name} staticMessages`);
        // и возвращаем сообщение об этом, которое отобразится в соотв. месте на странице
        return `No translations for ${name} message`;
      }
      // ##### 23-10-11 Komarov: и возвращаем перевод из вспомогательного объекта
      return staticMessages[language][name];
    }
    // ##### 23-10-11 Komarov: Если попали сюда, - перевод в Смарте есть и пришёл, - возвращаем его
    // ##### 25-03-26 Komarov
    return pagesObj[name].title[0].text;
  }

  // ##### 23-10-11 Komarov: Если name не указано, - возвращаем весь массив сообщений на текущем языке
  // ##### 25-03-26 Komarov
  return pagesObj;
};
