// ##### 24-09-13 Komarov
import React from "react";
import { useSelector } from "react-redux";
// ##### 23-08-02 Komarov
import { Helmet } from "react-helmet-async";
import { ValidateQuery } from "../../Pages/Helpers/helper";
// ##### 23-09-14 Komarov

export const SeoMetaTitle = () => {
  // ##### 24-08-19 Komarov
  const browserUrl = `${location.pathname}${location.search}`.substring(3);
  const urlToObject = ValidateQuery(browserUrl);
  // ##### 24-08-22 Komarov: 209 - главная страница без id в URL
  const page_id_from_url = urlToObject.id ? urlToObject.id : 209;
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  // ##### 24-08-20 Komarov
  // const purePagesSsr = useSelector((state) => state.pages.purePagesSsr);
  // ##### 24-20-08 Komarov: TODO: Убрать использование напрямую window.INITIAL_STATE (пока не получилось обойтись без него - в redux на этот момент ещё не успели записаться данные)
  const purePageSsr = useSelector((state) => state.pages.purePagesSsr[page_id_from_url]?.[locale]) 
  || 
  window.INITIAL_STATE.pages.purePagesSsr[page_id_from_url]?.[locale];
  // ##### 24-08-22 Komarov
  const purePageSsrDefault = useSelector((state) => state.pages.purePagesSsr[221]?.[locale]) 
  || 
  window.INITIAL_STATE.pages.purePagesSsr[221]?.[locale];
  // ##### 24-08-20 Komarov
  const purepage = useSelector((state) => state.pages.purepage);
  // ##### 24-08-21 Komarov
  const hotelcontent = useSelector((state) => state.hotelcontent.hotelcontent);
  // ##### 24-08-23 Komarov
  const currentPurePage = ((browserUrl.includes('hotel_details') || browserUrl.includes('hotel_overview')) && hotelcontent) || ((!urlToObject.id || (browserUrl.includes('sitepages') && ['id=2', 'id=210'].includes(`id=${urlToObject.id}`))) && purePageSsr) || (purepage.length > 0 && purepage) || purePageSsrDefault;
  // ##### 24-09-13 Komarov
  const metaTitleObject = currentPurePage.length > 0 && currentPurePage.find((item) => item.content_name === "Meta-Title");
  const metaTitleObjectText = metaTitleObject?.text || purePageSsrDefault.find((item) => item.content_name === "Meta-Title").text;

  return (
    <Helmet>
      {/* ##### 24-09-13 Komarov */}
      <title>{metaTitleObjectText}</title>
    </Helmet>
  );
};
