// ##### 25-03-13 Komarov
import React, { useEffect, useState } from "react";
// ##### 24-07-22 Komarov
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { connect, useDispatch, useSelector } from "react-redux";
// ##### 23-09-14 Komarov
// import './footer.css'
import uniqueId from "lodash.uniqueid";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../helpers/messages";
// ##### 25-03-26 Komarov
import {
  getAttractionsOverview,
  getAutocompleteData,
  getCities,
  setSitePageListSection,
} from "../../../Redux/actions";
// ##### 24-07-22 Komarov
import moment from "moment";
import dateFormats from "../../../helpers/dateFormats";
import { setOthersName } from "../../../Redux/actions/setOthersName";
import { LocalizationNavLink } from "../../Library/Localization/LocalizationNavLink";
import { makeParamsString } from "../Helpers/helper";
// ##### 24-09-05 Komarov
import {
  getCitiesOverview,
  getHotelsOverview,
  getOccupiedCities,
} from "../../../Redux/actions";
// ##### 24-12-27 Komarov
import { encryptString } from "../../../helpers/crypto";
// ##### 25-03-13 Komarov
import {
  BottomRow,
  formFooterColumns,
  getSitePageListSection,
} from "./helpers";

// ##### 24-07-31 Komarov
function mapStateToProps(state) {
  return {
    cities_overview_top: state.cities.cities_overview_top,
    // ##### 24-09-05 Komarov
    occupied_cities_top: state.cities.occupied_cities_top,
    // ##### 24-08-16 Komarov
    attractions_top: state.cities.attractions_top,
    hotels_overview_top: state.hotels.hotels_overview_top,
  };
}

// ##### 24-09-05 Komarov
export const Footer = connect(mapStateToProps)(({
  cities_overview_top,
  occupied_cities_top,
  attractions_top,
  hotels_overview_top,
}) => {
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  // ##### 24-09-05 Komarov
  const isWhiteLabelSet = useSelector((state) => state.whitelabel.set);
  // ##### 24-07-22 Komarov
  const companyCipher = useSelector((state) => state.whitelabel.company_cipher);

  // ##### 24-07-22 Komarov
  const sitePages = Object.values(useMessages());

  const dispatch = useDispatch();
  // ##### 25-03-26 Komarov
  const sale =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).sale;
  const promocode = useSelector((state) => state.promocode.promocode);
  const { user: currentUser } = useSelector((state) => state.auth);
  const company_id = useSelector((state) => state.auth.user.company_id);

  // ##### 25-03-13 Komarov
  useEffect(() => {
    dispatch(
      setSitePageListSection(
        getSitePageListSection(sitePages, isWhiteLabelSet),
      ),
    );
  }, []);

  // ##### 25-03-20 Komarov
  const cities = useSelector((state) => state.cities);

  // ##### 24-07-22 Komarov
  const sitePageRegionsList = sitePages
    .filter((item, index, array) => {
      if (item.sitepage_region_id !== null) {
        return (
          array.findIndex(
            (t) =>
              parseInt(t.sitepage_region_id) ===
              parseInt(item.sitepage_region_id),
          ) === index
        );
      }
    })
    .map((item1) => {
      return {
        // ##### 24-03-21 Komarov
        sitepage_region_id: item1.sitepage_region_id,
        sitepage_region_name: item1.sitepage_region_name,
      };
    });

  // ##### 24-07-22 Komarov
  const localeUpper = locale.toUpperCase();

  // ##### 25-03-13 Komarov
  const autocomplete_data = useSelector(
    (state) => state.hotels.autocomplete_data,
  );
  const autocomplete_data_loaded = useSelector(
    (state) => state.hotels.autocomplete_data_loaded,
  );

  // ##### 25-03-26 Komarov
  useEffect(() => {
    // console.log('locale', locale);
    const localeChanged =
      locale !== "undefined" && sessionStorage.getItem("language") !== locale;
    /*if (localeChanged) {
      console.log("locale changed");
    }*/
    // ##### 25-03-12 Komarov
    if (autocomplete_data_loaded && !localeChanged) {
      return;
    }
    // ##### 24-02-02 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/RoutesAndLinks/Footer.js:getAutocompleteData",
      );
    // ##### 25-03-12 Komarov
    dispatch(
      getAutocompleteData({
        sale_id: (sale && sale) || null,
        promocode,
        user_id: currentUser.user_id,
        refpartner: company_id,
        language: locale,
      }),
    );
    // ##### 25-03-12 Komarov
  }, [autocomplete_data_loaded, locale]);

  useEffect(() => {
    sessionStorage.setItem("language", locale);
  }, [locale]);

  useEffect(() => {
    const params = {
      user_id: currentUser.user_id,
      // ##### 24-08-05 Komarov
      refpartner: company_id,
      language: locale,
      primary_objects_list: 1,
      section_name: "CITIES Overview",
    };
    dispatch(getCitiesOverview(params));
    // ##### 24-07-31 Komarov
    // dispatch(setOthersName(footerColumns[params.section_name].title));
  }, [locale]);

  // ##### 24-09-05 Komarov
  useEffect(() => {
    const params = {
      user_id: currentUser.user_id,
      refpartner: company_id,
      language: locale,
      primary_objects_list: 1,
      section_name: "Occupied CITIES",
    };
    dispatch(getOccupiedCities(params));
  }, [locale]);

  useEffect(() => {
    const params = {
      user_id: currentUser.user_id,
      // ##### 24-08-05 Komarov
      refpartner: company_id,
      language: locale,
      primary_objects_list: 1,
      section_name: "HOTELS Overview",
    };
    dispatch(getHotelsOverview(params));
  }, [locale]);

  // ##### 25-03-26 Komarov
  useEffect(() => {
    const params = {
      user_id: currentUser.user_id,
      // ##### 24-08-05 Komarov
      refpartner: company_id,
      language: locale,
      primary_objects_list: 1,
      section_name: "ATTRACTIONS",
    };
    dispatch(getAttractionsOverview(params));
  }, [locale]);

  useEffect(() => {
    const params = {
      user_id: currentUser.user_id,
      // ##### 24-08-05 Komarov
      refpartner: company_id,
      language: locale,
      primary_objects_list: 1,
      section_name: "CITY HOTELS",
    };
    dispatch(getCities(params));
  }, [locale]);

  // ##### 24-07-24 Komarov
  const countryLocalized = useSelector((state) => state.country)[locale];
  // const onOthersClick = (othersName) => dispatch(setOthersName(othersName));
  // ##### 25-03-26 Komarov
  const onOthersClick = ({ othersTitle }) => {
    // Если othersSectionName === 'CITIES', то в section_name записываем 'CITIES Overview'
    // const section_name = footerColumnName === 'CITIES' && 'CITIES Overview';
    // ##### 24-10-28 Komarov
    /* const params = {
      user_id: currentUser.user_id,
      // ##### 24-08-05 Komarov
      refpartner: company_id,
      language: locale,
      primary_objects_list: 0,
      // section_name
    };
    dispatch(getCitiesOverview(params)); */
    dispatch(setOthersName(othersTitle));
  };

  const PagesLinks = () => {
    // ##### 24-07-25 Komarov
    const pickedcurrencyFromState = useSelector(
      (state) => state.currency.pickedcurrency,
    );
    // ##### 24-09-05 Komarov
    // const start = moment().add(1, 'days').format(dateFormats.en);
    // const end = moment().add(2, 'days').format(dateFormats.en);
    // ##### 24-12-27 Komarov
    const shouldCypherUrlParams = useSelector(
      (state) => !!parseInt(state.siteCypherCodeData.data.value),
    );

    // ##### 25-03-13 Komarov
    const [footerColumns, setFooterColumns] = useState({});
    const footerColunmsCount = Object.keys(footerColumns).length;

    // ##### 25-03-26 Komarov
    useEffect(() => {
      setFooterColumns(
        formFooterColumns(
          sitePages,
          isWhiteLabelSet,
          sitePageRegionsList,
          autocomplete_data,
          cities,
          cities_overview_top,
          occupied_cities_top,
          attractions_top,
          hotels_overview_top,
          localeUpper,
        ),
      );
    }, [footerColunmsCount, locale]);

    return (
      <>
        {Object.keys(footerColumns)?.map((footerColumnName) => {
          // ##### 25-03-26 Komarov
          const columnTitle =
            footerColumns[footerColumnName].title[0].text ||
            footerColumns[footerColumnName].langname;
          const columnPages =
            footerColumns[footerColumnName].itemList ||
            footerColumns[footerColumnName].object_ist;
          switch (footerColumnName) {
            case "INFORMATION":
              return (
                <Col lg={true} key={uniqueId()}>
                  <div className="footerLinks">
                    <h3>{columnTitle}</h3>
                    <ul>
                      {columnPages.map((page) => (
                        <li key={uniqueId()}>
                          <h2>
                            <LocalizationNavLink
                              to={
                                (isWhiteLabelSet &&
                                  `/ref${companyCipher}/sitepages/?name=${page.name
                                    .replace(/\s/g, "-")
                                    .toLowerCase()}&id=${page.id}`) ||
                                `/sitepages/?name=${page.name
                                  .replace(/\s/g, "-")
                                  .toLowerCase()}&id=${page.id}`
                              }
                            >
                              {page.title[0].text}
                            </LocalizationNavLink>
                          </h2>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              );

            case "CITY HOTELS":
              return (
                <Col lg={true} key={uniqueId()}>
                  <div className="footerLinks">
                    <h3>{columnTitle}</h3>
                    <ul>
                      {columnPages.map((page) => {
                        // ##### 25-03-20 Komarov
                        const title = cities.cities
                          .find(
                            (item) => item.name === page.name.split(" ", 1)[0],
                          )
                          ?.title.find(
                            (item) => item.language_name === localeUpper,
                          ).text;
                        // ##### 25-03-26 Komarov
                        const city_id = cities.cities.find(
                          (item) => item.name === page.name.split(" ", 1)[0],
                        ).id;
                        const params_string = makeParamsString({
                          selected_currency:
                            localStorage.getItem("current_currency") ||
                            pickedcurrencyFromState,
                          title,
                          start: moment().add(1, "days").format(dateFormats.en),
                          end: moment().add(2, "days").format(dateFormats.en),
                          // ##### 25-02-25 Komarov
                          // id: filtered_hotelcity_id,
                          hotel_id: 0,
                          // ##### 25-03-13 Komarov
                          city_id: city_id,
                          adults: 1,
                          children: 0,
                          rooms: 1,
                        });
                        return (
                          <li key={uniqueId()}>
                            <h2>
                              <LocalizationNavLink
                                // ##### 24-12-27 Komarov
                                /*to={(isWhiteLabelSet && `/ref${companyCipher}/search_results_hotels/?${params_string}`) || (`/search_results_hotels/?${params_string}`)}*/
                                to={
                                  (isWhiteLabelSet &&
                                    `/ref${companyCipher}/search_results_hotels/?${shouldCypherUrlParams ? encryptString(params_string) : params_string}`) ||
                                  `/search_results_hotels/?${shouldCypherUrlParams ? encryptString(params_string) : params_string}`
                                }
                              >
                                {page.title[0].text}
                              </LocalizationNavLink>
                            </h2>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
              );

            case "ATTRACTIONS":
              return (
                <Col lg={true} key={uniqueId()}>
                  <div className="footerLinks">
                    <h3>{columnTitle}</h3>
                    <ul>
                      {columnPages.map((page) => (
                        <li key={uniqueId()}>
                          <h2>
                            {/* ##### 24-08-16 Komarov */}
                            <LocalizationNavLink
                              to={`/city_overview/?name=${page.object_name
                                .replace(/\s/g, "-")
                                .toLowerCase()}&id=${page.sitepageobject_id}&sptid=${
                                page.type_id
                              }`}
                            >
                              {/* ##### 24-09-11 Komarov */}
                              {/* {page.object_langname}, {countryLocalized} */}
                              {page.object_langname}
                              {page.object_name !== "Others" &&
                                `, ${countryLocalized}`}
                            </LocalizationNavLink>
                          </h2>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              );

            case "CITIES Overview":
              return (
                <Col lg={true} key={uniqueId()}>
                  <div className="footerLinks">
                    <h3>{columnTitle}</h3>
                    <ul>
                      {columnPages.map((page) => {
                        return (
                          <li key={uniqueId()}>
                            <h2>
                              <LocalizationNavLink
                                to={`/city_overview/?name=${page.object_name
                                  .toLowerCase()
                                  .replace(/\s/g, "-")
                                  .toLowerCase()}&id=${page.sitepageobject_id}&sptid=${
                                  page.type_id
                                }`}
                                onClick={() =>
                                  (page.object_name === "Others" &&
                                    onOthersClick({
                                      footerColumnName,
                                      othersTitle:
                                        footerColumns[footerColumnName].title,
                                    })) ||
                                  null
                                }
                              >
                                {/* ##### 24-09-11 Komarov */}
                                {/* {page.object_langname}, {countryLocalized} */}
                                {page.object_langname}
                                {page.object_name !== "Others" &&
                                  `, ${countryLocalized}`}
                              </LocalizationNavLink>
                            </h2>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
              );

            // ##### 24-09-05 Komarov
            case "Occupied CITIES":
              return (
                <Col lg={true} key={uniqueId()}>
                  <div className="footerLinks">
                    <h3>{columnTitle}</h3>
                    <ul>
                      {columnPages.map((page) => {
                        return (
                          <li key={uniqueId()}>
                            <h2>
                              <LocalizationNavLink
                                to={`/city_overview/?name=${page.object_name
                                  .toLowerCase()
                                  .replace(/\s/g, "-")
                                  .toLowerCase()}&id=${page.sitepageobject_id}&sptid=${
                                  page.type_id
                                }`}
                                onClick={() =>
                                  (page.object_name === "Others" &&
                                    onOthersClick({
                                      footerColumnName,
                                      othersTitle:
                                        footerColumns[footerColumnName].title,
                                    })) ||
                                  null
                                }
                              >
                                {/* ##### 24-09-11 Komarov */}
                                {/* {page.object_langname}, {countryLocalized} */}
                                {page.object_langname}
                                {page.object_name !== "Others" &&
                                  `, ${countryLocalized}`}
                              </LocalizationNavLink>
                            </h2>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
              );

            // ##### 24-07-31 Komarov
            case "HOTELS Overview":
              return (
                <Col lg={true} key={uniqueId()}>
                  <div className="footerLinks">
                    <h3>{columnTitle}</h3>
                    <ul>
                      {columnPages.map((page) => {
                        return (
                          <li key={uniqueId()}>
                            <h2>
                              {/* ##### 24-08-05 Komarov */}
                              {/* <LocalizationNavLink
                              to={`/city_overview/?name=${page.object_name.toLowerCase()
                                .replace(/\s/g, "-")
                                .toLowerCase()}&id=${page.sitepageobject_id}&sptid=${page.type_id
                                }`}
                              onClick={() => (page.object_name === "Others" && onOthersClick({ footerColumnName, othersTitle: footerColumns[footerColumnName].title })) || null}
                            >
                              {page.object_langname}
                            </LocalizationNavLink> */}
                              {page.object_name === "Others" ? (
                                <LocalizationNavLink
                                  to={`/city_overview/?name=${page.object_name
                                    .toLowerCase()
                                    .replace(/\s/g, "-")
                                    .toLowerCase()}&id=${page.sitepageobject_id}&sptid=${
                                    page.type_id
                                  }`}
                                  onClick={() =>
                                    onOthersClick({
                                      footerColumnName,
                                      othersTitle:
                                        footerColumns[footerColumnName].title,
                                    })
                                  }
                                >
                                  {page.object_langname}
                                </LocalizationNavLink>
                              ) : (
                                // ##### 24-08-23 Komarov
                                // <LocalizationNavLink
                                //   to={`/hotel_details/?selected_currency=${pickedcurrencyFromState}&start=${start}&end=${end}&id=${page.object_id}&city_id=${page.city_id}&adults=1&children=0&rooms=1&hotel_name=${page.object_name.toLowerCase().replace(/\s/g, "-").toLowerCase()}&hotel_id=${page.object_id}`}
                                // >
                                //   {page.object_langname}
                                // </LocalizationNavLink>}
                                <LocalizationNavLink
                                  to={`/hotel_overview/?id=${page.object_id}&city_id=${page.city_id}&hotel_name=${page.object_name.toLowerCase().replace(/\s/g, "-").toLowerCase()}&hotel_id=${page.object_id}`}
                                >
                                  {page.object_langname}
                                </LocalizationNavLink>
                              )}
                            </h2>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
              );

            default:
              return <>Unexpected {footerColumnName}</>;
          }
        })}
      </>
    );
  };

  // ##### 24-07-22 Komarov
  return (
    <footer className="footer" data-component="Footer">
      <Container
        fluid="xs,sm,md,lg,xl"
        style={{ marginLeft: "auto", marginRight: "auto", width: "80%" }}
      >
        <Row
          xs={1}
          // ##### 23-03-30 Komarov
          // sm={1}
          sm={2}
          md={2}
          lg={3}
        >
          <PagesLinks />
        </Row>
        {/* ##### 25-03-13 Komarov */}
        <BottomRow />
      </Container>
    </footer>
  );
});
