// ##### 24-04-05 Komarov: Функция для определения, является ли аргумент массивом
export const isArray = (value) => Array.isArray(value);
// ##### 24-04-05 Komarov: Функция для определения, является ли аргумент объектом
export const isObject = (value) => typeof value === "object" && value !== null;
// ##### 24-04-05 Komarov: Функция для определения, является ли аргумент объектом, и при этом не массивом
export const isNonArrayObject = (value) => isObject(value) && !isArray(value);
// ##### 24-04-05 Komarov: Функция для создания массива из аргумента, если он - объект, иначе (если аргумент - массив) возвращает этот же массив
export const makeArray = (value) => {
  if (isArray(value)) return value;
  return Object.values(value);
};
