export default {
  // apiUrl:"https://next.json-generator.com/api/json/get/V1TbDaNiK"
  apiUrl: "https://jsonplaceholder.typicode.com/",

  //City Content
  // ##### 24-09-11 Komarov
  cityUrl:
    "https://hotels-ua.biz/interface/sitepagecitycontent?id=13&language=en&cityview=1",

  //Localization
  // ##### 24-03-05 Komarov: Оставил языки в этом объекте для посдтраховки, если они не успеют на момент использования переменной подгрузиться из Смарта в Redux, и значения отсюда пока нужны для использования на серверной части в файле express.js, так как они там нужны моментально, без ожидания подгрузки их из Смарта
  // supeportedLangs:['uk','en','tr','ru'],
  supportedLangs: ["uk", "en", "ru"],
  defaultLang: "uk",
  // ##### 24-03-14 Komarov
  /* whiteLabelCodes: [
        '*DE*DB*B7*AAey*7F*80d',
        '*E3*D8*A7*A6*60v*83*B9'
    ], */
  whiteLabelCodes: [
    { isDemo: false, company_cipher: "*DE*DB*B7*AAey*7F*80d" },
    { isDemo: true, company_cipher: "*E3*D8*A7*A6*60v*83*B9" },
  ],
  // defaultLang:'en'
  // ##### 24-02-15 Komarov
  deviceTypes: ["desktop", "tablet", "mobile"],
  defaultDeviceType: "mobile",

  // ##### 24-08-05 Komarov
  defaultCompanyId: 1361,
};
