// ##### 23-08-02 Krat-Komarov
import React from "react";
import { Helmet } from "react-helmet-async";
import ArkturDMClogoICO from "../Images/ArkturDMClogoICO.ico";

export const SeoLogoIco = () => {
  return (
    <Helmet>
      <link rel="shortcut icon" href={ArkturDMClogoICO} />
    </Helmet>
  );
};
