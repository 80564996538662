// ##### 25-03-13 Komarov
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import cards from "../../Library/Images/visamastercard.jpg";
import React from "react";
import parse from "html-react-parser";
import { useMessages } from "../../../helpers/messages";
import { useSelector } from "react-redux";

// ##### 25-03-13 Komarov
const getFooterSectionsList = (sitePages, isWhiteLabelSet) => {
  return sitePages
    .filter((item) => {
      if (item.title !== null) {
        // if (item.id > 222 && item.id < 231) // Old condition for choosing the names of Footer Sectors through ID
        // ##### 24-09-05 Komarov
        if (!isWhiteLabelSet) {
          if (
            item.sitepage_region_name.includes("Footer") &&
            (item.name === item.sitepage_type_name ||
              item.sitepage_type_name === "For_VIEW" ||
              item.sitepage_type_name === "Occupied")
          ) {
            return item.title;
          }
        } else {
          if (
            item.sitepage_region_name.includes("Footer") &&
            item.name === item.sitepage_type_name &&
            [
              "BEACH HOTELS",
              "WEEKEND BREAK HOTELS",
              "HOTELS IN INDUSTRIAL HUBS"
            ].indexOf(item.sitepage_type_name) === -1
          ) {
            return item.title;
          }
        }
      }
    })
    // ##### 24-08-06 Komarov
    .sort((a, b) => a.s_order - b.s_order)
    .map((item2) => {
      return {
        // ##### 24-03-21 Komarov
        title: item2.title,
        name: item2.name,
        sitepage_type_id: item2.sitepage_type_id,
        sitepage_type_name: item2.sitepage_type_name,
        sitepage_region_id: item2.sitepage_region_id,
        sitepage_region_name: item2.sitepage_region_name
      };
    });
}

// ##### 25-03-13 Komarov
export const getSitePageListSection = (sitePages, isWhiteLabelSet) => {
  return sitePages
    .filter((item) => {
      // ##### 24-09-05 Komarov
      if (
        item.title !== null &&
        [
          "ATTRACTIONS",
          "CITIES Overview",
          "Occupied CITIES",
          "HOTELS Overview"
        ].indexOf(item.sitepage_type_name) === -1
      ) {
        if (
          item.sitepage_region_name.includes("Footer") &&
          item.name !== item.sitepage_type_name
        ) {
          if (!isWhiteLabelSet) {
            return item.title;
          }
          // ##### 24-03-21 Komarov
          if (
            ["Terms and Conditions", "Privacy Policy"].indexOf(item.name) !== -1
          ) {
            return item.title;
          }
        }
      }
    })
    .map((itemList) => {
      return {
        // ##### 24-03-21 Komarov
        id: itemList.id,
        title: itemList.title,
        name: itemList.name,
        sitepage_type_id: itemList.sitepage_type_id,
        sitepage_type_name: itemList.sitepage_type_name,
        urlPart: `/sitepages_hotels/?name=${itemList.name
          .replace(/\s/g, "-")
          .toLowerCase()}&id=${itemList.id}`
      };
    });
}

export const formFooterColumns = (
  sitePages,
  isWhiteLabelSet,
  sitePageRegionsList,
  autocomplete_data,
  cities,
  cities_overview_top,
  occupied_cities_top,
  attractions_top,
  hotels_overview_top,
  localeUpper
) => {
  const footerColumns = {};
  let citiesTitles = Object.values(autocomplete_data.data.list).filter(i => i.sign === 1).map(city => city.title);

  // ##### 25-03-13 Komarov
  getFooterSectionsList(sitePages, isWhiteLabelSet)?.map((footerSection) => {
    return sitePageRegionsList?.map((sitePageRegion) => {
      if (
        parseInt(footerSection.sitepage_region_id) ===
          parseInt(sitePageRegion.sitepage_region_id) &&
        sitePageRegion.sitepage_region_name.includes("Footer") &&
        ((isWhiteLabelSet && footerSection.name !== "CITY HOTELS") || // В режиме While Label исключаем секцию "CITY HOTELS"
          true) &&
        !footerColumns[footerSection.name]
      ) {
        let itemList = [];
        if (footerSection.name === "CITY HOTELS") {
          itemList = sitePages.filter((sitePage) => {
            // ##### 25-03-20 Komarov
            const title = cities.cities?.find((item) => item.name === sitePage.name.split(" ", 1)[0])
              ?.title.find((item) => item.language_name === localeUpper).text;

            // ##### 24-06-19 Komarov: Если такого title нет (он undefined), то не отображаем ссылку на соотв. страницу (например, Гостиницы Ужгорода)
            if (!title) return false;

            // ##### 25-03-13 Komarov: Отбрасываем названия городов, которых нет в autocomplete_data
            if (citiesTitles.indexOf(title) < 0) return false;

            return (
              sitePage.sitepage_type_name === footerSection.name &&
              sitePage.name !== footerSection.name &&
              // Оставляем в массиве только те города, которые есть в массиве cities
              // ##### 25-03-20 Komarov
              !!cities.cities.find(
                (city) => city.name === sitePage.name.split(" ", 1)[0],
              )
            );
          });
        } else if (footerSection.name === "CITIES Overview") {
          itemList = cities_overview_top[0].data.object_list;
        }
        // ##### 24-09-05 Komarov
        else if (footerSection.name === "Occupied CITIES") {
          itemList = occupied_cities_top[0].data.object_list;
        } else if (footerSection.name === "HOTELS Overview") {
          itemList = hotels_overview_top[0].data.object_list;
        } else if (footerSection.name === "ATTRACTIONS") {
          itemList = attractions_top[0].data.object_list;
        } else {
          // когда footerSection.name !== 'CITY HOTELS' && footerSection.name !== 'CITIES' && footerSection.name !== 'ATTRACTIONS'
          itemList = sitePages.filter(
            (sitePage) =>
              sitePage.sitepage_type_name === footerSection.name &&
              sitePage.name !== footerSection.name,
          );
        }
        footerColumns[footerSection.name] = {
          title: footerSection.title,
          itemList,
        };
      } else return false;
    });
  });

  return footerColumns;
};

// ##### 25-03-13 Komarov
export const BottomRow = () => {
  const WeAccept = useMessages("WeAccept");
  const Developer = parse(useMessages("Developer"));
  const DemoPayment = parse(useMessages("DemoPayment"));
  const isWhiteLabelDemo = useSelector((state) => state.whitelabel.isDemo);
  return (
    <Row>
      <Col>
        <div style={{ marginTop: "2vw" }}>
          <span
            style={{
              marginRight: "1vw",
              color: "white",
              fontSize: "10px",
              fontFamily: "Arial",
              fontStyle: "italic",
            }}
          >
            {WeAccept}
          </span>
          <img
            src={cards}
            // ##### 24-03-18 Komarov: TODO: Взять значения атрибута alt из Смарта
            alt="Cards accepted"
            // ##### 24-03-04 Komarov
            style={{
              /* width: '5vw',  */ height: "2vw",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </div>
      </Col>
      <Col>
        <div style={{ marginTop: "2vw" }}>
          <span
            style={{
              marginRight: "1vw",
              color: "white",
              fontSize: "10px",
              fontFamily: "Arial",
              fontStyle: "italic",
            }}
          >
            {Developer}
          </span>
        </div>
        {/* ##### 24-03-14 Komarov */}
        {isWhiteLabelDemo && (
          <div style={{ marginTop: "2vw" }}>
            <span
              style={{
                marginRight: "1vw",
                color: "white",
                fontSize: "10px",
                fontFamily: "Arial",
                fontStyle: "italic",
              }}
            >
              {DemoPayment}
            </span>
          </div>
        )}
      </Col>
    </Row>
  );
};
