// ##### 25-02-05 Komarov
import { useMessages } from "../../../../helpers/messages";
import { LocalizationNavLink } from "../../../Library/Localization/LocalizationNavLink";
import uniqueId from "lodash.uniqueid";
import parse from "html-react-parser";
import React from "react";
import { CooperationMobile } from "./CooperationMobile";
import { RequestsMobile } from "./RequestsMobile";

export const MobileMenuTextItems = ({
  // ##### 24-08-08 Komarov
  sitepage_type,
  isWhiteLabelSet,
  companyCipher,
  sidebarListWidth,
}) => {
  // ##### 24-06-20 Komarov
  const pagesFromLocalStorageArray = Object.values(useMessages()).filter(
    (page) =>
      parseInt(sitepage_type.sitepage_type_id) ===
        parseInt(page.sitepage_type_id) &&
      parseInt(sitepage_type.sitepage_type_id) !== 7 &&
      parseInt(page.id) !== 198,
  );

  // ##### 24-08-08 Komarov
  const cooperation = pagesFromLocalStorageArray.find(
    (page) => page.name === "Cooperation",
  );
  const requests = pagesFromLocalStorageArray.find(
    (page) => page.name === "Requests",
  );

  // ##### 24-08-08 Komarov
  return pagesFromLocalStorageArray.length > 0 ? (
    <>
      {pagesFromLocalStorageArray
        .filter(
          (page) =>
            ![
              "Clients",
              "Suppliers",
              "Group Request",
              "Cooperation",
              "Requests",
            ].includes(page.name),
        )
        .map((page) => {
          return (
            // ##### 24-03-04 Komarov
            <LocalizationNavLink
              to={
                (isWhiteLabelSet &&
                  `/ref${companyCipher}/sitepages/?name=${page.name}&id=${page.id}`) ||
                `/sitepages/?name=${page.name}&id=${page.id}`
              }
              key={uniqueId()}
            >
              {/* ##### 25-03-26 Komarov */}
              <h4>{parse(page.title[0].text)}</h4>
            </LocalizationNavLink>
          );
        })}
      <CooperationMobile
        pages={pagesFromLocalStorageArray.filter((page) =>
          ["Clients", "Suppliers"].includes(page.name),
        )}
        // ##### 25-03-26 Komarov
        title={cooperation.title[0].text}
        isWhiteLabelSet={isWhiteLabelSet}
        companyCipher={companyCipher}
        sidebarListWidth={sidebarListWidth}
      />
      {/* ##### 24-08-07 Komarov */}
      <RequestsMobile
        pages={pagesFromLocalStorageArray.filter((page) =>
          ["Group Request"].includes(page.name),
        )}
        title={requests.title[0].text}
        isWhiteLabelSet={isWhiteLabelSet}
        companyCipher={companyCipher}
        sidebarListWidth={sidebarListWidth}
      />
    </>
  ) : null;
};
