// ##### 25-02-05 Komarov
import ReCAPTCHA from "react-google-recaptcha";
import { Alert } from "antd";
import React from "react";

export function CaptchaInput(props) {
  return (
    <>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE}
        ref={props.captchaRef}
      />
      {/* ##### 23-10-24 Komarov: TODO: взять перевод из Смарта */}
      {props.captchaNotSolved && (
        <Alert variant={"danger"}>Please solve a CAPTCHA!</Alert>
      )}
    </>
  );
}
