import React from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

// ##### 23-04-05 Komarov
import { getLangResponse } from "../../../../Redux/actions/locale";
// ##### 23-05-04 Komarov
import { setLanguage } from "../../../../Redux/actions/setLanguage";
// ##### 24-08-23 Komarov
import { getHotelContent, getPurePage } from "../../../../Redux/actions";
import { ValidateQuery } from "../../../Pages/Helpers/helper";
// ##### 23-11-02 Komarov
import { Media } from "../../../../helpers/MediaConfig";

// ##### 23-10-10 Komarov
const LangSelect = ({ dropdownStyle }) => {
  // ##### 24-05-10 Komarov
  const deviceType = useSelector((state) => state.deviceType);
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  const location = useLocation();

  // ##### 24-03-05 Komarov
  const supportedLangs = useSelector((state) => state.locale.languages).map(
    (lang) => lang.name
  );
  const history = useHistory();
  const { Option } = Select;

  const currentUrl = useSelector((state) => state.currentUrl);

  const search_data = ValidateQuery(location);
  // ##### 24-09-16 Komarov
  const { user: currentUser } = useSelector((state) => state.auth);
  const { user_id } = currentUser;

  const dispatch = useDispatch();

  const SelectRooms = (value) => {
    history.push(`/${value + currentUrl}`);
    // ##### 24-08-23 Komarov
    if (search_data.id) {
      if (currentUrl.includes("city_overview")) {
        dispatch(
          getPurePage({
            id: search_data.id,
            lang: value,
            deviceType,
            pageType: "sitepagecitycontent",
            user_id
          })
        );
      }
      else if (currentUrl.includes("hotel_overview")) {
        // ##### 24-09-16 Komarov
        dispatch(getHotelContent(search_data.id, value, user_id));
      }
    }
    // ##### 23-12-28 Komarov: Вроде, запрос getPurePageSsr здесь не нужен
    // search_data.id && dispatch(getPurePageSsr(value, deviceType, [search_data.id]));
    // ##### 23-04-07 Komarov
    // Устанавливаем выбранный язык в Redux, в объект locale
    dispatch(getLangResponse(value));
    // ##### 23-05-04 Komarov
    // Устанавливаем выбранный язык в Redux, в поле language
    dispatch(setLanguage(value));
  };

  return (
    <Select
      // ##### 24-07-24 Komarov: использование value позволяет изменить выбранное значение языка при смене locale (defaultValue не позволяло)
      // defaultValue={locale}
      value={locale}
      // ##### 22-11-14 Komarov
      dropdownStyle={dropdownStyle}
      onChange={SelectRooms}
      bordered={true}
      // ##### 23-03-24 Komarov
      // size="small"
      // ##### 24-08-16 Komarov
      size={deviceType === "tablet" ? "middle" : "small"}
    >
      <>
        {supportedLangs?.map((item) => (
          <Option value={item} key={item}>
            {/* ##### 22-11-10 Komarov */}
            {item}
          </Option>
        ))}
      </>
    </Select>
  );
};

export const LangSelectionTool = () => {
  // ##### 23-04-05 Komarov
  const deviceType = useSelector((state) => state.deviceType);

  return (
    <div style={deviceType === "desktop" ? null : { marginRight: "2vw" }}>
      {/* ##### 23-11-02 Komarov */}
      <Media lessThan="Mobile-L-425-768">
        <LangSelect
          dropdownStyle={{
            scale: "none",
            transformOrigin: "top left",
          }}
        />
      </Media>
      <Media between={["Mobile-L-425-768", "Laptop-1024-1440"]}>
        <LangSelect
          dropdownStyle={{
            scale: "1.1",
            transformOrigin: "top left",
          }}
        />
      </Media>
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <LangSelect />
      </Media>
    </div>
  );
};
