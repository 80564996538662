// ##### 25-02-05 Komarov
import React, { useEffect, useRef } from "react";
import { useWindowWidthAndHeight } from "../../Helpers/WindowResizeHook";
import OutsideClickHandler from "react-outside-click-handler";
import { LocalizationNavLink } from "../../../Library/Localization/LocalizationNavLink";
import uniqueId from "lodash.uniqueid";
import parse from "html-react-parser";

export const Cooperation = ({
  pages,
  title,
  isWhiteLabelSet,
  companyCipher,
}) => {
  const cooperationRef = useRef();
  const cooperationItemsRef = useRef();
  // ##### 24-09-20 Komarov
  const [innerWidth] = useWindowWidthAndHeight();
  useEffect(() => {
    cooperationItemsRef.current.style.left = `${cooperationRef.current.offsetLeft}px`;
  }, [innerWidth]);

  const removeCooperationItems = () => {
    const cooperationItemsclassList = cooperationItemsRef.current.classList;
    if (!cooperationItemsclassList.contains("DropDownMenuItemsHide")) {
      cooperationItemsclassList.add("DropDownMenuItemsHide");
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={removeCooperationItems}>
      <a
        ref={cooperationRef}
        role="button"
        className="DropDownMenu"
        data-component="DropDownMenu"
        onClick={() =>
          cooperationItemsRef.current.classList.toggle("DropDownMenuItemsHide")
        }
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            cooperationItemsRef.current.classList.toggle(
              "DropDownMenuItemsHide",
            );
          }
        }}
      >
        {title}
      </a>
      <div
        className="DropDownMenuItemsHide"
        data-component="DropDownMenuItems"
        ref={cooperationItemsRef}
      >
        {pages.map((page) => {
          return (
            // ##### 24-03-04 Komarov
            <LocalizationNavLink
              to={
                (isWhiteLabelSet &&
                  `/ref${companyCipher}/sitepages/?name=${page.name}&id=${page.id}`) ||
                `/sitepages/?name=${page.name}&id=${page.id}`
              }
              key={uniqueId()}
              onClick={removeCooperationItems}
            >
              {/* ##### 25-03-26 Komarov */}
              {parse(page.title[0].text)}
            </LocalizationNavLink>
          );
        })}
      </div>
    </OutsideClickHandler>
  );
};
