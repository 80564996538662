import React from "react";
// ##### 24-05-13 Komarov
import { useMessages } from "../../../../../helpers/messages";

// ##### 23-12-11 Komarov
export const ClickableLayer_02_StartEndDates_Alert = ({ width, height }) => {
  // ##### 24-05-13 Komarov
  const pickUpYourDatesOfStay = useMessages("PickUpYourDatesOfStay");

  return (
    <div
      data-component="ClickableLayer_02_StartEndDates_Alert"
      id="ClickableLayer_02_StartEndDates_Alert"
      style={{
        gridColumn: "1",
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-around",
        width,
        height,
        border: "red",
        borderWidth: "0.2em",
        borderStyle: "solid",
        borderRadius: "1rem",
        fontFamily: "Arial",
        alignItems: "center",
        margin: "0.3vw",
      }}
    >
      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
          // marginTop: "0.25em",
          textAlign: "center",
          lineHeight: 1,
          color: "red",
        }}
      >
        {pickUpYourDatesOfStay}
      </div>
    </div>
  );
};
