// ##### 24-07-25 Komarov
import {
  SET_PROMOCODE_REQ,
  SET_PROMOCODE_RES,
  SET_PROMOCODE_ERR,
} from "../constants";

const initState = {
  promocode: "",
  // ##### 24-03-18 Komarov
  promocode_loaded: false,
  errors: [],
};

const reducer = (state = initState, action) => {
  // console.log('ACTION_pax', action)
  // ##### 24-07-25 Komarov
  switch (action.type) {
    case SET_PROMOCODE_REQ:
      return {
        ...state,
        promocode_loaded: true,
      };

    case SET_PROMOCODE_RES:
      return {
        ...state,
        promocode_loaded: true,
        promocode: action.payload,
      };

    case SET_PROMOCODE_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    default:
      return state;
  }
};

export default reducer;
