// ##### 25-02-12 Komarov
import uniqueId from "lodash.uniqueid";
import React from "react";
import { Star } from "../../../../Library/Icons/star";
import { useSelector } from "react-redux";
import { getDomain } from "../../../Helpers/urls";
// ##### 25-02-15 Komarov
import ImageComponent from "./ImageComponent";

// ##### 23-03-01 Komarov
export const HotelItemContent = ({
  ulCLassName,
  liClassName,
  liImageClassName,
  divHotelNameStarRatingClassName,
  divHotelAddressClassName = null,
  width,
  height,
  hotel_id,
}) => {
  const deviceType = useSelector((state) => state.deviceType);
  const hotelcontent2 = useSelector(
    (state) => state.hotelcontent.hotelcontent2,
  );
  const cityName = hotelcontent2.data.ci_title.toUpperCase();
  const stars = Array.from(hotelcontent2.data.hotels[hotel_id].star_name);
  const hotelTitle = hotelcontent2.data.hotels[hotel_id].ho_title.toUpperCase();
  const hotelAddress = hotelcontent2.data.hotels[hotel_id].address;
  const main_image = hotelcontent2.data.hotels[hotel_id].main_image;
  const currentWebProtocol = process.env.REACT_APP_PROTOCOL || "https://";
  const hotelImageSrcStub = `${currentWebProtocol}/${getDomain(process.env.REACT_APP_SMART_URL)}/images_symlink/hotel/0/Hotel_when_no_image.jpeg`;
  const getMainImageSrc = (main_image) => {
    if (main_image.length === 0) {
      console.warn("There's no images for hotel with id", hotel_id);
      return hotelImageSrcStub;
    } else if (main_image[0].includes("localhost")) {
      console.warn("There's no images for hotel with id", hotel_id);
      return hotelImageSrcStub;
    } else if ( // ##### 25-03-26 Komarov: убрал требование к формату картинок webp
      !main_image[0].includes(`/${deviceType}/`) /*||
      !main_image[0].includes(".webp")*/
    ) {
      let fixedHotelImageSrc = main_image[0];
      if (!main_image[0].includes(`/${deviceType}/`)) {
        fixedHotelImageSrc = `${fixedHotelImageSrc.slice(0, fixedHotelImageSrc.indexOf("images_symlink") + "images_symlink".length)}/${deviceType}${fixedHotelImageSrc.slice(fixedHotelImageSrc.indexOf("images_symlink") + "images_symlink".length)}`;
      }
      // ##### 25-03-26 Komarov: убрал требование к формату картинок webp
      /*if (!main_image[0].includes(".webp")) {
        return `${fixedHotelImageSrc.slice(0, fixedHotelImageSrc.lastIndexOf("."))}.webp`;
      }*/
      return fixedHotelImageSrc;
    }
    return main_image[0];
  };
  const hotelMainImageSrc = getMainImageSrc(main_image);
  return (
    <ul className={ulCLassName} data-component="HotelItemContent">
      <li
        key={uniqueId()}
        className={liClassName}
        style={{ gridRow: "1", gridColumn: "1", order: 1 }}
      >
        <div className={divHotelNameStarRatingClassName}>
          <h3
            style={{
              // ##### 24-08-14 Komarov
              // fontSize: "27px",
              fontSize: "22px",
              color: "#001959",
              fontWeight: "bold",
              marginRight: "2vw",
            }}
          >
            {hotelTitle}
          </h3>
        </div>
      </li>
      <li
        key={uniqueId()}
        className={liClassName}
        style={{ gridRow: "1", gridColumn: "2/3", order: 2 }}
      >
        {/* ##### 24-10-18 Komarov */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className={divHotelNameStarRatingClassName}>
            {stars.map(() => (
              <span
                key={uniqueId()}
                style={{
                  marginLeft: "0.1vw",
                  marginRight: "0.1vw",
                }}
              >
                <Star />
              </span>
            ))}
          </div>
        </div>

        {/* ##### 24-03-21 Komarov */}
        <div className={divHotelAddressClassName}>
          <span style={{ fontWeight: "bold" }}>{cityName}</span> -{" "}
          {hotelAddress}
        </div>
      </li>
      <li
        key={uniqueId()}
        className={liImageClassName}
        style={{ gridRow: "2", gridColumn: "1", order: 3 }}
      >
        {/*##### 25-02-15 Komarov*/}
        {/*<img
          style={{
            // ##### 23-03-01 Komarov
            width,
            height,
            borderRadius: "5px"
          }}
          // ##### 23-09-05 Komarov: если у отеля нет фотографии (это отмечено добавленным свойством trip.isNotOwnImage = true), устанавливаем фото-"заглушку"
          src={hotelMainImageSrc}
          // ##### 24-02-22 Komarov
          alt="Hotel"
        />*/}
        <ImageComponent
          src={hotelMainImageSrc}
          alt="Hotel"
          width={width}
          height={height}
          hotel_id={hotel_id}
        />
      </li>
    </ul>
  );
};
