// ##### 25-02-05 Komarov
import { Helmet } from "react-helmet";
import ArkturDMClogoICO from "../../../Library/Images/ArkturDMClogoICO.ico";
import React from "react";

export const HOTELS = () => {
  return (
    <div data-component="HOTELS">
      <Helmet>
        <title>Hotels in Ukraine</title>
        <link rel="shortcut icon" href={ArkturDMClogoICO} />
      </Helmet>
      <h3>Hotels</h3>
    </div>
  );
};
