// ##### 24-10-18 Komarov
import moment from "moment";
import React from "react";

import "moment/locale/uk";

// ##### 23-09-14 Komarov
// import './HotelItemsCSS.css'
import config from "../../../../../Redux/config";
// ##### 24-04-23 Komarov
// ##### 23-11-02 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
import { HotelRatesContent } from "./HotelRatesContent";

moment.locale(config.defaultLang);

// ##### 23-02-17 Komarov: Убрал свойство "key", - оно не использовалось, и вызывало предупреждение в Консоли разработчика
// ##### 25-02-12 Komarov
export const HotelRates = ({ hotelPrice, hotel_id }) => {
  // ##### 24-10-18 Komarov
  return (
    <div style={{ gridColumn: "2", gridRow: "2" }} data-component="HotelRates">
      {/* ##### 23-11-02 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <HotelRatesContent
          gridAutoRows="minmax(40px, auto)"
          hotelPrice={hotelPrice}
          hotel_id={hotel_id}
        />
      </Media>
      <Media lessThan="Laptop-1024-1440">
        <HotelRatesContent
          gridAutoRows="minmax(15px, auto)"
          hotelPrice={hotelPrice}
          hotel_id={hotel_id}
        />
      </Media>
    </div>
  );
};
