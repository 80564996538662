// ##### 25-02-05 Komarov
import React, { useContext } from "react";
import { ClientDetails } from "./ClientDetails";
import { CartDetails } from "./CartDetails";
import { BookingFormContext } from "./BookingForm";

export const RegWrapperDiv = ({ formClassName, cartClass }) => {
  const { search_data } = useContext(BookingFormContext);
  return (
    <>
      <ClientDetails cart={search_data} formClassName={formClassName} />
      <CartDetails cart={search_data} cartClass={cartClass} />
    </>
  );
};
