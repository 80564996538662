import {
  // ##### 24-05-24 Komarov
  GET_HOTEL_CONTENT,
  GET_HOTEL_RATES_ERR,
  GET_HOTEL_RATES_REQ,
  GET_HOTEL_RATES_RES,
  // ##### 25-02-15 Komarov
  GET_HOTEL_RATES_ERR2,
  GET_HOTEL_RATES_REQ2,
  GET_HOTEL_RATES_RES2,
  // ##### 24-06-10 Komarov
  RESET_HOTEL_RATES,
} from "../constants";

const initState = {
  // ##### 24-05-24 Komarov
  hotelContent: [],
  hotelrates: [],
  hotelrates_loaded: false,
  hotelrates_errors: [],
  // ##### 25-02-15 Komarov
  hotelrates2: [],
  hotelrates_loaded2: false,
  hotelrates_errors2: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_HOTEL_RATES_REQ:
      return {
        ...state,
        hotelrates_loaded: false,
      };

    case GET_HOTEL_RATES_RES:
      return {
        ...state,
        hotelrates_loaded: true,
        hotelrates: action.payload,
      };

    case GET_HOTEL_RATES_ERR:
      return {
        ...state,
        hotelrates_errors: [...state.hotelrates_errors, action.error],
      };

    //   ##### 25-02-15 Komarov
    case GET_HOTEL_RATES_REQ2:
      return {
        ...state,
        hotelrates_loaded2: false,
      };

    case GET_HOTEL_RATES_RES2:
      return {
        ...state,
        hotelrates_loaded2: true,
        hotelrates2: action.payload,
      };

    //   ##### 25-02-15 Komarov
    case GET_HOTEL_RATES_ERR2:
      return {
        ...state,
        hotelrates_errors2: [...state.hotelrates_errors2, action.error],
      };

    // ##### 24-06-10 Komarov
    case RESET_HOTEL_RATES:
      return {
        ...state,
        hotelrates_loaded: false,
        hotelrates: initState.hotelrates,
      };

    // ##### 24-05-24 Komarov
    case GET_HOTEL_CONTENT:
      return {
        ...state,
        hotelContent: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
