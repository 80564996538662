// ##### 24-05-10 Komarov
import {
  SAVE_HOTELS_CONTEXT,
  SET_AUTOCOMPLETE_DIV_OPENED,
  SET_CITY_OR_HOTEL_NAME_OPENED,
  SET_COUNTER_ADULTS_OUTER,
  SET_COUNTER_CHILD_OUTER,
  SET_COUNTER_ROOMS_OUTER,
  SET_FORM_FILLED,
  SET_HOTEL_DATES_DIV_OPENED,
  SET_HOTELS_VALUE,
  SET_OPEN,
  SET_PAX_CHOICE_DIV_OPENED,
  SET_PICKED_END_DATE,
  SET_PICKED_HOTEL_VALUE,
  // ##### 24-05-13 Komarov
  SET_PICKED_START_DATE,
  SET_SEARCH_CLICKED,
  SET_SELECTED_ITEM_INFO,
  SET_STAY_END_DATE,
  SET_STAY_START_DATE,
} from "../constants";

const initialState = {
  stayStartDate: "",
  stayEndDate: "",
  hotelsvalue: "",
  open: false,
  counterAdultsOuter: 1,
  counterChildOuter: 0,
  counterRoomsOuter: 1,
  hotelDatesDivOpened: false,
  smart_translated_hotels: [],
  // ##### 25-03-12 Komarov
  autocomplete_data_list: [],
  selectedItemInfo: undefined,
  pickedHotelValue: false,
  cityOrHotelNameOpened: false,
  searchClicked: false,
  formFilled: false,
  // ##### 24-05-13 Komarov
  pickedStartDate: false,
  pickedEndDate: false,
  autocompleteDivOpened: false,
  paxChoiceDivOpened: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_HOTELS_CONTEXT:
      return { ...state, ...action.payload };
    case SET_STAY_START_DATE:
      return { ...state, stayStartDate: action.payload };
    case SET_STAY_END_DATE:
      return { ...state, stayEndDate: action.payload };
    case SET_HOTELS_VALUE:
      return { ...state, hotelsvalue: action.payload };
    case SET_SELECTED_ITEM_INFO:
      return { ...state, selectedItemInfo: action.payload };
    case SET_OPEN:
      return { ...state, open: action.payload };
    case SET_PICKED_HOTEL_VALUE:
      return { ...state, pickedHotelValue: action.payload };
    case SET_CITY_OR_HOTEL_NAME_OPENED:
      return { ...state, cityOrHotelNameOpened: action.payload };
    case SET_HOTEL_DATES_DIV_OPENED:
      return { ...state, hotelDatesDivOpened: action.payload };
    case SET_SEARCH_CLICKED:
      return { ...state, searchClicked: action.payload };
    case SET_FORM_FILLED:
      return { ...state, formFilled: action.payload };
    // ##### 24-05-13 Komarov
    case SET_PICKED_START_DATE:
      return { ...state, pickedStartDate: action.payload };
    case SET_PICKED_END_DATE:
      return { ...state, pickedEndDate: action.payload };
    case SET_COUNTER_ADULTS_OUTER:
      return { ...state, counterAdultsOuter: action.payload };
    case SET_COUNTER_CHILD_OUTER:
      return { ...state, counterChildOuter: action.payload };
    case SET_COUNTER_ROOMS_OUTER:
      return { ...state, counterRoomsOuter: action.payload };
    case SET_AUTOCOMPLETE_DIV_OPENED:
      return { ...state, autocompleteDivOpened: action.payload };
    case SET_PAX_CHOICE_DIV_OPENED:
      return { ...state, paxChoiceDivOpened: action.payload };
    default:
      return state;
  }
};
