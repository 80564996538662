// ##### 25-02-05 Komarov
import React, { createContext, useMemo, useState } from "react";
// ##### 25-02-05 Komarov
import { useHistory, useLocation } from "react-router-dom";
// ##### 23-11-09 Komarov
import { useSelector } from "react-redux";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages";
// ##### 23-11-22 Komarov
import { Media } from "../../../../helpers/MediaConfig";
// ##### 25-02-05 Komarov
import { makeParamsString, ValidateQuery } from "../../Helpers/helper";
// ##### 24-12-27 Komarov
import { encryptString } from "../../../../helpers/crypto";
// ##### 25-02-05 Komarov
import { BookButtonHotelButton } from "./BookButtonHotelButton";

// ##### 23-09-14 Komarov
// import './BookButtonHotelCSS.css'

// ##### 25-02-05 Komarov
export const BookButtonHotelContext = createContext();

export const BookButtonHotel = (props) => {
  // ##### 24-04-23 Komarov
  const Book_Now = useMessages("Book Now");
  // ##### 24-04-23 Komarov
  const LeaveRequest = useMessages("LeaveRequest");

  // ##### 24-04-05 Komarov
  const {
    selectedAvailability,
    room_id,
    totalsum,
    tariff_id,
    occupancy,
    pickedCurrency,
    // ##### 25-02-18 Komarov
    nrf,
    start,
    end,
    available_now,
    // ##### 24-04-17 Komarov
    room_name,
    tariff_type_name,
    cityName,
  } = props;
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  const [booking, setBooking] = useState([]);
  const history = useHistory();

  const redux_search_data = useSelector(
    (state) => state.hotelsearchdata.hotel_search_data,
  );
  // ##### 24-04-12 Komarov
  const redux_pax_data = useSelector((state) => state.paxchoice.pax);
  const hotelcontents = useSelector((state) => state.hotelcontent.hotelcontent);
  // ##### 25-01-15 Komarov
  const hotel_cities = useSelector((state) => state.hotelcities.hotel_cities);
  // ##### 24-04-19 Komarov
  const localized_city_name =
    hotel_cities.find(
      (hotel_city) =>
        parseInt(hotel_city.hotel_id) === parseInt(redux_search_data.hotel_id),
    )?.localized_city_name ||
    cityName ||
    "Not set";
  const location = useLocation();
  // ##### 25-01-15 Komarov
  const shouldCypherUrlParams = useSelector(
    (state) => !!parseInt(state.siteCypherCodeData.data.value),
  );
  // ##### 25-02-28 Komarov
  const cypherKey = useSelector(
    (state) => state.siteCypherCodeData.data.description,
  );
  const search_data = ValidateQuery(location, shouldCypherUrlParams, cypherKey);

  let hotel_name;
  // ##### 24-04-29 Komarov
  let hotel_category;
  // ##### 24-03-21 Komarov
  for (const item of hotelcontents) {
    if (item.content_name === "Title") hotel_name = item.text;
    if (item.hotel_parameters) hotel_category = item.hotel_parameters.category;
  }

  // console.log('BOOKBUTTON', search_data)
  // ##### 24-03-01 Komarov
  const whitelabel = useSelector((state) => state.whitelabel);
  const isWhiteLabelSet = whitelabel.set;
  const company_cipher = whitelabel.company_cipher;
  const promoCode = useSelector((state) => state.promocode.promocode);

  // ##### 24-04-17 Komarov
  const AddToBasket = () => {
    const newBooking = {
      tariff_id,
      room_id,
      amount: totalsum || "No price was shown",
      pickedCurrency,
      rooms: selectedAvailability || "No availability was shown",
    };
    setBooking([newBooking]);

    const params_string = makeParamsString({
      selected_currency: pickedCurrency,
      service_type_id: 1,
      start,
      end,
      tariff_id,
      room_id,
      numberofunits: selectedAvailability,
      hotel_id: redux_search_data.hotel_id,
      adults: occupancy || redux_pax_data.adults || "Not set",
      children:
        redux_search_data.children || redux_pax_data.children || "Not set",
      htlName: hotel_name,
      amount: totalsum,
      // ##### 25-02-18 Komarov
      nrf,
      // ##### 24-04-17 Komarov
      room_name,
      tariff_type_name,
      // ##### 24-05-01 Komarov
      cityName: search_data.cityName || localized_city_name,
      available_now,
      hotel_category,
      // ##### 24-05-01 Komarov
      city_id: search_data.city_id,
    });
    // ##### 24-12-27 Komarov
    const route_query = `?${shouldCypherUrlParams ? encryptString(params_string) : params_string}`;

    // ##### 24-02-29 Komarov: TODO: Убрать промокод из URL
    if (isWhiteLabelSet) {
      const currentUrl = `/ref${company_cipher}/booking_form/${route_query}`;
      // ##### 24-08-01 Komarov
      // writeUrlToRedux(currentUrl);
      history.push(`/${locale}${currentUrl}`, [...booking, newBooking]);
    } else if (promoCode) {
      // ##### 24-08-05 Komarov
      // TODO: Происходит смешивание понятий refpartner и promoCode, - нужно что-то с этим сделать
      const currentUrl = `/booking_form/${route_query}`;
      // ##### 24-08-01 Komarov
      // writeUrlToRedux(currentUrl);
      history.push(`/${locale}${currentUrl}`, [...booking, newBooking]);
    } else {
      const currentUrl = `/booking_form/${route_query}`;
      // ##### 24-08-01 Komarov
      // writeUrlToRedux(currentUrl);
      history.push(`/${locale}${currentUrl}`, [...booking, newBooking]);
    }
  };

  // ##### 25-01-22 Komarov
  const bookButtonHotelContextValue = useMemo(
    () => ({
      AddToBasket,
      Book_Now,
      // ##### 24-04-05 Komarov
      LeaveRequest,
      available_now,
    }),
    [Book_Now, LeaveRequest, available_now],
  );

  // ##### 23-03-01 Komarov
  return (
    //  ##### 23-02-22 Komarov
    <BookButtonHotelContext.Provider value={bookButtonHotelContextValue}>
      {/* ##### 24-04-16 Komarov */}
      <Media greaterThanOrEqual="Tablet-768-1024">
        <BookButtonHotelButton
          className={
            (available_now && "AddToBasketButtonAllotment") ||
            "AddToBasketButtonRequest"
          }
        />
      </Media>
      <Media lessThan="Tablet-768-1024">
        <BookButtonHotelButton
          className={
            (available_now && "AddToBasketButtonSmallScreenAllotment") ||
            "AddToBasketButtonSmallScreenRequest"
          }
        />
      </Media>
    </BookButtonHotelContext.Provider>
  );
};
