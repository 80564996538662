import config from "../config";
// ##### 24-03-21 Komarov
import {
  GET_LANG_ERR,
  GET_LANG_REQ,
  GET_LANG_RES,
  GET_LANGS_ERR,
  GET_LANGS_REQ,
  GET_LANGS_RES,
  GET_LOCALE_ERR,
  GET_LOCALE_REQ,
  GET_LOCALE_RES,
} from "../constants";

const initState = {
  // ##### 24-03-05 Komarov: Похоже, что свойство current_locale нигде не используется
  current_locale: config.defaultLang,
  current_locale_loaded: true,
  current_locale_errors: [],

  locales: "",
  locales_loaded: false,
  // ##### 24-03-21 Komarov
  // errors:[],

  // ##### 24-03-05 Komarov
  default_language: "uk",
  languages: "",
  languages_loaded: false,
  errors: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_LANG_REQ:
      return {
        ...state,
        locales_loaded: true,
      };

    case GET_LANG_RES:
      return {
        ...state,
        locales_loaded: true,
        locales: action.payload,
      };

    case GET_LANG_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // case CHANGE_LOCALE:
    // 	return({
    // 		...state,
    // 		current_locale: action.payload
    // 	})
    case GET_LOCALE_REQ:
      return {
        ...state,
        current_locale_loaded: true,
      };

    case GET_LOCALE_RES:
      return {
        ...state,
        current_locale_loaded: true,
        current_locale: action.payload,
      };

    case GET_LOCALE_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        current_locale_errors: (state.errors && [
          ...state.errors,
          action.error,
        ]) || [action.error],
      };

    //  ##### 23-08-17 Komarov
    case GET_LANGS_REQ:
      return {
        ...state,
        languages_loaded: true,
      };

    case GET_LANGS_RES:
      return {
        ...state,
        languages_loaded: true,
        languages: action.payload,
      };

    case GET_LANGS_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        languages_errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    default:
      return state;
  }
};

export default reducer;
