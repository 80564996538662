import React from "react";
import { Switch } from "react-router-dom";
import { localizeRoutePath } from "../../Pages/Helpers/localizeRoutePath";

// ##### 24-08-16 Komarov
export const LocalizationSwitch = ({ children }) => (
  <Switch>
    {React.Children.map(children, (children) => React.cloneElement(children, {
      ...children.props,
      path: localizeRoutePath(children.props.path),
    }))}
  </Switch>
);
