import { SET_DATA } from "../constants";

// ##### 23-03-28 Komarov: Функция для добавления в Redux store поля data, в которое можно записывать, например, в файле src/Redux/helpers/renderer.js любые нужные нам данные для отправки на клиент через объект window.INITIAL_STATE
export default (state = {}, action) => {
  switch (action.type) {
    case SET_DATA:
      return action.payload;
    default:
      return state;
  }
};
