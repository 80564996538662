import { isBrowser } from "./browserDetector";
import serverLog from "./serverLog";

export const uniLog = (...args) => {
  if (process.env.NODE_ENV === "development") {
    if (isBrowser) {
      console.log(args);
    } else {
      serverLog(args);
    }
  }
};
