// ##### 23-03-29 Komarov
import React, { useState } from "react";
// ##### 23-04-12 Komarov
import uniqueId from "lodash.uniqueid";

// ##### 23-09-14 Komarov
// import './CartGalleryShortVersionCSS.css'

export const CartGalleryShortVersion = ({ photos }) => {
  const [picked, setPicked] = useState(
    `${(process.env.REACT_APP_PROTOCOL || "https://") + photos.text[0]}`
  );
  const [activeIndex, setActiveIndex] = useState();

  const pickAction = (index) => (e) => {
    setPicked(e.target.getAttribute("src"));
    setActiveIndex(index);
    // console.log('[PICKED]:',e.target.getAttribute('src'))
  };

  return (
    <div data-component="CartGalleryShortVersion">
      <div>
        <img
          src={picked}
          // ##### 24-03-18 Komarov: TODO: Взять значение атрибута alt из Смарта
          alt="Picked gallery element"
          style={{
            padding: "5px",
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
            height: "45vh",
            borderRadius: "0.7vw",
            // ##### 23-11-09 Komarov
            objectFit: "cover",
          }}
        />
      </div>

      {/* ##### 24-03-21 Komarov */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          //  justifyContent:'space-evenly'
          // display:'grid',
          // gridTemplateColumns:'repeat(4,25%)'
        }}
      >
        {
          // ##### 24-03-21 Komarov
          photos.text.map((image, index /* ,array */) => {
            return (
              // ##### 23-04-12 Komarov
              <div key={uniqueId()}>
                {index > 1 && index <= 5 ? (
                  <img
                    src={(process.env.REACT_APP_PROTOCOL || "https://") + image}
                    // ##### 24-03-18 Komarov: TODO: Взять значение атрибута alt из Смарта
                    alt=""
                    className={
                      activeIndex === index ? "PickedImage" : "NotPickedImage"
                    }
                    onClick={pickAction(index)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") pickAction(index)();
                    }} // Example of adding keyboard accessibility
                  />
                ) : null}
              </div>
            );
          })
        }
      </div>
    </div>
  );
};
