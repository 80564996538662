// ##### 25-02-21 Komarov
import React from "react";
import { LangSelectionTool } from "../../../../Library/Localization/LangSelectionTool/LangSelectionTool";
import { CurrencySelectionTool } from "../../CurrencySelectionTool/CurrencySelectionTool";

export const CurrencyAndLanguageSelectionTools = ({ className }) => {
  return (
    <div
      className={className}
      data-component="CurrencyAndLanguageSelectionTools"
    >
      {/* ##### 25-02-21 Komarov */}
      <CurrencySelectionTool />
      <LangSelectionTool />
    </div>
  );
};
