// ##### 24-04-05 Komarov
import { SET_ORDER_ID, SET_ITEM_INFO } from "../actions/types";

const initialState = {
  orderId: null,
  selectedItemInfo: undefined,
};

function order(state = initialState, action) {
  switch (action.type) {
    case SET_ORDER_ID:
      return { ...state, orderId: action.payload };
    case SET_ITEM_INFO:
      return { ...state, selectedItemInfo: action.payload };
    default:
      return state;
  }
}

export default order;
