// ##### 23-03-29 Komarov
import {
  GET_TOGGLER_TYPE_REQ,
  GET_TOGGLER_TYPE_RES,
  GET_TOPHOTELS_ERR,
  GET_TOPHOTELS_REQ,
  GET_TOPHOTELS_RES,
} from "../constants";
// ##### 25-01-10 Komarov
import { getUrl } from "../../helpers/crypto";
// ##### 25-02-26 Komarov
import { uniLog } from "../../../server/helpers/uniLog";
// ##### 25-03-28 Komarov
import { getSavingDataClient } from "../../helpers/saveData";

// ##### 24-06-13 Komarov
export const getTopHotels =
  (deviceType = "desktop") =>
  // ##### 25-03-06 Komarov
  async (dispatch) => {
    dispatch({ type: GET_TOPHOTELS_REQ });

    // ##### 25-01-10 Komarov
    const url = getUrl("tophotels", `deviceType=${deviceType}`);

    // ##### 25-02-26 Komarov
    uniLog(
      "tophotels.js",
      "axios:src/Redux/actions/tophotels.js:getTopHotels, url:",
      url,
    );

    // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
    await getSavingDataClient({
      smartUrl: url,
      typeRes: GET_TOPHOTELS_RES,
      typeErr: GET_TOPHOTELS_ERR,
      dispatch,
    });
  };

export const togglerTypeResponse = (res) => ({
  type: GET_TOGGLER_TYPE_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
export const getTogglerType = (param) => (dispatch) => {
  dispatch({ type: GET_TOGGLER_TYPE_REQ });
  dispatch(togglerTypeResponse(param));
};
