// ##### 24-04-02 Komarov: Отображение перечня отелей города или одного отеля, если в Autocomplete был выбран отель
import { HotelRates } from "../HotelRates/HotelRates";
import { HotelContent } from "../HotelContent/HotelContent";
import React from "react";

export const HotelItem = (props) => {
  return (
    <li className={props.className}>
      <HotelRates
        hotelPrice={props.hotels[props.hotelId].min_price}
        hotel_id={props.hotelId}
      />

      <HotelContent hotel_id={props.hotelId} />
    </li>
  );
};
