// ##### 23-12-27 Komarov
import React, { useState } from "react";
// ##### 24-08-01 Komarov
import { /* useDispatch, */ useSelector } from "react-redux";
// ##### 23-03-29 Komarov
import { Link } from "react-router-dom";
import { localizePath } from "../../Pages/Helpers/localizeRoutePath";
// ##### 24-08-01 Komarov
// import { setCurrentUrl } from "../../../Redux/actions";
// ##### 23-08-29 Komarov
import { IconHint } from "../Icons/iconHint";

// ##### 23-08-29 Komarov
export const LocalizationNavLink = ({
  to,
  onClick,
  children,
  hintIsPresent = false,
  hintText = "",
}) => {
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  // ##### 24-08-07 Komarov
  const handleOnClick = () => {
    if (typeof onClick === "function") onClick();
  }

  // ##### 23-08-29 Komarov
  const [isHintShown, setIsHintShown] = useState(false);
  const [y, setY] = useState(undefined);
  const [x, setX] = useState(undefined);

  const onMouseEnter = (e) => {
    setIsHintShown(true);
    setY(e.clientY - 50);
    setX(e.clientX);
    e.stopPropagation();
  };

  const onMouseLeave = () => {
    setIsHintShown(false);
    setY(undefined);
    setX(undefined);
  };

  return (
    <>
      <Link
        to={localizePath(to, locale)}
        // ##### 24-08-05 Komarov
        // onClick={() => rememberLink(to)}
        // ##### 24-08-07 Komarov
        onClick={handleOnClick}
        // ##### 23-08-29 Komarov
        onMouseEnter={(hintIsPresent && onMouseEnter) || undefined}
        onMouseLeave={(hintIsPresent && onMouseLeave) || undefined}
      >
        {children}
      </Link>
      {/* ##### 23-08-29 Komarov */}
      {hintText !== "" && <span
        style={
          (x && { position: "absolute", left: x }) || { position: "absolute" }
        }
      >
        <IconHint isHintShown={isHintShown} hintText={hintText} y={y} />
      </span>}
    </>
  );
};
