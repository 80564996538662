import React from "react";
import { useSelector } from "react-redux";
import { HomeIcon } from "../Icons/homeicon";
import { LocalizationNavLink } from "../Localization/LocalizationNavLink";
// ##### 23-11-01 Komarov
import { Media } from "../../../helpers/MediaConfig";
import { useMessages } from "../../../helpers/messages";
import parse from "html-react-parser";

export const HomeLinkTag = () => {
  const deviceType = useSelector((state) => state.deviceType);
  const hintText = useMessages("Home_icon");

  return (
    <LocalizationNavLink exact to={""}>
      {
        // ##### 23-04-24 Komarov
        deviceType === "tablet" ? (
          <HomeIcon color={"white"} hintText={parse(hintText)} />
        ) : (
          <>
            {/* ##### 23-11-01 Komarov */}
            <Media greaterThanOrEqual="Laptop-1024-1440">
              <div
                style={{
                  position: "relative",
                  zIndex: 2001,
                }}
              >
                <HomeIcon color={"white"} hintText={parse(hintText)} />
              </div>
            </Media>
            <Media lessThan="Laptop-1024-1440">
              <HomeIcon color={"white"} hintText={parse(hintText)} />
            </Media>
          </>
        )
      }
    </LocalizationNavLink>
  );
};
