// ##### 25-02-05 Komarov
import React, { useContext } from "react";
import { ToursAutocompleteSmallScreenContext } from "./ToursAutocompleteSmallScreen";

export const ClickableLayer_02_ToursAutocompleteSmallScreenDivTag_Alert = ({
  width,
  height,
}) => {
  const { chooseMonth } = useContext(ToursAutocompleteSmallScreenContext);
  return (
    <div
      data-component="ClickableLayer_02_ToursAutocompleteSmallScreenDivTag_Alert"
      style={{
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-around",
        width: width,
        backgroundColor: "white",
        border: "red",
        borderStyle: "solid",
        borderRadius: "1rem",
        height: height,
        fontFamily: "Arial",
        alignItems: "center",
        // ##### 23-04-26 Komarov
        margin: "0.3vw",
      }}
      id="clickableLayer_02_ToursAutocompleteSmallScreen_Alert"
    >
      {/* ##### 23-03-30 Komarov */}
      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
          // marginTop: "0.25em",
          textAlign: "center",
          lineHeight: 1,
          color: "red",
        }}
      >
        {chooseMonth}
      </div>
    </div>
  );
};
