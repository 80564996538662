// ##### 24-06-12 Komarov
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { LangSelectionTool } from "../../../../Library/Localization/LangSelectionTool/LangSelectionTool";
import { CurrencySelectionTool } from "../../CurrencySelectionTool/CurrencySelectionTool";
import { TopMenuContext } from "./Header";

export const CurrencyAndLanguageSelectionTools = ({ className }) => {
  // ##### 24-05-10 Komarov
  const { lang, path, location } = useContext(TopMenuContext);
  const deviceType = useSelector((state) => state.deviceType);
  return (
    <div
      className={className}
      data-component="CurrencyAndLanguageSelectionTools"
    >
      {/* ##### 23-04-24 Komarov */}
      <CurrencySelectionTool path={path} />
      <LangSelectionTool
        language={lang}
        location={location}
        deviceType={deviceType}
      />
    </div>
  );
};
