// ##### 23-09-26 Komarov
import React from "react";
import { CupOfCoffee } from "./Animation/CupOfCoffee";
// ##### 24-04-23 Komarov
// ##### 23-12-29 Komarov
// ##### 25-02-05 Komarov
import { NoSearchResult } from "./NoSearchResult";

// ##### 25-01-28 Komarov
export const LoadingMessage = ({
  loadingMessageClass,
  itemWidth,
  noTextMessage,
  noBackground,
  smartResultFound,
}) => {
  return (
    <div
      className={loadingMessageClass ? loadingMessageClass : "Loading"}
      style={{
        width: `${itemWidth ? itemWidth : null}`,
        // @@@@@ 23-01-30 Komarov: Разместил надпись по центру вертикально
        //  display:'block',
        // ##### 23-11-22 Komarov
        //  position:'absolute',
        zIndex: "30000",
        backgroundColor: `${noBackground ? "white" : "#cce0ff"}`,
        borderRadius: "5px",
      }}
    >
      {
        // ##### 23-02-17 Komarov: Отображаем текст с предложением обратиться на email в том случае, если из Смарта пришёл ответ с пустым результатом (не нашлось отелей по этому результату)
        // ##### 23-12-29 Komarov
        smartResultFound === "Nothing found" ? (
          <NoSearchResult noTextMessage={noTextMessage} />
        ) : (
          <CupOfCoffee />
        )
      }
    </div>
  );
};
