// ##### 23-12-27 Komarov
import React from "react";
// ##### 24-08-01 Komarov
import { useSelector/* , useDispatch */ } from "react-redux";
import parse from "html-react-parser";
import { useHistory } from "react-router-dom";
import uniqueId from "lodash.uniqueid";
// ##### 24-08-01 Komarov
// import { setCurrentUrl } from "../../../../Redux/actions";
// ##### 23-11-02 Komarov
import { Media } from "../../../../helpers/MediaConfig";

// ##### 23-09-14 Komarov
// import '../../PageComponents/ResponsiveHeader/header.css'
// import './TopToursCSS.css'

// ##### 23-11-02 Komarov
const BodyPageImg = ({ item, style = null /* , className = '' */ }) => (
  <img
    data-component="BodyPageImg"
    // className={className}
    // ##### 24-03-21 Komarov
    alt="Banner"
    style={style}
    src={`${process.env.REACT_APP_PROTOCOL + item.text}`}
  />
);

export const BodyPage = ({ sidePageID, sitePageTypeID, sitePageName }) => {
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  // ##### 24-07-25 Komarov
  const purePagesSsr = useSelector((state) => state.pages.purePagesSsr);
  const article = purePagesSsr[sidePageID][locale] || JSON.parse(localStorage.getItem("purePagesSsr"))[sidePageID];

  const history = useHistory();

  const GetBannerDetails = () => {
    // ##### 24-03-21 Komarov
    const route_query = `/${locale}/sitepages/?name=${sitePageName
      .replace(/\s/g, "-")
      .toLowerCase()}&id=${sidePageID}`;
    history.push(route_query);
  };

  return (
    <div
      data-component="BodyPage"
      className="frontPageArticle"
      style={{ order: `${sitePageTypeID === 46 ? 3 : 5}` }}
    >
      {article
        ? article.map((item) => {
          if (item.content_name === "Image" && sitePageTypeID === 46) {
            return (
              <div
                // ##### 23-11-07 Komarov
                key={uniqueId()}
                onClick={GetBannerDetails}
                // ##### 24-03-21 Komarov
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    GetBannerDetails();
                  }
                }}
                className="frontPageBanner"
              >
                {/* ##### 23-11-08 Komarov */}
                <BodyPageImg item={item} style={{ width: "100%" }} />
              </div>
            );
          }

          // ##### 24-03-21 Komarov
          if (item.content_name === "Body" && sitePageTypeID !== 46) {
            // ##### 23-03-16 Komarov
            return (
              <React.Fragment key={uniqueId()}>
                {/* ##### 23-11-07 Komarov */}
                <Media
                  lessThan="Laptop-1024-1440"
                  key={uniqueId()}
                  style={{
                    order: "2",
                    width: "95%",
                  }}
                >
                  {parse(item.text)}
                </Media>
                <Media
                  greaterThanOrEqual="Laptop-1024-1440"
                  key={uniqueId()}
                  style={{
                    order: "2",
                    width: "70%",
                  }}
                >
                  {parse(item.text)}
                </Media>
              </React.Fragment>
            );
          }
        })
        : null}
    </div>
  );
};
