import React from "react";
// ##### 24-05-13 Komarov
import { useSelector } from "react-redux";
import moment from "moment";
import { dateIsBeforeToday } from "../helpers/dateIsBeforeToday";
// ##### 24-05-13 Komarov
import { PlaceHolderStrings } from "../../../../Library/Localization/placeholders";
import dateFormats from "../../../../../helpers/dateFormats";

export const Day = ({
  day,
  currentMonth,
  yearsMonths,
  markedDates,
  calendarHeader,
  // ##### 24-05-20 Komarov
  selectedDays,
}) => {
  const placeholder = PlaceHolderStrings();
  const locale = useSelector((state) => state.language);

  // ##### 24-01-19 Komarov: Алгоритм работы с кликами по датам календаря
  // 1. Кликнули по дате в первый раз - она отмечается как дата заезда в ref. И отмечается соотв. CSS-классом, и добавляется в набор отмеченных дат.
  // 2. Кликнули по дате большей (наступающей позже), чем первая, - она отмечается как дата выезда в ref. И отмечается соотв. классом CSS, и добавляется в набор отмеченных дат. И промежуточные даты выделяются соотв. CSS-классом и добавляются в набор отмеченных дат.
  // 3. Кликнули по дате меньшей (наступающей раньше), чем первая, - все даты (в наборе отмеченных дат) очищаются от отметок, а эта дата отмечается как дата заезда в ref, и дальше, как в п. 1.
  // 4. При выбранном диапазоне дат кликнули по любой дате (отмеченной или нет), - все отметки очищаются, эта дата отмечается как дата заезда в ref, и дальше, как в п. 1.
  // 5. Кликнули на кнопку OK - даты заезда и выезда переносятся из ref в state. И очищается набор отмеченных дат
  // 6. Кликнули по кнопке закрытия окна, - все отмеченные даты очищаются от отметок и окно закрывается (без установки значений отмеченных дат в state).
  // 7. Пока не выбраны дата заезда и дата выезда, - кнопка OK не активна.
  const clickOnDay = (e) => {
    const yearsMonthsElement = yearsMonths.current;
    const calendarHeaderElement = calendarHeader.current;
    // ##### 24-01-20 Komarov: Функция для испарвления выбранного элемента даты на на элемент, дата которого принадлежит его месяцу (например, при выборе элемента даты первого апреля, находящегося в конце блока марта, выберется элемент в блоке апреля)
    const setRightDateElement = (element) => {
      const selectedDateCurrentMonth = parseInt(element.dataset.currentmonth);
      const selectedDateMonthDayFrom = element.dataset.monthdayfrom;
      const selectedDateDayOfYear = element.dataset.dayofyear;
      if (selectedDateMonthDayFrom === "prev") {
        const rightDateElement = yearsMonthsElement.querySelector(
          `[data-currentmonth='${
            selectedDateCurrentMonth - 1
          }'][data-dayofyear='${selectedDateDayOfYear}']`
        );
        return rightDateElement;
      }
      if (selectedDateMonthDayFrom === "next") {
        const rightDateElement = yearsMonthsElement.querySelector(
          `[data-currentmonth='${
            selectedDateCurrentMonth + 1
          }'][data-dayofyear='${selectedDateDayOfYear}']`
        );
        return rightDateElement;
      }
      return element;
    };
    const selectedDateElement = setRightDateElement(e.target);
    const selectedDateDay = selectedDateElement.dataset.day;
    const selectedDateMonth = parseInt(
      selectedDateElement.dataset.currentmonth
    );
    const headerStartDateElement =
      calendarHeaderElement.querySelector("#headerStartDate");
    const headerStartDateInitialValue = placeholder.placeHolderStartDate;
    const headerEndDateElement =
      calendarHeaderElement.querySelector("#headerEndDate");
    const headerEndDateInitialValue = placeholder.placeHolderEndDate;
    const okButtonCalendarElement = document.querySelector("#okButtonCalendar");
    const markedDatesSet = markedDates.current;
    const markedDatesCount = markedDatesSet.size;
    // ##### 24-05-20 Komarov
    const markStartDate = () => {
      const classToAdd = "calendar-start-date";
      selectedDateElement.classList.add(classToAdd);
      selectedDays.current = {
        ...selectedDays.current,
        [`${selectedDateElement.dataset.dayofyear}`]: classToAdd,
      };
    };
    const markEndDate = () => {
      const classToAdd = "calendar-end-date";
      selectedDateElement.classList.add(classToAdd);
      selectedDays.current = {
        ...selectedDays.current,
        [`${selectedDateElement.dataset.dayofyear}`]: classToAdd,
      };
    };
    const markMiddleDates = () => {
      const classToAdd = "calendar-middle-date";
      const startDateElement = yearsMonthsElement.querySelector(
        ".calendar-start-date"
      );
      const endDateElement =
        yearsMonthsElement.querySelector(".calendar-end-date");
      const startDateDateRelativeDay = parseInt(
        startDateElement.dataset.relativeday
      );
      const endDateDateRelativeDay = parseInt(
        endDateElement.dataset.relativeday
      );
      for (
        let relativeday = startDateDateRelativeDay + 1;
        relativeday < endDateDateRelativeDay;
        relativeday++
      ) {
        const middleDateElement = document.querySelector(
          `[data-relativeday='${relativeday}'][data-monthdayfrom='current']`
        );
        // ##### 24-05-20 Komarov
        middleDateElement.classList.add(classToAdd);
        selectedDays.current = {
          ...selectedDays.current,
          [`${middleDateElement.dataset.dayofyear}`]: classToAdd,
        };
      }
    };
    const rememberDate = () =>
      markedDatesSet.add({
        selectedDateDay,
        selectedDateMonth,
      });
    const forgetDates = () => markedDatesSet.clear();
    const clearMarkedDates = () => {
      const daysWithCalendarStartDateClass =
        yearsMonthsElement.querySelectorAll(".calendar-start-date");
      for (const day of daysWithCalendarStartDateClass) {
        day.classList.remove("calendar-start-date");
      }
      const daysWithCalendarMiddleDateClass =
        yearsMonthsElement.querySelectorAll(".calendar-middle-date");
      for (const day of daysWithCalendarMiddleDateClass) {
        day.classList.remove("calendar-middle-date");
      }
      const daysWithCalendarEndDateClass =
        yearsMonthsElement.querySelectorAll(".calendar-end-date");
      for (const day of daysWithCalendarEndDateClass) {
        day.classList.remove("calendar-end-date");
      }
      forgetDates();
      // ##### 24-05-20 Komarov
      selectedDays.current = undefined;
    };
    const formattedDate = moment(parseInt(selectedDateDay)).format(
      dateFormats[locale]
    );
    const setStartDateInHeader = () => {
      headerStartDateElement.textContent = formattedDate;
    };
    const setEndDateInHeader = () => {
      headerEndDateElement.textContent = formattedDate;
    };
    const clearStartDateInHeader = () => {
      headerStartDateElement.textContent = headerStartDateInitialValue;
    };
    const clearEndDateInHeader = () => {
      headerEndDateElement.textContent = headerEndDateInitialValue;
    };
    const setStartDateInHeaderSelected = (isSelected) => {
      headerStartDateElement.dataset.isdateselected = isSelected;
    };
    const setEndDateInHeaderSelected = (isSelected) => {
      headerEndDateElement.dataset.isdateselected = isSelected;
    };
    const setOkButtonActive = () => {
      okButtonCalendarElement.classList.remove("calendar-ok-button-inactive");
      okButtonCalendarElement.classList.add("calendar-ok-button-active");
    };
    const setOkButtonInActive = () => {
      okButtonCalendarElement.classList.remove("calendar-ok-button-active");
      okButtonCalendarElement.classList.add("calendar-ok-button-inactive");
    };

    // 1. Кликнули по дате в первый раз - она отмечается как дата заезда в ref. И отмечается соотв. CSS-классом, и добавляется в набор отмеченных дат.
    if (markedDatesCount === 0) {
      markStartDate();
      setStartDateInHeader();
      setStartDateInHeaderSelected(true);
      setOkButtonInActive();
    }

    // 2. Кликнули по дате большей (наступающей позже), чем первая, - она отмечается как дата выезда в ref. И отмечается соотв. классом CSS, и добавляется в набор отмеченных дат. И промежуточные даты выделяются соотв. CSS-классом и добавляются в набор отмеченных дат.
    else if (markedDatesCount === 1) {
      const startDateDay = Array.from(markedDatesSet)[0].selectedDateDay;
      if (selectedDateDay > startDateDay) {
        markEndDate();
        setEndDateInHeader();
        setEndDateInHeaderSelected(true);
        setOkButtonActive();
        markMiddleDates();
      }

      // 3. Кликнули по дате меньшей (наступающей раньше), чем первая, - все даты (в наборе отмеченных дат) очищаются от отметок, а эта дата отмечается как дата заезда в ref, и дальше, как в п. 1.
      else if (selectedDateDay < startDateDay) {
        clearMarkedDates();
        clearStartDateInHeader();
        clearEndDateInHeader();
        setStartDateInHeaderSelected(false);
        setEndDateInHeaderSelected(false);
        markStartDate();
        setStartDateInHeader();
        setStartDateInHeaderSelected(true);
        setOkButtonInActive();
      }
    }

    // 4. При выбранном диапазоне дат кликнули по любой дате (отмеченной или нет), - все отметки очищаются, эта дата отмечается как дата заезда в ref, и дальше, как в п. 1.
    else if (markedDatesCount === 2) {
      clearMarkedDates();
      clearStartDateInHeader();
      clearEndDateInHeader();
      setStartDateInHeaderSelected(false);
      setEndDateInHeaderSelected(false);
      markStartDate();
      setStartDateInHeader();
      setStartDateInHeaderSelected(true);
      setOkButtonInActive();
    }
    rememberDate();
  };

  const fromPrevOrNextMonth = (day) => {
    if (day.year() === moment().month(currentMonth).year()) {
      if (day.month() < currentMonth % 12) {
        return "prev";
      }
      if (day.month() > currentMonth % 12) {
        return "next";
      }
    } else if (day.year() < moment().month(currentMonth).year()) {
      return "prev";
    } else {
      return "next";
    }
    return "current";
  };

  return (
    <div
      data-component="Day"
      // ##### 24-05-20 Komarov
      // className="center"
      className={`center ${
        fromPrevOrNextMonth(day) === "current" &&
        selectedDays.current?.[`${moment(day).dayOfYear()}`] &&
        selectedDays.current[`${moment(day).dayOfYear()}`]
      }`}
      style={{
        width: "100%",
        height: "100%",
      }}
      onClick={(e) => {
        if (!dateIsBeforeToday(day)) {
          clickOnDay(e);
        }
      }}
      onKeyDown={(e) => {
        // Trigger click action on Enter key press
        if (!dateIsBeforeToday(day)) {
          if (e.key === "Enter") {
            clickOnDay(e);
          }
        }
      }}
      data-day={day}
      data-dayofyear={`${moment(day).dayOfYear()}`}
      data-currentmonth={`${currentMonth}`}
      data-monthdayfrom={`${fromPrevOrNextMonth(day)}`}
      data-relativeday={`${moment(day).diff(moment(), "days")}`}
    >
      {day.format("DD")}
    </div>
  );
};
