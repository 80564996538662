// ##### 24-09-09 Komarov
import React, { createContext, useMemo } from "react";
// ##### 24-03-21 Komarov
// ##### 23-09-14 Komarov
// import './SliderCss.css'
// ##### 25-02-04 Komarov
// ##### 23-11-02 Komarov
import { Media } from "../../../helpers/MediaConfig";
// ##### 25-02-05 Komarov
import { SliderDiv } from "./SliderDiv";

export const SliderContext = createContext();

const Slider = (props) => {
  // ##### 24-03-21 Komarov
  // const deviceType = useSelector(state => state.deviceType);

  // console.log(props.slides)

  // ##### 24-09-11 Komarov
  const sliderContextValue = useMemo(() => ({ props }), [props]); // value is cached by useMemo

  return (
    <SliderContext.Provider value={sliderContextValue}>
      {/* ##### 23-11-02 Komarov */}
      <Media lessThan="Mobile-L-425-768">
        <SliderDiv className="SliderTabletPortraitCSS" />
      </Media>
      <Media between={["Mobile-L-425-768", "Laptop-1024-1440"]}>
        <SliderDiv className="SliderMobilePortraitCSS" />
      </Media>
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <SliderDiv className="SliderCSS" />
      </Media>
    </SliderContext.Provider>
  );
};

export default Slider;
