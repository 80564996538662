export const VehicleTypes = (locale) => {
  // ##### 24-04-13 Komarov: TODO: Взять переводы из Смарта
  const obj = {
    en: ["car", "minibus", "32-seater bus", "45-seater bus"],
    uk: ["авто", "мікроавтобус", "32-місний автобус", "45-місний автобус"],
    ru: ["машина", "микроавтобус", "32-местный автобус", "45-местный автобус"],
    tr: ["araba", "minibus", "32-koltuklu otobus", "45-koltuklu otobus"],
  };

  // ##### 24-03-21 Komarov
  for (const key in obj) {
    if (key === locale) return obj[key];
  }
  // console.log('AAA_KATE_KATE',obj)
};
