// ##### 25-02-05 Komarov
import React, { useContext } from "react";
import { SwitcherItemContext } from "./Switcher";

export const SwitcherButton = ({ className }) => {
  const { changeHandler, children } = useContext(SwitcherItemContext);
  // ##### 24-03-21 Komarov
  return (
    <button
      type="button"
      className={className}
      onClick={changeHandler}
      data-component="SwitcherButton"
    >
      {children}
    </button>
  );
};
