import React, { useEffect } from "react";
import uniqueId from "lodash.uniqueid";
import getIcon from "../helpers/getIcon";
// ##### 24-05-10 Komarov
import { PlaceHolderStrings } from "../../../../Library/Localization/placeholders";
import { saveHotelsContext } from "../../../../../Redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

// ##### 24-05-15 Komarov
export const AutocompleteDiv = ({ cityHotelInput }) => {
  const cityOrHotelNameOpened = useSelector(
    (state) => state.hotelsContext.cityOrHotelNameOpened
  );

  useEffect(() => {
    // ##### 24-05-15 Komarov
    if (cityOrHotelNameOpened && cityHotelInput.current) {
      cityHotelInput.current.firstChild.firstChild.style.cssText =
        "font-weight: bold;";
      cityHotelInput.current.querySelector("input").style.cssText =
        "padding: 0; text-align: center;";
      cityHotelInput.current.querySelector("input").focus();
    }
  }, [cityOrHotelNameOpened]);

  const placeholder = PlaceHolderStrings();
  const smart_translated_hotels = useSelector(
    (state) => state.hotelsContext.smart_translated_hotels
  );
  const dispatch = useDispatch();

  const handleOnSelect = (item) => {
    // ##### 24-05-09 Komarov: TODO: Использовать сохранение city_id в другом месте
    localStorage.setItem("city_id", item.city_id);
    dispatch(
      saveHotelsContext({
        hotelsvalue: item.name,
        selectedItemInfo: item,
        pickedHotelValue: true,
      })
    );
  };

  const formatResult = (item) => {
    return (
      <div key={uniqueId()}>
        {getIcon(item)} {item.name} {item.city === 0 ? " city" : null}
      </div>
    );
  };

  return (
    <div
      ref={cityHotelInput}
      style={{ width: "80vw" }}
      data-component="AutocompleteDiv"
    >
      <ReactSearchAutocomplete
        styling={{
          // width: "60vw",
          height: "38px",
          border: "solid rgb(229, 151, 0)",
          borderRadius: "0.4rem",
          textAlign: "center",
          hoverBackgroundColor: "lightblue",
          fontFamily: "Arial Narrow",
          fontWeight: "bold",
          fontSize: "18px",
          color: "darkslategrey",
          placeholderColor: "grey",
        }}
        showIcon={false}
        autoFocus
        placeholder={placeholder.placeHolderString}
        items={smart_translated_hotels}
        onSelect={handleOnSelect}
        formatResult={formatResult}
        resultStringKeyName="name"
        fuseOptions={{
          minMatchCharLength: 2,
        }}
      />
    </div>
  );
};
