// ##### 24-04-23 Komarov
import { useMessages } from "../../../helpers/messages";

export const PlaceHolderStrings = () => {
  // ##### 24-04-23 Komarov
  const feature1 = useMessages("PleaseInputCityorHotelName");
  const feature2 = useMessages("StartDate");
  const feature3 = useMessages("EndDate");
  const feature4 = useMessages("PleaseInputTourName");
  const feature5 = useMessages("ChooseMonth");
  const feature6 = useMessages("Name");
  const feature7 = useMessages("Surname");
  const feature8 = useMessages("PleaseInputPhoneNumber");
  const feature9 = useMessages("EnterYourEmailAdddress");
  const feature10 = useMessages("SupplierForm_Country");
  const feature11 = useMessages("SupplierForm_city");
  const feature12 = useMessages("SupplierForm_PleaseSelect");
  const feature13 = useMessages("Yes");
  const feature14 = useMessages("No");
  const feature15 = useMessages("NotSure");
  const feature16 = useMessages("RefugeeForm_DateofArrivaltoTurkey");
  const feature17 = useMessages("StarRating");

  // ##### 24-08-16 Komarov
  return {
    placeHolderString: feature1,
    placeHolderStartDate: feature2,
    placeHolderEndDate: feature3,
    placeHolderTour: feature4,
    placeHolderMonth: feature5,
    placeHolderName: feature6,
    placeHolderSurname: feature7,
    placeHolderPhone: feature8,
    placeHolderEmail: feature9,
    placeHolderCountry: feature10,
    placeHolderCity: feature11,
    placeHolderSelect: feature12,
    placeHolderYes: feature13,
    placeHolderNo: feature14,
    placeHolderNotsure: feature15,
    placeHolderDateTurkey: feature16,
    placeHolderStarRating: feature17,
  };
};
