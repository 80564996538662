// ##### 24-05-17 Komarov
import React from "react";
// ##### 24-06-13 Komarov
import { HotelsPaxChoice } from "../HotelsPaxChoice/HotelsPaxChoice";

export const PaxChoice = ({ transform }) => {
  return (
    <div
      data-component="PaxChoice"
      style={{
        transform,
      }}
    >
      <HotelsPaxChoice isOnModalDiv />
    </div>
  );
};
