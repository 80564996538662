// ##### 25-02-05 Komarov
import React from "react";
import { useSelector } from "react-redux";
// ##### 23-10-17 Komarov
import { InputNumber, Select, Space } from "antd";
import parse from "html-react-parser";
// ##### 24-09-09 Komarov
import uniqueId from "lodash.uniqueid";
import { TravelServiceTypesObj } from "../../../../../StaticJsonData/TravelServiceTypes";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../../../../helpers/messages";
// ##### 25-01-02 Komarov
import { markRequired } from "../../../../../../../helpers/markRequired.js";
import { AccommodationComponent } from "./AccommodationComponent";
import { TransfersComponent } from "./TransfersComponent";
import { MealComponent } from "./MealComponent";
import { GuideAssistantComponent } from "./GuideAssistantComponent";
import { ExcursionComponent } from "./ExcursionComponent";
import { FlightTixComponent } from "./FlightTixComponent";
import { TrainTixComponent } from "./TrainTixComponent";
import { ConferenceFacilitiesComponent } from "./ConferenceFacilitiesComponent";
import { AvEquipmentComponent } from "./AvEquipmentComponent";
import { VisaSupportComponent } from "./VisaSupportComponent";
import { BusTransferComponent } from "./BusTransferComponent";
import { RiverCruiseComponent } from "./RiverCruiseComponent";
import { SeaCruiseComponent } from "./SeaCruiseComponent";
import { PackageTourComponent } from "./PackageTourComponent";
import { OtherServiceComponent } from "./OtherServiceComponent";
import { CharterComponent } from "./CharterComponent";

// ##### 23-09-14 Komarov
// import '../GroupTravelFormCSS.css'

// ##### 23-10-14 Komarov
export const ServicesBlock = ({
  selectingAccomTypes,
  accomType,
  accomTypeInitialState,
  SelectSingleRoomsAmount,
  SelectTwinRoomsAmount,
  // ##### 23-10-11 Komarov
  SelectTouristsNumber,
  selectStarRating,
  starRating,
  starRatingInitialState,
  selectVehicleTypes,
  vehicleTypes,
  vehicleTypesInitialState,
  selectMealType,
  mealType,
  mealTypeInitialState,
  chooseMultServices,
  multServices,
  selectGuideTypes,
  guideTypes,
  guideTypesTypesInitialState,
  // ##### 23-10-17 Komarov
  status,
  AddExcursionComments,
  // AddGuideAssistantComments,
  AddFlightTixComments,
  AddTrainTixComments,
  AddConferenceFacilitiesComments,
  AddAvEquipmentComments,
  AddVisaSupportComments,
  AddBusTransferComments,
  AddRiverCruiseComments,
  AddSeaCruiseComments,
  AddPackageTourComments,
  AddOtherServiceComments,
  AddCharterComments,
  excursionComments,
  // guideAssistantComments,
  flightTixComments,
  trainTixComments,
  conferenceFacilitiesComments,
  avEquipmentComments,
  visaSupportComments,
  busTransferComments,
  riverCruiseComments,
  seaCruiseComments,
  packageTourComments,
  otherServiceComments,
  charterComments,
}) => {
  // ##### 23-10-17 Komarov
  const maxLength = 512;

  // ##### 23-10-02 Komarov: вынес вычисление значений для рендеринга (вызовы функций) в код перед рендерингом, иначе возникают ошибки при рендеринге компонентов, скрытых до нажатия кнопки или другого события, по которому соотв. компонент появляется на странице.
  // ##### 24-04-23 Komarov
  const Group_request_services = parse(useMessages("Group_request_services"));
  const SupplierForm_PleaseSelect = useMessages("SupplierForm_PleaseSelect");

  // ##### 23-09-29 Komarov: вычисляем значение переменной для перевода этого message заранее, а не вызывая функцию при рендеринге: такой подход помогает исправить ошибку с сообщением типа "Rendered more hooks than during the previous render" (см. использование этой переменной groupRequestEstablishment ниже в коде)
  // ##### 23-10-11 Komarov
  const group_request_tourists_number = useMessages(
    "Group_request_tourists_number",
  );
  // ##### 23-10-17 Komarov
  // const chooseTypeOfExcursion = useMessages('ChooseTypeOfExcursion');

  // ##### 23-10-17 Komarov
  // const guideAssistantCommentsMessage = useMessages('Guide-assistant');

  // ##### 23-07-20 Komarov

  const { Option } = Select;
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  const servicesBlockComponents = {
    Accommodation: (
      <AccommodationComponent
        props={{
          selectingAccomTypes,
          accomType,
          accomTypeInitialState,
          SelectSingleRoomsAmount,
          SelectTwinRoomsAmount,
          selectStarRating,
          starRating,
          starRatingInitialState,
        }}
      />
    ),
    Transfers: (
      <TransfersComponent
        props={{
          selectVehicleTypes,
          vehicleTypes,
          vehicleTypesInitialState,
        }}
      />
    ),
    Meal: (
      <MealComponent
        props={{
          selectMealType,
          mealType,
          mealTypeInitialState,
        }}
      />
    ),
    "Guide-assistant": (
      <GuideAssistantComponent
        props={{
          selectGuideTypes,
          guideTypes,
          guideTypesTypesInitialState,
        }}
      />
    ),
    Excursion: (
      <ExcursionComponent
        props={{
          AddExcursionComments,
          excursionComments,
          maxLength,
        }}
      />
    ),
    "Flight tix": (
      <FlightTixComponent
        props={{
          AddFlightTixComments,
          flightTixComments,
          maxLength,
        }}
      />
    ),
    "Train tix": (
      <TrainTixComponent
        props={{
          maxLength,
          AddTrainTixComments,
          trainTixComments,
        }}
      />
    ),
    "Conference facilities": (
      <ConferenceFacilitiesComponent
        props={{
          maxLength,
          AddConferenceFacilitiesComments,
          conferenceFacilitiesComments,
        }}
      />
    ),
    "A/v equipment": (
      <AvEquipmentComponent
        props={{
          maxLength,
          AddAvEquipmentComments,
          avEquipmentComments,
        }}
      />
    ),
    "Visa Support": (
      <VisaSupportComponent
        props={{
          maxLength,
          AddVisaSupportComments,
          visaSupportComments,
        }}
      />
    ),
    "Bus Transfer": (
      <BusTransferComponent
        props={{
          maxLength,
          AddBusTransferComments,
          busTransferComments,
        }}
      />
    ),
    "River Cruise": (
      <RiverCruiseComponent
        props={{
          maxLength,
          AddRiverCruiseComments,
          riverCruiseComments,
        }}
      />
    ),
    "Sea Cruise": (
      <SeaCruiseComponent
        props={{
          maxLength,
          AddSeaCruiseComments,
          seaCruiseComments,
        }}
      />
    ),
    "Package Tour": (
      <PackageTourComponent
        props={{
          maxLength,
          AddPackageTourComments,
          packageTourComments,
        }}
      />
    ),
    "Other Service": (
      <OtherServiceComponent
        props={{
          maxLength,
          AddOtherServiceComments,
          otherServiceComments,
        }}
      />
    ),
    Charter: (
      <CharterComponent
        props={{
          maxLength,
          AddCharterComments,
          charterComments,
        }}
      />
    ),
  };

  const ServicesBlockComponents = () =>
    (Object.keys(multServices).length > 0 &&
      Object.keys(multServices).map(
        (serviceName) => servicesBlockComponents[serviceName],
      )) ||
    null;

  return (
    <>
      {/* ##### 23-10-11 Komarov: компонент множественного выбора сервисов - start */}
      <div
        className="groupTravelMultipleServices"
        data-component="ServicesBlock"
      >
        <span>
          {
            // ##### 23-10-04 Komarov
            markRequired(Group_request_services)
          }
        </span>

        <Select
          mode="multiple"
          allowClear
          // ##### 23-10-11 Komarov
          showArrow
          style={{
            width: "100%",
          }}
          // ##### 23-10-04 Komarov
          placeholder={SupplierForm_PleaseSelect}
          onChange={chooseMultServices}
          // ##### 23-10-04 Komarov
          status={status}
        >
          {
            // ##### 24-03-21 Komarov
            Object.keys(TravelServiceTypesObj[locale]).map(
              (travelServiceTypeName) => {
                return (
                  // ##### 23-04-12 Komarov
                  <Option
                    key={uniqueId()}
                    // key={index}
                    value={travelServiceTypeName}
                  >
                    {TravelServiceTypesObj[locale][travelServiceTypeName]}
                  </Option>
                );
              },
            )
          }
        </Select>
        {/* ##### 23-10-11 Komarov: компонент множественного выбора сервисов - end */}

        {/* ##### 23-10-11 Komarov: компонент выбора количества туристов - start */}
        <span>{markRequired(group_request_tourists_number)}</span>
        <Space>
          <InputNumber
            min={2}
            max={100000}
            defaultValue={2}
            onChange={SelectTouristsNumber}
          />
        </Space>
        {/* ##### 23-10-11 Komarov: компонент выбора количества туристов - end */}
      </div>
      <ServicesBlockComponents />
    </>
  );
};
