// ##### 24-04-05 Komarov
import React from "react";
import {
  HotelsAutocompleteItemAllotment,
  HotelsAutocompleteItemRequest,
} from "../../../../Library/Icons/hotels";
import {
  MapDotAllotment,
  MapDotRequest,
} from "../../../../Library/Icons/mapdot";

// ##### 24-04-05 Komarov: Функция для взятия нужной иконки города или отеля, доступных сейчас или под запрос, для Autocomplete
const getIcon = (item) => {
  const isCity = item.city === "0";
  const isHotel = item.city === "1";
  // ##### 24-03-28 Komarov: '!!' вначале преобразуют значение, идущее за ними, в логическое (true или false).
  const isCityOnAllotment = !!item.available_now;
  const isCityOnRequest = !!!item.available_now;
  const isHotelOnAllotment = !!item.available_now;
  const isHotelOnRequest = !!!item.available_now;
  if (isCity && isCityOnAllotment) return <MapDotAllotment />;
  if (isCity && isCityOnRequest) return <MapDotRequest />;
  if (isHotel && isHotelOnAllotment) return <HotelsAutocompleteItemAllotment />;
  if (isHotel && isHotelOnRequest) return <HotelsAutocompleteItemRequest />;
  // ##### 24-03-26 Komarov: Если попадаем в последний return, значит, что-то пошло не так, возможно, где-то ошибка
  return (
    <>
      <MapDotRequest />
      {(() =>
        console.error(
          "Error: Something unexpected happened in src/Components/Pages/FirstPageofSearchModule/HotelsAutocompleteBlock/HotelsAutocomplete.js:getIcon"
        ))()}
    </>
  );
};

export default getIcon;
