// ##### 25-02-05 Komarov
import { AutocompleteDiv } from "./AutocompleteDiv";
import React from "react";

export const AutocompleteOuterDivTag = ({ transform }) => (
  <div style={{ transform }} data-component="AutocompleteOuterDivTag">
    {/* ##### 23-11-21 Komarov */}
    <AutocompleteDiv height="37px" />
  </div>
);
