// ##### 25-02-05 Komarov
import { useMessages } from "../../../../../../../helpers/messages";
import { Input } from "antd";
import uniqueId from "lodash.uniqueid";
import React from "react";

export const SeaCruiseComponent = ({ props }) => {
  const { maxLength, AddSeaCruiseComments, seaCruiseComments } = props;
  const seaCruiseCommentsMessage = useMessages("Sea Cruise");
  // ##### 23-10-17 Komarov
  const { TextArea } = Input;
  return (
    <div key={uniqueId()} className="form-block-lf">
      <span>{seaCruiseCommentsMessage}</span>
      <TextArea
        rows={1}
        maxLength={maxLength}
        style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
        onChange={AddSeaCruiseComments}
        value={seaCruiseComments}
      />
    </div>
  );
};
