// ##### 23-11-24 Komarov: форматы дат для разных локалей для использования при отображении сайта на соотв. языке
export default {
  fr: "dd/MM/yyyy",
  en: "YYYY-MM-DD",
  uk: "DD.MM.YYYY",
  ru: "DD.MM.YYYY",
  tr: "DD.MM.YYYY",
};

// ##### 23-12-15 Komarov: Добавил дату (дефолтный экспорт пригодится, и для обратной совместимости с существующим кодом) и месяц
export const date = {
  fr: "dd/MM/yyyy",
  en: "YYYY-MM-DD",
  uk: "DD.MM.YYYY",
  ru: "DD.MM.YYYY",
  tr: "DD.MM.YYYY",
};

export const month = {
  fr: "MM/yyyy",
  en: "YYYY-MM",
  uk: "MM.YYYY",
  ru: "MM.YYYY",
  tr: "MM.YYYY",
};

export const time = "HH:mm";

// ##### 24-05-01 Komarov: форматы даты и времени для разных локалей для использования при отображении сайта на соотв. языке
export const dateTime = {
  fr: `${date.fr} ${time}`,
  en: `${date.en} ${time}`,
  uk: `${date.uk} ${time}`,
  ru: `${date.ru} ${time}`,
  tr: `${date.tr} ${time}`,
};
