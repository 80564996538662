// ##### 24-09-09 Komarov
import React, { createContext, useContext, useMemo, useState } from "react";
import { IconHint } from "./iconHint";
// ##### 23-11-01 Komarov
import { Media } from "../../../helpers/MediaConfig";

// ##### 23-10-10 Komarov
const SvgTag = ({ style = null }) => {
  const { color, onSmallScreen, setIsHintShown, setY } =
    useContext(SignInContext);

  // ##### 24-08-16 Komarov
  const [crossColor, setCrossColor] = useState(color ? color : "#102D69");

  // ##### 24-08-16 Komarov
  const onMouseEnter = (e) => {
    if (!color) {
      return;
    }
    setCrossColor((!onSmallScreen && "yellow") || color);
    setIsHintShown(true);
    setY(e.clientY + 5);
  };

  // ##### 24-08-16 Komarov
  const onMouseLeave = () => {
    if (!color) {
      return;
    }
    setCrossColor(color);
    setIsHintShown(false);
    setY(undefined);
  };

  return (
    <svg
      style={style}
      // ##### 23-11-01 Komarov
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      color={crossColor}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 95.95 122.88"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      {/* ##### 23-04-04 Komarov */}
      <g>
        <path
          fill="currentColor"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.16,0h13.33c0.25,0,0.46,0.21,0.46,0.46v121.96c0,0.25-0.21,0.46-0.46,0.46H82.16 c-0.25,0-0.46-0.21-0.46-0.46V56.08l-6.16,2.44l0,0l-0.86,0.3c-4.96,1.76-8.55,3.03-14.84,1.48c-0.05-0.01-0.09-0.03-0.13-0.05 c-4.4-1.71-6.68-4.08-8.9-6.4l-0.33-0.34l-2.81,17.91c2.51,1.58,5.02,2.75,7.39,3.86c7.2,3.36,13.12,6.12,14.39,17.43 c0.23,2.03,0.12,3.94,0.01,6.02l0,0.03c-0.02,0.3-0.03,0.63-0.07,1.4l-0.88,21.91c-0.02,0.43-0.38,0.76-0.8,0.75l-11.13,0.03 c-0.43,0-0.78-0.35-0.78-0.78c0.12-7.55,0.34-15.46,0.73-23l0.06-1.02c0.06-1.18,0.12-2.27,0.06-3.29 c-0.06-0.97-0.24-1.88-0.64-2.75l-0.11-0.23c-0.45-0.98-0.86-1.88-1.48-2.17c-1.23-0.57-3.18-1.28-5.4-2.01 c-2.54-0.84-5.38-1.68-7.88-2.39c-1.84,6.08-4.33,13.44-7,20.43c-2.39,6.24-4.92,12.21-7.27,16.74c-0.14,0.28-0.44,0.44-0.73,0.42 l-11.69,0.06c-0.43,0-0.78-0.34-0.78-0.77c0-0.1,0.02-0.2,0.06-0.29l0,0c2.34-5.75,5.1-13.22,7.75-20.81 c2.71-7.77,5.31-15.69,7.23-22.05c-1.14-1.4-2.23-2.96-2.97-4.68c-0.82-1.9-1.22-3.96-0.81-6.17l4.01-21.66l-0.23-0.01 c-1.39-0.06-2.55-0.11-3.88,0.47c-1.82,0.8-3.26,2.42-4.86,4.22c-0.65,0.73-1.32,1.49-2.09,2.27l-0.05,0.05 c-0.57,0.59-1.17,1.2-1.73,1.77l-7.08,7.15c-0.3,0.3-0.79,0.31-1.1,0l-7.72-8.1c-0.29-0.31-0.28-0.8,0.02-1.09l7.07-7.14 c0.61-0.62,1.13-1.16,1.64-1.67l0.08-0.08c3.19-3.48,5.78-6.26,9.1-8.14c3.36-1.9,7.4-2.84,13.42-2.58l0.01,0 c2.15,0.03,4.69,0.26,6.78,0.46c0.97,0.09,1.84,0.17,2.59,0.22c10.16,0.67,14.92,6.01,18.62,10.17c1.63,1.84,3.05,3.42,4.57,4.15 c0.72,0.34,1.85-0.14,3.14-0.68c0.78-0.33,1.6-0.68,2.47-0.91c0.56-0.21,0.87-0.33,1.11-0.42c0.31-0.12,0.52-0.2,0.64-0.24 l10.24-3.54V0.46C81.7,0.21,81.91,0,82.16,0L82.16,0z M41.74,6.41c3.18-1.09,6.51-0.78,9.31,0.59c2.8,1.37,5.08,3.82,6.17,7 c1.09,3.18,0.78,6.51-0.59,9.31c-1.37,2.8-3.82,5.08-7,6.17c-3.18,1.09-6.51,0.78-9.31-0.59c-2.8-1.37-5.08-3.82-6.17-7 c-1.09-3.18-0.78-6.51,0.59-9.31C36.11,9.78,38.56,7.5,41.74,6.41L41.74,6.41z M84.77,39.71h3.08v14.75h-3.08V39.71L84.77,39.71z"
        />
      </g>
    </svg>
  );
};
// ##### 23-10-10 Komarov
const SignInContext = createContext();

// ##### 23-10-10 Komarov
export const SignIn = ({ color, hintText, onSmallScreen }) => {
  const [isHintShown, setIsHintShown] = useState(false);
  const [y, setY] = useState(undefined);

  // ##### 24-09-09 Komarov
  const signInContextValue = useMemo(
    () => ({
      color,
      onSmallScreen,
      setIsHintShown,
      setY,
    }),
    [
      // ##### 24-09-11 Komarov
      color,
      onSmallScreen,
      setIsHintShown,
      setY,
    ],
  ); // value is cached by useMemo

  return (
    <div
      data-component="SignIn"
      style={{
        position: "relative",
        zIndex: 2001,
      }}
    >
      <SignInContext.Provider value={signInContextValue}>
        {/* ##### 23-11-01 Komarov */}
        <Media lessThan="Mobile-M-375-425">
          <SvgTag
            style={{
              width: "7.5vw",
              enableBackground: "new 0 0 95.95 122.88",
            }}
          />
        </Media>
        <Media at="Mobile-M-375-425">
          <SvgTag
            style={{
              height: "2.14em",
              enableBackground: "new 0 0 95.95 122.88",
            }}
          />
        </Media>
        <Media at="Mobile-L-425-768">
          <SvgTag
            style={{
              height: "8vw",
              enableBackground: "new 0 0 93.52 122.88",
            }}
          />
        </Media>
        <Media at="Tablet-768-1024">
          <SvgTag
            style={{
              height: "6vw",
              enableBackground: "new 0 0 93.52 122.88",
            }}
          />
        </Media>
        <Media greaterThanOrEqual="Laptop-1024-1440">
          <SvgTag
            style={{
              height: "2.2vw",
              enableBackground: "new 0 0 95.95 122.88",
            }}
          />
        </Media>
      </SignInContext.Provider>
      <IconHint isHintShown={isHintShown} hintText={hintText} y={y} />
    </div>
  );
};
