import axios from "../helpers/public.axios";

import {
  GET_TOPTOURS_REQ,
  GET_TOPTOURS_RES,
  GET_TOPTOURS_ERR,
} from "../constants";

export const toptoursResponse = (res) => ({
  type: GET_TOPTOURS_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
export const getTopTours = () => (dispatch) => {

  dispatch({ type: GET_TOPTOURS_REQ });

  // ##### 24-09-24 Komarov
  const url = `${process.env.REACT_APP_SMART_URL}interface/toptours`;

  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/toptours.js:getTopTours, url:",
      url
    );
  axios
    .get(url, {})
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      // console.log('[TOP TOURS] : ' , res.data)
      dispatch(toptoursResponse(await res.data));
    })
    .catch((err) => {
      dispatch({ type: GET_TOPTOURS_ERR, error: err });
    });
};
