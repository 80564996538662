// ##### 24-06-14 Komarov
import React, { useContext } from "react";
// ##### 24-08-10 Komarov
// import ArkturCollectionHotels from "../../../../Library/Images/ArkturCollectionHotels.jpg";
import { MediumScreenTopHotelsContext } from "./MediumScreenTopHotels";
// ##### 24-08-10 Komarov
import { TopHotelsTitle } from "../../ResponsiveHeader/Header/TopHotelsTitle";

export const MediumScreenTopHotelsDiv = ({ width }) => {
  const { TopHotelContents, GetHotelDetails } = useContext(
    MediumScreenTopHotelsContext,
  );
  // ##### 23-11-08 Komarov
  return (
    <div data-component="MediumScreenTopHotelsDiv">
      <div
        style={{
          padding: "19px",
          backgroundColor: "#005AEB",
          marginTop: "2vw",
          borderRadius: "5px",
        }}
      />
      <div style={{ textAlign: "center" }}>
        {/* ##### 24-03-18 Komarov: TODO: Брать перевод значения атрибута alt из Смарта */}
        {/* ##### 24-08-10 Komarov */}
        {/* <img src={ArkturCollectionHotels} alt="Arktur Collection Hotels" /> */}
        <TopHotelsTitle fontSize="1.1em" />
      </div>

      <div>
        <ul
          style={{
            display: "grid",
            //  ##### 23-11-08 Komarov
            //  gridTemplateColumns: `repeat(2, ${width*0.6/2}px)`,
            // gridTemplateColumns: `repeat(2, 16.8em)`,
            // ##### 24-03-21 Komarov
            gridTemplateColumns: "repeat(2, 50vw)",
            listStyle: "none",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {TopHotelContents.length > 0 ? (
            TopHotelContents.map((hotel) => {
              if (hotel.main_photo.length > 0) {
                return (
                  <li
                    key={hotel.hotel_id}
                    //  ##### 22-09-05 Komarov: исправил вход в конкретный отель
                    onClick={GetHotelDetails(hotel)}
                    // ##### 24-03-21 Komarov
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        GetHotelDetails(hotel);
                      }
                    }}
                  >
                    <div>
                      {
                        hotel.main_photo[0] ? (
                          <img
                            // ##### 23-03-30 Komarov
                            // style={{ width: '14em' }}
                            // ##### 24-03-18 Komarov: TODO: Брать перевод значения атрибута alt из Смарта
                            alt={hotel.hotel_name}
                            style={{ width }}
                            id={hotel.hotel_id}
                            className="TopToursImage"
                            // ##### 23-03-30 Komarov
                            // width={`${width*0.5/2}px`}
                            src={
                              (process.env.REACT_APP_PROTOCOL || "https://") +
                              hotel.main_photo[0]
                            }
                          />
                        ) : null
                        // (<LoadingMessage itemWidth={`${width*0.5/2}px`}/>)
                      }
                    </div>
                    {hotel.main_photo[0] ? (
                      // ##### 23-03-30 Komarov
                      <div className="TopToursTitleTablet" style={{ width }}>
                        <h4
                          id={hotel.hotel_id}
                          // ##### 23-03-30 Komarov
                          style={{ width }}
                        >
                          {hotel.hotel_name.toUpperCase()}
                        </h4>
                      </div>
                    ) : null}
                  </li>
                );
              }
            })
          ) : (
            <div>{null}</div>
          )}
        </ul>
      </div>
    </div>
  );
};
