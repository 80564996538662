import {
  GET_HOTEL_CONTENT_ERR,
  GET_HOTEL_CONTENT_REQ,
  GET_HOTEL_CONTENT_RES,
  // ##### 25-02-12 Komarov
  GET_HOTEL_CONTENT_ERR2,
  GET_HOTEL_CONTENT_REQ2,
  GET_HOTEL_CONTENT_RES2,
  // ##### 25-02-15 Komarov
  GET_HOTEL_CONTENT_ERR3,
  GET_HOTEL_CONTENT_REQ3,
  GET_HOTEL_CONTENT_RES3,
  GET_HOTEL_CONTENT_ERR4,
  GET_HOTEL_CONTENT_REQ4,
  GET_HOTEL_CONTENT_RES4,
} from "../constants";

const initState = {
  hotelcontent: [],
  hotelcontent_loaded: false,
  hotelerrors: [],
  // ##### 25-02-12 Komarov
  hotelcontent2: [],
  hotelcontent_loaded2: false,
  hotelerrors2: [],
  // ##### 25-02-15 Komarov
  hotelcontent3: [],
  hotelcontent_loaded3: false,
  hotelerrors3: [],
  hotelcontent4: [],
  hotelcontent_loaded4: false,
  hotelerrors4: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_HOTEL_CONTENT_REQ:
      return {
        ...state,
        hotelcontent_loaded: false,
      };

    case GET_HOTEL_CONTENT_RES:
      return {
        ...state,
        hotelcontent_loaded: true,
        hotelcontent: action.payload,
      };

    case GET_HOTEL_CONTENT_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        hotelerrors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 25-02-12 Komarov
    case GET_HOTEL_CONTENT_REQ2:
      return {
        ...state,
        hotelcontent_loaded2: false,
      };

    case GET_HOTEL_CONTENT_RES2:
      return {
        ...state,
        hotelcontent_loaded2: true,
        hotelcontent2: action.payload,
      };

    case GET_HOTEL_CONTENT_ERR2:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        hotelerrors2: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    //   ##### 25-02-15 Komarov
    case GET_HOTEL_CONTENT_REQ3:
      return {
        ...state,
        hotelcontent_loaded3: false,
      };

    case GET_HOTEL_CONTENT_RES3:
      return {
        ...state,
        hotelcontent_loaded3: true,
        hotelcontent3: action.payload,
      };

    case GET_HOTEL_CONTENT_ERR3:
      return {
        ...state,
        hotelerrors3: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    case GET_HOTEL_CONTENT_REQ4:
      return {
        ...state,
        hotelcontent_loaded4: false,
      };

    case GET_HOTEL_CONTENT_RES4:
      return {
        ...state,
        hotelcontent_loaded4: true,
        hotelcontent4: action.payload,
      };

    case GET_HOTEL_CONTENT_ERR4:
      return {
        ...state,
        hotelerrors4: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    default:
      return state;
  }
};

export default reducer;
