import React from "react";
import { NavComponent } from "./NavComponent";

// ##### 23-06-15 Komarov: Для объединения серверного и клиентского кода (import файлов CSS на сервере вызывает ошибку при билде) перенёс CSS выше по дереву, в файл src/LocalizationWrapper.js. На вид приложления это не влияет, так как CSS при билде собирается всё равно в один общий файл main.css.
// import './header.css'

export const LargeScreensNavBar = ({ sitepage_type, linkClassName }) => {
  //navClass

  return (
    <div className="nav_big" data-component="LargeScreensNavBar">
      <NavComponent
        linkClassName={linkClassName}
        sitepage_type={sitepage_type}
        // navClass={navClass}
      />
    </div>
  );
};
