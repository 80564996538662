// ##### 23-12-27 Komarov
import uniqueId from "lodash.uniqueid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "../../FirstPageofSearchModule/SearchResizersAndSwitchers/SearchFront";
// ##### 25-01-22 Komarov
import { getPurePage } from "../../../../Redux/actions";
// ##### 24-06-14 Komarov
import { BodyPage } from "./BodyPage";
import { TopHotels } from "./TopHotels/TopHotels";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages";
// ##### 25-03-27 Komarov
import SlickSlider from "../../../Library/Slider/SlickSlider";
import { Media } from "../../../../helpers/MediaConfig";

export const HomePage = () => {
  // ##### 24-06-27 Komarov
  const locale = useSelector((state) => state.language);

  // ##### 23-10-17 Komarov
  // const promoCode = useSelector(state => state.promocode.promocode)
  const deviceType = useSelector((state) => state.deviceType);
  // ##### 23-02-21 Komarov: Оказывается, чтобы отображались ТопТуры, состояние toggler_type нужно брать именно из state
  // ##### 24-09-10 Komarov
  // const toggler_type = useSelector((state) => state.tophotels.toggler_type);

  const dispatch = useDispatch();
  // ##### 25-03-26 Komarov
  const purepages = useSelector((state) => state.pages.purepages);

  // 199 - id SitePage, которая относится к Слайдеру
  // $$$$$ 22-09-26 Komarov: Исправление отсутствующей Главной страницы при переключении на неё с другой
  const purepagesCount = Object.keys(purepages).length;
  useEffect(() => {
    if (purepagesCount > 0) {
      return;
    }
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/PageComponents/HomePage/HomePage.js:getPurePage(199,locale,deviceType)",
      );
    // ##### 23-08-18 Komarov
    dispatch(
      getPurePage({
        id: 199,
        lang: locale,
        deviceType,
      }),
    );
    // ##### 25-03-21 Komarov
  }, [purepagesCount, dispatch, locale, deviceType]);

  // ##### 23-10-04 Komarov
  const body_messages = Object.values(useMessages()).filter(
    (item) => parseInt(item.sitepage_region_id) === 13,
  );

  // ##### 25-03-21 Komarov
  const purePage = purepages[199];

  return (
    <div
      style={{ display: "flex", flexDirection: "column" }}
      data-component="HomePage"
    >
      {/* ##### 22-11-19 Komarov - start */}
      <>
        {purePage
          ? purePage.map((item) => {
              if (item.content_name === "Image") {
                // ##### 25-03-27 Komarov
                return (
                  <React.Fragment key={uniqueId()}>
                    <Media
                      lessThan="Mobile-L-425-768"
                      style={{ height: "37vw" }}
                    >
                      <SlickSlider
                        style={{ order: "1" }}
                        images={item.text}
                        key={uniqueId()}
                        className="SliderTabletPortraitCSS"
                      />
                    </Media>
                    <Media
                      between={["Mobile-L-425-768", "Laptop-1024-1440"]}
                      style={{ height: "37vw" }}
                    >
                      <SlickSlider
                        style={{ order: "1" }}
                        images={item.text}
                        key={uniqueId()}
                        className="SliderMobilePortraitCSS"
                      />
                    </Media>
                    <Media
                      greaterThanOrEqual="Laptop-1024-1440"
                      style={{ height: "27vw" }}
                    >
                      <SlickSlider
                        style={{ order: "1" }}
                        images={item.text}
                        key={uniqueId()}
                        className="SliderCSS"
                      />
                    </Media>
                  </React.Fragment>
                );
              }
            })
          : null}
      </>
      {/* ##### 22-11-19 Komarov - end */}
      <Search style={{ order: "2" }} />
      {body_messages.length > 0
        ? body_messages.map((item) => {
            return (
              <BodyPage
                className="BodyPageClass"
                sidePageID={item.id}
                sitePageTypeID={item.sitepage_type_id}
                sitePageName={item.name}
                key={uniqueId()}
              />
            );
          })
        : null}
      <TopHotels />
    </div>
  );
};
