// ##### 24-11-08 Komarov
import axios from "../helpers/public.axios";

import {
  GET_COMPANY_RES,
  GET_COMPANY_ERR, GET_COMPANY_NOT_FOUND
} from "../constants";

export const getCompany =
  (code, user_id) => (dispatch) => {
    const ActionRQ = {
      username: process.env.REACT_APP_SMART_USERNAME,
      password: process.env.REACT_APP_SMART_PASSWORD,
      action: "GetCompanyRQ",
      user_id,
      data: {
        promocode: code,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_SMART_URL}interface/xmlsubj/`,
        JSON.stringify({ ActionRQ })
      )
      .then(async (res) => {
        if (!res.data.success) {
          dispatch({
            type: GET_COMPANY_NOT_FOUND,
            payload: await res.data
          });
        } else {
          dispatch({
            type: GET_COMPANY_RES,
            payload: await res.data
          });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_COMPANY_ERR, error: error });
      });
  };
