// ##### 23-03-29 Komarov
import React, { useState } from "react";
// ##### 23-04-12 Komarov
import uniqueId from "lodash.uniqueid";
// ##### 25-02-27 Komarov
import { useSelector } from "react-redux";
import { getDomain } from "../../../Pages/Helpers/urls";

// ##### 23-09-14 Komarov
// import './CartGalleryShortVersionCSS.css'

export const CartGalleryShortVersion = ({ photos }) => {
  // ##### 25-02-19 Komarov
  const [picked, setPicked] = useState(`${photos[0]}`);
  const [activeIndex, setActiveIndex] = useState();

  const pickAction = (index) => (e) => {
    setPicked(e.target.getAttribute("src"));
    setActiveIndex(index);
    // console.log('[PICKED]:',e.target.getAttribute('src'))
  };
  // ##### 25-02-15 Komarov: TODO: Smart отправляет неправильный src картинок отеля. Функция для исправления src картинки
  const deviceType = useSelector((state) => state.deviceType);
  const currentWebProtocol = process.env.REACT_APP_PROTOCOL || "https://";
  const hotelImageSrcStub = `${currentWebProtocol}/${getDomain(process.env.REACT_APP_SMART_URL)}/images_symlink/hotel/0/Hotel_when_no_image.jpeg`;
  const hotelId = Object.keys(
    useSelector((state) => state.hotelcontent.hotelcontent4.data.hotels),
  )[0];
  const getImageSrc = (image) => {
    // ##### 25-02-15 Komarov: Если картинки нет вообще, то возвращаем картинку для отеля без картинок
    if (!image) {
      console.warn("There's no images for hotel with id", hotelId);
      return hotelImageSrcStub;
    }
    // ##### 25-02-15 Komarov: Если папка с картинками не содержит картинки формата webp, а содержит jpg (в ответе от Смарта пришли ссылки на картинки jpg, а не webp), то показываем картинки jpg
    if (
      image.includes(`/${deviceType}/`) &&
      image.includes(".jpg") &&
      !image.includes(".jpg_orig")
    ) {
      return image;
    }
    if (!image.includes(`/${deviceType}/`) || !image.includes(".webp")) {
      let fixedHotelImageSrc = image;
      if (!image.includes(`/${deviceType}/`)) {
        fixedHotelImageSrc = `${fixedHotelImageSrc.slice(0, fixedHotelImageSrc.indexOf("images_symlink") + "images_symlink".length)}/${deviceType}${fixedHotelImageSrc.slice(fixedHotelImageSrc.indexOf("images_symlink") + "images_symlink".length)}`;
      }
      if (!image.includes(".webp")) {
        return `${fixedHotelImageSrc.slice(0, fixedHotelImageSrc.lastIndexOf("."))}.webp`;
      }
      return fixedHotelImageSrc;
    }
    return image;
  };

  return (
    <div data-component="CartGalleryShortVersion">
      <div>
        <img
          // ##### 25-02-27 Komarov
          src={getImageSrc(picked)}
          // ##### 24-03-18 Komarov: TODO: Взять значение атрибута alt из Смарта
          alt="Picked gallery element"
          style={{
            padding: "5px",
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
            height: "45vh",
            borderRadius: "0.7vw",
            // ##### 23-11-09 Komarov
            objectFit: "cover",
          }}
        />
      </div>

      {/* ##### 24-03-21 Komarov */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          //  justifyContent:'space-evenly'
          // display:'grid',
          // gridTemplateColumns:'repeat(4,25%)'
        }}
      >
        {
          // ##### 25-02-19 Komarov
          photos.map((image, index) => {
            return (
              // ##### 23-04-12 Komarov
              <div key={uniqueId()}>
                {index > 1 && index <= 5 ? (
                  <img
                    // ##### 25-02-27 Komarov
                    src={getImageSrc(image)}
                    // ##### 24-03-18 Komarov: TODO: Взять значение атрибута alt из Смарта
                    alt=""
                    className={
                      activeIndex === index ? "PickedImage" : "NotPickedImage"
                    }
                    onClick={pickAction(index)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") pickAction(index)();
                    }} // Example of adding keyboard accessibility
                  />
                ) : null}
              </div>
            );
          })
        }
      </div>
    </div>
  );
};
