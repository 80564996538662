// ##### 25-02-05 Komarov
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// ##### 25-01-22 Komarov
import parse from "html-react-parser";
import uniqueId from "lodash.uniqueid";
import { changeLang, getPages } from "../../../../Redux/actions";
import { EmailIcon } from "../../../Library/Icons/email";
import { PhoneIcon } from "../../../Library/Icons/phoneicon";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages";
// ##### 23-10-31 Komarov
import { Media } from "../../../../helpers/MediaConfig";
// ##### 24-06-21 Komarov
// ##### 25-02-05 Komarov
import { MobileMenuTextItems } from "./MobileMenuTextItems";
import { DesktopMenuTextItems } from "./DesktopMenuTextItems";

// ##### 24-08-08 Komarov
export const NavComponent = ({
  linkClassName,
  sitepage_type,
  sidebarListWidth,
}) => {
  // ##### 24-04-23 Komarov
  const Mail_icon = useMessages("Mail_icon");
  const Phone_icon = useMessages("Phone_icon");
  // ##### 24-03-04 Komarov
  const whiteLabel = useSelector((state) => state.whitelabel);
  const isWhiteLabelSet = whiteLabel.set;
  const companyCipher = whiteLabel.company_cipher;

  // ##### 23-05-04 Komarov
  // let lang = useSelector((state) => state.locale.current_locale); // It was const
  let lang = useSelector((state) => state.language);
  if (lang === undefined) {
    lang = localStorage.getItem("currentLanguage");
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeLang());
    // ##### 23-04-12 Komarov
  }, [dispatch]);

  // ##### 24-06-19 Komarov
  const pagesCount = Object.values(useMessages()).length;
  useEffect(() => {
    // ##### 24-03-21 Komarov
    if (pagesCount > 0) {
      return;
    }
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/PageComponents/ResponsiveHeader/NavComponent.js:getPages(lang)",
      );
    dispatch(getPages(lang));
    // ##### 23-04-12 Komarov
  }, [lang, pagesCount, dispatch]);

  return (
    <div className={linkClassName} data-component="NavComponent">
      {/* ##### 25-01-16 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        {/* ##### 23-03-31 Komarov */}
        <EmailIcon
          key={uniqueId()}
          hintText={
            Mail_icon.includes("No translations for")
              ? "Email"
              : parse(Mail_icon)
          }
        />
      </Media>
      {/* ##### 25-01-16 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        {/* ##### 23-10-31 Komarov */}
        <PhoneIcon
          key={uniqueId()}
          hintText={
            Phone_icon.includes("No translations for")
              ? "Phone"
              : parse(Phone_icon)
          }
        />
      </Media>
      {/* ##### 24-06-19 Komarov */}
      <Media
        greaterThanOrEqual="Laptop-1024-1440"
        // ##### 24-06-21 Komarov
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <DesktopMenuTextItems
          sitepage_type={sitepage_type}
          isWhiteLabelSet={isWhiteLabelSet}
          companyCipher={companyCipher}
        />
      </Media>
      <Media lessThan="Laptop-1024-1440">
        <MobileMenuTextItems
          // ##### 24-08-08 Komarov
          sitepage_type={sitepage_type}
          isWhiteLabelSet={isWhiteLabelSet}
          companyCipher={companyCipher}
          sidebarListWidth={sidebarListWidth}
        />
      </Media>
    </div>
  );
};
