// ##### 24-09-20 Komarov
import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeLang } from "../../../../Redux/actions/locale";
import { LocalizationNavLink } from "../../../Library/Localization/LocalizationNavLink";
import { getPages } from "../../../../Redux/actions/pages";
import uniqueId from "lodash.uniqueid";
import parse from "html-react-parser";
import { EmailIcon } from "../../../Library/Icons/email";
import { PhoneIcon } from "../../../Library/Icons/phoneicon";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages";
// ##### 23-10-31 Komarov
import { Media } from "../../../../helpers/MediaConfig";
// ##### 24-06-21 Komarov
import OutsideClickHandler from "react-outside-click-handler";
// ##### 24-09-20 Komarov
import { useWindowWidthAndHeight } from "../../Helpers/WindowResizeHook";

// ##### 24-06-19 Komarov
const MobileMenuTextItems = ({
  // ##### 24-08-08 Komarov
  sitepage_type,
  isWhiteLabelSet,
  companyCipher,
  sidebarListWidth }) => {
  // ##### 24-06-20 Komarov
  const pagesFromLocalStorageArray = Object.values(useMessages()).filter(page => parseInt(sitepage_type.sitepage_type_id) ===
    parseInt(page.sitepage_type_id) &&
    parseInt(sitepage_type.sitepage_type_id) !== 7 &&
    parseInt(page.id) !== 198);

  // ##### 24-08-08 Komarov
  const cooperation = pagesFromLocalStorageArray.find(page => page.name === "Cooperation");
  const requests = pagesFromLocalStorageArray.find(page => page.name === "Requests");

  // ##### 24-08-08 Komarov
  return pagesFromLocalStorageArray.length > 0
    ?
    <>
      {pagesFromLocalStorageArray.filter(page => !["Clients", "Suppliers", "Group Request", "Cooperation", "Requests"].includes(page.name)).map((page) => {
        return (
          // ##### 24-03-04 Komarov
          <LocalizationNavLink
            to={
              (isWhiteLabelSet &&
                `/ref${companyCipher}/sitepages/?name=${page.name}&id=${page.id}`) ||
              `/sitepages/?name=${page.name}&id=${page.id}`
            }
            key={uniqueId()}
          >
            <h4>{parse(page.title)}</h4>
          </LocalizationNavLink>
        );
      })}
      <CooperationMobile
        pages={pagesFromLocalStorageArray.filter(page => ["Clients", "Suppliers"].includes(page.name))}
        title={cooperation.title}
        isWhiteLabelSet={isWhiteLabelSet}
        companyCipher={companyCipher}
        sidebarListWidth={sidebarListWidth}
      />
      {/* ##### 24-08-07 Komarov */}
      <RequestsMobile
        pages={pagesFromLocalStorageArray.filter(page => ["Group Request"].includes(page.name))}
        title={requests.title}
        isWhiteLabelSet={isWhiteLabelSet}
        companyCipher={companyCipher}
        sidebarListWidth={sidebarListWidth}
      />
    </>
    : null
}

// ##### 24-06-21 Komarov
const CooperationMobile = ({ pages, title, isWhiteLabelSet, companyCipher, sidebarListWidth }) => {
  const cooperationRef = useRef();
  const cooperationItemsRef = useRef();

  useEffect(() => {
    cooperationItemsRef.current.style.right = `${sidebarListWidth}px`;
    cooperationItemsRef.current.style.top = `${cooperationRef.current.offsetTop}px`;
  }, [cooperationItemsRef.current]);

  const removeCooperationItems = () => {
    const cooperationItemsclassList = cooperationItemsRef.current.classList;
    if (!cooperationItemsclassList.contains('CooperationMobileItemsHide')) {
      cooperationItemsclassList.add("CooperationMobileItemsHide");
    }
  }

  const toggleCooperationItems = (e) => {
    cooperationItemsRef.current.classList.toggle("CooperationMobileItemsHide");
    e.stopPropagation();
  }

  return <OutsideClickHandler
    onOutsideClick={removeCooperationItems}>
    <a
      ref={cooperationRef}
      role="button"
      data-component="CooperationMobile"
      onClick={toggleCooperationItems}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          toggleCooperationItems();
        }
      }}
    >
      <h4>{title}</h4>
    </a>
    <div
      className="CooperationMobileItemsHide"
      data-component="CooperationMobileItems"
      ref={cooperationItemsRef}
    >
      {pages.map((page) => {
        return (
          // ##### 24-03-04 Komarov
          
            <LocalizationNavLink
              to={
                (isWhiteLabelSet &&
                  `/ref${companyCipher}/sitepages/?name=${page.name}&id=${page.id}`) ||
                `/sitepages/?name=${page.name}&id=${page.id}`
              }
              key={uniqueId()}
              onClick={removeCooperationItems}
            >
              <h4>{parse(page.title)}</h4>
            </LocalizationNavLink>
          
        );
      })}
    </div>
  </OutsideClickHandler>
}

// ##### 24-06-21 Komarov
const Cooperation = ({ pages, title, isWhiteLabelSet, companyCipher }) => {
  const cooperationRef = useRef();
  const cooperationItemsRef = useRef();
  // ##### 24-09-20 Komarov
  const [innerWidth] = useWindowWidthAndHeight();
  useEffect(() => {
    cooperationItemsRef.current.style.left = `${cooperationRef.current.offsetLeft}px`;
  }, [innerWidth]);

  const removeCooperationItems = () => {
    const cooperationItemsclassList = cooperationItemsRef.current.classList;
    if (!cooperationItemsclassList.contains('DropDownMenuItemsHide')) {
      cooperationItemsclassList.add("DropDownMenuItemsHide");
    }
  }

  return <OutsideClickHandler
    onOutsideClick={removeCooperationItems}>
    <a
      ref={cooperationRef}
      role="button"
      className="DropDownMenu"
      data-component="DropDownMenu"
      onClick={() => cooperationItemsRef.current.classList.toggle("DropDownMenuItemsHide")}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          cooperationItemsRef.current.classList.toggle("DropDownMenuItemsHide");
        }
      }}
    >
      {title}
    </a>
    <div
      className="DropDownMenuItemsHide"
      data-component="DropDownMenuItems"
      ref={cooperationItemsRef}
    >
      {pages.map((page) => {
        return (
          // ##### 24-03-04 Komarov
          <LocalizationNavLink
            to={
              (isWhiteLabelSet &&
                `/ref${companyCipher}/sitepages/?name=${page.name}&id=${page.id}`) ||
              `/sitepages/?name=${page.name}&id=${page.id}`
            }
            key={uniqueId()}
            onClick={removeCooperationItems}
          >
            {parse(page.title)}
          </LocalizationNavLink>
        );
      })}
    </div>
  </OutsideClickHandler>
}

// ##### 24-08-08 Komarov
const RequestsMobile = ({ pages, title, isWhiteLabelSet, companyCipher, sidebarListWidth }) => {
  const requestsRef = useRef();
  const requestsItemsRef = useRef();
  useEffect(() => {
    requestsItemsRef.current.style.right = `${sidebarListWidth}px`;
    requestsItemsRef.current.style.top = `${requestsRef.current.offsetTop}px`;
  }, [sidebarListWidth]);

  const removeRequestsItems = () => {
    const requestsItemsclassList = requestsItemsRef.current.classList;
    if (!requestsItemsclassList.contains('RequestsMobileItemsHide')) {
      requestsItemsclassList.add("RequestsMobileItemsHide");
    }
  }

  const toggleRequestsItems = (e) => {
    requestsItemsRef.current.classList.toggle("RequestsMobileItemsHide");
    e.stopPropagation();
  }

  return <OutsideClickHandler
    onOutsideClick={removeRequestsItems}>
    <a
      ref={requestsRef}
      role="button"
      data-component="Requests"
      onClick={toggleRequestsItems}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          toggleRequestsItems();
        }
      }}
    >
      <h4>{title}</h4>
    </a>
    <div
      className="RequestsMobileItemsHide"
      data-component="RequestsMobileItems"
      ref={requestsItemsRef}
    >
      {pages.map((page) => {
        return (
          // ##### 24-03-04 Komarov
          <LocalizationNavLink
            to={
              (isWhiteLabelSet &&
                `/ref${companyCipher}/sitepages/?name=${page.name}&id=${page.id}`) ||
              `/sitepages/?name=${page.name}&id=${page.id}`
            }
            key={uniqueId()}
            onClick={removeRequestsItems}
          >
            <h4>{parse(page.title)}</h4>
          </LocalizationNavLink>
        );
      })}
    </div>
  </OutsideClickHandler>
}

// ##### 24-08-07 Komarov
const Requests = ({ pages, title, isWhiteLabelSet, companyCipher }) => {
  const requestsRef = useRef();
  const requestsItemsRef = useRef();
  // ##### 24-09-20 Komarov
  const [innerWidth] = useWindowWidthAndHeight();
  useEffect(() => {
    requestsItemsRef.current.style.left = `${requestsRef.current.offsetLeft}px`;
  }, [innerWidth]);

  const removeRequestsItems = () => {
    const requestsItemsclassList = requestsItemsRef.current.classList;
    if (!requestsItemsclassList.contains('DropDownMenuItemsHide')) {
      requestsItemsclassList.add("DropDownMenuItemsHide");
    }
  }

  const toggleRequestsItems = () => {
    requestsItemsRef.current.classList.toggle("DropDownMenuItemsHide");
  }

  return <OutsideClickHandler
    onOutsideClick={removeRequestsItems}>
    <a
      ref={requestsRef}
      role="button"
      className="DropDownMenu"
      data-component="Requests"
      onClick={toggleRequestsItems}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          toggleRequestsItems();
        }
      }}
    >
      {title}
    </a>
    <div
      className="DropDownMenuItemsHide"
      data-component="DropDownMenuItems"
      ref={requestsItemsRef}
    >
      {pages.map((page) => {
        return (
          // ##### 24-03-04 Komarov
          <LocalizationNavLink
            to={
              (isWhiteLabelSet &&
                `/ref${companyCipher}/sitepages/?name=${page.name}&id=${page.id}`) ||
              `/sitepages/?name=${page.name}&id=${page.id}`
            }
            key={uniqueId()}
            onClick={removeRequestsItems}
          >
            {parse(page.title)}
          </LocalizationNavLink>
        );
      })}
    </div>
  </OutsideClickHandler>
}

const DesktopMenuTextItems = ({
  sitepage_type,
  isWhiteLabelSet,
  companyCipher }) => {
  const pagesFromLocalStorageArray = Object.values(useMessages()).filter(page => parseInt(sitepage_type.sitepage_type_id) ===
    parseInt(page.sitepage_type_id) &&
    parseInt(sitepage_type.sitepage_type_id) !== 7 &&
    parseInt(page.id) !== 198);

  // ##### 24-06-21 Komarov
  const cooperation = pagesFromLocalStorageArray.find(page => page.name === "Cooperation");
  // ##### 24-08-07 Komarov
  const requests = pagesFromLocalStorageArray.find(page => page.name === "Requests");

  return pagesFromLocalStorageArray.length > 0
    ?
    <>
      {pagesFromLocalStorageArray.filter(page => !["Clients", "Suppliers", "Group Request", "Cooperation", "Requests"].includes(page.name)).map((page) => {
        return (
          // ##### 24-03-04 Komarov
          <LocalizationNavLink
            to={
              (isWhiteLabelSet &&
                `/ref${companyCipher}/sitepages/?name=${page.name}&id=${page.id}`) ||
              `/sitepages/?name=${page.name}&id=${page.id}`
            }
            key={uniqueId()}
          >
            {parse(page.title)}
          </LocalizationNavLink>
        );
      })}
      <Cooperation
        pages={pagesFromLocalStorageArray.filter(page => ["Clients", "Suppliers"].includes(page.name))}
        title={cooperation.title}
        isWhiteLabelSet={isWhiteLabelSet}
        companyCipher={companyCipher}
      />
      {/* ##### 24-08-07 Komarov */}
      <Requests
        pages={pagesFromLocalStorageArray.filter(page => ["Group Request"].includes(page.name))}
        title={requests.title}
        isWhiteLabelSet={isWhiteLabelSet}
        companyCipher={companyCipher}
      />
      {/* <LocalizationNavLink
        to={
          (isWhiteLabelSet &&
            `/ref${companyCipher}/sitepages/?name=${cooperation.name}&id=${cooperation.id}`) ||
          `/sitepages/?name=${cooperation.name}&id=${cooperation.id}`
        }
        key={uniqueId()}
      >
        {parse(cooperation.title)}
      </LocalizationNavLink> */}
    </>
    : React.Fragment
}

// ##### 24-08-08 Komarov
export const NavComponent = ({ linkClassName, sitepage_type, sidebarListWidth }) => {
  // ##### 24-04-23 Komarov
  const Mail_icon = useMessages("Mail_icon");
  const Phone_icon = useMessages("Phone_icon");
  // ##### 24-03-04 Komarov
  const whiteLabel = useSelector((state) => state.whitelabel);
  const isWhiteLabelSet = whiteLabel.set;
  const companyCipher = whiteLabel.company_cipher;

  // ##### 23-05-04 Komarov
  // let lang = useSelector((state) => state.locale.current_locale); // It was const
  let lang = useSelector((state) => state.language);
  if (lang === undefined) {
    lang = localStorage.getItem("currentLanguage");
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeLang());
    // ##### 23-04-12 Komarov
  }, [dispatch]);

  // ##### 24-06-19 Komarov
  const pagesCount = Object.values(useMessages()).length;
  useEffect(() => {
    // ##### 24-03-21 Komarov
    if (pagesCount > 0) {
      return;
    }
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/PageComponents/ResponsiveHeader/NavComponent.js:getPages(lang)"
      );
    dispatch(getPages(lang));
    // ##### 23-04-12 Komarov
  }, [lang, pagesCount, dispatch]);

  return (
    <div className={linkClassName} data-component="NavComponent">
      {/* ##### 23-10-31 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        {/* ##### 23-03-31 Komarov */}
        <div>
          <EmailIcon
            key={uniqueId()}
            color={"white"}
            // ##### 24-06-19 Komarov
            hintText={Mail_icon.includes('No translations for') ? "Email" : parse(Mail_icon)}
          />
        </div>
      </Media>
      {/* ##### 23-10-31 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        {/* ##### 23-10-31 Komarov */}
        <div>
          <PhoneIcon
            key={uniqueId()}
            color={"white"}
            // ##### 24-06-19 Komarov
            hintText={Phone_icon.includes('No translations for') ? "Phone" : parse(Phone_icon)}
          />
        </div>
      </Media>
      {/* ##### 24-06-19 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440"
        // ##### 24-06-21 Komarov
        style={{
          display: "flex",
          alignItems: "center"
        }}>
        <DesktopMenuTextItems
          sitepage_type={sitepage_type}
          isWhiteLabelSet={isWhiteLabelSet}
          companyCipher={companyCipher}
        />
      </Media>
      <Media lessThan="Laptop-1024-1440">
        <MobileMenuTextItems
          // ##### 24-08-08 Komarov
          sitepage_type={sitepage_type}
          isWhiteLabelSet={isWhiteLabelSet}
          companyCipher={companyCipher}
          sidebarListWidth={sidebarListWidth}
        />
      </Media>
    </div>
  );
};
