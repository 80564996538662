// ##### 23-10-11 Komarov: вспомогательный объект с переводами на случай, если нет такого перевода в Смарте
// ##### 24-03-21 Komarov
export const staticMessages = {
  en: {
    chooseTypeOfMeal: "Please choose type of meal",
    "All Inclusive": "All Inclusive",
    "Full board": "Full board",
    Breakfast: "Breakfast",
    Lunch: "Lunch",
    Dinner: "Dinner",
    "Half board": "Half board",
    "Breakfast and Lanch": "Breakfast and Lanch",
    "Lunch and Dinner": "Lunch and Dinner",
    "Without Food": "Without Food",
    Excursion: "Your requests for Excursion service",
    "Guide-assistant": "Your requests for Guide-assistant service",
    "Flight tix": "Your requests for Flight tix service",
    "Train tix": "Your requests for Train tix service",
    "Conference facilities": "Your requests for Conference facilities service",
    "A/v equipment": "Your requests for A/v equipment service",
    "Visa Support": "Your requests for Visa Support service",
    "Bus Transfer": "Your requests for Bus Transfer service",
    "River Cruise": "Your requests for River Cruise service",
    "Sea Cruise": "Your requests for Sea Cruise service",
    "Package Tour": "Your requests for Package Tour service",
    "Other Service": "Your requests for Other service",
    Charter: "Your requests for Charter service",
    sign_out: "Sign out",
  },
  uk: {
    chooseTypeOfMeal: "Будь ласка, оберіть тип харчування",
    "All Inclusive": "Все включено",
    "Full board": "Повне харчування",
    Breakfast: "Сніданок",
    Lunch: "Обід",
    Dinner: "Вечеря",
    "Half board": "Дворазове харчування",
    "Breakfast and Lanch": "Сніданок і ланч",
    "Lunch and Dinner": "Обід і вечеря",
    "Without Food": "Без їжі",
    Excursion: "Ваші побажання щодо сервісу Екскурсії",
    "Guide-assistant": "Ваші побажання щодо сервісу Гід-перекладач",
    "Flight tix": "Ваші побажання щодо сервісу Авіа квитки",
    "Train tix": "Ваші побажання щодо сервісу З/д квитки",
    "Conference facilities":
      "Ваші побажання щодо сервісу Конференц обслуговування",
    "A/v equipment": "Ваші побажання щодо сервісу Аудіо/відео обладнання",
    "Visa Support": "Ваші побажання щодо сервісу Візова підтримка",
    "Bus Transfer": "Ваші побажання щодо сервісу Автобусний трансфер",
    "River Cruise": "Ваші побажання щодо сервісу Річковий круїз",
    "Sea Cruise": "Ваші побажання щодо сервісу Морський круїз",
    "Package Tour": "Ваші побажання щодо сервісу Пакетний тур",
    "Other Service": "Ваші побажання щодо сервісу Інші послуги",
    Charter: "Ваші побажання щодо сервісу Чартер",
    sign_out: "Вихід",
  },
  tr: {
    chooseTypeOfMeal: "Lütfen yemek türünü seçiniz",
    "All Inclusive": "Her Şey Dahil",
    "Full board": "Tam beslenme",
    Breakfast: "Kahvaltı",
    Lunch: "Yemek",
    Dinner: "Akşam Yemeği",
    "Half board": "Günde iki öğün",
    "Breakfast and Lanch": "Kahvaltı ve Lanch",
    "Lunch and Dinner": "Öğle ve Akşam Yemekleri",
    "Without Food": "Yiyecek Olmadan",
    Excursion: "Gezi hizmeti için talepleriniz",
    "Guide-assistant": "Rehber asistan hizmeti için talepleriniz",
    "Flight tix": "Flight tix hizmeti için talepleriniz",
    "Train tix": "Tren bileti hizmeti için talepleriniz",
    "Conference facilities": "Konferans tesisleri hizmeti için talepleriniz",
    "A/v equipment": "A/v ekipman servisi için talepleriniz",
    "Visa Support": "Vize Destek hizmeti için talepleriniz",
    "Bus Transfer": "Otobüs Transfer hizmeti için talepleriniz",
    "River Cruise": "Nehir Gezisi hizmeti için talepleriniz",
    "Sea Cruise": "Deniz Turu hizmeti için talepleriniz",
    "Package Tour": "Paket Tur hizmeti için talepleriniz",
    "Other Service": "Diğer hizmet talepleriniz",
    Charter: "Charter hizmeti için talepleriniz",
    sign_out: "Çıkış yap",
  },
  ru: {
    chooseTypeOfMeal: "Пожалуйста, выберите тип питания",
    "All Inclusive": "Все включено",
    "Full board": "Полное питание",
    Breakfast: "Завтрак",
    Lunch: "Обед",
    Dinner: "Ужин",
    "Half board": "Двухразовое питание",
    "Breakfast and Lanch": "Завтрак и обед",
    "Lunch and Dinner": "Обед и ужин",
    "Without Food": "Без еды",
    Excursion: "Ваши пожелания по сервису Экскурсия",
    "Guide-assistant": "Ваши пожелания по сервису Гид-переводчик",
    "Flight tix": "Ваши пожелания по сервису Авиа билеты",
    "Train tix": "Ваши пожелания по сервису Ж/д билеты",
    "Conference facilities": "Ваши пожелания по сервису Конференц обслуживание",
    "A/v equipment": "Ваши пожелания по сервису Аудио/видео оборудование",
    "Visa Support": "Ваши пожелания по сервису Визовая поддержка",
    "Bus Transfer": "Ваши пожелания по сервису Автобусный трансфер",
    "River Cruise": "Ваши пожелания по сервису Речной круиз",
    "Sea Cruise": "Ваши пожелания по сервису Морской круиз",
    "Package Tour": "Ваши пожелания по сервису Пакетный тур",
    "Other Service": "Ваши пожелания по сервису Прочие услуги",
    Charter: "Ваши пожелания по сервису Чартер",
    sign_out: "Выход",
  },
};
