// ##### 25-02-05 Komarov
import { useSelector } from "react-redux";
import uniqueId from "lodash.uniqueid";
import { LocalizationNavLink } from "../../../Library/Localization/LocalizationNavLink";
import React from "react";

export const AllCitiesList = ({
  style = null,
  /* cities_overview_all_data, */ countryLocalized,
}) => {
  const cities_overview_all_data = useSelector(
    (state) => state.cities.cities_overview_all[0].data.object_list,
  );
  return (
    <ul style={style}>
      {cities_overview_all_data.map((page) => {
        return (
          <li key={uniqueId()}>
            <LocalizationNavLink
              to={`/city_overview/?name=${page.object_name
                .toLowerCase()
                .replace(/\s/g, "-")
                .toLowerCase()}&id=${page.sitepageobject_id}&sptid=${
                page.type_id
              }`}
            >
              {/* ##### 24-07-24 Komarov */}
              {page.object_langname}, {countryLocalized}
            </LocalizationNavLink>
          </li>
        );
      })}
    </ul>
  );
};
