// ##### 23-03-29 Komarov
import {
  // ##### 25-03-14 Komarov
  GET_HOTELS_OVERVIEW_ALL_ERR,
  GET_HOTELS_OVERVIEW_ALL_REQ,
  GET_HOTELS_OVERVIEW_ALL_RES,
  GET_HOTELS_OVERVIEW_TOP_ERR,
  // ##### 24-07-31 Komarov
  GET_HOTELS_OVERVIEW_TOP_REQ,
  GET_HOTELS_OVERVIEW_TOP_RES,
  // ##### 25-03-14 Komarov
  GET_HOTELS_AVAIL_REQ,
  GET_HOTELS_AVAIL_RES,
  GET_HOTELS_AVAIL_ERR,
  // ##### 25-03-12 Komarov
  GET_AUTOCOMPLETE_DATA_REQ,
  GET_AUTOCOMPLETE_DATA_RES,
  GET_AUTOCOMPLETE_DATA_ERR,
  // ##### 25-02-21 Komarov
  RESET_HOTELS_AVAIL,
} from "../constants";
// ##### 25-03-14 Komarov
import config from "../config";
// ##### 25-03-14 Komarov
import { getPostObject } from "../../helpers/crypto";
import { uniLog } from "../../../server/helpers/uniLog";
// ##### 25-03-28 Komarov
import { postSavingDataClient } from "../../helpers/saveData";

// ##### 25-02-21 Komarov
export const resetHotelsAvail = () => ({
  type: RESET_HOTELS_AVAIL,
});

export const getHotelsAvail =
  ({
    sale_id,
    promocode,
    deviceType,
    company_cipher,
    start,
    end,
    city_id,
    hotel_id = 0,
    // ##### 25-03-18 Komarov: данные по умоляанию для Смарта
    adults = 1,
    children = 0,
    quantity = 1,
    // ##### 25-03-11 Komarov
    // lower_cost_limit,
    // upper_cost_limit,
    user_id,
    refpartner,
    language,
  }) =>
  async (dispatch) => {
    dispatch({ type: GET_HOTELS_AVAIL_REQ });

    const data = {
      sale_id: (sale_id && sale_id) || null,
      promocode,
      deviceType,
      company_cipher: company_cipher || "",
      start, // date of arrival
      end, // date of departure
      city_id,
      hotel_id,
      calculation_data: {
        adults,
        children,
        // ##### 25-03-11 Komarov
        // lower_cost_limit, // lower cost limit of room in USD, NOT OBLIGATORY
        // upper_cost_limit, // upper cost limit of room in USD, NOT OBLIGATORY
        quantity, // Quantity of rooms, 1 by default, NOT OBLIGATORY
      },
    };

    const postObject = getPostObject({
      action: "GetHotelAvailAlphaRQ",
      user_id,
      refpartner,
      language,
      sale: (sale_id && sale_id) || null,
      data,
    });

    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Redux/actions/hotels.js:getHotelsAvail, url:",
        // url
        postObject.url,
      );

    // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
    await postSavingDataClient({
      postObject,
      typeRes: GET_HOTELS_AVAIL_RES,
      typeErr: GET_HOTELS_AVAIL_ERR,
      dispatch,
    });
  };

// ##### 25-03-12 Komarov
export const getAutocompleteData =
  ({ sale_id, promocode, user_id, refpartner, language }) =>
  async (dispatch) => {
    dispatch({ type: GET_AUTOCOMPLETE_DATA_REQ });

    const data = {
      sale_id: (sale_id && sale_id) || null,
      promocode,
    };

    const postObject = getPostObject({
      action: "GetAutocompleteAlphaRQ",
      user_id,
      refpartner,
      language,
      sale: (sale_id && sale_id) || null,
      data,
    });

    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Redux/actions/hotels.js:getAutocompleteData, url:",
        // url
        postObject.url,
      );

    // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
    await postSavingDataClient({
      postObject,
      typeRes: GET_AUTOCOMPLETE_DATA_RES,
      typeErr: GET_AUTOCOMPLETE_DATA_ERR,
      dispatch,
    });
  };

// ##### 24-08-05 Komarov
export const getHotelsOverview =
  ({
    user_id = 1426,
    refpartner = config.defaultCompanyId,
    language,
    primary_objects_list = 1,
    section_name = "HOTELS Overview",
  }) =>
  async (dispatch) => {
    // ##### 24-07-31 Komarov: !! приводит к логическому типу значение, которое идёт после двух восклицательных знаков
    const doWeGetTopHotelsOnly = !!primary_objects_list;

    dispatch({
      type:
        (doWeGetTopHotelsOnly && GET_HOTELS_OVERVIEW_TOP_REQ) ||
        GET_HOTELS_OVERVIEW_ALL_REQ,
    });

    const data = {
      section_name,
      primary_objects_list,
    };

    const postObject = getPostObject({
      action: "GetSitePageObjectListRQ",
      user_id,
      refpartner,
      language,
      data,
    });

    // ##### 25-02-26 Komarov
    uniLog(
      "hotels.js",
      "axios:src/Redux/actions/hotels.js:getHotelsOverview, url:",
      postObject.url,
    );

    // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
    await postSavingDataClient({
      postObject,
      typeRes:
        (doWeGetTopHotelsOnly && GET_HOTELS_OVERVIEW_TOP_RES) ||
        GET_HOTELS_OVERVIEW_ALL_RES,
      typeErr:
        (doWeGetTopHotelsOnly && GET_HOTELS_OVERVIEW_TOP_ERR) ||
        GET_HOTELS_OVERVIEW_ALL_ERR,
      dispatch,
    });
  };
