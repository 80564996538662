// ##### 24-09-09 Komarov
import axios from "axios";
// ##### 25-02-05 Komarov
import React, { createContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
// ##### 23-04-12 Komarov
import { useDispatch, useSelector } from "react-redux";
// ##### 23-04-12 Komarov
import { Search } from "../../FirstPageofSearchModule/SearchResizersAndSwitchers/SearchFront";
import { ValidateQuery } from "../../Helpers/helper";
import { useWindowWidthAndHeight } from "../../Helpers/WindowResizeHook";
// ##### 23-11-08 Komarov
import { Media } from "../../../../helpers/MediaConfig";
// ##### 25-01-10 Komarov
import { getUrl } from "../../../../helpers/crypto";
// ##### 25-02-05 Komarov
import { TopToursDetailsDiv } from "./TopToursDetailsDiv";

// ##### 23-09-14 Komarov
// import './TopToursDetailsCSS.css'

// ##### 25-02-05 Komarov
export const TopToursDetailsContext = createContext();

// ##### 24-03-21 Komarov
export const TopToursDetails = () => {
  // ##### 24-05-31 Komarov
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [ttDetails, setTTDetails] = useState();
  // ##### 24-03-21 Komarov
  const location = useLocation();

  const shouldCypherUrlParams = useSelector(
    (state) => !!parseInt(state.siteCypherCodeData.data.value),
  );
  // ##### 25-02-28 Komarov
  const cypherKey = useSelector(
    (state) => state.siteCypherCodeData.data.description,
  );
  const search_data = ValidateQuery(location, shouldCypherUrlParams, cypherKey);

  // ##### 23-03-29 Komarov
  const [width /* height */] = useWindowWidthAndHeight();
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  const dispatch = useDispatch();

  useEffect(() => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/PageComponents/HomePage/TopToursDetails.js:TopToursDetails",
      );

    const url = getUrl(
      "content",
      `id=${search_data.tour_id}&language=${locale}`,
    );
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/PageComponents/HomePage/TopToursDetails.js:useEffect, url:",
        url,
      );

    axios
      .get(url)
      // ##### 24-11-01 Komarov
      .then(async (res) => {
        setTTDetails(await res.data);
      })

      .catch((error) => {
        setTTDetails(undefined);
        // ##### 24-03-21 Komarov
        console.log("[axios error] : ", error);
      });
    //  ##### 23-04-12 Komarov
  }, [
    locale,
    location.pathname,
    location.search,
    search_data.tour_id,
    dispatch,
  ]);

  // ##### 24-09-09 Komarov
  const topToursDetailsContextValue = useMemo(
    () => ({
      width,
      ttDetails,
    }),
    [
      // ##### 24-09-11 Komarov
      width,
      ttDetails,
    ],
  );

  // ##### 23-03-01 Komarov
  return (
    <TopToursDetailsContext.Provider value={topToursDetailsContextValue}>
      <Search />
      {/* ##### 23-11-08 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <TopToursDetailsDiv className="TopToursDetailsBlock" />
      </Media>
      <Media lessThan="Laptop-1024-1440">
        <TopToursDetailsDiv className="TopToursDetailsBlockSmallScreen" />
      </Media>
    </TopToursDetailsContext.Provider>
  );
};
