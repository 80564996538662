import {
  GET_TOPTOURS_ERR,
  GET_TOPTOURS_REQ,
  GET_TOPTOURS_RES,
} from "../constants";
// ##### 25-01-10 Komarov
import { getUrl } from "../../helpers/crypto";
// ##### 25-02-26 Komarov
import { uniLog } from "../../../server/helpers/uniLog";
// ##### 25-03-28 Komarov
import { getSavingDataClient } from "../../helpers/saveData";

// ##### 24-03-21 Komarov
export const getTopTours = () => async (dispatch) => {
  dispatch({ type: GET_TOPTOURS_REQ });

  // ##### 25-01-10 Komarov
  const url = getUrl("toptours", "");

  // ##### 25-02-26 Komarov
  uniLog(
    "toptours.js",
    "axios:src/Redux/actions/toptours.js:getTopTours, url:",
    url,
  );

  // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
  await getSavingDataClient({
    smartUrl: url,
    typeRes: GET_TOPTOURS_RES,
    typeErr: GET_TOPTOURS_ERR,
    dispatch,
  });
};
