// ##### 23-12-27 Komarov
import React from "react";
// ##### 24-08-01 Komarov
import parse from "html-react-parser";
import uniqueId from "lodash.uniqueid";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// ##### 23-11-02 Komarov
import { Media } from "../../../../helpers/MediaConfig";
// ##### 24-12-26 Komarov
import { makeParamsString } from "../../Helpers/helper";
// ##### 25-02-05 Komarov
import { BodyPageImg } from "./BodyPageImg";

// ##### 23-09-14 Komarov
// import '../../PageComponents/ResponsiveHeader/header.css'
// import './TopToursCSS.css'

export const BodyPage = ({ sidePageID, sitePageTypeID, sitePageName }) => {
  // ##### 25-03-26 Komarov
  const purepages = useSelector((state) => state.pages.purepages);
  const article = purepages[sidePageID];

  const history = useHistory();

  const GetBannerDetails = () => {
    // ##### 24-12-26 Komarov
    const params_string = makeParamsString({
      name: sitePageName.replace(/\s/g, "-").toLowerCase(),
      id: sidePageID,
    });
    // ##### 25-03-26 Komarov
    const route_query = `/${locale}/sitepages/?${params_string}`;
    history.push(route_query);
  };

  return (
    <div
      data-component="BodyPage"
      className="frontPageArticle"
      style={{ order: `${sitePageTypeID === 46 ? 3 : 5}` }}
    >
      {article
        ? article.map((item) => {
            if (item.content_name === "Image" && sitePageTypeID === 46) {
              return (
                <div
                  // ##### 23-11-07 Komarov
                  key={uniqueId()}
                  onClick={GetBannerDetails}
                  // ##### 24-03-21 Komarov
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      GetBannerDetails();
                    }
                  }}
                  className="frontPageBanner"
                >
                  {/* ##### 23-11-08 Komarov */}
                  <BodyPageImg item={item} style={{ width: "100%" }} />
                </div>
              );
            }

            // ##### 24-03-21 Komarov
            if (item.content_name === "Body" && sitePageTypeID !== 46) {
              // ##### 23-03-16 Komarov
              return (
                <React.Fragment key={uniqueId()}>
                  {/* ##### 23-11-07 Komarov */}
                  <Media
                    lessThan="Laptop-1024-1440"
                    key={uniqueId()}
                    style={{
                      order: "2",
                      width: "95%",
                    }}
                  >
                    {parse(item.text)}
                  </Media>
                  <Media
                    greaterThanOrEqual="Laptop-1024-1440"
                    key={uniqueId()}
                    style={{
                      order: "2",
                      width: "70%",
                    }}
                  >
                    {parse(item.text)}
                  </Media>
                </React.Fragment>
              );
            }
          })
        : null}
    </div>
  );
};
