// ##### 24-06-12 Komarov
import React, { useContext } from "react";
import parse from "html-react-parser";
import { LocalizationNavLink } from "../../../../Library/Localization/LocalizationNavLink";
import { PhoneIcon } from "../../../../Library/Icons/phoneicon";
import { EmailIcon } from "../../../../Library/Icons/email";
import { Operations } from "../../../../Library/Icons/operations";
import { LogInLinkTag } from "../../../../Library/Common/LogInLinkTag";
import { HomeLinkTag } from "../../../../Library/Common/HomeLinkTag";
import uniqueId from "lodash.uniqueid";
import { useMessages } from "../../../../../helpers/messages";
import { Media } from "../../../../../helpers/MediaConfig";
import { TopMenuContext } from "./Header";

// ##### 23-04-24 Komarov
export const TopMenuRightDivPartForNotDesktop = () => {
  const { Mail_icon, Phone_icon, Operations_icon } = useContext(TopMenuContext);
  return (
    <div
      data-component="TopMenuRightDivPartForNotDesktop"
      className="topMenu_right_mobile"
    >
      <Media lessThan="Laptop-1024-1440">
        {
          // ##### 24-04-23 Komarov
          Object.values(useMessages()).length > 0 ? (
            <EmailIcon
              key={uniqueId()}
              color={"white"}
              // ##### 23-10-04 Komarov
              hintText={parse(Mail_icon)}
            />
          ) : (
            <EmailIcon color={"white"} hintText={"Email"} />
          )
        }
      </Media>
      <Media lessThan="Laptop-1024-1440">
        {
          // ##### 24-04-23 Komarov
          Object.values(useMessages()).length > 0 ? (
            <PhoneIcon
              key={uniqueId()}
              color={"white"}
              // ##### 23-10-04 Komarov
              hintText={parse(Phone_icon)}
            />
          ) : (
            <PhoneIcon color={"white"} hintText={"Phone"} />
          )
        }
      </Media>

      {/* ##### 23-04-05 Komarov: элемент Home */}
      <HomeLinkTag />

      {/* ##### 23-04-05 Komarov: элемент Operations */}
      {
        // ##### 24-04-23 Komarov
        Object.values(useMessages()).length > 0 ? (
          <LocalizationNavLink key={uniqueId()} exact to={"/mybooking"}>
            <Operations
              key={uniqueId()}
              color={"white"}
              // ##### 23-10-04 Komarov
              hintText={parse(Operations_icon)}
            />
            {/* ##### 24-03-21 Komarov */}
          </LocalizationNavLink>
        ) : (
          <LocalizationNavLink exact to={"/mybooking"}>
            <Operations color={"white"} hintText={"Operations"} />
          </LocalizationNavLink>
        )
      }

      {/* ##### 23-11-01 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <LogInLinkTag right="9rem" />
      </Media>
    </div>
  );
};
