// ##### 23-12-27 Komarov
import React, { useEffect } from "react";
// ##### 23-03-29 Komarov
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// ##### 25-01-22 Komarov
import { changeLang } from "../../../../Redux/actions";
// ##### 24-04-17 Komarov
import { ValidateQuery, makeParamsString } from "../../Helpers/helper";
// ##### 24-12-27 Komarov
import { encryptString } from "../../../../helpers/crypto";

// ##### 24-04-19 Komarov
export const HotelBookButton = ({ innerText, hotelName, hotel_id }) => {
  // ##### 25-02-12 Komarov
  const cityName = useSelector(
    (state) => state.hotelcontent.hotelcontent2.data.ci_title,
  );
  const city_id = useSelector(
    (state) => state.hotels.hotels_avail.data.city_id,
  );
  // ##### 25-02-08 Komarov: Если в hotel значение notavail установлено в 0, то выводим этот отель как доступный сейчас, иначе - как отель под запрос
  const hotel_available = !!!parseInt(
    useSelector(
      (state) => state.hotels.hotels_avail.data.hotels[hotel_id].notavail,
    ),
  );
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  // ##### 24-12-27 Komarov
  const shouldCypherUrlParams = useSelector(
    (state) => !!parseInt(state.siteCypherCodeData.data.value),
  );
  // ##### 25-02-28 Komarov
  const cypherKey = useSelector(
    (state) => state.siteCypherCodeData.data.description,
  );
  const search_data = ValidateQuery(location, shouldCypherUrlParams, cypherKey);
  const promoCode = useSelector((state) => state.promocode.promocode);
  // ##### 24-03-01 Komarov
  const whitelabel = useSelector((state) => state.whitelabel);
  const isWhiteLabelSet = whitelabel.set;
  const company_cipher = whitelabel.company_cipher;
  // ##### 24-02-22 Komarov
  const pickedcurrencyFromState = useSelector(
    (state) => state.currency.pickedcurrency,
  );
  // ##### 24-07-25 Komarov
  const pickedcurrency =
    pickedcurrencyFromState ||
    JSON.parse(localStorage.getItem("currency")).pickedcurrency;

  // console.log('HOTELSEARCH',search_data )
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeLang());
    // ##### 23-04-12 Komarov
  }, [dispatch]);

  // ##### 24-04-12 Komarov
  const redux_pax_data = useSelector((state) => state.paxchoice.pax);

  const addToHotelDetails = (e) => {
    e.preventDefault();

    const params_string = makeParamsString({
      selected_currency:
        localStorage.getItem("current_currency") || pickedcurrency,
      cityName: cityName || "",
      start: search_data.start || "",
      end: search_data.end || "",
      // ##### 25-02-12 Komarov
      id: hotel_id,
      city_id: search_data.city_id || city_id,
      adults: search_data.adults || redux_pax_data.adults || "Not set",
      // ##### 24-05-28 Komarov: 0 в логическом выражении с использованием || превращается в false, и так как значение children по умолчанию у нас 0, и этот 0 нужен, я его преобразовываю в строку
      children:
        search_data.children ||
        (!isNaN(redux_pax_data.children) &&
          typeof redux_pax_data.children !== "undefined" &&
          String(redux_pax_data.children)) ||
        "Not set",
      rooms: search_data.rooms || redux_pax_data.rooms || "Not set",
      hotel_name: hotelName || "",
      // ##### 25-02-12 Komarov
      hotel_id,
      // ##### 25-02-20 Komarov
      hotel_available,
    });
    // ##### 24-12-27 Komarov
    const route_query = `?${shouldCypherUrlParams ? encryptString(params_string) : params_string}`;

    // ##### 24-02-29 Komarov: TODO: Убрать промокод из URL
    if (isWhiteLabelSet) {
      history.push(
        `/${locale}/ref${company_cipher}/hotel_details/${route_query}`,
      );
    } else if (promoCode) {
      history.push(`/${locale}/hotel_details/${route_query}`);
    } else {
      history.push(`/${locale}/hotel_details/${route_query}`);
    }
  };

  // ##### 25-02-12 Komarov
  return (
    <button
      data-component="HotelBookButton"
      type="submit"
      className={
        (hotel_available && "availableButtonHotel") || "onRequestButtonHotel"
      }
      onClick={addToHotelDetails}
    >
      {innerText}
    </button>
  );
};
