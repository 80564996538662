// ##### 24-06-12 Komarov
import parse from "html-react-parser";
import uniqueId from "lodash.uniqueid";
import React, { useContext } from "react";
import { Media } from "../../../../../helpers/MediaConfig";
import { useMessages } from "../../../../../helpers/messages";
import { LogInLinkTag } from "../../../../Library/Common/LogInLinkTag";
import { HomeIcon } from "../../../../Library/Icons/homeicon";
import { Operations } from "../../../../Library/Icons/operations";
import { LocalizationNavLink } from "../../../../Library/Localization/LocalizationNavLink";
import { LargeScreensNavBar } from "../LargeScreensNavBar";
import { TopMenuContext } from "./Header";
// ##### 23-04-24 Komarov
export const TopMenuRightDivPartForDesktop = () => {
  // ##### 25-02-21 Komarov
  const { sitePagesObj } = useContext(TopMenuContext);
  const Home_icon = useMessages("Home_icon");
  const Operations_icon = useMessages("Operations_icon");
  // ##### 24-06-12 Komarov
  return (
    <div
      className="topMenu_right"
      data-component="TopMenuRightDivPartForDesktop"
    >
      {/* ##### 23-11-01 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        {/* ##### 24-06-20 Komarov */}
        {sitePagesObj.Header.UPPER && (
          <LargeScreensNavBar
            sitepage_type={sitePagesObj.Header.UPPER}
            linkClassName={"Upper"}
            key={() => uniqueId()}
          />
        )}
      </Media>
      {/* ##### 25-01-16 Komarov */}
      <Media
        greaterThanOrEqual="Laptop-1024-1440"
        style={{ display: "flex", alignItems: "center" }}
      >
        {/* ##### 23-04-05 Komarov: элемент Home */}
        {/* ##### 24-06-20 Komarov */}
        <LocalizationNavLink key={uniqueId()} exact to={""}>
          <HomeIcon hintText={parse(Home_icon)} />
        </LocalizationNavLink>

        {/* ##### 23-04-05 Komarov: элемент Operations */}
        {
          // ##### 24-04-23 Komarov
          Object.values(useMessages()).length > 0 ? (
            <LocalizationNavLink key={uniqueId()} exact to={"/mybooking"}>
              <Operations
                // ##### 24-06-20 Komarov
                key={uniqueId()}
                hintText={parse(Operations_icon)}
              />
              {/* ##### 24-03-21 Komarov */}
            </LocalizationNavLink>
          ) : (
            <LocalizationNavLink exact to={"/mybooking"}>
              <Operations hintText={"Operations"} />
            </LocalizationNavLink>
          )
        }

        {/* ##### 23-04-05 Komarov: элемент Login */}
        <LogInLinkTag className="" right="9rem" />
      </Media>
    </div>
  );
};
