// ##### 24-09-09 Komarov
import React, { useState, useEffect, useContext, createContext, useMemo } from "react";
// ##### 24-03-21 Komarov
import uniqueId from "lodash.uniqueid";
import SliderContent from "./SliderContent";
import Slide from "./Slide";
// ##### 23-09-14 Komarov
// import './SliderCss.css'
import { Arrow } from "./ArrowSlider";
import { sliderFrequency } from "../../Context/config.js";
// ##### 23-11-02 Komarov
import { Media } from "../../../helpers/MediaConfig";

// ##### 23-10-10 Komarov
const SliderDiv = ({ className }) => {
  const { props } = useContext(SliderContext);

  const getWidth = () => window.innerWidth;

  const prevSlide = () => {
    if (activeIndex === 0) {
      return setState({
        ...state,
        translate: (props.slides.length - 1) * getWidth(),
        activeIndex: props.slides.length - 1,
      });
    }

    setState({
      ...state,
      activeIndex: activeIndex - 1,
      translate: (activeIndex - 1) * getWidth(),
    });
  };

  const [state, setState] = useState({
    activeIndex: 0,
    translate: 0,
    transition: 0.45,
  });

  const { translate, transition, activeIndex } = state;

  const nextSlide = () => {
    if (activeIndex === props.slides.length - 1) {
      return setState({
        ...state,
        translate: 0,
        activeIndex: 0,
      });
    }
    // ##### 24-03-21 Komarov
    return setState({
      ...state,
      activeIndex: activeIndex + 1,
      translate: (activeIndex + 1) * getWidth(),
    });
  };

  // ##### 23-04-12 Komarov
  useEffect(() => {
    let timer = null;
    timer = setTimeout(nextSlide, sliderFrequency);
    return () => {
      clearInterval(timer);
    };
  });

  // ##### 23-10-06 Komarov: TODO: Может, здесь лишний один слой div ?..
  return (
    <div data-component="SliderDiv">
      <div className={className}>
        <SliderContent
          translate={translate}
          transition={transition}
          index={activeIndex}
          width={getWidth() * props.slides.length}
        // width='100%'
        >
          {/* ##### 24-03-21 Komarov */}
          {props.slides.map((slide /* , index */) => (
            <Slide
              key={uniqueId()}
              content={(process.env.REACT_APP_PROTOCOL || "https://") + slide}
            />
          ))}
        </SliderContent>

        <div className="Arrows">
          <Arrow direction="left" handleClick={prevSlide} />

          <Arrow direction="right" handleClick={nextSlide} />
        </div>
      </div>
    </div>
  );
};

const SliderContext = createContext();

const Slider = (props) => {
  // ##### 24-03-21 Komarov
  // const deviceType = useSelector(state => state.deviceType);

  // console.log(props.slides)

  // ##### 24-09-11 Komarov
  const sliderContextValue = useMemo(() => ({ props }), [props]); // value is cached by useMemo

  return (
    <SliderContext.Provider value={sliderContextValue}>
      {/* ##### 23-11-02 Komarov */}
      <Media lessThan="Mobile-L-425-768">
        <SliderDiv className="SliderTabletPortraitCSS" />
      </Media>
      <Media between={["Mobile-L-425-768", "Laptop-1024-1440"]}>
        <SliderDiv className="SliderMobilePortraitCSS" />
      </Media>
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <SliderDiv className="SliderCSS" />
      </Media>
    </SliderContext.Provider>
  );
};

export default Slider;
