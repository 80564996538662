// ##### 24-06-13 Komarov
import React, { useContext } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Pax } from "../../../../Library/Icons/pax.js";
import { useMessages } from "../../../../../helpers/messages.js";
import { Media } from "../../../../../helpers/MediaConfig.js";
import { HotelsPaxChoiceContext } from "./HotelsPaxChoice.js";

// ##### 23-11-03 Komarov

export const PaxValue = ({ style = null }) => {
  const { TotalPax, counterAdults, counterChild, counterRooms } = useContext(
    HotelsPaxChoiceContext
  );

  // ##### 24-05-10 Komarov
  const Adults = useMessages("Adults");
  const Children = useMessages("Children");
  const Rooms = useMessages("Rooms");
  // ##### 24-01-31 Komarov
  return (
    <div
      data-component="PaxValue"
      style={style}
      onClick={TotalPax}
      onKeyDown={(e) => {
        // Trigger click action on Enter key press
        if (e.key === "Enter") {
          TotalPax(e);
        }
      }}
    >
      <Pax />
      <div style={{ paddingLeft: "1vw", alignContent: "center" }}>
        <center>
          {/* ##### 22-11-14 Komarov */}
          <b>{counterAdults}</b>
          <span style={{ marginLeft: "0.3rem" }} />
          {
            // ##### 23-10-04 Komarov
            Adults}
          ,
        </center>
      </div>
      <div style={{ paddingLeft: "1vw" }}>
        <center>
          {/* ##### 23-03-01 Komarov */}
          <b>{counterChild}</b>
          <span style={{ marginLeft: "0.3rem" }} />
          {
            // ##### 23-10-04 Komarov
            Children}
          ,
        </center>
      </div>
      <div style={{ paddingLeft: "1vw" }}>
        <center>
          <b>{counterRooms}</b>
          <span style={{ marginLeft: "0.3rem" }} />
          {
            // ##### 23-10-04 Komarov
            Rooms}
        </center>
      </div>
      {/* ##### 23-11-03 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <DownOutlined className="DownOutlined" />
      </Media>
      <Media lessThan="Laptop-1024-1440">
        <DownOutlined className="DownOutlined_SmallPortrait" />
      </Media>
    </div>
  );
};
