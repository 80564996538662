import React from "react";
// ##### 25-02-05 Komarov
import { useSelector } from "react-redux";
import { Media } from "../../../../helpers/MediaConfig";
import { LangSelect } from "./LangSelect";

export const LangSelectionTool = () => {
  // ##### 23-04-05 Komarov
  const deviceType = useSelector((state) => state.deviceType);

  return (
    <div style={deviceType === "desktop" ? null : { marginRight: "2vw" }}>
      {/* ##### 23-11-02 Komarov */}
      <Media lessThan="Mobile-L-425-768">
        <LangSelect
          dropdownStyle={{
            scale: "none",
            transformOrigin: "top left",
          }}
        />
      </Media>
      <Media between={["Mobile-L-425-768", "Laptop-1024-1440"]}>
        <LangSelect
          dropdownStyle={{
            scale: "1.1",
            transformOrigin: "top left",
          }}
        />
      </Media>
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <LangSelect />
      </Media>
    </div>
  );
};
