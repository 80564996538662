import React from "react";

const SaveReference = ({ customer_reference }) => {
  return (
    <div data-component="SaveReference">
      {/* <div>Hi there!</div> */}
      {/* ##### 23-10-05 Komarov: TODO: брать перевод из Смарта */}
      <div>
        Your Customer Reference:{" "}
        <span
          style={{
            fontSize: "17px",
            fontFamily: "Arial Narrow",
            color: "#4D4B4B",
            fontWeight: "bold",
          }}
        >
          {customer_reference}{" "}
        </span>
      </div>
    </div>
  );
};

export default SaveReference;
