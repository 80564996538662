// ##### 23-03-29 Komarov
import React, { Component } from "react";
import { connect } from "react-redux";
// ##### 23-12-22 Komarov
import { bookingFromCart, getPaymentInfo } from "../../../Redux/actions";
import { compose } from "../../../Redux/helpers";
import { withPaymentService } from "../../HOC";
import { CartDetails } from "../BookingForm/CartDetails";

// ##### 23-09-14 Komarov
// import './CartDetailsSummary.css'
import { Redirect } from "react-router";
// ##### 24-12-23 Komarov
import { decrypt } from "../../../helpers/crypto";

class PaymentResult extends Component {
  componentDidMount() {
    // ##### 23-12-22 Komarov
    this.props.bookingFromCart();
    this.props.getPaymentInfo();
  }

  render() {
    if (this.props.paymentInfo === null) {
      return (
        <div data-component="PaymentResult">
          {/* ##### 23-09-07 Komarov: TODO: Сделать сюда перевод текста на разные языки */}
          <div>Please wait...</div>
          {/* 245 PleaseWait */}
        </div>
      );
    }
    // ##### 24-03-21 Komarov
    // console.log("work_with_payment, paymentInfo: ", this.props.paymentInfo);
    // const orderId = this.props.paymentInfo[0].data.smart_service_id;
    // const orderId = this.props.paymentInfo[0].data.smart_order_id;
    // const orderId = this.props.paymentInfo[0].data.booking_reference;
    // ##### 23-12-27 Komarov
    // const orderId = this.props.paymentInfo[0].data.smart_reference;
    const orderId = this.props.paymentInfo[0].data.customer_reference;
    // ##### 23-03-29 Komarov
    // const warnings = this.props.paymentInfo[0].warnings;
    const isPaymentAbsent = this.props.paymentInfo[0].warnings[0].payment;
    // console.log("work_with_payment, paymentInfo: isPaymentAbsent ", isPaymentAbsent);
    // ##### 23-09-07 Komarov: TODO: Сделать сюда перевод текста на разные языки
    const isSuccess = this.props.paymentInfo[0].success
      ? "Your reservation is confirmed and prepaid"
      : "Please wait...";
    // 246 ReservationConfirmed | 245 PleaseWait
    const openInNewTab = (url) => {
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    };
    const searchDataEncrypted = localStorage.getItem("search_data");
    // ##### 24-12-23 Komarov
    /*const CryptoJS = require("crypto-js");
    // ##### 24-03-19 Komarov
    const bytes = CryptoJS.AES.decrypt(
      searchDataEncrypted,
      process.env.REACT_APP_PRIVATE_KEY_PROD
    );
    const searchData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));*/
    const searchData = decrypt(searchDataEncrypted);

    if (isPaymentAbsent === "absent") {
      const lastLocation = localStorage.getItem("lastLocation");
      return <Redirect to={lastLocation} />;
    }
    // ##### 24-03-21 Komarov
    return (
      <div
        data-component="PaymentResult"
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "2vh",
          marginBottom: "3vh",
        }}
      >
        {/* ##### 23-12-27 Komarov: TODO: взять переводы из Смарта */}
        <div className="VoucherText">
          <h4 style={{ marginRight: "5px" }}>Thank you.</h4>
          <h4 style={{ marginRight: "5px" }}>{isSuccess}.</h4>
          {/* ##### 23-12-27 Komarov */}
          {/* <h4 style={{marginRight: '5px'}}> Your booking ID is {orderId}.</h4> */}
          <h4 style={{ marginRight: "5px" }}>
            {" "}
            Your Customer reference is {orderId}.
          </h4>
          <h4 style={{ marginRight: "5px" }}>Click here to </h4>
          {/* ##### 24-03-21 Komarov */}
          <button
            type="submit"
            style={{
              backgroundColor: "#337ab7",
              color: "#fff",
              borderColor: "#2e6da4",
              border: "1px solid transparent",
              borderRadius: "4px",
              padding: "6px 12px",
              cursor: "pointer",
            }}
            onClick={() => {
              openInNewTab(this.props.paymentInfo[0].data.voucherUrl);
            }}
          >
            Get voucher
          </button>
        </div>

        <CartDetails cart={searchData} cartClass={"CartDetailsSummary"} />
      </div>
    );
  }
}

const mapStateToProps = ({ payment, voucher }) => {
  // console.log("work_with_payment: mapStateToProps ");
  // ##### 23-03-29 Komarov
  const { paymentInfo /* loading */ } = payment;
  const { voucherData } = voucher;

  return { paymentInfo, voucherData };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { paymentService } = ownProps;
  return {
    // ##### 23-12-22 Komarov
    bookingFromCart: bookingFromCart(paymentService, dispatch),
    getPaymentInfo: getPaymentInfo(paymentService, dispatch),
    // fetchPaymentVoucher: (orderId) => fetchPaymentVoucher(paymentService, dispatch, orderId)
  };
};

export default compose(
  withPaymentService(),
  connect(mapStateToProps, mapDispatchToProps),
)(PaymentResult);
