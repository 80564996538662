// ##### 25-02-05 Komarov
import React, { useContext, useEffect, useState } from "react";
import { sliderFrequency } from "../../Context/config";
import SliderContent from "./SliderContent";
import Slide from "./Slide";
import uniqueId from "lodash.uniqueid";
import { ArrowSlider } from "./ArrowSlider";
import { SliderContext } from "./Slider";

export const SliderDiv = ({ className }) => {
  const { props } = useContext(SliderContext);

  const getWidth = () => window.innerWidth;

  const prevSlide = () => {
    if (activeIndex === 0) {
      return setState({
        ...state,
        translate: (props.slides.length - 1) * getWidth(),
        activeIndex: props.slides.length - 1,
      });
    }

    setState({
      ...state,
      activeIndex: activeIndex - 1,
      translate: (activeIndex - 1) * getWidth(),
    });
  };

  const [state, setState] = useState({
    activeIndex: 0,
    translate: 0,
    transition: 0.45,
  });

  const { translate, transition, activeIndex } = state;

  const nextSlide = () => {
    if (activeIndex === props.slides.length - 1) {
      return setState({
        ...state,
        translate: 0,
        activeIndex: 0,
      });
    }
    // ##### 24-03-21 Komarov
    return setState({
      ...state,
      activeIndex: activeIndex + 1,
      translate: (activeIndex + 1) * getWidth(),
    });
  };

  // ##### 23-04-12 Komarov
  useEffect(() => {
    let timer = null;
    timer = setTimeout(nextSlide, sliderFrequency);
    return () => {
      clearInterval(timer);
    };
  });

  // ##### 23-10-06 Komarov: TODO: Может, здесь лишний один слой div ?..
  return (
    <div data-component="SliderDiv">
      <div className={className}>
        <SliderContent
          translate={translate}
          transition={transition}
          index={activeIndex}
          width={getWidth() * props.slides.length}
          // width='100%'
        >
          {/* ##### 24-03-21 Komarov */}
          {props.slides.map((slide /* , index */) => (
            <Slide
              key={uniqueId()}
              content={(process.env.REACT_APP_PROTOCOL || "https://") + slide}
            />
          ))}
        </SliderContent>

        <div className="Arrows">
          <ArrowSlider direction="left" handleClick={prevSlide} />

          <ArrowSlider direction="right" handleClick={nextSlide} />
        </div>
      </div>
    </div>
  );
};
