import axios from "../helpers/public.axios";

import {
  GET_EN_TOUR_ERR,
  GET_EN_TOUR_REQ,
  GET_EN_TOUR_RES,
  GET_IMAGES_TOUR_ERR,
  GET_IMAGES_TOUR_REQ,
  GET_IMAGES_TOUR_RES,
  GET_TOURDETAILS_ERR,
  GET_TOURDETAILS_REQ,
  GET_TOURDETAILS_RES,
} from "../constants";
// ##### 25-01-10 Komarov
import { getUrl } from "../../helpers/crypto";
// ##### 25-03-28 Komarov
import { getSavingDataClient } from "../../helpers/saveData";

export const getTourDetails = (id, date) => async (dispatch) => {
  //
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/tours.js:getTourDetails");

  // console.log('REDUX',tour_id,selectionDetails)

  dispatch({ type: GET_TOURDETAILS_REQ });

  // ##### 25-01-10 Komarov
  const url = getUrl("sitechoice3new", `tour_id=${id}&date=${date}`);
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/tours.js:getTourDetails, url:", url);

  // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
  await getSavingDataClient({
    smartUrl: url,
    typeRes: GET_TOURDETAILS_RES,
    typeErr: GET_TOURDETAILS_ERR,
    dispatch,
  });
};

export const ENtourResponse = (res) => ({
  type: GET_EN_TOUR_RES,
  payload: res,
});

// ##### 25-03-26 Komarov
export const ENgetTour = () => async (dispatch) => {
  //
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/tours.js:ENgetTour");

  dispatch({ type: GET_EN_TOUR_REQ });

  //
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/tours.js:ENgetTour, url:",
      "https://hotelsukraine.com.ua/ua/my_list_hotels/?type=full&hotels=16752&json=1&_dc=1608289903506&getDescription=Ext.data.JsonP.getDescription",
    );
  // ##### 25-03-26 Komarov
  await axios
    .get(
      "https://hotelsukraine.com.ua/ua/my_list_hotels/?type=full&hotels=16752&json=1&_dc=1608289903506&getDescription=Ext.data.JsonP.getDescription",
      {},
    )
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      const obj = JSON.parse(
        res.data.substring(30, (await res.data.length) - 1),
      );
      //
      for (const key in obj) {
        if (key === "en") {
          // console.log(obj[key])
          dispatch(ENtourResponse(obj[key]));
        }
      }
    })
    .catch((err) => {
      dispatch({ type: GET_EN_TOUR_ERR, error: err });
    });
};

export const IMGtourResponse = (res) => ({
  type: GET_IMAGES_TOUR_RES,
  payload: res,
});

// ##### 25-03-26 Komarov
export const IMGgetTour = () => async (dispatch) => {
  //
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/tours.js:IMGgetTour");

  dispatch({ type: GET_IMAGES_TOUR_REQ });

  //
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/tours.js:IMGgetTour, url:",
      "https://hotelsukraine.com.ua/ua/my_list_hotels/?type=full&hotels=16752&json=1&_dc=1608289903506&getDescription=Ext.data.JsonP.getDescription",
    );
  // ##### 25-03-26 Komarov
  await axios
    .get(
      "https://hotelsukraine.com.ua/ua/my_list_hotels/?type=full&hotels=16752&json=1&_dc=1608289903506&getDescription=Ext.data.JsonP.getDescription",
      {},
    )
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      const obj = JSON.parse(
        res.data.substring(30, (await res.data.length) - 1),
      );
      //
      for (const key in obj) {
        if (key === "photo_gallery") {
          // console.log(obj[key])
          const newArr = [];
          //
          for (const item in obj[key]) {
            newArr.push(obj[key][item]);
          }

          dispatch(IMGtourResponse(newArr));
        }
      }
    })
    .catch((err) => {
      dispatch({ type: GET_IMAGES_TOUR_ERR, error: err });
    });
};
