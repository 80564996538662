// ##### 24-03-21 Komarov
import React from "react";
import { useSelector } from "react-redux";
// ##### 23-10-17 Komarov
import { Space, Select, InputNumber, Input } from "antd";
import parse from "html-react-parser";
// ##### 24-09-09 Komarov
import { /* TravelServiceTypes,  */TravelServiceTypesObj } from "../../../../StaticJsonData/TravelServiceTypes";
import { StarRating } from "../../../../StaticJsonData/StarRating";
import { VehicleTypes } from "../../../../StaticJsonData/VehicleTypes";
import { GuideTypes } from "../../../../StaticJsonData/GuideTypes";
import { PlaceHolderStrings } from "../../../../Localization/placeholders.js";
import uniqueId from "lodash.uniqueid";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../../../helpers/messages";

import LodgingTypes from "../../../../StaticJsonData/LodgingTypes.json";

// ##### 23-09-14 Komarov
// import '../GroupTravelFormCSS.css'
// ##### 23-10-04 Komarov: функция для помечания полей обязательными для заполнения (она добавляет звёздочку к названию поля)
// ##### 24-09-09 Komarov
const markReuired = (labelText) => `${labelText} *`;

// ##### 24-09-09 Komarov
const AccommodationComponent = ({ props }) => {
  const {
    selectingAccomTypes,
    accomType,
    accomTypeInitialState,
    SelectSingleRoomsAmount,
    SelectTwinRoomsAmount,
    selectStarRating,
    starRating,
    starRatingInitialState
  } = props;
  // ##### 24-04-23 Komarov
  const groupRequestEstablishment = useMessages("Group_request_establishment");
  const placeholder = PlaceHolderStrings();
  const locale = useSelector((state) => state.language);
  return (<div
    className="groupTravelblocks_2_accommodation"
    key={uniqueId()}
  >
    <span>
      {
        // ##### 23-10-04 Komarov
        markReuired(groupRequestEstablishment)
      }
    </span>
    <Select
      onChange={selectingAccomTypes}
      bordered={true}
      style={{ minWidth: "5vw" }}
      // ##### 24-03-21 Komarov
      value={
        (accomType === accomTypeInitialState &&
          placeholder.placeHolderSelect) ||
        accomType
      }
    >
      <>
        {
          // ##### 24-03-21 Komarov
          LodgingTypes?.map((item) => {
            if (item.lang === locale) {
              return item.type.map((item1) => {
                return (
                  <Option value={item1} key={item1}>
                    {item1}
                  </Option>
                );
              });
            }
          })
        }
      </>
    </Select>

    <Select
      onChange={selectStarRating}
      bordered={true}
      style={{ marginLeft: "0.5vw", marginRight: "0,5vw" }}
      value={
        (starRating === starRatingInitialState &&
          placeholder.placeHolderStarRating) ||
        starRating
      }
    >
      {
        // ##### 24-03-21 Komarov
        StarRating?.map((item) => {
          return (
            <Option
              value={item}
              key={item}
            >
              {item}
            </Option>
          );
        })
      }
    </Select>
    <span>
      {/* ##### 23-10-02 Komarov: TODO: заменить на перевод из Смарта */}
      Twin rooms
    </span>
    <Space>
      <InputNumber
        min={1}
        max={100000}
        defaultValue={1}
        onChange={SelectTwinRoomsAmount}
      />
    </Space>
    <span>
      {/* ##### 23-10-02 Komarov: TODO: заменить на перевод из Смарта */}
      Single rooms
    </span>
    <Space>
      <InputNumber
        min={1}
        max={100000}
        defaultValue={1}
        onChange={SelectSingleRoomsAmount}
      />
    </Space>
  </div>
  )
};

const TransfersComponent = ({ props }) => {
  const chooseTypeOfVehicle = useMessages("ChooseTypeOfVehicle");
  const {
    selectVehicleTypes,
    vehicleTypes,
    vehicleTypesInitialState
  } = props;
  const placeholder = PlaceHolderStrings();
  const locale = useSelector((state) => state.language);
  return (<div className="groupTravelblocks_2" key={uniqueId()}>
    <span>
      {/* ##### 23-09-07 Komarov */}
      {chooseTypeOfVehicle}
    </span>
    <Select
      onChange={selectVehicleTypes}
      bordered={true}
      style={{ minWidth: "5vw" }}
      key={uniqueId()}
      // ##### 24-03-21 Komarov
      value={
        (vehicleTypes === vehicleTypesInitialState &&
          placeholder.placeHolderSelect) ||
        vehicleTypes
      }
    >
      <>
        {
          // ##### 24-03-21 Komarov
          VehicleTypes(locale)?.map((item) => {
            return (
              <Option value={item} key={item}>
                {item}
              </Option>
            );
          })
        }
      </>
    </Select>
  </div>
  )
};

const MealComponent = ({ props }) => {
  const {
    selectMealType,
    mealType,
    mealTypeInitialState
  } = props;
  const placeholder = PlaceHolderStrings();

  const mealTypes = [
    useMessages("All Inclusive"),
    useMessages("Full board"),
    useMessages("Breakfast"),
    useMessages("Lunch"),
    useMessages("Dinner"),
    useMessages("Half board"),
    useMessages("Breakfast and Lanch"),
    useMessages("Lunch and Dinner"),
    useMessages("Without Food"),
  ];

  const chooseTypeOfMeal = useMessages("chooseTypeOfMeal");
  return (<div className="groupTravelblocks_2" key={uniqueId()}>
    <span>{chooseTypeOfMeal}</span>
    <Select
      onChange={selectMealType}
      bordered={true}
      style={{ minWidth: "5vw" }}
      key={uniqueId()}
      // ##### 24-03-21 Komarov
      value={
        (mealType === mealTypeInitialState &&
          placeholder.placeHolderSelect) ||
        mealType
      }
    >
      <>
        {
          // ##### 24-03-21 Komarov
          mealTypes?.map((item) => (
            <Option value={item} key={item}>
              {item}
            </Option>
          ))
        }
      </>
    </Select>
  </div>
  )
};

const GuideAssistantComponent = ({ props }) => {
  const {
    selectGuideTypes,
    guideTypes,
    guideTypesTypesInitialState
  } = props;
  const placeholder = PlaceHolderStrings();
  const chooseGuideLanguage = useMessages("ChooseGuideLanguage");
  const locale = useSelector((state) => state.language);

  return (<div className="groupTravelblocks_2" key={uniqueId()}>
    <span>
      {/* ##### 23-09-07 Komarov */}
      {chooseGuideLanguage}
    </span>
    <Select
      onChange={selectGuideTypes}
      bordered={true}
      style={{ minWidth: "5vw" }}
      // ##### 24-03-21 Komarov
      value={
        (guideTypes === guideTypesTypesInitialState &&
          placeholder.placeHolderSelect) ||
        guideTypes
      }
    >
      <>
        {
          // ##### 24-03-21 Komarov
          GuideTypes(locale)?.map((item) => {
            return (
              <Option value={item} key={item}>
                {item}
              </Option>
            );
          })
        }
      </>
    </Select>
  </div>
  )
};

const ExcursionComponent = ({ props }) => {
  const {
    AddExcursionComments,
    excursionComments,
    maxLength
  } = props;
  const excursionCommentsMessage = useMessages("Excursion");
  // ##### 23-10-17 Komarov
  const { TextArea } = Input;
  return (<div key={uniqueId()} className="groupTravelFormComments">
    <span>{excursionCommentsMessage}</span>
    <TextArea
      rows={1}
      maxLength={maxLength}
      style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
      onChange={AddExcursionComments}
      value={excursionComments}
    />
  </div>
  )
};

const FlightTixComponent = ({ props }) => {
  const {
    AddFlightTixComments,
    flightTixComments,
    maxLength
  } = props;
  const flightTixCommentsMessage = useMessages("Flight tix");
  // ##### 23-10-17 Komarov
  const { TextArea } = Input;
  return (<div key={uniqueId()} className="groupTravelFormComments">
    <span>{flightTixCommentsMessage}</span>
    <TextArea
      rows={1}
      maxLength={maxLength}
      style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
      onChange={AddFlightTixComments}
      value={flightTixComments}
    />
  </div>
  )
};

const TrainTixComponent = ({ props }) => {
  const {
    maxLength,
    AddTrainTixComments,
    trainTixComments
  } = props;
  const trainTixCommentsMessage = useMessages("Train tix");
  // ##### 23-10-17 Komarov
  const { TextArea } = Input;
  return (<div key={uniqueId()} className="groupTravelFormComments">
    <span>{trainTixCommentsMessage}</span>
    <TextArea
      rows={1}
      maxLength={maxLength}
      style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
      onChange={AddTrainTixComments}
      value={trainTixComments}
    />
  </div>
  )
};

const ConferenceFacilitiesComponent = ({ props }) => {
  const {
    maxLength,
    AddConferenceFacilitiesComments,
    conferenceFacilitiesComments
  } = props;
  const conferenceFacilitiesCommentsMessage = useMessages("Conference facilities");
  // ##### 23-10-17 Komarov
  const { TextArea } = Input;
  return (<div key={uniqueId()} className="groupTravelFormComments">
    <span>{conferenceFacilitiesCommentsMessage}</span>
    <TextArea
      rows={1}
      maxLength={maxLength}
      style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
      onChange={AddConferenceFacilitiesComments}
      value={conferenceFacilitiesComments}
    />
  </div>
  )
};

const AvEquipmentComponent = ({ props }) => {
  const {
    maxLength,
    AddAvEquipmentComments,
    avEquipmentComments
  } = props;
  const avEquipmentCommentsMessage = useMessages("A/v equipment");
  // ##### 23-10-17 Komarov
  const { TextArea } = Input;
  return (<div key={uniqueId()} className="groupTravelFormComments">
    <span>{avEquipmentCommentsMessage}</span>
    <TextArea
      rows={1}
      maxLength={maxLength}
      style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
      onChange={AddAvEquipmentComments}
      value={avEquipmentComments}
    />
  </div>
  )
};

const VisaSupportComponent = ({ props }) => {
  const {
    maxLength,
    AddVisaSupportComments,
    visaSupportComments
  } = props;
  const visaSupportCommentsMessage = useMessages("Visa Support");
  // ##### 23-10-17 Komarov
  const { TextArea } = Input;
  return (<div key={uniqueId()} className="groupTravelFormComments">
    <span>{visaSupportCommentsMessage}</span>
    <TextArea
      rows={1}
      maxLength={maxLength}
      style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
      onChange={AddVisaSupportComments}
      value={visaSupportComments}
    />
  </div>
  )
};

const BusTransferComponent = ({ props }) => {
  const {
    maxLength,
    AddBusTransferComments,
    busTransferComments
  } = props;
  const busTransferCommentsMessage = useMessages("Bus Transfer");
  // ##### 23-10-17 Komarov
  const { TextArea } = Input;
  return (<div key={uniqueId()} className="groupTravelFormComments">
    <span>{busTransferCommentsMessage}</span>
    <TextArea
      rows={1}
      maxLength={maxLength}
      style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
      onChange={AddBusTransferComments}
      value={busTransferComments}
    />
  </div>
  )
};

const RiverCruiseComponent = ({ props }) => {
  const {
    maxLength,
    AddRiverCruiseComments,
    riverCruiseComments
  } = props;
  const riverCruiseCommentsMessage = useMessages("River Cruise");
  // ##### 23-10-17 Komarov
  const { TextArea } = Input;
  return (<div key={uniqueId()} className="groupTravelFormComments">
    <span>{riverCruiseCommentsMessage}</span>
    <TextArea
      rows={1}
      maxLength={maxLength}
      style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
      onChange={AddRiverCruiseComments}
      value={riverCruiseComments}
    />
  </div>
  )
};

const SeaCruiseComponent = ({ props }) => {
  const {
    maxLength,
    AddSeaCruiseComments,
    seaCruiseComments
  } = props;
  const seaCruiseCommentsMessage = useMessages("Sea Cruise");
  // ##### 23-10-17 Komarov
  const { TextArea } = Input;
  return (<div key={uniqueId()} className="groupTravelFormComments">
    <span>{seaCruiseCommentsMessage}</span>
    <TextArea
      rows={1}
      maxLength={maxLength}
      style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
      onChange={AddSeaCruiseComments}
      value={seaCruiseComments}
    />
  </div>
  )
};

const PackageTourComponent = ({ props }) => {
  const {
    maxLength,
    AddPackageTourComments,
    packageTourComments
  } = props;
  const packageTourCommentsMessage = useMessages("Package Tour");
  // ##### 23-10-17 Komarov
  const { TextArea } = Input;
  return (<div key={uniqueId()} className="groupTravelFormComments">
    <span>{packageTourCommentsMessage}</span>
    <TextArea
      rows={1}
      maxLength={maxLength}
      style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
      onChange={AddPackageTourComments}
      value={packageTourComments}
    />
  </div>
  )
};

const OtherServiceComponent = ({ props }) => {
  const {
    maxLength,
    AddOtherServiceComments,
    otherServiceComments
  } = props;
  const otherServiceCommentsMessage = useMessages("Other Service");
  // ##### 23-10-17 Komarov
  const { TextArea } = Input;
  return (<div key={uniqueId()} className="groupTravelFormComments">
    <span>{otherServiceCommentsMessage}</span>
    <TextArea
      rows={1}
      maxLength={maxLength}
      style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
      onChange={AddOtherServiceComments}
      value={otherServiceComments}
    />
  </div>
  )
};

const CharterComponent = ({ props }) => {
  const {
    maxLength,
    AddCharterComments,
    charterComments
  } = props;
  const charterCommentsMessage = useMessages("Charter");
  // ##### 23-10-17 Komarov
  const { TextArea } = Input;
  return (<div key={uniqueId()} className="groupTravelFormComments">
    <span>{charterCommentsMessage}</span>
    <TextArea
      rows={1}
      maxLength={maxLength}
      style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
      onChange={AddCharterComments}
      value={charterComments}
    />
  </div>
  )
};

// ##### 23-10-14 Komarov
export const ServicesBlock = ({
  selectingAccomTypes,
  accomType,
  accomTypeInitialState,
  SelectSingleRoomsAmount,
  SelectTwinRoomsAmount,
  // ##### 23-10-11 Komarov
  SelectTouristsNumber,
  selectStarRating,
  starRating,
  starRatingInitialState,
  selectVehicleTypes,
  vehicleTypes,
  vehicleTypesInitialState,
  selectMealType,
  mealType,
  mealTypeInitialState,
  chooseMultServices,
  multServices,
  selectGuideTypes,
  guideTypes,
  guideTypesTypesInitialState,
  // ##### 23-10-17 Komarov
  status,
  AddExcursionComments,
  // AddGuideAssistantComments,
  AddFlightTixComments,
  AddTrainTixComments,
  AddConferenceFacilitiesComments,
  AddAvEquipmentComments,
  AddVisaSupportComments,
  AddBusTransferComments,
  AddRiverCruiseComments,
  AddSeaCruiseComments,
  AddPackageTourComments,
  AddOtherServiceComments,
  AddCharterComments,
  excursionComments,
  // guideAssistantComments,
  flightTixComments,
  trainTixComments,
  conferenceFacilitiesComments,
  avEquipmentComments,
  visaSupportComments,
  busTransferComments,
  riverCruiseComments,
  seaCruiseComments,
  packageTourComments,
  otherServiceComments,
  charterComments,
}) => {
  // ##### 23-10-17 Komarov
  const maxLength = 512;

  // ##### 23-10-02 Komarov: вынес вычисление значений для рендеринга (вызовы функций) в код перед рендерингом, иначе возникают ошибки при рендеринге компонентов, скрытых до нажатия кнопки или другого события, по которому соотв. компонент появляется на странице.
  // ##### 24-04-23 Komarov
  const Group_request_services = parse(useMessages("Group_request_services"));
  const SupplierForm_PleaseSelect = useMessages("SupplierForm_PleaseSelect");

  // ##### 23-09-29 Komarov: вычисляем значение переменной для перевода этого message заранее, а не вызывая функцию при рендеринге: такой подход помогает исправить ошибку с сообщением типа "Rendered more hooks than during the previous render" (см. использование этой переменной groupRequestEstablishment ниже в коде)
  // ##### 23-10-11 Komarov
  const group_request_tourists_number = useMessages(
    "Group_request_tourists_number"
  );
  // ##### 23-10-17 Komarov
  // const chooseTypeOfExcursion = useMessages('ChooseTypeOfExcursion');

  // ##### 23-10-17 Komarov
  // const guideAssistantCommentsMessage = useMessages('Guide-assistant');

  // ##### 23-07-20 Komarov

  const { Option } = Select;
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  const servicesBlockComponents = {
    "Accommodation": <AccommodationComponent props={{
      selectingAccomTypes,
      accomType,
      accomTypeInitialState,
      SelectSingleRoomsAmount,
      SelectTwinRoomsAmount,
      selectStarRating,
      starRating,
      starRatingInitialState
    }} />,
    "Transfers": <TransfersComponent props={{
      selectVehicleTypes,
      vehicleTypes,
      vehicleTypesInitialState
    }} />,
    "Meal": <MealComponent props={{
      selectMealType,
      mealType,
      mealTypeInitialState
    }} />,
    "Guide-assistant": <GuideAssistantComponent props={{
      selectGuideTypes,
      guideTypes,
      guideTypesTypesInitialState
    }} />,
    "Excursion": <ExcursionComponent props={{
      AddExcursionComments,
      excursionComments,
      maxLength
    }} />,
    "Flight tix": <FlightTixComponent props={{
      AddFlightTixComments,
      flightTixComments,
      maxLength
    }} />,
    "Train tix": <TrainTixComponent props={{
      maxLength,
      AddTrainTixComments,
      trainTixComments
    }} />,
    "Conference facilities": <ConferenceFacilitiesComponent props={{
      maxLength,
      AddConferenceFacilitiesComments,
      conferenceFacilitiesComments
    }} />,
    "A/v equipment": <AvEquipmentComponent props={{
      maxLength,
      AddAvEquipmentComments,
      avEquipmentComments
    }} />,
    "Visa Support": <VisaSupportComponent props={{
      maxLength,
      AddVisaSupportComments,
      visaSupportComments
    }} />,
    "Bus Transfer": <BusTransferComponent props={{
      maxLength,
      AddBusTransferComments,
      busTransferComments
    }} />,
    "River Cruise": <RiverCruiseComponent props={{
      maxLength,
      AddRiverCruiseComments,
      riverCruiseComments
    }} />,
    "Sea Cruise": <SeaCruiseComponent props={{
      maxLength,
      AddSeaCruiseComments,
      seaCruiseComments
    }} />,
    "Package Tour": <PackageTourComponent props={{
      maxLength,
      AddPackageTourComments,
      packageTourComments
    }} />,
    "Other Service": <OtherServiceComponent props={{
      maxLength,
      AddOtherServiceComments,
      otherServiceComments
    }} />,
    "Charter": <CharterComponent props={{
      maxLength,
      AddCharterComments,
      charterComments
    }} />
  };

  const ServicesBlockComponents = () => Object.keys(multServices).length > 0 &&
    Object.keys(multServices).map(serviceName => servicesBlockComponents[serviceName]) || null;

  return (
    <>
      {/* ##### 23-10-11 Komarov: компонент множественного выбора сервисов - start */}
      <div
        className="groupTravelMultipleServices"
        data-component="ServicesBlock"
      >
        <span>
          {
            // ##### 23-10-04 Komarov
            markReuired(Group_request_services)
          }
        </span>

        <Select
          mode="multiple"
          allowClear
          // ##### 23-10-11 Komarov
          showArrow
          style={{
            width: "100%",
          }}
          // ##### 23-10-04 Komarov
          placeholder={SupplierForm_PleaseSelect}
          onChange={chooseMultServices}
          // ##### 23-10-04 Komarov
          status={status}
        >
          {
            // ##### 24-03-21 Komarov
            Object.keys(TravelServiceTypesObj[locale]).map((travelServiceTypeName) => {
              return (
                // ##### 23-04-12 Komarov
                <Option
                  key={uniqueId()}
                  // key={index}
                  value={travelServiceTypeName}
                >
                  {TravelServiceTypesObj[locale][travelServiceTypeName]}
                </Option>
              );
            })
          }
        </Select>
        {/* ##### 23-10-11 Komarov: компонент множественного выбора сервисов - end */}

        {/* ##### 23-10-11 Komarov: компонент выбора количества туристов - start */}
        <span>{markReuired(group_request_tourists_number)}</span>
        <Space>
          <InputNumber
            min={2}
            max={100000}
            defaultValue={2}
            onChange={SelectTouristsNumber}
          />
        </Space>
        {/* ##### 23-10-11 Komarov: компонент выбора количества туристов - end */}
      </div>
      <ServicesBlockComponents />
    </>
  );
};
