// ##### 24-06-14 Komarov
import React, { useEffect, useMemo, useState } from "react";
// ##### 23-09-04 Komarov
// ##### 24-01-05 Komarov: Импортируем компоненты для отображения календаря
import { useDispatch } from "react-redux";
import { RatesBlock } from "../RatesBlock/RatesBlock";
// ##### 25-01-22 Komarov
import { getPaxInnerSearch } from "../../../../../Redux/actions";
// ##### 24-01-05 Komarov: Библиотека для работы с датами
import moment from "moment";
// ##### 23-11-08 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
// ##### 24-01-05 Komarov: Конфиг с массивом доступных языков и значением языка по умолчанию
import config from "../../../../../Redux/config";
// ##### 24-06-10 Komarov
import { InnerSearchBlockDiv } from "./InnerSearchBlockDiv";

// ##### 24-01-05 Komarov: Устанавливаем локаль в английскую (не знаю, нужно ли и для чего)
moment.locale(config.defaultLang);

// TODO: В этом Компоненте можно отправить запрос на action GetHotelPriceAlphaRQ Smart
export const InnerSearchBlock = ({ search_data }) => {
  const [newList, setNewList] = useState({});
  const [clicked, setClicked] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getPaxInnerSearch(
        search_data.adults,
        search_data.children,
        search_data.rooms,
      ),
    );
    // ##### 23-04-12 Komarov
  }, [dispatch, search_data.adults, search_data.children, search_data.rooms]);

  // ##### 23-12-28 Komarov
  // ##### 24-01-04 Komarov: TODO: мне кажется, что это применение хука useMemo() - "костыль". Без него отправляется запрос в Смарт 5 раз с одними и теми же параметрами. А если этот хук подправить в соответствии с подсказкой редактора (React Hook useMemo has missing dependencies: 'clicked', 'newList', and 'search_data'. Either include them or remove the dependency array. (eslintreact-hooks/exhaustive-deps)), то один и тот же запрос к Смарт повторяется 5 раз, но уже из кода RatesBlock (строка 47 - search_data.start && dispatch (getHotelRates(search_data, userId, sale));)

  // ##### 25-01-22 Komarov
  const ratesBlock = useMemo(() => {
    return clicked === true ? (
      <RatesBlock search_data={newList} />
    ) : (
      <RatesBlock search_data={search_data} />
    );
  }, [clicked, newList, search_data]);

  return (
    <div data-component="InnerSearchBlock">
      {/* ##### 23-11-08 Komarov */}
      <Media lessThan="Tablet-768-1024">
        <InnerSearchBlockDiv
          divClassName="SearchDetailsSmallScreen"
          buttonClassName="availableButtonSmallScreen"
          setNewList={setNewList}
          setClicked={setClicked}
          search_data={search_data}
        />
      </Media>
      <Media greaterThanOrEqual="Tablet-768-1024">
        <InnerSearchBlockDiv
          divClassName="SearchDetails"
          buttonClassName="availableButton"
          setNewList={setNewList}
          setClicked={setClicked}
          search_data={search_data}
        />
      </Media>

      {ratesBlock}
    </div>
  );
};
