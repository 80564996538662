// ##### 25-03-26 Komarov
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// ##### 23-08-02 Komarov
import { Helmet } from "react-helmet-async";
import { ValidateQuery } from "../../Pages/Helpers/helper";
// ##### 25-03-26 Komarov
import { getPurePageSsr } from "../../../Redux/actions";
// ##### 23-09-14 Komarov

export const SeoMetaTitle = () => {
  // ##### 24-08-19 Komarov
  const browserUrl = `${location.pathname}${location.search}`.substring(3);
  const shouldCypherUrlParams = useSelector(
    (state) => !!parseInt(state.siteCypherCodeData.data.value),
  );
  // ##### 25-02-28 Komarov
  const cypherKey = useSelector(
    (state) => state.siteCypherCodeData.data.description,
  );
  const urlToObject = ValidateQuery(location, shouldCypherUrlParams, cypherKey);
  // ##### 24-08-22 Komarov: 209 - главная страница без id в URL
  const page_id_from_url = urlToObject.id ? urlToObject.id : 209;
  const dispatch = useDispatch();
  const purePageSsrLoaded = useSelector(
    (state) => state.pages.purepages_loaded[page_id_from_url],
  );
  const purePageSsrDefaultLoaded = useSelector(
    (state) => state.pages.purepages_loaded[221],
  );
  const deviceType = useSelector((state) => state.deviceType);
  const language = useSelector((state) => state.language);
  useEffect(() => {
    !!!purePageSsrLoaded &&
      dispatch(getPurePageSsr(page_id_from_url, deviceType, language));
  }, [purePageSsrLoaded]);
  useEffect(() => {
    !!!purePageSsrDefaultLoaded &&
      dispatch(getPurePageSsr(221, deviceType, language));
  }, [purePageSsrDefaultLoaded]);
  // ##### 24-20-08 Komarov: TODO: Убрать использование напрямую window.INITIAL_STATE (пока не получилось обойтись без него - в redux на этот момент ещё не успели записаться данные)
  // ##### 25-03-21 Komarov
  const purePageSsr =
    useSelector((state) => state.pages.purepages[page_id_from_url]) ||
    window.INITIAL_STATE.pages.purepages[page_id_from_url];
  // ##### 24-08-22 Komarov
  const purePageSsrDefault =
    useSelector((state) => state.pages.purepages[221]) ||
    window.INITIAL_STATE.pages.purepages[221];
  // ##### 24-08-20 Komarov
  const purepage = useSelector((state) => state.pages.purepage);
  // ##### 24-08-21 Komarov
  const hotelcontent = useSelector((state) => state.hotelcontent.hotelcontent);
  // ##### 24-08-23 Komarov
  const currentPurePage =
    ((browserUrl.includes("hotel_details") ||
      browserUrl.includes("hotel_overview")) &&
      hotelcontent) ||
    ((!urlToObject.id ||
      (browserUrl.includes("sitepages") &&
        ["id=2", "id=210"].includes(`id=${urlToObject.id}`))) &&
      purePageSsr) ||
    (purepage.length > 0 && purepage) ||
    purePageSsrDefault;
  // ##### 24-09-13 Komarov
  const metaTitleObject =
    currentPurePage.length > 0 &&
    currentPurePage.find((item) => item.content_name === "Meta-Title");
  const metaTitleObjectText =
    metaTitleObject?.text ||
    purePageSsrDefault.find((item) => item.content_name === "Meta-Title").text;

  return (
    <Helmet>
      {/* ##### 24-09-13 Komarov */}
      <title>{metaTitleObjectText}</title>
    </Helmet>
  );
};
