// ##### 23-04-07 Komarov
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ValidateQuery } from "../../Helpers/helper";
import {
  getHotelContent,
  getHotelSearch,
  // ##### 24-08-01 Komarov
  // setCurrentUrl,
} from "../../../../Redux/actions";
// ##### 24-06-14 Komarov
import { ContentBlock } from "./ContentBlock/ContentBlock";
// ##### 24-06-14 Komarov
import { InnerSearchBlock } from "./InnerSearchBlock/InnerSearchBlock";
import { getHotelCities } from "../../../../Redux/actions/hotelcities";
import { getPickedCurrencyResponse } from "../../../../Redux/actions/currency";
import { LoadingMessage } from "../../../Library/PageDevices/LoadingMessage";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages";
// ##### 24-04-05 Komarov
import { setItemInfo } from "../../../../Redux/actions";

export const HotelDetails = () => {
  // ##### 24-05-31 Komarov
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // ##### 24-04-23 Komarov
  const Availability = useMessages("Availability");

  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  const location = useLocation();

  // ##### 24-02-22 Komarov
  const search_data = ValidateQuery(location);

  // console.log('[HOTEL_DETAILS_SEARCH]', search_data)

  const dispatch = useDispatch();
  const hotelcontents = useSelector((state) => state.hotelcontent.hotelcontent);
  // ##### 24-06-27 Komarov
  const citiesList = useSelector((state) => state.hotelcities.hotel_cities);

  dispatch(getPickedCurrencyResponse(search_data.selected_currency));

  // ##### 24-04-05 Komarov
  useEffect(() => {
    const selectedItemInfo =
      localStorage.getItem("selectedItemInfo") &&
      localStorage.getItem("selectedItemInfo") !== "undefined" &&
      JSON.parse(localStorage.getItem("selectedItemInfo"));
    dispatch(setItemInfo(selectedItemInfo));
    localStorage.removeItem("selectedItemInfo");
  }, [dispatch]);
  // ##### 24-09-16 Komarov
  const { user: currentUser } = useSelector((state) => state.auth);
  const { user_id } = currentUser;

  // ##### 24-02-22 Komarov
  useEffect(() => {
    // ##### 23-03-06 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/ThirdPageofSearchModule/Hotels/HotelDetails.js:getHotelContent(search_data.hotel_id,locale)"
      );
      // ##### 24-09-16 Komarov
    dispatch(getHotelContent(search_data.hotel_id, locale, user_id));
    // ##### 23-04-12 Komarov
  }, [search_data.hotel_id, locale, dispatch]);

  useEffect(() => {
    // ##### 23-04-12 Komarov
    const rememberLink = (to) => {
      // ##### 24-06-27 Komarov
      localStorage.setItem("urlToBackToRooms", to);
    };
    // ##### 24-02-22 Komarov
    const strToRemove = `/${locale}`;
    rememberLink(
      location.pathname.substring(
        location.pathname.indexOf(strToRemove) + strToRemove.length
      ) + location.search
    );
    // ##### 23-04-12 Komarov
  }, [locale, dispatch, location.pathname, location.search]);

  // ##### 23-04-12 Komarov
  useEffect(() => {
    dispatch(getHotelSearch(search_data));
    // ##### 23-04-12 Komarov
  }, [search_data, dispatch]);

  useEffect(() => {
    // ##### 24-02-22 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/ThirdPageofSearchModule/Hotels/HotelDetails.js:getHotelCities(locale)"
      );
    dispatch(getHotelCities(locale));
    // ##### 23-04-12 Komarov
  }, [locale, dispatch]);

  let filtered_hotel_name;
  let filtered_city_name;
  // ##### 24-02-22 Komarov
  for (const hotel of citiesList) {
    if (parseInt(hotel.hotel_id) === parseInt(search_data.hotel_id)) {
      filtered_hotel_name = hotel.localized_hotel_name;
      // ##### 24-04-05 Komarov
      filtered_city_name = hotel.localized_city_name;
    }
  }

  const hotelstransorig = useSelector((state) => state.hotels.hotelstransorig);

  if (!filtered_hotel_name) {
    filtered_hotel_name =
      hotelstransorig?.[locale]?.[1][search_data.hotel_id]?.name;
  }

  if (!filtered_city_name) {
    filtered_city_name =
      hotelstransorig?.[locale]?.[0][search_data.city_id]?.name;
  }

  if (!hotelcontents) {
    return <LoadingMessage />;
  }

  return (
    <div className="HotelDetailsWrapper" data-component="HotelDetails">
      {/* ##### 24-03-04 Komarov */}
      {(filtered_hotel_name && <h2>{filtered_hotel_name}</h2>) ||
        (filtered_hotel_name && filtered_city_name && (
          <h2>
            {filtered_hotel_name}, {filtered_city_name}
          </h2>
        ))}

      <ContentBlock
        hotelcontents={hotelcontents}
        search_data={search_data}
      />

      <h3
        style={{
          fontFamily: "Arial",
          color: "#102D69",
          fontSize: "24px",
          fontWeight: "bold",
          marginTop: "3vh",
        }}
      >
        {
          // ##### 23-10-04 Komarov
          Availability
        }
      </h3>

      {/* ##### 23-07-03 Komarov: исключил повторные 2 запроса к Смарт на Xmlsubj */}
      {filtered_hotel_name && (
        <InnerSearchBlock
          search_data={search_data}
          hotelName={filtered_hotel_name}
        />
      )}
    </div>
  );
};
