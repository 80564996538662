import React from "react";
// ##### 23-11-01 Komarov
import { Media } from "../../../helpers/MediaConfig";

// ##### 23-10-10 Komarov
const SvgTag = ({ style = null }) => (
  <svg
    data-component="MenuBar"
    // width="60%"
    // width="80%"
    style={style}
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="bars"
    className="svg-inline--fa fa-bars fa-w-14"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    {/* ##### 24-08-16 Komarov */}
    <path fill="currentColor"
    d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" />
  </svg>
);

// ##### 23-10-10 Komarov
export const MenuBar = () => (
  <>
    {/* ##### 23-11-01 Komarov */}
    <Media lessThan="Mobile-L-425-768">
      <SvgTag style={{ width: "80%" }} />
    </Media>
    <Media at="Mobile-L-425-768">
      <SvgTag style={{ height: "10vw" }} />
    </Media>
    <Media at="Tablet-768-1024">
      <SvgTag style={{ height: "7.7vw" }} />
    </Media>
    <Media greaterThanOrEqual="Laptop-1024-1440">
      <SvgTag style={{ width: "80%" }} />
    </Media>
  </>
);
