// ##### 24-06-14 Komarov
import parse from "html-react-parser";
import uniqueId from "lodash.uniqueid";
import React, { useContext } from "react";
import { ItemContentContext } from "./ItemContent";

// @@@@@ 23-03-03 Komarov
export const UlTag = ({ liSummaryWidth, liImageWidth }) => {
  const { result } = useContext(ItemContentContext);
  return (
    <ul className="Ul_ItemContent" data-component="UlTag">
      <>
        {result ? (
          result.map((trip) => {
            if (trip.content_name === "Summary") {
              return (
                // ##### 23-04-12 Komarov
                <li
                  key={uniqueId()}
                  className="Li_ItemContent"
                  style={{
                    liSummaryWidth,
                  }}
                >
                  {parse(trip.text)}
                </li>
              );
            }

            // ##### 24-03-21 Komarov
            if (trip.content_name === "Image") {
              return (
                // ##### 23-04-12 Komarov
                <li
                  key={uniqueId()}
                  style={{
                    listStyleType: "none",
                    textAlign: "left",
                    // ##### 23-02-22 Komarov
                    liImageWidth,
                  }}
                >
                  <img
                    // ##### 24-03-18 Komarov: TODO: Подобрать более удачное значение (может, типа {trip.name}). Взять перевод значения атрибута alt из Смарта
                    alt="Trip"
                    style={{
                      width: "100%",
                      borderRadius: "5px",
                      height: "20vw",
                    }}
                    src={
                      (process.env.REACT_APP_PROTOCOL || "https://") +
                      trip.text[2]
                    }
                  />
                </li>
              );
            }
          })
        ) : (
          // <div>{tour.name}{tour.duration}</div>
          <div>Content is not available</div>
        )}
      </>
    </ul>
  );
};
