// ##### 23-12-27 Komarov
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// ##### 23-09-21 Komarov
import { useLocation } from "react-router-dom";
// import { ValidateQuery } from "./Components/Pages/Helpers/helper";
// ##### 23-05-10 Komarov
import { HelmetProvider } from "react-helmet-async";
// ##### 24-06-12 Komarov
import { TopMenu } from "./Components/Pages/PageComponents/ResponsiveHeader/Header/Header";
// ##### 24-02-21 Komarov
import { HeaderWhiteLabel } from "./Components/Pages/PageComponents/ResponsiveHeader/HeaderWhiteLabel";
import {
  Footer /* ,MenuLinks */,
} from "./Components/Pages/RoutesAndLinks/Footer";
// ##### 24-08-01 Komarov
import { setLanguage, setCurrentUrl } from "./Redux/actions";
import { changeLang, getLangResponse } from "./Redux/actions/locale";
// ##### 24-07-25 Komarov
// import { setPromoCode } from "./Redux/actions/promocode";

export const LocalizationWrapper = (props) => {
  // ##### 23-09-21 Komarov
  // let path;
  const location = useLocation();

  // ##### 24-07-24 Komarov
  const browserLang = location.pathname.substring(1, 3);
  useEffect(() => {
    dispatch(setLanguage(browserLang));
  }, [browserLang]);

  // ##### 24-08-01 Komarov
  const browserUrl = `${location.pathname}${location.search}`.substring(3);
  useEffect(() => {
    dispatch(setCurrentUrl(browserUrl));
  }, [browserUrl]);

  // ##### 24-08-05 Komarov
  // const company_id = useSelector(state => state.auth.user.company_id);

  // TODO: Исправить код в соответствии с задачей убрать Промокод из URL
  /* if (location.search) {
    path = ValidateQuery(location);
  } else path = { refpartner: "", default: true }; */

  const dispatch = useDispatch();

  // ##### 23-09-21 Komarov: Если значение промокода есть в URL, на который перешёл пользователь, а значение кода при этом пустое, то устанавливаем промокод в state из URL
  // ##### 24-07-25 Komarov
  // TODO: Происходит смешивание понятий refpartner и promoCode, - нужно что-то с этим сделать
  /* useEffect(() => {
    path.refpartner && dispatch(setPromoCode(path.refpartner));
  }, [dispatch, path.refpartner]); */

  // ##### 23-11-09 Komarov: Прокручиваем окно вверх (на начало) при переходе на любую страницу
  const currentUrl = useSelector((state) => state.currentUrl);
  useEffect(() => {
    // ##### 23-11-09 Komarov
    // Если пользователь уточняет даты поиска во вторичной форме поиска, или пользователь находится в Галлерее и открывает или закрывает блок с фотографиями, то не прокручиваем страницу наверх
    if (
      // ##### 24-03-21 Komarov
      (typeof currentUrl === "string" &&
        currentUrl.includes("start") &&
        currentUrl.includes("hotel_details")) ||
      // ##### 24-03-21 Komarov
      typeof currentUrl === "object"
    )
      return;
    window.scrollTo(0, 0);
  }, [currentUrl]);

  // ##### 24-03-21 Komarov
  // const promoCode = useSelector((state) => state.promocode.promocode);
  // ##### 23-05-10 Komarov
  const helmetContext = {};
  const { locale } = props.match.params;
  // ##### 24-02-28 Komarov
  const isWhiteLabelSet = useSelector((state) => state.whitelabel.set);

  useEffect(() => {
    dispatch(changeLang(locale));
    // ##### 23-04-12 Komarov
  }, [locale, dispatch]);

  useEffect(() => {
    dispatch(getLangResponse());
    // ##### 23-04-12 Komarov
  }, [dispatch]);

  localStorage.setItem("currentLanguage", locale);

  // ##### 24-02-28 Komarov
  return (
    <HelmetProvider context={helmetContext}>
      {(isWhiteLabelSet && <HeaderWhiteLabel />) || <TopMenu />}
      <Footer />
    </HelmetProvider>
  );
};
