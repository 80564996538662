// ##### 25-02-05 Komarov
import React, { useContext } from "react";
import { PlaceHolderStrings } from "../../../Library/Localization/placeholders";
import Autocomplete from "react-autocomplete";
import uniqueId from "lodash.uniqueid";
import { ToursAutocompleteSmallScreenContext } from "./ToursAutocompleteSmallScreen";

export const AutocompleteDiv = ({ height }) => {
  const {
    props,
    geo,
    setOpen,
    open,
    tourValue,
    optionChecker,
    setTourValue,
    // ##### 23-12-12 Komarov
    setPickedTourValue,
  } = useContext(ToursAutocompleteSmallScreenContext);
  // ##### 24-05-10 Komarov
  const placeholder = PlaceHolderStrings();

  const autoCompleteMenuTop = "38px";

  return (
    <Autocomplete
      {...props}
      inputProps={{
        style: {
          // width: `${width*0.8}px`,
          // ##### 23-02-28 Komarov: TODO: проверить правильность width при работе с SSR для страницы Топ-Туров (это нужно включить на сервере для проверки)
          // width: '80%',
          width: "60vw",
          //  height: '37px',
          height: height,
          // ##### 23-03-29 Komarov
          // fontFamily: 'Tahoma',
          fontFamily: "Arial",
          border: "#e59700",
          borderStyle: "solid",
          borderRadius: "0.4rem",
          // fontSize: '16px',
          // border: '1px solid lightgrey',
          textAlign: "center",
          // marginTop: '1vh',
          // marginRight: 'auto',
          // marginLeft: 'auto',
          // marginBottom: '1vh'
          // ##### 23-12-11 Komarov
          left: "0",
          top: autoCompleteMenuTop,
        },

        placeholder: placeholder.placeHolderTour,
        // @@@@@ 23-03-08 Komarov
        id: "cityOrTourNameInput",
      }}
      menuStyle={{
        // width: `${width*0.8}px`,
        // ##### 23-02-28 Komarov: TODO: проверить правильность width при работе с SSR для страницы Топ-Туров (это нужно включить на сервере для проверки)
        // width: '80%',
        // marginRight: 'auto',
        // marginLeft: 'auto',
        // fontFamily: 'Tahoma',
        // fontSize: '16px',
        // background: '#d9e6f1',
        // borderRadius: '5px',
        // border: '2px solid #BCD7EE',
        // color: 'darkslategrey',
        // zIndex: '100',
        // overflow: 'auto'
        width: "60vw",
        marginRight: "auto",
        marginLeft: "auto",
        fontFamily: "Arial Narrow",
        fontWeight: "bold",
        fontSize: "18px",
        color: "darkslategrey",
        borderRadius: "3px",
        boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
        background: "rgba(255, 255, 255)",
        padding: "3px",
        position: "absolute",
        overflow: "scroll",
        // maxHeight: '30vh',
        maxHeight: "358px",
        // maxHeight: '652px',
        zIndex: "2001",
        border: "2px solid grey",
        // left: '50px',
        // top: '865px',
        // ##### 22-11-07 Komarov
        // left: '-4vw',
        left: "0",
        top: autoCompleteMenuTop,
      }}
      items={geo}
      shouldItemRender={(item, value) =>
        value !== ""
          ? item.name.toLowerCase().includes(value.toLowerCase())
          : null
      }
      getItemValue={(item) => item.name}
      open={open}
      // ##### 24-03-21 Komarov
      onMenuVisibilityChange={() => setOpen(false)}
      renderItem={(item, highlighted) => (
        <div
          // ##### 24-04-05 Komarov
          key={uniqueId()}
          style={{ backgroundColor: highlighted ? "lightblue" : "transparent" }}
        >
          {item.name}
        </div>
      )}
      value={tourValue}
      onChange={optionChecker}
      // ##### 23-12-18 Komarov
      onSelect={(value) =>
        setTourValue(value) + setOpen(false) + setPickedTourValue(true)
      }
    />
  );
};
