import axios from "axios";

/* My new server */
// const API_URL = "http://authentication-jwt/api/";
// const API_URL = "http://content-server/interface/remote/";
// const API_URL = "http://smartbooker.biz/interface/remote/";
const API_URL = `${process.env.REACT_APP_SMART_URL}interface/remote/`;

// ##### 23-10-27 Komarov
const register = (
  firstName,
  lastName,
  username,
  email,
  password,
  promocode,
  locale
) => {
  // ##### 24-03-21 Komarov
  return axios.post(
    `${API_URL}create_user`,
    JSON.stringify({
      firstname: firstName,
      lastname: lastName,
      username: username,
      email: email,
      password: password,
      promocode: promocode,
      locale: locale,
    })
  );
};

// ##### 24-03-21 Komarov
const login = (username, password) => {
  return axios
    .post(
      `${API_URL}login`,
      JSON.stringify({
        username: username,
        password: password,
      })
    )
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  register,
  login,
  logout,
};

export default authService;
