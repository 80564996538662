// ##### 25-04-03 Komarov
import React, { useEffect, useState } from "react";
import axios from "axios";

// ##### 25-03-06 Komarov: Фунция для запроса к Серверу Сайта сохранения данных в файл. Передаёт Серверу данные, необходимые для того, чтобы Сервер Сайта отправил соотв. запрос Смарту (повторил со своей стороны запрос Клиента к Смарту и сохранил данные ответа). Служит для того, чтобы при запросе данных у Смарта ещё и сохранить ответ в файл, как резервную копию для чтения из неё в случае ошибки ответа от Смарта. На стороне Сервера Сайта если requestType === 'POST', то url для запроса к Смарту берётся из postObject.url, а если requestType === 'GET', - то - из smartUrl
export const saveData = async ({
  smartUrl,
  actionType,
  requestType,
  postObject,
}) => {
  const url = `${process.env.REACT_APP_URL}/saveData`;
  await axios.post(
    url,
    {
      smartUrl,
      actionType,
      requestType,
      postObject,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

// ##### 25-03-06 Komarov: Фунция для запроса на чтение данных из файла в случае ошибки ответа от Смарта. Функция посылает запрос Серверу Сайта на чтение данных из соотв. файла, который, возможно, сохранён раньше. Параметр func - ссылка на функцию, которая диспатчит action с типом, означающим запись данных успешного ответа в Redux (например, тип action GET_TOPHOTELS_RES).
export const readData = async ({
  smartUrl,
  actionType,
  postObject,
  dispatch,
}) => {
  const url = `${process.env.REACT_APP_URL}/readData`;
  await axios
    .post(
      url,
      {
        smartUrl,
        actionType,
        postObject,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
    .then(async (res) => {
      // ##### 25-03-28 Komarov
      dispatch({
        type: actionType,
        payload: await res.data.data,
      });
    });
};

// ##### 25-03-28 Komarov
export const getSavingDataClient = async ({
  smartUrl,
  typeRes,
  typeErr,
  dispatch,
  processDataFunction, // ##### 25-03-28 Komarov: функция обработки полученного ответа от Смарта (не обязательная, если нужно), создаётся в соотв. action (если нужно записать в Redux не просто ответ, а объект с дополнительными полями, в одном из которых будет ответ от Смарта)
  isSpecialCondition, // ##### 25-03-28 Komarov: функция проверки ополнительного условия (не обязательная, если нужно), создаётся в соотв. action
}) => {
  // ##### 25-03-10 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
  await saveData({
    smartUrl,
    actionType: typeRes,
    requestType: "GET",
  });

  await axios
    .get(smartUrl)
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      if ((await res.data) === "Are you lost?") {
        throw new Error("Smart's response: Are you lost?");
      }
      // ##### 25-03-28 Komarov: если передана функция проверки некоторого дополнительного условия и оно не выполняется, то не произодим записи в Redux, - не выполняем дальше dispatch
      if (
        typeof isSpecialCondition === "function" &&
        !isSpecialCondition(await res.data)
      ) {
        // ##### 25-03-28 Komarov: если спец. условие не выполнилось, возвращаем false, чтобы в следующем методе then обработать его и в таком случае выйти, ничего не делая.
        return false;
      }
      let data;
      if (processDataFunction) {
        // ##### 25-03-31 Komarov
        data = await processDataFunction(await res.data);
      } else {
        data = await res.data;
      }
      dispatch({
        type: typeRes,
        // ##### 25-03-31 Komarov
        payload: await data,
      });
    })
    .catch(async (err) => {
      // ##### 25-03-10 Komarov: в случае ошибки ответа от Смарта запрашиваем данные у Сервера Сайта
      await readData({
        smartUrl,
        actionType: typeRes,
        dispatch,
      });
      dispatch({ type: typeErr, error: err });
    });
};

// ##### 25-03-28 Komarov
export const postSavingDataClient = async ({
  postObject,
  typeRes,
  typeErr,
  dispatch,
  processDataFunction, // ##### 25-03-28 Komarov: функция обработки полученного ответа от Смарта (не обязательная, если нужно), создаётся в соотв. action (если нужно записать в Redux не просто ответ, а объект с дополнительными полями, в одном из которых будет ответ от Смарта)
  isSpecialCondition, // ##### 25-03-28 Komarov: функция проверки ополнительного условия (не обязательная, если нужно), создаётся в соотв. action
}) => {
  // ##### 25-03-10 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
  await saveData({
    actionType: typeRes,
    requestType: "POST",
    postObject,
  });

  // ##### 25-03-26 Komarov
  await axios
    .post(postObject.url, postObject.parameters)
    // ##### 24-10-25 Komarov
    .then(async (res) => {
      if ((await res.data) === "Are you lost?") {
        throw new Error("Smart's response: Are you lost?");
      }
      // ##### 25-03-28 Komarov: если передана функция проверки некоторого дополнительного условия и оно не выполняется, то не произодим записи в Redux, - не выполняем дальше dispatch
      if (
        typeof isSpecialCondition === "function" &&
        !isSpecialCondition(await res.data)
      ) {
        // ##### 25-03-28 Komarov: если спец. условие не выполнилось, возвращаем false, чтобы в следующем методе then обработать его и в таком случае выйти, ничего не делая.
        return false;
      }
      let data;
      if (processDataFunction) {
        data = processDataFunction(await res.data);
      } else {
        data = await res.data;
      }
      dispatch({
        type: typeRes,
        payload: data,
      });
    })
    .catch(async (err) => {
      // ##### 25-03-10 Komarov: в случае ошибки ответа от Смарта запрашиваем данные у Сервера Сайта
      await readData({
        smartUrl: postObject.url,
        actionType: typeRes,
        dispatch,
      });
      dispatch({
        type: typeErr,
        error: err,
      });
    });
};

// ##### 25-04-03 Komarov: механизм загузки и хранения картинок в БД браузера
function openDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("imageCacheDB", 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore("images", { keyPath: "url" });
    };

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject("Ошибка при открытии базы данных");
  });
}

async function saveImage(url, blob) {
  const db = await openDB();
  const transaction = db.transaction("images", "readwrite");
  const store = transaction.objectStore("images");

  store.put({ url, blob, timestamp: Date.now() });

  return new Promise((resolve) => {
    transaction.oncomplete = () => resolve();
  });
}

async function shouldUpdateImage(url, maxAge = 86400000) {
  // 24 часа
  const db = await openDB();
  const transaction = db.transaction("images", "readonly");
  const store = transaction.objectStore("images");

  return new Promise((resolve) => {
    const request = store.get(url);
    request.onsuccess = () => {
      const data = request.result;
      if (!data || Date.now() - data.timestamp > maxAge) {
        resolve(true); // Нужно обновить
      } else {
        resolve(false); // Можно использовать кеш
      }
    };
  });
}

async function fetchAndCacheImage(url) {
  const response = await fetch(url);
  const blob = await response.blob();
  await saveImage(url, blob);
  return blob;
}

async function getCachedImage(url) {
  const db = await openDB();
  const transaction = db.transaction("images", "readonly");
  const store = transaction.objectStore("images");

  return new Promise((resolve) => {
    const request = store.get(url);
    request.onsuccess = () => resolve(request.result?.blob || null);
  });
}

export function CachedImage({
  src,
  shownImgRef,
  style = { maxWidth: "100%" },
  alt = "Cached",
  className,
  onClick,
  onKeyUp,
  onLoad,
  id
}) {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    // ##### 25-04-03 Komarov: флаг для определения того, новая ли сессия пользователя в браузере. Session Storage автоматически очищается при перезагрузке вкладки браузера и при закрытии вкладки или браузера
    const isNewSession = !!!sessionStorage.getItem('session');
    if (isNewSession) {
      sessionStorage.setItem('session', 'true');
    }
    let isMounted = true; // Флаг монтирования компонента
    const loadImage = async () => {
      let blob;
      if (isNewSession) {
        blob = await fetchAndCacheImage(src);
      } else if (await shouldUpdateImage(src)) {
        blob = await fetchAndCacheImage(src);
      } else {
        blob = await getCachedImage(src);
      }
      /*const updateNeeded = await shouldUpdateImage(src);
      let blob = updateNeeded || isNewSession
        ? await fetchAndCacheImage(src)
        : await getCachedImage(src);*/

      if (isMounted) {
        setImageSrc(URL.createObjectURL(blob));
      }
      return () => {
        isMounted = false; // Компонент размонтирован, больше не обновляем state
        URL.revokeObjectURL(src); // Освобождаем память
      };
    };

    loadImage();
  }, [src]);

  return imageSrc ? (
    <img
      src={src}
      ref={shownImgRef}
      alt={alt}
      style={style}
      className={className || null}
      onClick={onClick || null}
      onKeyUp={onKeyUp || null}
      onLoad={onLoad || null}
      id={id || null}
    />
  ) : (
    <></>
  );
}
