// ##### 25-02-05 Komarov
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { useLocation } from "react-router-dom";
import { LocalizationNavLink } from "../../../Library/Localization/LocalizationNavLink";
import { CurrencySelectionTool } from "../CurrencySelectionTool/CurrencySelectionTool";
import { LangSelectionTool } from "../../../Library/Localization/LangSelectionTool/LangSelectionTool";
import { HeaderWhiteLabelContext } from "./HeaderWhiteLabel";

export const HeaderClassDiv = ({ headerClass }) => {
  // ##### 24-03-01 Komarov
  const { backGroundImg, lang } = useContext(HeaderWhiteLabelContext);
  // ##### 24-03-07 Komarov
  const company_cipher = useSelector(
    (state) => state.whitelabel.company_cipher,
  );
  // ##### 24-03-21 Komarov
  const imgTag = useSelector((state) => state.whitelabel.data[lang].ImageSvg);
  const img = parse(imgTag);
  // ##### 24-08-05 Komarov
  // const whiteLabelLogo = img.firstChild.getAttribute("src");
  const whiteLabelLogo = img.props.src;
  const location = useLocation();
  // ##### 25-01-15 Komarov
  const deviceType = useSelector((state) => state.deviceType);
  // ##### 24-03-02 Komarov
  return (
    <div
      data-component="HeaderClassDiv"
      className={headerClass}
      style={{
        order: "0",
      }}
    >
      <div
        style={{
          display: "flex",
          // ##### 24-02-28 Komarov
          // flexDirection: 'row-reverse',
          // justifyContent: 'space-evenly',
          justifyContent: "space-around",
          // maxWidth: '80%'
        }}
      >
        {/* ##### 24-03-07 Komarov */}
        <LocalizationNavLink exact to={`/ref${company_cipher}`}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "75%",
              marginTop: "2vh",
            }}
          >
            {/* ##### 24-03-21 Komarov */}
            {/* ##### 24-03-18 Komarov: TODO: Взять перевод значения атрибута alt из Смарта */}
            <img src={whiteLabelLogo} alt="White Label Logo" />
          </div>
        </LocalizationNavLink>
        <div className="LangCurrency">
          {/* TODO: Сделать html-разметку select-list'а с соотв. опциями (пунктами) */}
          {/* ##### 25-02-21 Komarov */}
          <CurrencySelectionTool language={lang} deviceType={deviceType} />
          {/* TODO: Сделать html-разметку select-list'а с соотв. опциями (пунктами) */}
          <LangSelectionTool
            language={lang}
            location={location}
            deviceType={deviceType}
          />
        </div>
      </div>
      {/* ##### 24-03-02 Komarov */}
      {/* ##### 24-03-18 Komarov: TODO: Взять перевод значения атрибута alt из Смарта */}
      <img
        alt="White Label Background"
        src={backGroundImg}
        style={{
          width: "100%",
        }}
      />
    </div>
  );
};
