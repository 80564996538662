// ##### 24-09-09 Komarov
import React, { useState, useContext, createContext, useMemo } from "react";
// ##### 23-11-01 Komarov
import { Media } from "../../../../server/helpers/MediaConfig";

// ##### 23-10-10 Komarov
const SvgTag = ({ style = null }) => {
  const { color, removeDestination } = useContext(MenuCloseContext);

  // ##### 24-08-16 Komarov
  const [crossColor, setCrossColor] = useState(color ? color : "");

  const onMouseEnter = () => {
    if (color) {
      setCrossColor("#5C0606");
    }
  };

  const onMouseLeave = () => {
    if (color) {
      setCrossColor("#E89090");
    }
  };

  return (
    <svg
      data-component="MenuClose"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={removeDestination}
      onKeyDown={removeDestination}
      // ##### 23-09-05 Komarov
      // width={`${!size ? '60%' : size}`}
      style={style}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      cursor="pointer"
      color={crossColor}
      data-icon="times-circle"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path fill="currentColor"
        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" />
    </svg>
  );
};
// ##### 23-10-10 Komarov
const MenuCloseContext = createContext();

// ##### 24-09-09 Komarov
export const MenuClose = ({ size, color, removeDestination }) => {

  const menuCloseContextValue = useMemo(() => ({
    color,
    removeDestination,
  }), [
    // ##### 24-09-11 Komarov
    color,
    removeDestination,
  ]); // value is cached by useMemo

  return (
    <MenuCloseContext.Provider
      value={menuCloseContextValue}
    >
      {/* ##### 23-11-01 Komarov */}
      <Media lessThan="Mobile-L-425-768">
        <SvgTag style={{ width: size || "80%" }} />
      </Media>
      <Media at="Mobile-L-425-768">
        <SvgTag style={{ width: size || "10vw" }} />
      </Media>
      <Media at="Tablet-768-1024">
        <SvgTag style={{ width: size || "7.7vw" }} />
      </Media>
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <SvgTag style={{ width: size || "80%" }} />
      </Media>
    </MenuCloseContext.Provider>
  )
};
