// ##### 24-03-21 Komarov
import React, { useEffect } from "react";
// ##### 24-02-28 Komarov
import { useSelector } from "react-redux";
import axios from "axios";

export const BookingRQ = () => {
  // ##### 24-03-21 Komarov
  //  const [bookingRQ, setBookingRQ] = useState()
  // ##### 24-02-28 Komarov
  const { user: currentUser } = useSelector((state) => state.auth);

  // ##### 24-03-01 Komarov: TODO: Проверить использование этого файла: тут в объекте фиксированные данные
  // ##### 24-03-21 Komarov
  useEffect(() => {
    const ActionRQ = {
      // GetBookingsRQ
      username: process.env.REACT_APP_SMART_USERNAME,
      password: process.env.REACT_APP_SMART_PASSWORD,
      // ##### 24-02-28 Komarov
      // "user_id": 1426, // Id of client - booking_entity.user_id
      user_id: currentUser.user_id,
      // "refpartner": 1361, // If "= 1361" - it is Id of "hotelsukraine.com.ua" else
      // If "!= 0" - it is Id of partner; and it is in both cases "bo.company_id".
      // Otherwise, if "= 0", then "bo.company_id" is company of user, defined by "user_id" (by default - 1361)
      language: "EN", // current site language
      action: "GetBookingsRQ",
      data: {
        selection_period_start: "2022-01-25", // selection period start date, by default - today
        selection_period_end: "2022-02-10", // selection period end date, by default - "selection_period_start"
        getting_from: "cart", // "cart" - services from cart, "bookings" - booked services (by default), "all" - both services from cart and booked
        getting_clients: "no", // "yes" - with list of clients, "no" - without list of clients (by default)
        // buyer must be extracted in any case but only when "getting_clients": "yes"
        all_users: "no", // "yes" - services for all "user_id" of the "refpartner", "no" - services of the "user_id" only (by default)
        all_refpartners: "no", // "yes" - services for all "refpartner" of the "user_id", "no" - services of the "refpartner" only	(by default)
        all_services: "no", // "yes" - all services of Smart, "no" - services from Site only (by default)
      },
    };
    axios.post(
      `${process.env.REACT_APP_SMART_URL}interface/xmlsubj/`,
      JSON.stringify({ ActionRQ })
    );
    // ##### 24-03-21 Komarov
    /* .then(response => {
                setBookingRQ(response.data)
            }) */
  }, [currentUser.user_id]);

  return (
    <div data-component="BookingRQ">
      {/* ##### 23-10-06 Komarov: TODO: Может (, если этот текст нужен пользователю сайта,) взять перевод из Смарта? */}
      {/* BookingResponse */}
    </div>
  );
};
