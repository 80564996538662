import React from "react";

// ##### 24-08-16 Komarov
export const IconHint = ({
  hintText,
  x = undefined,
  y = undefined,
  isHintShown = false,
}) => (
  <div
    data-component="IconHint"
    style={{
      display: isHintShown ? "inline" : "none",
      position: "absolute",
      borderRadius: "3px",
      padding: "3px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#8eaace",
      backgroundColor: "#e9f2ff",
      color: "#212529",
      fontSize: "12px",
      fontWeight: "normal",
      top: y,
      left: x,
    }}
  >
    {hintText}
  </div>
);
