// ##### 25-03-05 Komarov
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// ##### 23-02-17 Komarov
import moment from "moment";
import { ValidateQuery } from "../../../Helpers/helper";
import { useWindowWidthAndHeight } from "../../../Helpers/WindowResizeHook";
// ##### 25-02-12 Komarov
import {
  // ##### 25-02-03 Komarov
  getHotelContent2,
  getHotelCities,
  getPickedCurrencyResponse,
  getHotelsAvail,
} from "../../../../../Redux/actions";

import { Search } from "../../../FirstPageofSearchModule/SearchResizersAndSwitchers/SearchFront";
// ##### 23-03-29 Komarov
import { LoadingMessage } from "../../../../Library/PageDevices/LoadingMessage";
// ##### 23-09-14 Komarov
// import './HotelItemsCSS.css'
import config from "../../../../../Redux/config";
// ##### 23-11-02 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
// ##### 27-01-25 Komarov
import { HotelItemsList } from "./HotelItemsList";
// ##### 25-02-05 Komarov
import { SearchResultsHeader } from "./SearchResultsHeader";
import { PropertiesFoundHeader } from "./PropertiesFoundHeader";

moment.locale(config.defaultLang);

export const HotelItems = () => {
  // ##### 24-06-26 Komarov
  useEffect(() => {
    localStorage.getItem("clientData") && localStorage.removeItem("clientData");
  }, []);
  // ##### 24-03-21 Komarov
  // const RotaryMessage = useMessages("RotaryMessage");

  // console.log('HOTEL_TITLE', title)

  ///получаю с помощью своиства истории (history) из компонента search результаты поиска - массив с одним объектом.
  // ##### 24-03-21 Komarov
  const location = useLocation();

  // ##### 23-03-29 Komarov
  const [width /* height */] = useWindowWidthAndHeight();

  // ##### 24-12-27 Komarov
  const shouldCypherUrlParams = useSelector(
    (state) => !!parseInt(state.siteCypherCodeData.data.value),
  );
  // ##### 25-02-28 Komarov
  const cypherKey = useSelector(
    (state) => state.siteCypherCodeData.data.description,
  );
  const search_data = ValidateQuery(location, shouldCypherUrlParams, cypherKey);
  // console.log('HOTEL ITEM LOCATION', location)

  const dispatch = useDispatch();

  // ##### 24-08-05 Komarov
  // TODO: Исправить код в соответствии с задачей убрать Промокод из URL
  const { selected_currency /* , refpartner */ } = search_data;
  useEffect(() => {
    dispatch(getPickedCurrencyResponse(selected_currency));
  }, [dispatch, selected_currency /* , refpartner */]);

  // ##### 23-02-17 Komarov
  const [smartResultFound, setSmartResultFound] = useState("");

  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  useEffect(() => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/SecondPageofSearchModule/Hotels/HotelItems.js:getHotelCities(locale)",
      );
    dispatch(getHotelCities(locale));
    // ##### 23-04-12 Komarov
  }, [dispatch, locale]);

  // console.log('[GENERAL_HOTELS] , ' , generalHotelItems)///получаю из смарта имя отеля, айди отеля, сити имя, сити айди

  ///используется непосредственно для вывода названий отелей на странице.
  ///если айди города, который мне приходит первоначально от Саши Ефица (классификатор contracts) не находится в данных, пришедших в результате поиска клиентом,
  ///то в переменную filtered возвращается тур айди из классификатора contracts, равное айди, пришедшему от клиентского поиска
  ///иначе возвращается сити айди из классификатора contracts, равное айди из поиска

  // console.log('[DATE DIFFERENCE]',date_difference)
  // ##### 23-11-07 Komarov
  const sale =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).sale;

  // console.log('[TODAY MONTH]' , currentMonth, '[TODAY DATE]', today, '[TEST_DATE] : ' , search_data.date + '-01')
  const { user: currentUser } = useSelector((state) => state.auth);
  // ##### 24-03-01 Komarov
  const company_cipher = useSelector(
    (state) => state.whitelabel.company_cipher,
  );

  // ##### 25-02-20 Komarov
  const available_now = search_data.hotel_available;

  // ##### 24-05-23 Komarov
  const deviceType = useSelector((state) => state.deviceType);

  // console.log('USER',currentUser.user_id,search_data.refpartner)
  // ##### 24-08-05 Komarov
  const company_id = useSelector((state) => state.auth.user.company_id);
  const promocode = useSelector((state) => state.promocode.promocode);

  // ##### 25-02-12 Komarov
  useEffect(() => {
    dispatch(
      getHotelContent2({
        hotel_id: 0,
        city_id: search_data.city_id,
        language: locale,
        user_id: currentUser.user_id,
        refpartner: company_id,
        sale,
        promocode,
        deviceType,
        company_cipher,
        content_size: 0,
      }),
    );
  }, [
    currentUser.user_id,
    search_data.refpartner,
    search_data.city_id,
    // ##### 25-02-25 Komarov
    search_data.hotel_id,
    sale,
    company_cipher,
  ]);

  useEffect(() => {
    dispatch(
      getHotelsAvail({
        sale_id: (sale && sale) || null,
        promocode,
        deviceType,
        company_cipher: company_cipher || "",
        start: search_data.start, // date of arrival
        end: search_data.end, // date of departure
        city_id: search_data.city_id,
        // ##### 25-02-25 Komarov
        hotel_id: search_data.hotel_id || 0,
        adults: search_data.adults,
        children: search_data.children,
        // ##### 25-03-11 Komarov
        // lower_cost_limit: 0.0, // lower cost limit of room in USD, NOT OBLIGATORY
        // upper_cost_limit: 200.0, // upper cost limit of room in USD, NOT OBLIGATORY
        quantity: search_data.rooms, // Quantity of rooms, 1 by default, NOT OBLIGATORY
        user_id: currentUser.user_id,
        refpartner: company_id,
        language: locale,
      }),
    );
  }, [
    currentUser.user_id,
    search_data.refpartner,
    search_data.start,
    search_data.end,
    search_data.city_id,
    // ##### 25-02-25 Komarov
    search_data.hotel_id,
    search_data.rooms,
    search_data.adults,
    search_data.children,
    sale,
    company_cipher,
  ]);

  const hotelcontent_loaded2 = useSelector(
    (state) => state.hotelcontent.hotelcontent_loaded2,
  );
  const hotels_avail_loaded = useSelector(
    (state) => state.hotels.hotels_avail_loaded,
  );

  // ##### 25-02-03 Komarov
  const showLoader = !hotelcontent_loaded2 || !hotels_avail_loaded;

  return (
    <>
      <div data-component="HotelItems">
        <Search searchProps={search_data} />
      </div>
      <div className="searchrendering_Wrapper">
        <SearchResultsHeader />

        <div
          // @@@@@ 23-01-30 Komarov: Подвинул выше блок с сообщением о загрузке, чтобы он не накладывался на Footer
          // ##### 27-01-25 Komarov
          style={showLoader ? { height: "250px" } : null}
        >
          {/* ##### 24-06-06 Komarov */}
          {showLoader ? (
            <div>
              {/* ##### 23-02-17 Komarov */}
              <LoadingMessage
                loadingMessageClass="RateLoading"
                smartResultFound={smartResultFound}
              />
            </div>
          ) : (
            <>
              <PropertiesFoundHeader />

              {/* ##### 27-01-25 Komarov */}
              <Media greaterThanOrEqual="Laptop-1024-1440">
                <HotelItemsList
                  ulCLassName="HotelDescriptionUl"
                  width={width * 0.65}
                  liClassName="HotelDescriptionLi"
                />
              </Media>
              {/* ##### 23-04-20 Komarov */}
              <Media lessThan="Laptop-1024-1440">
                <HotelItemsList
                  ulCLassName="HotelDescriptionUlSmallScreen"
                  width={width * 0.9}
                  liClassName="HotelDescriptionLiSmallScreen"
                />
              </Media>
            </>
          )}
        </div>
      </div>
    </>
  );
};
