// ##### 24-03-21 Komarov
export const logger = () => (next) => (action) => {
  // console.log('[LOGGER]', action );
  return next(action);
};
// ##### 24-03-21 Komarov
export function loggerFn() {
  return (next) => (action) => {
    // console.log('[LOGGER]', action );
    return next(action);
  };
}

export const loggerMiddleware = (store) => (next) => (action) => {
  // console.log("Dispatching Action:", action);
  return next(action);
};
