// ##### 25-02-05 Komarov
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { PlaceHolderStrings } from "../../../Library/Localization/placeholders";
import { ConfigProvider, DatePicker, Space } from "antd";
import { ToursAutocompleteSmallScreenContext } from "./ToursAutocompleteSmallScreen";
// ##### 25-03-11 Komarov
import { dateLocales } from "../../../../helpers/locales";

export const SpaceTag = ({ transform }) => {
  const {
    onChange,
    disabledDate,
    // ##### 23-11-21 Komarov
    datepickerClass,
    isTourMonthDivOpened,
  } = useContext(ToursAutocompleteSmallScreenContext);
  const locale = useSelector((state) => state.language);
  const placeholder = PlaceHolderStrings();
  return (
    <Space
      id="monthInput"
      direction="vertical"
      style={{
        transform: transform,
        border: "solid rgb(229, 151, 0)",
        borderRadius: "0.4rem",
        display: "inline-flex",
        // ##### 23-11-21 Komarov
        flexDirection: "row",
        marginTop: "0.5em",
        marginBottom: "20em",
        width: "68vw",
      }}
    >
      {/* ##### 23-12-18 Komarov */}
      <ConfigProvider locale={dateLocales[locale]}>
        <DatePicker
          size={"large"}
          // ##### 23-11-21 Komarov: TODO: добавить такое свойство и во вторичную панель поиска
          inputReadOnly // ##### 23-11-21 Komarov: свойство inputReadOnly предотвращает появление клавиатуры смартфона или планшета при касании поля выбора даты
          open={true}
          onChange={onChange}
          picker="month"
          disabledDate={disabledDate}
          //  format={dateFormat}
          placeholder={placeholder.placeHolderMonth}
          // ##### 23-11-21 Komarov
          // bordered={true}
          bordered={false}
          // className={datepickerClass}
          popupClassName={datepickerClass}
          /* style={{
          // width: `${width*0.8}px`,
          // ##### 23-02-28 Komarov: TODO: проверить правильность width при работе с SSR для страницы Топ-Туров (это нужно включить на сервере для проверки)
          width: '80%',
          marginRight: 'auto',
          marginLeft: 'auto'
        }} */
          // ##### 23-11-21 Komarov
          popupStyle={{
            zIndex: "2001",
            display: (!isTourMonthDivOpened && "none") || "unset",
            textAlign: "center", // Align the calendar in the center horizontally
            marginTop: "10px", // Add some space between the input and the calendar
            // ##### 23-08-04 Komarov
            transform,
          }}
        />
      </ConfigProvider>
    </Space>
  );
};
