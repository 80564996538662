import { FETCH_USERS } from "../constants";

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_USERS:
      // console.log('src/Redux/reducers/usersReducer.js: ', action.payload.data);
      return action.payload.data;
    default:
      return state;
  }
};
