// ##### 25-02-05 Komarov
import { useMessages } from "../../../../../../../helpers/messages";
import { Input } from "antd";
import uniqueId from "lodash.uniqueid";
import React from "react";

export const ExcursionComponent = ({ props }) => {
  const { AddExcursionComments, excursionComments, maxLength } = props;
  const excursionCommentsMessage = useMessages("Excursion");
  // ##### 23-10-17 Komarov
  const { TextArea } = Input;
  return (
    <div key={uniqueId()} className="form-block-lf">
      <span>{excursionCommentsMessage}</span>
      <TextArea
        rows={1}
        maxLength={maxLength}
        style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
        onChange={AddExcursionComments}
        value={excursionComments}
      />
    </div>
  );
};
