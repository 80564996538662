// ##### 24-09-09 Komarov
import React, { useState, useContext, createContext, useMemo/*, useEffect*/ } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "antd";
// ##### 24-07-25 Komarov
import { setPromoCode } from "../../../../Redux/actions/promocode";
// ##### 23-09-21 Komarov
import { useHistory, useLocation } from "react-router-dom";
import { ValidateQuery } from "../../Helpers/helper";
// ##### 24-11-08 Komarov
import { getCompany } from "../../../../Redux/actions";
// ##### 24-08-05 Komarov
// import { setWhitelabel } from "../../../../Redux/actions";
// ##### 23-09-14 Komarov
// import './PromoCodeCSS.css'
// import 'antd/dist/antd.css';

// ##### 23-10-10 Komarov
const PromoCodeValue = ({ fontSize }) => {
  const { code } = useContext(PromoCodeContext);
  return (
    <h4
      data-component="PromoCodeValue"
      style={{
        color: "rgb(136, 6, 6)",
        fontSize,
        fontWeight: "bold",
        marginTop: "auto",
        marginBottom: "auto",
      }}
    >
      Promo Code
    </h4>
  );
};

const PromoCodeContext = createContext();

// ##### 23-09-23 Komarov
export const PromoCode = () => {
  let path;
  const location = useLocation();
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  if (location.search) {
    path = ValidateQuery(location);
  } else path = { refpartner: "", default: true };

  // ##### 24-08-05 Komarov
  // const codeFromState = useSelector((state) => state.promocode.promocode);
  // TODO: Исправить код с учтётом различия понятий refpartner и promoCode
  // const codeFromPath = (path.refpartner && path.refpartner) || "";

  // ##### 23-09-21 Komarov: Устанавливаем значение промокода для отображения в соотв. поле из state, а если там его нет, то из URL
  // ##### 24-11-08 Komarov
  const usedCode = useSelector(state => state.promocode.promocode );
  const [code, setCode] = useState(usedCode);
  const [divClicked, setDivClicked] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  // ##### 23-09-21 Komarov
  const history = useHistory();

  const dispatch = useDispatch();

  // ##### 23-10-17 Komarov
  const maxPromoCodeLength = 12;

  // ##### 23-09-23 Komarov
  const SetInput = (e) => {
    // ##### 23-10-17 Komarov
    if (e.target.value.length <= maxPromoCodeLength) {
      setCode(e.target.value);
    }
  };

  // ##### 24-11-08 Komarov
  const { user: currentUser } = useSelector((state) => state.auth);
  const userId = currentUser.user_id;
  // ##### 24-07-25 Komarov
  const saveCode = () => {
    // ##### 24-08-05 Komarov
    // dispatch(setWhitelabel({promocode: code}));
    dispatch(setPromoCode(code));
    // ##### 24-11-08 Komarov
    dispatch(getCompany(code, userId));
    // ##### 23-09-21 Komarov: Если пользователь очистил поле с промокодом, перенаправляем его на главную страницу на текущем языке
    // ##### 24-03-21 Komarov
    code === "" && history.push(`/${locale}`);
  };

  // ##### 24-09-11 Komarov
  const promoCodeContextValue = useMemo(() => ({ code }), [code]); // value is cached by useMemo

  // ##### 23-10-10 Komarov
  return (
    <PromoCodeContext.Provider value={promoCodeContextValue}>
      <div
        data-component="PromoCode"
        // ##### 24-03-21 Komarov
        // tabIndex={200}
        onBlur={() =>
          inputFocused &&
          code === "" &&
          setDivClicked(false) &&
          setInputFocused(false)
        }
        style={{
          margin: "0",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <div
          onClick={() => {
            setDivClicked(true);
            setTimeout(() => {
              document.getElementById("inputTag").focus();
              setInputFocused(true);
            }, 0);
          }}
          // ##### 24-03-21 Komarov
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              setDivClicked(true);
              setTimeout(() => {
                document.getElementById("inputTag").focus();
                setInputFocused(true);
              }, 0);
            }
          }}
        // tabIndex={21}
        >
          {(!divClicked && <PromoCodeValue fontSize="14px" />) || (
            <Input
              id="inputTag"
              // ##### 24-08-05 Komarov
              type="password"
              bordered={false}
              allowClear
              onChange={SetInput}
              // ##### 24-03-21 Komarov
              onBlur={() => {
                if (code === "") {
                  setDivClicked(false);
                  setInputFocused(false);
                  setTimeout(() => {
                    saveCode();
                  }, 0);
                }
              }}
              placeholder="Partner ID/Promo Code"
              value={code}
              size="small"
            />
          )}
        </div>
        {/* ##### 24-03-21 Komarov */}
        <button
          type="button"
          style={{
            display: (inputFocused && code !== "" && "unset") || "none",
            position: "absolute",
          }}
          onClick={() => {
            setDivClicked(false);
            setInputFocused(false);
            setTimeout(() => {
              saveCode();
            }, 0);
          }}
          // ##### 24-03-21 Komarov
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              setDivClicked(false);
              setInputFocused(false);
              setTimeout(() => {
                saveCode();
              }, 0);
            }
          }}
        >
          OK
        </button>
      </div>
    </PromoCodeContext.Provider>
  );
};
