// ##### 24-06-12 Komarov
import React, { useContext } from "react";
import parse from "html-react-parser";
import { HomeIcon } from "../../../../Library/Icons/homeicon";
import { LargeScreensNavBar } from "../LargeScreensNavBar";
import { LocalizationNavLink } from "../../../../Library/Localization/LocalizationNavLink";
import { Operations } from "../../../../Library/Icons/operations";
import { LogInLinkTag } from "../../../../Library/Common/LogInLinkTag";
import uniqueId from "lodash.uniqueid";
import { useMessages } from "../../../../../helpers/messages";
import { Media } from "../../../../../helpers/MediaConfig";
import { TopMenuContext } from "./Header";
// ##### 23-04-24 Komarov
export const TopMenuRightDivPartForDesktop = () => {
  // ##### 24-06-20 Komarov
  const { /* sitePageType, sitePageRegion, */ sitePagesObj, Home_icon, Operations_icon } =
    useContext(TopMenuContext);
  // ##### 24-06-12 Komarov
  return (
    <div
      className="topMenu_right"
      data-component="TopMenuRightDivPartForDesktop"
    >
      {/* ##### 23-11-01 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        {/* ##### 24-06-20 Komarov */}
        {sitePagesObj.Header.UPPER && <LargeScreensNavBar
          sitepage_type={sitePagesObj.Header.UPPER}
          linkClassName={"Upper"}
          key={() => uniqueId()}
        />}
      </Media>
      {/* ##### 23-11-01 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* ##### 23-04-05 Komarov: элемент Home */}
          {/* ##### 24-06-20 Komarov */}
          <LocalizationNavLink key={uniqueId()} exact to={""}>
            <HomeIcon color={"white"} hintText={parse(Home_icon)} />
          </LocalizationNavLink>

          {/* ##### 23-04-05 Komarov: элемент Operations */}
          {
            // ##### 24-04-23 Komarov
            Object.values(useMessages()).length > 0 ? (
              <LocalizationNavLink key={uniqueId()} exact to={"/mybooking"}>
                <Operations
                  // ##### 24-06-20 Komarov
                  key={uniqueId()}
                  color={"white"}
                  // ##### 23-10-04 Komarov
                  hintText={parse(Operations_icon)}
                />
                {/* ##### 24-03-21 Komarov */}
              </LocalizationNavLink>
            ) : (
              <LocalizationNavLink exact to={"/mybooking"}>
                <Operations
                  // ##### 24-06-20 Komarov
                  color={"white"}
                  hintText={"Operations"}
                />
              </LocalizationNavLink>
            )
          }

          {/* ##### 23-04-05 Komarov: элемент Login */}
          <LogInLinkTag className="" right="9rem" />
        </div>
      </Media>
    </div>
  );
};
