// ##### 23-02-21 Komarov: htmlCleaner - функция-middleware для обработки объектов action.payload перед их попаданием в state
import parse from "html-react-parser";

// ##### 24-03-21 Komarov
export const htmlCleaner = () => (next) => (action) => {
  // ##### 23-02-21 Komarov: Убираем спец-символы в Html в названиях гостиниц, который приходит из Смарта
  if (action.type === "GET_HOTELSTRANS_RES") {
    // ##### 24-03-21 Komarov
    const processedData = [];
    for (const element of action.payload) {
      processedData.push({
        city: element.city,
        id: element.id,
        name: parse(element.name),
      });
    }
    action.payload = processedData;
  }
  next(action);
};
