// ##### 23-03-29 Komarov
import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ScrollToTopButton } from "./Components/Library/PageDevices/ScrollToTopButton/ScrollToTopButton";
// ##### 23-06-19 Komarov
import { PaymentServiceProvider } from "./Components/Context/PaymentServiceContext";
// ##### 25-03-05 Komarov
import { LocalizationWrapper } from "./LocalizationWrapper";
import config from "./Redux/config";
import { history } from "./Redux/helpers/history";
import PaymentService from "./Redux/services/payment.service";
// ##### 23-12-28 Komarov
import { loggedIn } from "./Redux/actions/auth";
// ##### 24-07-25 Komarov
import { useDispatch, useSelector } from "react-redux";
// ##### 24-08-05 Komarov
import moment from "moment";
import { setPromoCode } from "./Redux/actions/promocode";

const paymentService = new PaymentService();

const supportedLangs = config.supportedLangs.join("|");
// ##### 24-03-21 Komarov
process.env.NODE_ENV === "development" &&
  console.log("src/App.js:window.INITIAL_STATE", window.INITIAL_STATE);

// ##### 25-03-05 Komarov
function App() {
  useEffect(() => {
    // ##### 25-02-26 Komarov: Выводим в клиентскую консоль выводы серверной консоли
    const data = window.INITIAL_STATE.data;
    if (
      process.env.NODE_ENV === "development" &&
      Object.keys(data).length > 0
    ) {
      console.log("src/App.js: console.logs from server", data);
    }

    // ##### 25-03-04 Komarov
    const errorsCities = window.INITIAL_STATE.cities.errors;
    if (process.env.NODE_ENV === "development" && errorsCities.length > 0) {
      console.error("src/App.js: errors in cities", errorsCities);
    }

    // ##### 25-03-05 Komarov
    const errorsTopHotels = window.INITIAL_STATE.tophotels.errors;
    if (process.env.NODE_ENV === "development" && errorsTopHotels.length > 0) {
      console.error("src/App.js: errors in tophotels", errorsTopHotels);
    }

    // ##### 25-03-05 Komarov
    const errorSiteCypherCodeData =
      window.INITIAL_STATE.siteCypherCodeData?.error;
    if (process.env.NODE_ENV === "development" && errorSiteCypherCodeData) {
      console.error(
        "src/App.js: error in siteCypherCodeData",
        errorSiteCypherCodeData,
      );
    }
  }, []);

  // ##### 24-08-05 Komarov
  function getCurrentTime() {
    return moment();
  }

  function isTimeDifferenceLessThanMinute(oldTime) {
    // Calculate the difference in milliseconds
    const diffInMilliseconds = moment().diff(oldTime);

    // Convert milliseconds to minutes
    const diffInMinutes = diffInMilliseconds / (1000 * 60);

    return Math.abs(diffInMinutes) < 1;
  }

  const dispatch = useDispatch();

  // ##### 23-12-28 Komarov: Если пользователь был в системе, то атворизуем его
  const user =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    if (!user || user === "undefined") {
      return;
    }
    dispatch(loggedIn(user));
    // ##### 24-03-21 Komarov
  }, [dispatch, user]);

  // ##### 24-08-05 Komarov
  const promocode = useSelector((state) => state.promocode.promocode);

  // ##### 24-08-03 Komarov: Функционал для сохранения Промокода в Redux при работе приложения, когда оно переключается на новый URL и Redux "сбрасывается" к начальному состоянию
  useEffect(() => {
    // Actions to perform on app startup
    const savedPromocode =
      localStorage.getItem("promocode") &&
      JSON.parse(localStorage.getItem("promocode"));
    if (
      savedPromocode &&
      isTimeDifferenceLessThanMinute(savedPromocode.timePromocodeSet)
    ) {
      dispatch(setPromoCode(savedPromocode.promocode));
    }
    savedPromocode && localStorage.removeItem("promocode");

    // ##### 24-08-03 Komarov: Перед закрытием приложения или переключением его на новый URL сохраняем значение Промокода в localStorage
    const savePromocode = () => {
      localStorage.setItem(
        "promocode",
        JSON.stringify({
          timePromocodeSet: getCurrentTime(),
          promocode,
        }),
      );
    };

    // Handle beforeunload event
    window.addEventListener(
      "beforeunload",
      // Actions to perform before app closure
      savePromocode,
    );

    return () => {
      // Cleanup function
      window.removeEventListener("beforeunload", savePromocode);
    };
  }, [dispatch, promocode]);

  // ##### 31-05-24 Komarov
  return (
    <>
      <BrowserRouter history={history}>
        <PaymentServiceProvider value={paymentService}>
          <Switch>
            <Route
              path={`/:locale(${supportedLangs})`}
              component={LocalizationWrapper}
            />
            <Redirect to={config.defaultLang} />
          </Switch>
          {/* ##### 23-07-27 Komarov */}
          {/* <ScrollToTop /> */}
        </PaymentServiceProvider>
      </BrowserRouter>
      <ScrollToTopButton />
    </>
  );
}

export default App;
