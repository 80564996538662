// ##### 24-11-29 Komarov
import React, { useRef/*, useState*/ } from "react";
import uniqueId from "lodash.uniqueid";
// ##### 23-11-08 Komarov
import { Media } from "../../../../helpers/MediaConfig";
// ##### 24-11-29 Komarov
import AsynchronousImage from './AsynchronousImage';

// ##### 23-11-08 Komarov
// import './CartGalleryCSS.css'

const CartGalleryDiv = ({
  height,
  flexDirection,
  width1,
  width2,
  photos,
  smallImageHeight,
}) => {
  // ##### 24-11-29 Komarov
  const shownImgRef = useRef({src: undefined});
  const imgPreviews = useRef();
  let currentPreviewIndex = undefined;

  /*const [picked, setPicked] = useState(
    `${(process.env.REACT_APP_PROTOCOL || "https://") + photos.text[0]}`
  );*/
  // const [activeIndex, setActiveIndex] = useState();

  const pickAction = (index) => (e) => {
    // ##### 24-11-29 Komarov
    // setPicked(e.target.getAttribute("src"));
    // setActiveIndex(index);
    shownImgRef.current.src = e.target.getAttribute("src");
    const imagePreviews = imgPreviews.current.children;
    console.log('currentPreviewIndex', currentPreviewIndex);
    console.log('index', index);
    if (currentPreviewIndex || currentPreviewIndex === 0) {
      const prevChosenImage = imagePreviews[currentPreviewIndex].firstChild.firstChild;
      prevChosenImage.classList.remove("CartPickedImage");
      prevChosenImage.classList.add("CartNotPickedImage");
    }
    currentPreviewIndex = index;
    const chosenImage = imagePreviews[index].firstChild.firstChild;
    chosenImage.classList.remove("CartNotPickedImage");
    chosenImage.classList.add("CartPickedImage");
  };

  // ##### 23-11-08 Komarov
  return (
    <div
      data-component="CartGalleryDiv"
      style={{
        width: "96%",
        height: height,
        display: "flex",
        flexDirection: flexDirection,
      }}
    >
      <>
        {/* ##### 24-11-29 Komarov */}
        {/*<div
          style={{
            width: width1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={picked}
            // ##### 24-03-18 Komarov: TODO: Взять значение атрибута alt из Смарта
            alt="Picked gallery element"
            style={{
              padding: "5px",
              marginLeft: "auto",
              marginRight: "auto",
              // ##### 22-09-05 Komarov: сделал использование width, а не maxHeight
              width: "100%",
              // maxHeight:'100%',
              // height:'65vh',
              // maxHeight:'65vh',
              objectFit: "cover",
              //   height:`${photoHeight}`,
              borderRadius: "0.7vw",
              // overflow: "auto",
            }}
          />
        </div>*/}
        {/*##### 24-11-29 Komarov*/}
        <ImageShown
          width1={width1}
          shownImgRef={shownImgRef}
          firstPhotoUrl={(process.env.REACT_APP_PROTOCOL || "https://") + photos.text[0]}
        />

        <div
          ref={imgPreviews}
          style={{
            // ##### 24-06-26 Komarov
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'flex-start',
            //  flexDirection:'row',
            //  justifyContent:'space-evenly'
            // display: "grid",
            // gridTemplateColumns: "repeat(4,25%)",
            // rowGap: "0",
            // ##### 22-09-05 Komarov
            width: width2,
            // overflow: "scroll",
            overflowY: 'auto',
            //  justifyContent:'space-evenly'
          }}
        >
          {photos.text.map((image, index /* , array */) => {
            // ##### 24-11-29 Komarov
            /*return (
              <div key={uniqueId()} className="galleryPage">
                <img
                  src={(process.env.REACT_APP_PROTOCOL || "https://") + image}
                  // ##### 24-03-18 Komarov: TODO: Взять значение атрибута alt из Смарта
                  alt="Gallery element"
                  className={
                    activeIndex === index
                      ? "CartPickedImage"
                      : "CartNotPickedImage"
                  }
                  style={{
                    height: `${smallImageHeight ? smallImageHeight : null}`,
                  }}
                  onClick={pickAction(index)}
                  // ##### 24-03-21 Komarov
                  onKeyUp={(e) => {
                    if (e.key === "Enter") pickAction(index);
                  }} // Example of adding keyboard accessibility
                />
              </div>
            );*/
            return (
              <div key={uniqueId()} className="galleryPage">
                <AsynchronousImage
                  src={(process.env.REACT_APP_PROTOCOL || "https://") + image}
                  alt="Gallery element"
                  style={{
                    height: `${smallImageHeight ? smallImageHeight : null}`,
                  }}
                  onClick={pickAction(index)}
                  // ##### 24-03-21 Komarov
                  onKeyUp={(e) => {
                    if (e.key === "Enter") pickAction(index);
                  }} // Example of adding keyboard accessibility
                />
              </div>
            );
          })}
        </div>
      </>
    </div>
  );
};

// ##### 24-11-29 Komarov
const ImageShown = ({width1, shownImgRef, firstPhotoUrl}) => {
  return (
    <div
      style={{
        width: width1,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <img
        ref={shownImgRef}
        src={shownImgRef.current.src || firstPhotoUrl}
        // ##### 24-03-18 Komarov: TODO: Взять значение атрибута alt из Смарта
        alt="Picked gallery element"
        style={{
          padding: "5px",
          marginLeft: "auto",
          marginRight: "auto",
          // ##### 22-09-05 Komarov: сделал использование width, а не maxHeight
          width: "100%",
          // maxHeight:'100%',
          // height:'65vh',
          // maxHeight:'65vh',
          objectFit: "cover",
          //   height:`${photoHeight}`,
          borderRadius: "0.7vw",
          // overflow: "auto",
        }}
      />
    </div>
  )
}

// ##### 23-10-10 Komarov
export const CartGallery = ({ photos, smallImageHeight }) => (
  <>
    {/* ##### 23-11-08 Komarov */}
    <Media lessThan="Laptop-1024-1440">
      <CartGalleryDiv
        height="unset"
        flexDirection="column"
        width1="100%"
        width2="100%"
        photos={photos}
        smallImageHeight={smallImageHeight}
      />
    </Media>
    <Media greaterThanOrEqual="Laptop-1024-1440">
      <CartGalleryDiv
        height="55vh"
        flexDirection="row"
        width1="60%"
        width2="40%"
        photos={photos}
        smallImageHeight={smallImageHeight}
      />
    </Media>
  </>
);
