import React from "react";
import { useSelector, useDispatch } from "react-redux";
import uniqueId from "lodash.uniqueid";
import parse from "html-react-parser";
import { LocalizationNavLink } from "../Localization/LocalizationNavLink";
import { logout } from "../../../Redux/actions/auth";
import { SignIn } from "../Icons/signIn";
import { SignOut } from "../Icons/signOut";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../helpers/messages";

// ##### 23-11-01 Komarov
export const LogInLinkTag = ({ onSmallScreen }) => {
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(logout());
  };
  // ##### 23-11-07 Komarov
  // const pageName = 'sign_in';
  // const page = messages()[pageName];
  // ##### 24-04-23 Komarov
  const signIn = useMessages().sign_in;
  const signOut = useMessages("sign_out");

  const { user: currentUser } = useSelector((state) => state.auth);

  return (
    <div
      id="id_logInDivTag"
      style={{
        padding: "1vw",
      }}
      key={uniqueId()}
    >
      {parseInt(currentUser.user_id) !== 1426 ? (
        <>
          <LocalizationNavLink
            exact
            to={""}
            activeClassName="active"
            onClick={logOut}
          >
            {/* ##### 23-04-01 Komarov TODO: Прислать сюда перевод из Смарта */}
            <SignOut
              key={uniqueId()}
              color={(!onSmallScreen && "white") || "#102D69"}
              // ##### 23-11-07 Komarov
              hintText={parse(signOut)}
              onSmallScreen={onSmallScreen}
            />
          </LocalizationNavLink>
        </>
      ) : (
        <LocalizationNavLink
          // exact to={`/sign_in`}
          // ##### 23-11-09 Komarov
          to={`/profile/?name=${signIn.name}&id=${signIn.id}`}
          activeClassName="activeMenuLinks"
        >
          {/* ##### 22-08-29 Komarov: убрал вывод спецсимволов HTML в пунктах меню в мобильном устройстве: отправил текст пунктов в функцию parse */}
          {/* ##### 23-04-01 Komarov */}
          <SignIn
            key={uniqueId()}
            color={(!onSmallScreen && "white") || "#102D69"}
            // ##### 23-11-07 Komarov
            hintText={parse(signIn.title)}
            onSmallScreen={onSmallScreen}
          />
        </LocalizationNavLink>
      )}
    </div>
  );
};
