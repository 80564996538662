// ##### 24-09-09 Komarov
import React, { useState, createContext, useMemo } from "react";
// ##### 24-04-05 Komarov
import { useDispatch } from "react-redux";
import { getPax } from "../../../../Redux/actions/paxchoice.js";
// ##### 24-04-23 Komarov
// ##### 23-11-08 Komarov
import { Media } from "../../../../helpers/MediaConfig.js";
import { HotelPaxChoiceWrapper } from "./HotelPaxChoiceWrapper.js";
export const HotelsPaxChoiceContext = createContext();

// ##### 23-11-15 Komarov
export const HotelsPaxChoice = ({
  rooms,
  adults,
  children,
  isSecondSearch,
}) => {
  const [counterAdults, setCounterAdults] = useState(parseInt(adults));
  const [counterChild, setCounterChild] = useState(parseInt(children));
  // const [counterInfant, setCounterInfant] = useState(0)
  const [counterRooms, setCounterRooms] = useState(parseInt(rooms));
  // ##### 23-12-27 Komarov
  const [paxListOpen, setPaxListOpen] = useState(false);
  const toggleContextPaxListOpen = (value) => setPaxListOpen(value);

  const dispatch = useDispatch();

  const TotalPax = () => {
    dispatch(getPax(counterAdults, counterChild, counterRooms));
  };

  // ##### 24-09-09 Komarov
  const hotelsPaxChoiceContextValue = useMemo(() => ({
    counterAdults,
    setCounterAdults,
    counterChild,
    setCounterChild,
    counterRooms,
    setCounterRooms,
    toggleContextPaxListOpen,
    paxListOpen,
    TotalPax,
  }), [
    // ##### 24-09-11 Komarov
    counterAdults,
    setCounterAdults,
    counterChild,
    setCounterChild,
    counterRooms,
    setCounterRooms,
    toggleContextPaxListOpen,
    paxListOpen,
    TotalPax,
  ]); // value is cached by useMemo

  return (
    <HotelsPaxChoiceContext.Provider
      value={hotelsPaxChoiceContextValue}
    >
      {/* ##### 23-11-08 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <HotelPaxChoiceWrapper />
      </Media>
      {/* ##### 23-11-15 Komarov */}
      <Media at="Tablet-768-1024">
        <HotelPaxChoiceWrapper
          // width='100%'
          width="35vw"
          isSecondSearch={isSecondSearch}
        />
      </Media>
      <Media lessThan="Tablet-768-1024">
        <HotelPaxChoiceWrapper
          // ##### 23-11-15 Komarov
          // width='100%'
          width="78vw"
          isSecondSearch={isSecondSearch}
        />
      </Media>
    </HotelsPaxChoiceContext.Provider>
  );
};
