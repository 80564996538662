// ##### 24-06-19 Komarov
import React, { useEffect, useMemo } from "react";
// ##### 23-03-29 Komarov
import { useLocation } from "react-router-dom";
// ##### 24-07-22 Komarov
import parse from "html-react-parser";
import { connect, useDispatch, useSelector } from "react-redux";
// ##### 25-03-26 Komarov
import uniqueId from "lodash.uniqueid";
// ##### 25-02-05 Komarov
import { getCitiesOverview, getHotelContent, getHotelsOverview, getPurePage } from "../../../../Redux/actions";
import Login from "../../../Library/Authorization/Login.js";
import { AboutUs } from "../../../Library/PageDevices/Animation/AboutUs.js";
import { CartGallery } from "../../../Library/PageDevices/CartGallery/CartGallery.js";
import Slider from "../../../Library/Slider/Slider.js";
import { ValidateQuery } from "../../Helpers/helper.js";
import { YearBuilder } from "../../../Library/Images/YearBuilder.js";
import { GroupTravelForm } from "../../../Library/PageDevices/Forms/GroupTravel/GroupTravelForm/GroupTravelForm.js";
import { PartnershipForm } from "../../../Library/PageDevices/Forms/PartnershipForm/PartnershipForm.js";
import { SupplierPartnerForm } from "../../../Library/PageDevices/Forms/SupplierPartnerForm.js";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages.js";
// ##### 24-02-21 Komarov
import ssrPages from "../../../../../server/helpers/ssrPages.js";
// ##### 24-06-17 Komarov
import { ShowGallery } from "./ShowGallery.js";
// ##### 24-07-31 Komarov
import moment from "moment";
import dateFormats from "../../../../helpers/dateFormats.js";
import { Media } from "../../../../helpers/MediaConfig.js";
// ##### 25-02-05 Komarov
import { AllHotelsList } from "./AllHotelsList";
import { AllCitiesList } from "./AllCitiesList";
// ##### 23-09-14 Komarov
// import '../PageComponents/ResponsiveHeader/header.css'
// import './MenuPageGeneratorCSS.css'

// ##### 24-07-22 Komarov
function mapStateToProps(state) {
  return {
    cities_overview_all: state.cities.cities_overview_all,
    // ##### 24-10-28 Komarov
    // cities_overview_all_data: state.cities.cities_overview_all[0].data.object_list,
    hotels_overview_all: state.hotels.hotels_overview_all,
    // hotels_overview_all_data: state.hotels.hotels_overview_all[0].data.object_list,
    locale: state.language,
    othersName: state.othersName,
    otherCitiesName: state.cities.cities_overview_top[0].data.langname,
    otherHotelsName: state.hotels.hotels_overview_top[0].data.langname,
  };
}

// ##### 24-10-28 Komarov
export const MenuPageGenerator = connect(mapStateToProps)(({
  cities_overview_all,
  hotels_overview_all,
  locale,
  otherCitiesName,
  otherHotelsName /* , cities_overview_all_data, hotels_overview_all_data */,
}) => {
  // ##### 24-06-27 Komarov
  const location = useLocation();
  // ##### 24-04-23 Komarov
  const messagesFromRedux = useMessages();

  const shouldCypherUrlParams = useSelector(
    (state) => !!parseInt(state.siteCypherCodeData.data.value),
  );
  // ##### 25-02-28 Komarov
  const cypherKey = useSelector(
    (state) => state.siteCypherCodeData.data.description,
  );
  const search_data = useMemo(
    () => ValidateQuery(location, shouldCypherUrlParams, cypherKey),
    [location, shouldCypherUrlParams],
  );

  // ##### 24-03-21 Komarov
  const src =
    "https://hotels-ua.biz/images_symlink/desktop/sitepage/210/Bucha_video.mp4";
  // ##### 23-09-14 Komarov
  // console.log('SRC', src)

  // console.log('LOCATION',search_data.id)
  // ##### 23-12-27 Komarov
  const lang = locale;

  // ##### 23-07-14 Komarov
  const purePageFromState = useSelector((state) => state.pages.purepage);
  // ##### 25-03-21 Komarov
  const purepages = useSelector((state) => state.pages.purepages);
  // ##### 25-03-31 Komarov
  const preloadedpages_loaded = useSelector((state) => state.pages.preloadedpages_loaded);
  // ##### 25-04-01 Komarov
  const preloadedpages = useSelector((state) => state.pages.preloadedpages);
  const preloadedcitiespages_loaded = useSelector((state) => state.pages.preloadedcitiespages_loaded);
  const preloadedcitiespages = useSelector((state) => state.pages.preloadedcitiespages);
  // ##### 24-08-23 Komarov
  const hotelcontent = useSelector((state) => state.hotelcontent.hotelcontent);

  // ##### 23-07-14 Komarov
  let purePage;
  // ##### 25-03-21 Komarov
  if (location.pathname.includes("sitepages")) {
    // ##### 25-03-28 Komarov: TODO: почему-то при переключении на Галлерею в объект pages.purepages[2] попадает контент Галлереи, и остаётся там при переключении на страницу О нас. Нужно проверить и исправить это.
    /*if (purepages[search_data.id]) {
      console.log('purepages[search_data.id]', purepages[search_data.id]);
      purePage = purepages[search_data.id];
    } else if (purePageFromState.length > 0) {
      purePage = purePageFromState;
    }*/
    // ##### 25-03-31 Komarov
    // purePage = purePageFromState;
    if (preloadedpages_loaded[search_data.id]) {
      // ##### 25-04-01 Komarov
      purePage = preloadedpages[search_data.id].data;
    } else if (purePageFromState.length > 0) {
      purePage = purePageFromState;
    }
    // ##### 24-08-23 Komarov
  } else if (location.pathname.includes("hotel_overview")) {
    purePage = hotelcontent;
  //   ##### 25-04-01 Komarov
  } else if (location.pathname.includes("city_overview")) {
    if (preloadedcitiespages_loaded[search_data.id]) {
      purePage = preloadedcitiespages[search_data.id].data;
    } else if (purePageFromState.length > 0) {
      purePage = purePageFromState;
    }
  } else {
    // ##### 24-05-21 Komarov
    purePage = purePageFromState;
  }

  const deviceType = useSelector((state) => state.deviceType);
  const dispatch = useDispatch();

  // ##### 24-10-28 Komarov
  const url = `${location.pathname}${location.search}`;
  let browserUrl = url.substring(3);
  useEffect(() => {
    browserUrl = url.substring(3);
  }, [lang, url]);

  // ##### 24-08-07 Komarov
  const isItOtherCitiesPage =
    browserUrl === ssrPages["Other Cities"] || search_data.id == 191;
  const isItOtherHotelsPage =
    browserUrl === ssrPages["Other Hotels"] || search_data.id == 365;
  // ##### 25-01-14 Komarov
  const company_id = useSelector((state) => state.auth.user.company_id);

  // ##### 25-01-14 Komarov
  useEffect(
    () => {
      if (isItOtherCitiesPage) {
        const params = {
          user_id: currentUser.user_id,
          // ##### 24-08-05 Komarov
          refpartner: company_id,
          language: locale,
          primary_objects_list: 0,
          section_name: "CITIES Overview",
        };
        dispatch(getCitiesOverview(params));
      }
    },
    // ##### 24-10-28 Komarov
    [lang, url],
  );

  // ##### 24-07-22 Komarov
  useEffect(
    () => {
      if (isItOtherHotelsPage) {
        dispatch(
          getHotelsOverview({ language: lang, primary_objects_list: 0 }),
        );
      }
    },
    // ##### 24-10-28 Komarov
    [lang, url],
  );

  const cities = cities_overview_all[0]?.data?.object_list;
  const hotels = hotels_overview_all[0]?.data?.object_list;
  // ##### 24-09-16 Komarov
  const { user: currentUser } = useSelector((state) => state.auth);
  const { user_id } = currentUser;

  useEffect(() => {
    // ##### 24-07-25 Komarov
    (process.env.NODE_ENV === "development" && purePageFromState.length > 0) ||
      console.log(
        "axios:src/Components/Pages/PageComponents/MenuPageGenerator.js:getPurePage(search_data.id,lang,deviceType)",
      );
    // ##### 23-04-05 Komarov: пока вывожу информацию для отладки, TODO: позже нужно удалить эти выводы
    // ##### 24-07-25 Komarov
    (process.env.NODE_ENV === "development" && purePageFromState.length > 0) ||
      console.log(
        "axios:src/Components/Pages/PageComponents/MenuPageGenerator.js:search_data.id",
        search_data.id,
      );
    // ##### 24-07-25 Komarov
    (process.env.NODE_ENV === "development" && purePageFromState.length > 0) ||
      console.log(
        "axios:src/Components/Pages/PageComponents/MenuPageGenerator.js:lang",
        lang,
      );

    // ##### 24-02-15 Komarov
    // TODO: Добавить в getPurePage аргумент для распознания 'sitepagecitycontent'
    // ##### 24-08-23 Komarov
    if (!isItOtherCitiesPage && !isItOtherHotelsPage) {
      if (location.pathname.includes("city_overview")) {
        dispatch(
          getPurePage({
            id: search_data.id,
            lang,
            deviceType,
            sitepage_type_id: search_data.sptid,
            pageType: "sitepagecitycontent",
            user_id,
          }),
        );
        // ##### 24-08-23 Komarov
      } else if (location.pathname.includes("hotel_overview")) {
        // ##### 24-09-16 Komarov
        dispatch(getHotelContent(search_data.id, lang, user_id));
      } else {
        // ##### 25-03-21 Komarov
        if (!purepages[search_data.id])
          console.log('MenuPageGenerator.js', search_data.id);
          dispatch(
            getPurePage({
              id: search_data.id,
              lang,
              deviceType,
            }),
          );
      }
    }
    // ##### 24-03-21 Komarov
  }, [
    search_data.id,
    lang,
    deviceType,
    dispatch,
    // ##### 24-08-23 Komarov
    location.pathname,
    search_data.sptid,
    // ##### 25-03-21 Komarov
    purepages[search_data.id],
  ]);

  // ##### 24-07-31 Komarov
  const countryLocalized = useSelector((state) => state.country)[locale];

  const selected_currency = useSelector(
    (state) => state.currency.pickedcurrency,
  );
  const start = moment().add(1, "days").format(dateFormats.en);
  const end = moment().add(2, "days").format(dateFormats.en);

  if (isItOtherCitiesPage && cities) {
    return (
      <div className="purecontentPage" data-component="MenuPageGenerator">
        <h2>{otherCitiesName}</h2>
        <div
          style={{ overflow: "hidden", order: "1", marginTop: "2vh" }}
          key={uniqueId()}
        >
          <Media greaterThanOrEqual="Laptop-1024-1440">
            <AllCitiesList
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
              }}
              // ##### 24-10-28 Komarov
              // cities_overview_all_data={cities_overview_all_data}
              countryLocalized={countryLocalized}
            />
          </Media>
          <Media at="Tablet-768-1024">
            <AllCitiesList
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
              }}
              // ##### 24-10-28 Komarov
              // cities_overview_all_data={cities_overview_all_data}
              countryLocalized={countryLocalized}
            />
          </Media>
          <Media lessThan="Tablet-768-1024">
            <AllCitiesList
              style={{
                display: "grid",
              }}
              // ##### 24-10-28 Komarov
              // cities_overview_all_data={cities_overview_all_data}
              countryLocalized={countryLocalized}
            />
          </Media>
        </div>
      </div>
    );
  }

  if (isItOtherHotelsPage && hotels) {
    return (
      <div className="purecontentPage" data-component="MenuPageGenerator">
        <h2>{otherHotelsName}</h2>
        <div
          style={{ overflow: "hidden", order: "1", marginTop: "2vh" }}
          key={uniqueId()}
        >
          <Media greaterThanOrEqual="Laptop-1024-1440">
            <AllHotelsList
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
              }}
              // ##### 24-10-28 Komarov
              // hotels_overview_all_data={hotels_overview_all_data}
              selected_currency={selected_currency}
              start={start}
              end={end}
            />
          </Media>
          <Media at="Tablet-768-1024">
            <AllHotelsList
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
              }}
              // ##### 24-10-28 Komarov
              // hotels_overview_all_data={hotels_overview_all_data}
              selected_currency={selected_currency}
              start={start}
              end={end}
            />
          </Media>
          <Media lessThan="Tablet-768-1024">
            <AllHotelsList
              style={{
                display: "grid",
              }}
              // ##### 24-10-28 Komarov
              // hotels_overview_all_data={hotels_overview_all_data}
              selected_currency={selected_currency}
              start={start}
              end={end}
            />
          </Media>
        </div>
      </div>
    );
  }

  // ##### 25-04-01 Komarov
  const shouldShowLoader = () => (!preloadedpages_loaded[search_data.id] && !preloadedcitiespages_loaded[search_data.id]) || !purePage || purePage.length === 0;

  // ##### 25-04-01 Komarov
  if (shouldShowLoader()) {
    return <div> Loading...</div>;
  }

  // ##### 23-07-12 Komarov
  return (
    <div className="purecontentPage" data-component="MenuPageGenerator">
      {
        // ##### 24-08-23 Komarov
        purePage &&
          purePage.length > 0 &&
          purePage.map((purePageElement) => {
            if (purePageElement.content_name === "Title") {
              return (
                <React.Fragment key={uniqueId()}>
                  <h2>{parse(purePageElement.text)}</h2>
                </React.Fragment>
              );
            }

            if (purePageElement.content_name === "Image") {
              // ##### 23-09-12 Komarov
              if (
                location.pathname.includes("sitepages") ||
                location.pathname.includes("profile") ||
                location.pathname.includes("sign_in") ||
                location.pathname.includes("sign_up")
              ) {
                if (parseInt(search_data.id) === parseInt("18")) {
                  return (
                    <Slider slides={purePageElement.text} key={uniqueId()} />
                  );
                }
                // ##### 24-03-21 Komarov
                if (parseInt(search_data.id) === parseInt("210")) {
                  return (
                    // ##### 23-04-12 Komarov
                    <div
                      key={uniqueId()}
                      style={{
                        background: "lightgrey",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "10px",
                      }}
                    >
                      <iframe
                        width="560"
                        height="315"
                        // style={{marginRight:'auto', marginLeft:'auto'}}
                        src={src}
                        title="YouTube video player"
                        // ##### 23-07-04 Komarov
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        // ##### 23-07-04 Komarov
                        allowFullScreen
                      />
                    </div>
                  );
                }
              } else {
                return (
                  <CartGallery
                    photos={purePageElement}
                    key={uniqueId()}
                    photoHeight={"55vh"}
                  />
                );
              }
            }

            if (purePageElement.content_name === "Body") {
              // ##### 23-09-12 Komarov
              if (
                location.pathname.includes("sitepages") ||
                location.pathname.includes("profile") ||
                location.pathname.includes("sign_in") ||
                location.pathname.includes("sign_up")
              ) {
                if (parseInt(search_data.id) === parseInt("21")) {
                  return (
                    // ##### 23-09-26 Komarov
                    <React.Fragment key={uniqueId()}>
                      {/* ##### 23-08-15 Komarov */}
                      {/* <YearAnimation/> */}
                      <YearBuilder />
                      {
                        // ##### 24-03-21 Komarov
                        Object.values(messagesFromRedux).length > 0 &&
                          Object.values(messagesFromRedux).map((item) => {
                            if (parseInt(item.sitepage_region_id) === 12) {
                              return (
                                <ShowGallery
                                  innerText={item.title[0].text}
                                  id={item.id}
                                  key={uniqueId()}
                                  componentKey={uniqueId()}
                                />
                              );
                            }
                          })
                      }
                    </React.Fragment>
                  );
                }
                // ##### 24-03-21 Komarov
                if (
                  parseInt(search_data.id) === parseInt("1") ||
                  parseInt(search_data.id) === parseInt("2")
                ) {
                  return (
                    <AboutUs text={purePageElement.text} key={uniqueId()} />
                  );
                }

                // ##### 24-03-21 Komarov
                if (parseInt(search_data.id) === parseInt("167")) {
                  return (
                    // ##### 23-04-12 Komarov
                    <SupplierPartnerForm
                      key={uniqueId()}
                      text={purePageElement.text}
                    />
                  );
                }
                // ##### 24-03-21 Komarov
                if (parseInt(search_data.id) === parseInt("42")) {
                  return (
                    <PartnershipForm
                      text={purePageElement.text}
                      key={uniqueId()}
                    />
                  );
                }
                // ##### 24-03-21 Komarov
                if (parseInt(search_data.id) === parseInt("187")) {
                  return (
                    <GroupTravelForm
                      text={purePageElement.text}
                      key={uniqueId()}
                    />
                  );
                }
                // ##### 24-03-21 Komarov
                if (parseInt(search_data.id) === parseInt("198")) {
                  return (
                    // <div>Hi Kate</div>
                    <Login text={purePageElement.text} key={uniqueId()} />
                  );
                }
              }
              // ##### 23-07-18 Komarov
              return (
                <div
                  style={{ overflow: "hidden", order: "1", marginTop: "2vh" }}
                  key={uniqueId()}
                >
                  {parse(purePageElement.text)}
                </div>
              );
            }
          })
      }
    </div>
  );
});
