// ##### 24-06-13 Komarov
import React from "react";
import { Media } from "../../../../helpers/MediaConfig.js";
import { HotelsPaxChoicePopUpPageDevices } from "./HotelsPaxChoicePopUpPageDevices.js";
import { HotelsPaxResultPageDevices } from "./HotelsPaxResultPageDevices.js";

// ##### 23-11-08 Komarov
export const HotelsPaxChoiceWrapperPageDevices = ({
  width = null,
  // ##### 23-11-15 Komarov
  isSecondSearch,
}) => (
  <div
    className="HotelPaxChoiceWrapper"
    style={{ width }}
    data-component="HotelPaxChoiceWrapper"
  >
    <Media greaterThanOrEqual="Laptop-1024-1440">
      <HotelsPaxResultPageDevices className="HotelPaxResult" />
    </Media>
    <Media lessThan="Laptop-1024-1440">
      <HotelsPaxResultPageDevices
        className="HotelPaxResultSmallScreen"
        width="100%"
      />
    </Media>
    <Media greaterThanOrEqual="Laptop-1024-1440">
      <HotelsPaxChoicePopUpPageDevices
        // ##### 23-11-15 Komarov
        // width='23vw'
        width="27vw"
      />
    </Media>
    {/* ##### 23-11-15 Komarov */}
    <Media at="Tablet-768-1024">
      <HotelsPaxChoicePopUpPageDevices
        // width='45%'
        // ##### 23-11-14 Komarov
        width="35vw"
        screenIsSmall
        isSecondSearch={isSecondSearch}
      />
    </Media>
    <Media lessThan="Tablet-768-1024">
      <HotelsPaxChoicePopUpPageDevices
        // ##### 23-11-15 Komarov
        // width='45%'
        // ##### 23-11-15 Komarov
        width="78vw"
        screenIsSmall
        isSecondSearch={isSecondSearch}
      />
    </Media>
  </div>
);
