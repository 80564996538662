// ##### 23-03-29 Komarov
import React from "react";
// ##### 23-09-14 Komarov
// import '../PageComponents/ResponsiveHeader/header.css'
import { Helmet } from "react-helmet";
import ArkturDMClogoICO from "../../Library/Images/ArkturDMClogoICO.ico";

export const HOTELS = () => {
  return (
    <div data-component="HOTELS">
      <Helmet>
        <title>Hotels in Ukraine</title>
        <link rel="shortcut icon" href={ArkturDMClogoICO} />
      </Helmet>
      <h3>Hotels</h3>
    </div>
  );
};

export const SOCIAL = () => {
  return <h2 data-component="SOCIAL"> My Home Page</h2>;
};

export const LOGO = () => {
  return <h2 data-component="LOGO"> My Home Page</h2>;
};

// export const NotFound = () => <h2>Not Found</h2>
