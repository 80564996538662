// ##### 24-05-31 Komarov
import React, { useEffect } from "react";
// ##### 23-09-14 Komarov
// import '../PageComponents/ResponsiveHeader/header.css'
import { Helmet } from "react-helmet";
import ArkturDMClogoICO from "../../Library/Images/ArkturDMClogoICO.ico";

//Call: +38 044 498 4880 / inquiry@arktur.ua / COVID-19 / ABOUT US / CONTACT US / SIGN IN / FOR GROUPS / LOGIN / TRANSLATE

///CITY HOTELS/ RESORT & HOTELIDAY DESTINATIONS / INFORMATION

export const CONTACTUS = () => {
  // ##### 24-05-31 Komarov
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div data-component="CONTACTUS">
      <Helmet>
        <title>Contact Arktur DMC</title>
        <link rel="shortcut icon" href={ArkturDMClogoICO} />
      </Helmet>
      <h2> Our Contact details</h2>
    </div>
  );
};

export const SIGNIN = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div data-component="SIGNIN">
      <Helmet>
        <title>Arktur DMC Partners</title>
        <link rel="shortcut icon" href={ArkturDMClogoICO} />
      </Helmet>
      <h2>Sign In</h2>
    </div>
  );
};

export const FORGROUPS = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div data-component="FORGROUPS">
      <Helmet>
        <title>Arktur Groups</title>
        <link rel="shortcut icon" href={ArkturDMClogoICO} />
      </Helmet>
      <h2>For Groups</h2>
    </div>
  );
};
