import {
  GET_ATTRACTIONS_ALL_ERR,
  GET_ATTRACTIONS_ALL_REQ,
  GET_ATTRACTIONS_ALL_RES,
  GET_ATTRACTIONS_TOP_ERR,
  GET_ATTRACTIONS_TOP_REQ,
  GET_ATTRACTIONS_TOP_RES,
  GET_CITIES_ERR,
  GET_CITIES_OVERVIEW_ALL_ERR,
  GET_CITIES_OVERVIEW_ALL_REQ,
  GET_CITIES_OVERVIEW_ALL_RES,
  GET_CITIES_OVERVIEW_TOP_ERR,
  GET_CITIES_OVERVIEW_TOP_REQ,
  GET_CITIES_OVERVIEW_TOP_RES,
  GET_CITIES_REQ,
  GET_CITIES_RES,
  GET_GENERAL_GEO_ERR,
  GET_GENERAL_GEO_REQ,
  GET_GENERAL_GEO_RES,
  GET_GEO_ERR,
  GET_GEO_REQ,
  GET_GEO_RES,
  GET_OCCUPIED_CITIES_ALL_ERR,
  GET_OCCUPIED_CITIES_ALL_REQ,
  GET_OCCUPIED_CITIES_ALL_RES,
  GET_OCCUPIED_CITIES_TOP_ERR,
  GET_OCCUPIED_CITIES_TOP_REQ,
  GET_OCCUPIED_CITIES_TOP_RES,
  GET_SITEPAGECITY_ERR,
  GET_SITEPAGECITY_REQ,
  GET_SITEPAGECITY_RES,
} from "../constants";

const initState = {
  locs: [],
  loc_loaded: false,
  // ##### 24-03-21 Komarov
  // errors: [],

  gen_locs: [],
  gen_loc_loaded: false,
  // errors: [],

  cities: [],
  cities_loaded: false,
  // errors: [],

  // ##### 24-07-22 Komarov
  cities_overview_top: [],
  cities_overview_top_loaded: false,

  // ##### 24-07-22 Komarov
  cities_overview_all: [],
  cities_overview_all_loaded: false,

  // ##### 24-09-05 Komarov
  occupied_cities_top: [],
  occupied_cities_top_loaded: false,

  // ##### 24-09-05 Komarov
  occupied_cities_all: [],
  occupied_cities_all_loaded: false,

  // ##### 24-07-22 Komarov
  attractions_top: [],
  attractions_top_loaded: false,

  // ##### 24-07-22 Komarov
  attractions_all: [],
  attractions_all_loaded: false,

  // ##### 23-08-15 Komarov
  sitepagecity: [],
  sitepagecity_loaded: false,
  errors: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_GEO_REQ:
      return {
        ...state,
        loc_loaded: false,
      };

    case GET_GEO_RES:
      return {
        ...state,
        loc_loaded: true,
        locs: action.payload,
      };

    // case 'REMOVE_DESC':
    // 	return({
    //         ...state,
    //         htls: state.htls.filter( htl => parseInt(htl.id) !== parseInt(action.payload) )
    // 	})

    case GET_GEO_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 24-07-22 Komarov
    case GET_CITIES_OVERVIEW_ALL_REQ:
      return {
        ...state,
        cities_overview_all_loaded: false,
      };

    case GET_CITIES_OVERVIEW_ALL_RES:
      return {
        ...state,
        cities_overview_all_loaded: true,
        cities_overview_all: action.payload,
      };

    case GET_CITIES_OVERVIEW_ALL_ERR:
      return {
        ...state,
        cities_overview_all_loaded: false,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    case GET_CITIES_OVERVIEW_TOP_REQ:
      return {
        ...state,
        cities_overview_top_loaded: false,
      };

    case GET_CITIES_OVERVIEW_TOP_RES:
      return {
        ...state,
        cities_overview_top_loaded: true,
        cities_overview_top: action.payload,
      };

    case GET_CITIES_OVERVIEW_TOP_ERR:
      return {
        ...state,
        cities_overview_top_loaded: false,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 24-09-05 Komarov
    case GET_OCCUPIED_CITIES_ALL_REQ:
      return {
        ...state,
        occupied_cities_all_loaded: false,
      };

    case GET_OCCUPIED_CITIES_ALL_RES:
      return {
        ...state,
        occupied_cities_all_loaded: true,
        occupied_cities_all: action.payload,
      };

    case GET_OCCUPIED_CITIES_ALL_ERR:
      return {
        ...state,
        occupied_cities_all_loaded: false,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    case GET_OCCUPIED_CITIES_TOP_REQ:
      return {
        ...state,
        occupied_cities_top_loaded: false,
      };

    case GET_OCCUPIED_CITIES_TOP_RES:
      return {
        ...state,
        occupied_cities_top_loaded: true,
        occupied_cities_top: action.payload,
      };

    case GET_OCCUPIED_CITIES_TOP_ERR:
      return {
        ...state,
        occupied_cities_top_loaded: false,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 24-08-16 Komarov
    case GET_ATTRACTIONS_ALL_REQ:
      return {
        ...state,
        attractions_all_loaded: false,
      };

    case GET_ATTRACTIONS_ALL_RES:
      return {
        ...state,
        attractions_all_loaded: true,
        attractions_all: action.payload,
      };

    case GET_ATTRACTIONS_ALL_ERR:
      return {
        ...state,
        attractions_all_loaded: false,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    case GET_ATTRACTIONS_TOP_REQ:
      return {
        ...state,
        attractions_top_loaded: false,
      };

    case GET_ATTRACTIONS_TOP_RES:
      return {
        ...state,
        attractions_top_loaded: true,
        attractions_top: action.payload,
      };

    case GET_ATTRACTIONS_TOP_ERR:
      return {
        ...state,
        attractions_top_loaded: false,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    case GET_CITIES_REQ:
      return {
        ...state,
        cities_loaded: false,
      };

    case GET_CITIES_RES:
      return {
        ...state,
        cities_loaded: true,
        cities: action.payload,
      };

    case GET_CITIES_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    case GET_SITEPAGECITY_REQ:
      return {
        ...state,
        sitepagecity_loaded: false,
      };

    case GET_SITEPAGECITY_RES:
      return {
        ...state,
        sitepagecity_loaded: true,
        sitepagecity: action.payload,
      };

    case GET_SITEPAGECITY_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    case GET_GENERAL_GEO_REQ:
      return {
        ...state,
        gen_loc_loaded: false,
      };

    case GET_GENERAL_GEO_RES:
      return {
        ...state,
        gen_loc_loaded: true,
        gen_locs: action.payload,
      };

    case GET_GENERAL_GEO_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    default:
      return state;
  }
};

export default reducer;
