// ##### 24-09-09 Komarov
import React, { useState, createContext, useContext, useMemo } from "react";
import { IconHint } from "./iconHint";
// ##### 23-10-31 Komarov
import { Media } from "../../../helpers/MediaConfig";

// ##### 23-10-10 Komarov
const SvgTag = ({
  style = null,
  // screenWidthClassName = ''
}) => {
  const { color, setIsHintShown, setY } = useContext(EmailIconContext);

  // ##### 24-08-16 Komarov
  const [crossColor, setCrossColor] = useState(color ? color : "");

  const onMouseEnter = (e) => {
    if (!color) {
      return;
    }
    setCrossColor("yellow");
    setIsHintShown(true);
    setY(e.clientY + 25);
  };

  // ##### 24-08-16 Komarov
  const onMouseLeave = () => {
    if (!color) {
      return;
    }
    setCrossColor("white");
    setIsHintShown(false);
    setY(undefined);
  };

  // ##### 24-05-31 Komarov
  return (
    <a href="mailto:inquiry@arktur.ua" data-component="EmailIcon">
      <svg
        aria-hidden="true"
        // ##### 23-03-23 Komarov
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        color={crossColor}
        focusable="false"
        data-prefix="fas"
        data-icon="envelope"
        // ##### 23-10-31 Komarov
        className="svg-inline--fa fa-envelope fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
        />
      </svg>
    </a>
  );
};

// ##### 24-05-31 Komarov
const ATag = () => (
  <>
    {/* ##### 23-10-31 Komarov */}
    <Media lessThan="Mobile-L-425-768">
      <SvgTag style={{ width: "7.5vw" }} />
    </Media>
    <Media at="Mobile-L-425-768">
      <SvgTag style={{ height: "2.33em" }} />
    </Media>
    <Media at="Tablet-768-1024">
      <SvgTag style={{ height: "2em" }} />
    </Media>
    <Media greaterThanOrEqual="Laptop-1024-1440">
      <SvgTag style={{ width: "3vw", marginRight: "1vw" }} />
    </Media>
  </>
);

// ##### 23-10-10 Komarov
const EmailIconContext = createContext();

export const EmailIcon = ({ color, hintText }) => {
  const [isHintShown, setIsHintShown] = useState(false);
  const [y, setY] = useState(undefined);
  // ##### 24-09-09 Komarov
  const emailIconContextValue = useMemo(() => ({
    color,
    hintText,
    isHintShown,
    setIsHintShown,
    y,
    setY,
  }), [
    // ##### 24-09-11 Komarov
    color,
    hintText,
    isHintShown,
    setIsHintShown,
    y,
    setY,
  ]); // value is cached by useMemo

  // ##### 24-09-09 Komarov
  return (
    <EmailIconContext.Provider
      value={emailIconContextValue}
    >
      <ATag />
      <IconHint isHintShown={isHintShown} hintText={hintText} y={y} />
    </EmailIconContext.Provider>
  );
};
