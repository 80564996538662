// ##### 25-02-12 Komarov
import React from "react";
// ##### 23-09-05 Komarov
import moment from "moment";
// ##### 23-03-29 Komarov
import "moment/locale/uk";

// ##### 23-09-14 Komarov
// import './HotelItemsCSS.css'
import config from "../../../../../Redux/config";
// ##### 23-11-02 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
import { HotelItemContent } from "./HotelItemContent";

moment.locale(config.defaultLang);

// ##### 24-05-23 Komarov
export const HotelContent = ({ hotel_id }) => {
  // ##### 23-03-01 Komarov
  return (
    <div className="WrapperHotel_ItemContent" data-component="HotelContent">
      {/* ##### 23-11-13 Komarov */}
      <Media greaterThanOrEqual="Tablet-768-1024">
        <HotelItemContent
          ulCLassName="Hotel_ItemContent"
          liClassName="Li_HotelContent"
          liImageClassName="Li_Image"
          divHotelNameStarRatingClassName="HotelNameStarRating"
          width="18vw"
          height="14vw"
          hotel_id={hotel_id}
        />
      </Media>
      {/* ##### 23-11-13 Komarov */}
      <Media lessThan="Tablet-768-1024">
        <HotelItemContent
          ulCLassName="Hotel_ItemContentSmallScreen"
          liClassName="Li_HotelContentSmallScreen"
          liImageClassName="Li_ImageSmallScreen"
          divHotelNameStarRatingClassName="HotelNameStarRatingSmallScreen"
          divHotelAddressClassName="HotelAddressSmallScreen"
          width="90%"
          height="100%"
          hotel_id={hotel_id}
        />
      </Media>
    </div>
  );
};
