// ##### 24-06-13 Komarov
import React, { useContext } from "react";
import { Media } from "../../../../../helpers/MediaConfig.js";
import { HotelsPaxChoiceContext } from "./HotelsPaxChoice.js";
import { HotelsPaxChoiceGrid } from "./HotelsPaxChoiceGrid.js";
import { PaxPopUpButton } from "./PaxPopUpButton.js";

// ##### 23-12-27 Komarov

export const PopUp = ({
  style,
  screenIsSmall = false,
  isSecondSearch = false /* , className = '' */,
}) => {
  const { paxListOpen, toggleContextPaxListOpen, TotalPax, isOnModalDiv } =
    useContext(HotelsPaxChoiceContext);
  return (
    <div
      data-component="PopUp"
      // ##### 24-02-08 Komarov
      className={
        ((!screenIsSmall || isSecondSearch) &&
          (paxListOpen === false
            ? "PopUpNotActive"
            : "PopUpActive-HotelsAutocompleteBlock")) ||
        "PopUpActive-HotelsAutocompleteBlock-Mobile"
      }
      // ##### 23-12-27 Komarov
      style={style}
      onClick={(e) => {
        toggleContextPaxListOpen(true);
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        // Trigger click action on Enter key press
        if (e.key === "Enter") {
          toggleContextPaxListOpen(true);
          e.stopPropagation();
        }
      }}
      id={
        process.env.NODE_ENV === "development" &&
        "src/Components/Pages/FirstPageofSearchModule/HotelsAutocompleteBlock/HotelsPaxChoice.js:PopUpDivTag"
      }
    >
      {/* ##### 23-11-03 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <HotelsPaxChoiceGrid rowGap="10px" />
      </Media>
      <Media lessThan="Laptop-1024-1440">
        <HotelsPaxChoiceGrid rowGap="16px" />
      </Media>

      {/* ##### 22-11-08 Komarov - start */}
      {!isOnModalDiv ? (
        <div
          onClick={TotalPax}
          onKeyDown={(e) => {
            // Trigger click action on Enter key press
            if (e.key === "Enter") {
              TotalPax(e);
            }
          }}
          style={{ textAlign: "center" }}
        >
          <Media greaterThanOrEqual="Laptop-1024-1440">
            <PaxPopUpButton marginTop="3vw" />
          </Media>
          <Media lessThan="Laptop-1024-1440">
            <PaxPopUpButton />
          </Media>
        </div>
      ) : null}
      {/* ##### 22-11-08 Komarov - end */}
    </div>
  );
};
