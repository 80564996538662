// ##### 25-02-05 Komarov
import React, { useRef } from "react";
import { ImageShown } from "./ImageShown";
import uniqueId from "lodash.uniqueid";
import AsynchronousImage from "./AsynchronousImage";
// ##### 25-02-15 Komarov
import { useSelector } from "react-redux";
import { getDomain } from "../../../Pages/Helpers/urls";

export const CartGalleryDiv = ({
  height,
  flexDirection,
  width1,
  width2,
  photos,
  smallImageHeight,
}) => {
  // ##### 25-02-28 Komarov
  const isGalleryPage = !!photos.text;
  const currentPhotos = photos.text || photos;
  // ##### 24-11-29 Komarov
  const shownImgRef = useRef({ src: undefined });
  const imgPreviews = useRef();
  let currentPreviewIndex = undefined;

  const pickAction = (index) => (e) => {
    shownImgRef.current.src = e.target.getAttribute("src");
    const imagePreviews = imgPreviews.current.children;
    if (currentPreviewIndex || currentPreviewIndex === 0) {
      // ##### 25-04-03 Komarov
      const prevChosenImage =
        imagePreviews[currentPreviewIndex].firstChild;
      prevChosenImage.classList.remove("CartPickedImage");
      prevChosenImage.classList.add("CartNotPickedImage");
    }
    currentPreviewIndex = index;
    // ##### 25-04-03 Komarov
    const chosenImage = imagePreviews[index].firstChild;
    chosenImage.classList.remove("CartNotPickedImage");
    chosenImage.classList.add("CartPickedImage");
  };

  // ##### 25-02-15 Komarov
  const deviceType = useSelector((state) => state.deviceType);
  const currentWebProtocol = process.env.REACT_APP_PROTOCOL || "https://";
  // ##### 25-04-03 Komarov
  const hotelImageSrcStub = `${currentWebProtocol}${getDomain(process.env.REACT_APP_SMART_URL)}/images_symlink/hotel/0/Hotel_when_no_image.jpeg`;
  // ##### 25-02-28 Komarov
  const hotelId =
    !isGalleryPage &&
    Object.keys(
      useSelector((state) => state.hotelcontent.hotelcontent3.data?.hotels),
    )[0];
  // ##### 25-02-15 Komarov: TODO: Smart отправляет неправильный src картинок отеля. Функция для исправления src картинки
  const getImageSrc = (image) => {
    // ##### 25-02-15 Komarov: Если картинки нет вообще, то возвращаем картинку для отеля без картинок
    if (!image) {
      console.warn("There's no images for hotel with id", hotelId);
      return hotelImageSrcStub;
    }
    // ##### 25-02-15 Komarov: Если папка с картинками не содержит картинки формата webp, а содержит jpg (в ответе от Смарта пришли ссылки на картинки jpg, а не webp), то показываем картинки jpg
    if (
      image.includes(`/${deviceType}/`) &&
      image.includes(".jpg") &&
      !image.includes(".jpg_orig")
    ) {
      return image;
    }
    if (!image.includes(`/${deviceType}/`) || !image.includes(".webp")) {
      let fixedHotelImageSrc = image;
      if (!image.includes(`/${deviceType}/`)) {
        fixedHotelImageSrc = `${fixedHotelImageSrc.slice(0, fixedHotelImageSrc.indexOf("images_symlink") + "images_symlink".length)}/${deviceType}${fixedHotelImageSrc.slice(fixedHotelImageSrc.indexOf("images_symlink") + "images_symlink".length)}`;
      }
      if (!image.includes(".webp")) {
        return `${fixedHotelImageSrc.slice(0, fixedHotelImageSrc.lastIndexOf("."))}.webp`;
      }
      return fixedHotelImageSrc;
    }
    return image;
  };

  // ##### 23-11-08 Komarov
  return (
    <div
      data-component="CartGalleryDiv"
      style={{
        width: "96%",
        height,
        display: "flex",
        flexDirection,
      }}
    >
      <>
        {/* ##### 25-02-28 Komarov */}
        {(isGalleryPage && (
          <ImageShown
            width1={width1}
            shownImgRef={shownImgRef}
            firstPhotoUrl={
              (process.env.REACT_APP_PROTOCOL || "https://") + currentPhotos[0]
            }
          />
        )) || (
          <ImageShown
            width1={width1}
            shownImgRef={shownImgRef}
            firstPhotoUrl={getImageSrc(currentPhotos[0])}
          />
        )}

        <div
          ref={imgPreviews}
          style={{
            // ##### 24-06-26 Komarov
            display: "flex",
            flexWrap: "wrap",
            alignContent: "flex-start",
            //  flexDirection:'row',
            //  justifyContent:'space-evenly'
            // display: "grid",
            // gridTemplateColumns: "repeat(4,25%)",
            // rowGap: "0",
            // ##### 22-09-05 Komarov
            width: width2,
            // overflow: "scroll",
            overflowY: "auto",
            //  justifyContent:'space-evenly'
          }}
        >
          {/* ##### 25-02-28 Komarov */}
          {currentPhotos.map((image, index) => {
            return (
              <div key={uniqueId()} className="galleryPage">
                <AsynchronousImage
                  // ##### 25-04-01 Komarov
                  emptyLoader
                  className="CartNotPickedImage"
                  // ##### 25-02-28 Komarov
                  src={
                    (isGalleryPage &&
                      (process.env.REACT_APP_PROTOCOL || "https://") + image) ||
                    getImageSrc(image)
                  }
                  alt="Gallery element"
                  style={{
                    height: `${smallImageHeight ? smallImageHeight : null}`,
                  }}
                  onClick={pickAction(index)}
                  // ##### 24-03-21 Komarov
                  onKeyUp={(e) => {
                    if (e.key === "Enter") pickAction(index);
                  }} // Example of adding keyboard accessibility
                />
              </div>
            );
          })}
        </div>
      </>
    </div>
  );
};
