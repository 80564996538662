// ##### 23-03-29 Komarov
import React from "react";
// ##### 23-04-12 Komarov
import uniqueId from "lodash.uniqueid";
import { Pax } from "../../../../Library/Icons/pax";
import { AvailableOptions } from "./AvailableOptions";
// ##### 24-08-05 Komarov
import { useSelector } from "react-redux";
// ##### 25-02-18 Komarov
import { useLocation } from "react-router-dom";
import { ValidateQuery } from "../../../Helpers/helper";

// ##### 23-09-14 Komarov
// import './RatesBlockCSS.css'

// ##### 25-02-18 Komarov
export const SmallScreenRatesBlock = () => {
  const location = useLocation();
  const shouldCypherUrlParams = useSelector(
    (state) => !!parseInt(state.siteCypherCodeData.data.value),
  );
  // ##### 25-02-28 Komarov
  const cypherKey = useSelector(
    (state) => state.siteCypherCodeData.data.description,
  );
  const search_data = ValidateQuery(location, shouldCypherUrlParams, cypherKey);
  // console.log('PICKEDHOTELROOMS',pickedHotelRooms)

  // ##### 24-05-30 Komarov
  const getGridTemplateColumns = (itemsCount) => {
    if (itemsCount === 1) {
      return "100%";
    }
    return "50% 50%";
  };

  // ##### 24-08-05 Komarov
  const company_id = useSelector((state) => state.auth.user.company_id);
  // ##### 25-02-18 Komarov
  const hotelrates2 = useSelector((state) => state.hotelrates.hotelrates2.data);

  return (
    // ##### 23-11-08 Komarov
    <div data-component="SmallScreenRatesBlock">
      {/*##### 25-02-18 Komarov*/}
      <div>
        {Object.keys(hotelrates2.prices.rooms).map((room_id) => {
          const room = hotelrates2.prices.rooms[room_id];
          return Object.keys(room.tariffs).map((tariff_id) => {
            const room_name =
              (hotelrates2.rooms[room_id].ro_name === "0" && "Regular") ||
              hotelrates2.rooms[room_id].ro_name;
            const tariff = room.tariffs[tariff_id];
            return (
              <div
                className="DetailsSmallScreen"
                style={{
                  gridTemplateColumns: getGridTemplateColumns(
                    tariff.paxes.length,
                  ),
                }}
              >
                <h5 className="RoomTypeColumnSmallScreen">{room_name}</h5>
                {Object.keys(tariff.paxes).map((paxNum) => {
                  const data =
                    hotelrates2.prices.rooms[room_id].tariffs[tariff_id].paxes[
                      paxNum
                    ];
                  const rat_name = hotelrates2.tariffs[tariff_id].rat_name;
                  const nrf = hotelrates2.tariffs[tariff_id].nrf;
                  const available_now = !!!parseInt(data.notavail);
                  const sum = data.sum;
                  return (
                    <div key={uniqueId()}>
                      <h5 key={uniqueId()}>
                        {
                          // ##### 25-02-18 Komarov: отображаем повторно иконку человечка (Pax), основываясь на числе (paxNum)
                          Array.from("1".repeat(paxNum)).map(() => {
                            return <Pax key={uniqueId()} />;
                          })
                        }
                      </h5>
                      <h5
                        key={uniqueId()}
                        style={{
                          fontSize: "17px",
                          color: "blue",
                          fontFamily: "Arial",
                          fontWeight: "bold",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>{rat_name}</span>
                        <span
                          style={{
                            color: "darkred",
                            marginLeft: "5px",
                            fontStyle: "italic",
                          }}
                        >
                          {parseInt(nrf) === parseInt("1")
                            ? "Non Refundable"
                            : null}
                        </span>
                      </h5>
                      <div
                        //  ##### 25-03-14 Komarov
                        className={
                          (available_now &&
                            "WrapperAvailableOptionsSmallScreen") ||
                          (sum === 0 &&
                            "WrapperAvailableOptionsOnRequestWithoutPrice") ||
                          "WrapperAvailableOptionsOnRequestSmallScreen"
                        }
                      >
                        <AvailableOptions
                          sum={sum}
                          rooms={search_data.rooms}
                          room_id={room_id}
                          room_name={room.ro_name}
                          tariff_type_name={rat_name}
                          tariff_id={tariff_id}
                          availability={parseInt(data.allotm)}
                          // ##### 24-08-05 Komarov: TODO: Исправить код в соответствии с задачей убрать Промокод из URL
                          refpartner={company_id}
                          start={search_data.start}
                          end={search_data.end}
                          // ##### 24-04-05 Komarov: Так как в эту ветку кода попадают целиком отели, у которых нет доступных на продажу с аллотмента номеров, то здесь и указываем значение "available_now_hotel"
                          available_now={available_now}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          });
        })}
      </div>
    </div>
  );
};
