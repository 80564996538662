// ##### 25-02-05 Komarov
import React from "react";

export const PromoCodeValue = ({ fontSize }) => {
  return (
    <h4
      data-component="PromoCodeValue"
      style={{
        color: "rgb(136, 6, 6)",
        fontSize,
        fontWeight: "bold",
        marginTop: "auto",
        marginBottom: "auto",
      }}
    >
      Promo Code
    </h4>
  );
};
