import axios from "axios";

// ##### 25-03-06 Komarov: Фунция для запроса к Серверу Сайта сохранения данных в файл. Передаёт Серверу данные, необходимые для того, чтобы Сервер Сайта отправил соотв. запрос Смарту (повторил со своей стороны запрос Клиента к Смарту и сохранил данные ответа). Служит для того, чтобы при запросе данных у Смарта ещё и сохранить ответ в файл, как резервную копию для чтения из неё в случае ошибки ответа от Смарта. На стороне Сервера Сайта если requestType === 'POST', то url для запроса к Смарту берётся из postObject.url, а если requestType === 'GET', - то - из smartUrl
export const saveData = async ({
  smartUrl,
  actionType,
  requestType,
  postObject,
}) => {
  const url = `${process.env.REACT_APP_URL}/saveData`;
  await axios.post(
    url,
    {
      smartUrl,
      actionType,
      requestType,
      postObject,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

// ##### 25-03-06 Komarov: Фунция для запроса на чтение данных из файла в случае ошибки ответа от Смарта. Функция посылает запрос Серверу Сайта на чтение данных из соотв. файла, который, возможно, сохранён раньше. Параметр func - ссылка на функцию, которая диспатчит action с типом, означающим запись данных успешного ответа в Redux (например, тип action GET_TOPHOTELS_RES).
export const readData = async ({
  smartUrl,
  actionType,
  postObject,
  dispatch,
}) => {
  const url = `${process.env.REACT_APP_URL}/readData`;
  await axios
    .post(
      url,
      {
        smartUrl,
        actionType,
        postObject,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
    .then(async (res) => {
      // ##### 25-03-28 Komarov
      dispatch({
        type: actionType,
        payload: await res.data.data,
      });
    });
};

// ##### 25-03-28 Komarov
export const getSavingDataClient = async ({
  smartUrl,
  typeRes,
  typeErr,
  dispatch,
  processDataFunction, // ##### 25-03-28 Komarov: функция обработки полученного ответа от Смарта (не обязательная, если нужно), создаётся в соотв. action (если нужно записать в Redux не просто ответ, а объект с дополнительными полями, в одном из которых будет ответ от Смарта)
  isSpecialCondition, // ##### 25-03-28 Komarov: функция проверки ополнительного условия (не обязательная, если нужно), создаётся в соотв. action
}) => {
  // ##### 25-03-10 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
  await saveData({
    smartUrl,
    actionType: typeRes,
    requestType: "GET",
  });

  await axios
    .get(smartUrl)
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      if ((await res.data) === "Are you lost?") {
        throw new Error("Smart's response: Are you lost?");
      }
      // ##### 25-03-28 Komarov: если передана функция проверки некоторого дополнительного условия и оно не выполняется, то не произодим записи в Redux, - не выполняем дальше dispatch
      if (typeof isSpecialCondition === 'function' && !isSpecialCondition(await res.data)) {
        // ##### 25-03-28 Komarov: если спец. условие не выполнилось, возвращаем false, чтобы в следующем методе then обработать его и в таком случае выйти, ничего не делая.
        return false;
      }
      let data;
      if (processDataFunction) {
        // ##### 25-03-31 Komarov
        data = await processDataFunction(await res.data);
      } else {
        data = await res.data;
      }
      dispatch({
        type: typeRes,
        // ##### 25-03-31 Komarov
        payload: await data,
      });
    })
    .catch(async (err) => {
      // ##### 25-03-10 Komarov: в случае ошибки ответа от Смарта запрашиваем данные у Сервера Сайта
      await readData({
        smartUrl,
        actionType: typeRes,
        dispatch,
      });
      dispatch({ type: typeErr, error: err });
    });
};

// ##### 25-03-28 Komarov
export const postSavingDataClient = async ({
  postObject,
  typeRes,
  typeErr,
  dispatch,
  processDataFunction, // ##### 25-03-28 Komarov: функция обработки полученного ответа от Смарта (не обязательная, если нужно), создаётся в соотв. action (если нужно записать в Redux не просто ответ, а объект с дополнительными полями, в одном из которых будет ответ от Смарта)
  isSpecialCondition, // ##### 25-03-28 Komarov: функция проверки ополнительного условия (не обязательная, если нужно), создаётся в соотв. action
}) => {
  // ##### 25-03-10 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
  await saveData({
    actionType: typeRes,
    requestType: "POST",
    postObject,
  });

  // ##### 25-03-26 Komarov
  await axios
    .post(postObject.url, postObject.parameters)
    // ##### 24-10-25 Komarov
    .then(async (res) => {
      if ((await res.data) === "Are you lost?") {
        throw new Error("Smart's response: Are you lost?");
      }
      // ##### 25-03-28 Komarov: если передана функция проверки некоторого дополнительного условия и оно не выполняется, то не произодим записи в Redux, - не выполняем дальше dispatch
      if (typeof isSpecialCondition === 'function' && !isSpecialCondition(await res.data)) {
        // ##### 25-03-28 Komarov: если спец. условие не выполнилось, возвращаем false, чтобы в следующем методе then обработать его и в таком случае выйти, ничего не делая.
        return false;
      }
      let data;
      if (processDataFunction) {
        data = processDataFunction(await res.data);
      } else {
        data = await res.data;
      }
      dispatch({
        type: typeRes,
        payload: data,
      });
    })
    .catch(async (err) => {
      // ##### 25-03-10 Komarov: в случае ошибки ответа от Смарта запрашиваем данные у Сервера Сайта
      await readData({
        smartUrl: postObject.url,
        actionType: typeRes,
        dispatch,
      });
      dispatch({
        type: typeErr,
        error: err,
      });
    });
};
