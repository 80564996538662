// ##### 23-03-29 Komarov
import { GET_HTLCHOICE_REQ, GET_HTLCHOICE_RES } from "../constants";

//action creator
export const hotelChoiceTours = (res) => ({
  type: GET_HTLCHOICE_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
export const getHotelChoiceTours = (param) => (dispatch) => {
  dispatch({ type: GET_HTLCHOICE_REQ });

  dispatch(hotelChoiceTours(param));
};
