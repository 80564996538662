// ##### 25-02-05 Komarov
import { useMessages } from "../../../../../../../helpers/messages";
import { PlaceHolderStrings } from "../../../../../Localization/placeholders";
import { useSelector } from "react-redux";
import uniqueId from "lodash.uniqueid";
import { markRequired } from "../../../../../../../helpers/markRequired";
import { InputNumber, Select, Space } from "antd";
import LodgingTypes from "../../../../../StaticJsonData/LodgingTypes.json";
import { StarRating } from "../../../../../StaticJsonData/StarRating";
import React from "react";

export const AccommodationComponent = ({ props }) => {
  const {
    selectingAccomTypes,
    accomType,
    accomTypeInitialState,
    SelectSingleRoomsAmount,
    SelectTwinRoomsAmount,
    selectStarRating,
    starRating,
    starRatingInitialState,
  } = props;
  // ##### 24-04-23 Komarov
  const groupRequestEstablishment = useMessages("Group_request_establishment");
  const placeholder = PlaceHolderStrings();
  const locale = useSelector((state) => state.language);
  return (
    <div className="groupTravelblocks_2_accommodation" key={uniqueId()}>
      <span>
        {
          // ##### 23-10-04 Komarov
          markRequired(groupRequestEstablishment)
        }
      </span>
      <Select
        onChange={selectingAccomTypes}
        bordered={true}
        style={{ minWidth: "5vw" }}
        // ##### 24-03-21 Komarov
        value={
          (accomType === accomTypeInitialState &&
            placeholder.placeHolderSelect) ||
          accomType
        }
      >
        <>
          {
            // ##### 24-03-21 Komarov
            LodgingTypes?.map((item) => {
              if (item.lang === locale) {
                return item.type.map((item1) => {
                  return (
                    <Option value={item1} key={item1}>
                      {item1}
                    </Option>
                  );
                });
              }
            })
          }
        </>
      </Select>

      <Select
        onChange={selectStarRating}
        bordered={true}
        style={{ marginLeft: "0.5vw", marginRight: "0,5vw" }}
        value={
          (starRating === starRatingInitialState &&
            placeholder.placeHolderStarRating) ||
          starRating
        }
      >
        {
          // ##### 24-03-21 Komarov
          StarRating?.map((item) => {
            return (
              <Option value={item} key={item}>
                {item}
              </Option>
            );
          })
        }
      </Select>
      <span>
        {/* ##### 23-10-02 Komarov: TODO: заменить на перевод из Смарта */}
        Twin rooms
      </span>
      <Space>
        <InputNumber
          min={1}
          max={100000}
          defaultValue={1}
          onChange={SelectTwinRoomsAmount}
        />
      </Space>
      <span>
        {/* ##### 23-10-02 Komarov: TODO: заменить на перевод из Смарта */}
        Single rooms
      </span>
      <Space>
        <InputNumber
          min={1}
          max={100000}
          defaultValue={1}
          onChange={SelectSingleRoomsAmount}
        />
      </Space>
    </div>
  );
};
