import axios from "../helpers/public.axios";

import {
  GET_TOURDETAILS_REQ,
  GET_TOURDETAILS_RES,
  GET_TOURDETAILS_ERR,
} from "../constants";
import {
  GET_EN_TOUR_REQ,
  GET_EN_TOUR_RES,
  GET_EN_TOUR_ERR,
} from "../constants";
import {
  GET_IMAGES_TOUR_REQ,
  GET_IMAGES_TOUR_RES,
  GET_IMAGES_TOUR_ERR,
} from "../constants";

export const tourDetailsResponse = (res) => ({
  type: GET_TOURDETAILS_RES,
  payload: res,
});

//
export const getTourDetails = (id, date) => (dispatch) => {
  //
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/tours.js:getTourDetails");

  // console.log('REDUX',tour_id,selectionDetails)

  dispatch({ type: GET_TOURDETAILS_REQ });

  //   axios.get(`${process.env.REACT_APP_SMART_URL}interface/sitechoice3new?tour_id=${tour_id}&date=${selectionDetails}`,  {
  //
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/tours.js:getTourDetails, url:",
      `${process.env.REACT_APP_SMART_URL}interface/sitechoice3new?tour_id=${id}&date=${date}`
    );
  axios
    .get(
      `${process.env.REACT_APP_SMART_URL}interface/sitechoice3new?tour_id=${id}&date=${date}`
    )

    // ##### 24-11-01 Komarov
    .then(async (res) => {
      dispatch(tourDetailsResponse(await res.data));
      // console.log('choiceDetailsNew',res)
    })
    .catch((err) => {
      dispatch({ type: GET_TOURDETAILS_ERR, error: err });
    });
};

export const ENtourResponse = (res) => ({
  type: GET_EN_TOUR_RES,
  payload: res,
});

//
export const ENgetTour = () => (dispatch) => {
  //
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/tours.js:ENgetTour");

  dispatch({ type: GET_EN_TOUR_REQ });

  //
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/tours.js:ENgetTour, url:",
      "https://hotelsukraine.com.ua/ua/my_list_hotels/?type=full&hotels=16752&json=1&_dc=1608289903506&getDescription=Ext.data.JsonP.getDescription"
    );
  axios
    .get(
      "https://hotelsukraine.com.ua/ua/my_list_hotels/?type=full&hotels=16752&json=1&_dc=1608289903506&getDescription=Ext.data.JsonP.getDescription",
      {}
    )
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      const obj = JSON.parse(res.data.substring(30, await res.data.length - 1));
      //
      for (const key in obj) {
        if (key === "en") {
          // console.log(obj[key])
          dispatch(ENtourResponse(obj[key]));
        }
      }
    })
    .catch((err) => {
      dispatch({ type: GET_EN_TOUR_ERR, error: err });
    });
};

export const IMGtourResponse = (res) => ({
  type: GET_IMAGES_TOUR_RES,
  payload: res,
});

//
export const IMGgetTour = () => (dispatch) => {
  //
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/tours.js:IMGgetTour");

  dispatch({ type: GET_IMAGES_TOUR_REQ });

  //
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/tours.js:IMGgetTour, url:",
      "https://hotelsukraine.com.ua/ua/my_list_hotels/?type=full&hotels=16752&json=1&_dc=1608289903506&getDescription=Ext.data.JsonP.getDescription"
    );
  axios
    .get(
      "https://hotelsukraine.com.ua/ua/my_list_hotels/?type=full&hotels=16752&json=1&_dc=1608289903506&getDescription=Ext.data.JsonP.getDescription",
      {}
    )
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      const obj = JSON.parse(res.data.substring(30, await res.data.length - 1));
      //
      for (const key in obj) {
        if (key === "photo_gallery") {
          // console.log(obj[key])
          const newArr = [];
          //
          for (const item in obj[key]) {
            newArr.push(obj[key][item]);
          }

          dispatch(IMGtourResponse(newArr));
        }
      }
    })
    .catch((err) => {
      dispatch({ type: GET_IMAGES_TOUR_ERR, error: err });
    });
};
