// ##### 24-06-12 Komarov
import React from "react";
import { RouteSwitcher } from "../../../RoutesAndLinks/RouteSwitcher";
import { SeoLogoIco } from "../../../../Library/SEO/SeoLogoIco";
import { SeoMetaTitle } from "../../../../Library/SEO/SeoMetaTitle";
import { Media } from "../../../../../helpers/MediaConfig";
import { MiddleMenuDiv } from "./MiddleMenuDiv";
import { HeaderClassDiv } from "./HeaderClassDiv";
import { SeoMetaKeywords } from "../../../../Library/SEO/SeoMetaKeywords";
import { SeoMetaDescription } from "../../../../Library/SEO/SeoMetaDescription";

export const HeaderWrapperMain = () => {
  return (
    <header className="wrapperMain" data-component="HeaderWrapperMain">
      <SeoLogoIco />
      <SeoMetaTitle />
      <SeoMetaKeywords />
      <SeoMetaDescription />
      {/* ##### 23-10-31 Komarov */}
      <Media lessThan="Laptop-1024-1440">
        <HeaderClassDiv headerClass="topMenuTabletPortrait" />
      </Media>
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <HeaderClassDiv headerClass="topMenu" />
      </Media>
      {/* ##### 23-10-31 Komarov */}
      <Media lessThan="Mobile-S-320-375">
        <MiddleMenuDiv
          // ##### 23-10-31 Komarov
          className="middleMenuSmallScreen middleMenuSmallScreenLandscapeMobile"
          logoWidth="25.5vw"
        />
      </Media>
      {/* ##### 23-10-31 Komarov */}
      <Media at="Mobile-S-320-375">
        <MiddleMenuDiv
          className="middleMenuSmallScreen middleMenuSmallScreenLandscapeMobile"
          logoWidth="19.5vw"
        />
      </Media>
      {/* ##### 23-10-31 Komarov */}
      <Media at="Mobile-M-375-425">
        <MiddleMenuDiv
          className="middleMenuSmallScreen middleMenuSmallScreenLandscapeMobile"
          logoWidth="19.5vw"
        />
      </Media>
      <Media at="Mobile-L-425-768">
        <MiddleMenuDiv
          className="middleMenuSmallScreen middleMenuSmallScreenLandscapeMobile"
          logoWidth="15vw"
        />
      </Media>
      {/* ##### 23-10-31 Komarov */}
      <Media at="Tablet-768-1024">
        <MiddleMenuDiv className="middleMenuSmallScreen" logoWidth="14.5vw" />
      </Media>
      {/* ##### 23-10-31 Komarov */}
      <Media at="Laptop-1024-1440">
        <MiddleMenuDiv className="middleMenu" logoWidth="13.5vw" />
      </Media>
      {/* ##### 23-10-31 Komarov */}
      <Media greaterThanOrEqual="Laptop-L-1440-2560">
        <MiddleMenuDiv className="middleMenu" logoWidth="13.5vw" />
      </Media>

      <RouteSwitcher />
    </header>
  );
};
