// ##### 25-02-12 Komarov
import uniqueId from "lodash.uniqueid";
import React from "react";
import { useSelector } from "react-redux";
// ##### 25-02-12 Komarov
import { LoadingMessage } from "../../../../Library/PageDevices/LoadingMessage";
import { HotelItem } from "./HotelItem";
// ##### 25-02-27 Komarov

// ##### 25-02-12 Komarov
export const HotelItemsList = ({ ulCLassName, width, liClassName }) => {
  const hotels_avail_loaded = useSelector(
    (state) => state.hotels.hotels_avail_loaded,
  );
  const hotels = useSelector((state) => state.hotels.hotels_avail.data.hotels);
  const hotelcontent2Hotels = useSelector(
    (state) => state.hotelcontent.hotelcontent2.data.hotels,
  );

  const showLoader = !hotels_avail_loaded;

  // ##### 24-04-05 Komarov
  return (
    <ul
      data-component="HotelItemsList"
      className={ulCLassName}
      style={{
        width: `${width}px`,
      }}
    >
      {/* ##### 25-02-12 Komarov */}
      {showLoader ? (
        <div>
          <LoadingMessage loadingMessageClass="RateLoading" />
        </div>
      ) : (
        Object.keys(hotels).map((hotel_id) => {
          // ##### 25-02-08 Komarov: Если в hotel значение notdispl установлено в отличное от 0 число, то не выводим этот отель
          // ##### 25-02-27 Komarov
          /* if (!!parseInt(hotels[hotel_id].notdispl)) {
              return;
            } */
          // TODO: Разный набор отелей в action'ах GetHotelAvailAlphaRQ и GetHotelContentAlphaRQ, что приводит к ошибкам на сайте.
          // Поэтому добавил фильтр, который отсекает (от показа из отелей, пришедших из Смарта по запросу на action "GetHotelAvailAlphaRS") отели, которые не содержатся в ответе на запрос к Смарту на action "GetHotelContentAlphaRS"
          if (!(hotel_id in hotelcontent2Hotels)) {
            return;
          }
          return (
            <HotelItem
              key={uniqueId()}
              className={liClassName}
              hotels={hotels}
              hotelId={hotel_id}
            />
          );
        })
      )}
    </ul>
  );
};
