import React from "react";
import { Media } from "../../../../../helpers/MediaConfig";
import { FrontHotelPaxResult } from "./FrontHotelPaxResult";
// ##### 24-05-13 Komarov
import { useDispatch } from "react-redux";
import { setPaxChoiceDivOpened } from "../../../../../Redux/actions";

// ##### 23-08-07 Komarov
export const ClickableLayer_03_PaxChoice = ({ width, height }) => {
  // ##### 24-05-13 Komarov
  const dispatch = useDispatch();
  const onPaxChoiceDivClick = (e) => {
    if (e.target.tagName === "BUTTON") {
      dispatch(setPaxChoiceDivOpened(false));
    } else {
      dispatch(setPaxChoiceDivOpened(true));
    }
    e.stopPropagation(); // Вызываем эту функцию, чтобы событие не отлавливалось дочерними компонентами, чтобы избежать его срабатвыания два раза подряд: и на div'е, и на целевом компоненте (в данном случае - Autocomplete).
  };

  return (
    <div
      data-component="ClickableLayer_03_PaxChoice"
      style={{
        border: "#196fbb",
        borderWidth: "0.2em",
        borderStyle: "solid",
        borderRadius: "1rem",
        width: width,
        height: height,
        // ##### 23-04-26 Komarov
        margin: "0.3vw",
      }}
      id="clickableLayer_03_PaxChoice"
      onClick={(e) => onPaxChoiceDivClick(e)}
      onKeyDown={(e) => {
        // Trigger click action on Enter key press
        if (e.key === "Enter") {
          onPaxChoiceDivClick(e);
        }
      }}
    >
      {/* ##### 23-11-02 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <FrontHotelPaxResult className="FrontHotelPaxResult" />
      </Media>
      <Media lessThan="Laptop-1024-1440">
        <FrontHotelPaxResult className="FrontHotelPaxResultSmallScreenOuterDiv" />
      </Media>
    </div>
  );
};
