// ##### 24-06-17 Komarov
import {
  FETCH_GALLERY_BEGIN,
  FETCH_GALLERY_FAILURE,
  FETCH_GALLERY_SUCCESS,
} from "../constants";

const initialState = {
  // items: [],
  items: {},
  loading: false,
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GALLERY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_GALLERY_SUCCESS:
      return {
        ...state,
        loading: false,
        // items: action.payload.galleryData
        items: {
          ...state.items,
          [action.payload.galleryData[0].id]: action.payload.galleryData,
        },
      };
    case FETCH_GALLERY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        // items: []
        items: {},
      };
    default:
      return state;
  }
}
