// ##### 24-03-21 Komarov
import React, { useEffect } from "react";
// ##### 24-02-28 Komarov
import axios from "axios";
import { useSelector } from "react-redux";
// ##### 25-01-14 Komarov
import { getPostObject } from "../../../helpers/crypto";

export const BookingRQ = () => {
  // ##### 24-02-28 Komarov
  const { user: currentUser } = useSelector((state) => state.auth);
  // ##### 25-01-13 Komarov
  const locale = useSelector((state) => state.language);
  const company_id = useSelector((state) => state.auth.user.company_id);

  // ##### 24-03-01 Komarov: TODO: Проверить использование этого файла: тут в объекте фиксированные данные
  // ##### 24-03-21 Komarov
  useEffect(() => {
    const data = {
      selection_period_start: "2022-01-25", // selection period start date, by default - today
      selection_period_end: "2022-02-10", // selection period end date, by default - "selection_period_start"
      getting_from: "cart", // "cart" - services from cart, "bookings" - booked services (by default), "all" - both services from cart and booked
      getting_clients: "no", // "yes" - with list of clients, "no" - without list of clients (by default)
      // buyer must be extracted in any case but only when "getting_clients": "yes"
      all_users: "no", // "yes" - services for all "user_id" of the "refpartner", "no" - services of the "user_id" only (by default)
      all_refpartners: "no", // "yes" - services for all "refpartner" of the "user_id", "no" - services of the "refpartner" only	(by default)
      all_services: "no", // "yes" - all services of Smart, "no" - services from Site only (by default)
    };

    const postObject = getPostObject({
      action: "GetBookingsRQ",
      user_id: currentUser.user_id,
      refpartner: company_id,
      language: locale,
      data,
    });

    axios.post(postObject.url, postObject.parameters);
    // ##### 24-03-21 Komarov
    /* .then(response => {
                setBookingRQ(response.data)
            }) */
  }, [currentUser.user_id]);

  return (
    <div data-component="BookingRQ">
      {/* ##### 23-10-06 Komarov: TODO: Может (, если этот текст нужен пользователю сайта,) взять перевод из Смарта? */}
      {/* BookingResponse */}
    </div>
  );
};
