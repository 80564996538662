// ##### 23-09-14 Komarov
import React from "react";
// ##### 23-04-12 Komarov
import uniqueId from "lodash.uniqueid";
import { Pax } from "../../../../Library/Icons/pax";
import { AvailableOptions } from "./AvailableOptions";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../../helpers/messages";
// ##### 24-08-05 Komarov
import { useSelector } from "react-redux";
// ##### 25-02-18 Komarov
import { ValidateQuery } from "../../../Helpers/helper";
import { useLocation } from "react-router-dom";

// ##### 24-11-02 Komarov
export const LargeScreenRatesBlock = () => {
  // ##### 24-11-02 Komarov
  /*
   * Примеры:
   *
   * available_now_hotel:
   * http://localhost:9090/uk/hotel_details/?selected_currency=UAH&start=2024-11-03&end=2024-11-04&title=Nadiya%20Hotel&id=173&city_id=40&adults=1&children=0&rooms=1&hotel_name=Nadiya%20Hotel&hotel_id=173
   *
   * available_now_hotel_dates_closed:
   * http://localhost:9090/uk/hotel_details/?selected_currency=UAH&cityName=%D0%9A%D0%B8%D1%97%D0%B2&start=2024-12-25&end=2024-12-28&id=145&city_id=19&adults=1&children=0&rooms=1&hotel_name=Manufactura%20Hotel&hotel_id=145
   *
   * Иначе:
   * http://localhost:9090/uk/hotel_details/?selected_currency=UAH&cityName=%D0%90%D0%B4%D1%80%D1%96%D1%8F%20%D0%9A%D0%B8%D1%97%D0%B2&start=2024-11-27&end=2024-11-28&id=196&city_id=19&adults=1&children=0&rooms=1&hotel_name=Adria%20Hotel%20Kyiv&hotel_id=196*/

  // ##### 25-02-15 Komarov
  const hotelrates2 = useSelector((state) => state.hotelrates.hotelrates2.data);

  // ##### 25-02-18 Komarov
  const location = useLocation();
  const shouldCypherUrlParams = useSelector(
    (state) => !!parseInt(state.siteCypherCodeData.data.value),
  );
  // ##### 25-02-28 Komarov
  const cypherKey = useSelector(
    (state) => state.siteCypherCodeData.data.description,
  );
  const search_data = ValidateQuery(location, shouldCypherUrlParams, cypherKey);
  // ##### 25-02-18 Komarov
  const hotel_id = search_data.hotel_id;
  const hotel_available = !!!parseInt(
    useSelector(
      (state) => state.hotels.hotels_avail.data.hotels[hotel_id].notavail,
    ),
  );

  // ##### 24-04-23 Komarov
  /*const hotelRateGridTitles = [
    useMessages("Room type"),
    useMessages("Pax"),
    useMessages("Tariff"),
    useMessages("TotalCostShort"),
    useMessages("AvailabilityShort"),
    useMessages("Book Now")
  ];

  const hotelOnRequestRatePricesGridTitles = [
    useMessages("Room type"),
    useMessages("Pax"),
    useMessages("Tariff"),
    useMessages("TotalCostShort"),
    useMessages("LeaveRequest")
  ];*/

  // ##### 24-08-05 Komarov
  const company_id = useSelector((state) => state.auth.user.company_id);

  // ##### 25-02-18 Komarov
  /*const tableTitles = (hotel_available && hotelRateGridTitles)
    || hotelOnRequestRatePricesGridTitles;

  const tableTitlesClassName = (hotel_available && "RatesGridWrapper")
    || "RatesGridWrapperOnRequestPrices";

  const tableTitlesClassNameNew = (hotel_available && "RatesGridWrapperNew")
    || "RatesGridWrapperOnRequestPrices";*/

  // ##### 25-02-20 Komarov
  let tableData = [
    {
      techData: {
        key: uniqueId(),
        nrf: "NRF",
        tariffsCount: "TariffsCount",
        header: true,
      },
      rowData: {
        room_type: useMessages("Room type"),
        pax: useMessages("Pax"),
        rat_name: useMessages("Tariff"),
        sum: useMessages("TotalCostShort"),
        available_now: useMessages("AvailabilityShort"),
        book_now: useMessages("Book Now"),
      },
    },
  ];
  Object.keys(hotelrates2.prices.rooms).map((room_id) => {
    const pricesRoom = hotelrates2.prices.rooms[room_id];
    return Object.keys(pricesRoom.tariffs).map((tariff_id) => {
      const tariff = pricesRoom.tariffs[tariff_id];
      return Object.keys(tariff.paxes).map((paxNum) => {
        const data =
          hotelrates2.prices.rooms[room_id].tariffs[tariff_id].paxes[paxNum];
        const rat_name = hotelrates2.tariffs[tariff_id].rat_name;
        const nrf = hotelrates2.tariffs[tariff_id].nrf;
        const tariffsCount = Object.keys(hotelrates2.tariffs).length;
        const available_now = !!!parseInt(data.notavail);
        const sum = data.sum;
        tableData.push({
          techData: {
            key: uniqueId(),
            nrf,
            tariffsCount,
            room_id,
            tariff_id,
            allotm: data.allotm,
          },
          rowData: {
            room_type: hotelrates2.rooms[room_id].ro_name,
            pax: paxNum,
            rat_name,
            sum,
            available_now,
            book_now: hotel_available && available_now,
          },
        });
      });
    });
  });

  // ##### 24-11-02 Komarov
  return (
    // ##### 23-11-08 Komarov
    <div data-component="LargeScreenRatesBlock">
      {/* ##### 25-02-20 Komarov */}
      <div>
        <div>
          {tableData.map((row) => {
            if (row.techData.header) {
              return (
                <div
                  key={uniqueId()}
                  className="DetailsNew HeaderNew"
                  // className="RatesGridHeaderNew"
                >
                  <h5
                    // className="RoomTypeColumnNew RoomTypeCell"
                    style={{
                      gridRow: `1 / span ${row.techData.tariffsCount}`,
                      borderBottom: "2px solid rgb(109, 109, 196)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "17px",
                      color: "#102D69",
                      fontFamily: "Arial",
                      fontWeight: "bold",
                      marginBottom: 0,
                    }}
                  >
                    {useMessages("Room type")}
                  </h5>
                  <h5
                    key={uniqueId()}
                    style={{
                      gridColumn: "2",
                      borderBottom: "2px solid rgb(109, 109, 196)",
                      borderRight: "2px solid rgb(109, 109, 196)",
                      borderLeft: "2px solid rgb(109, 109, 196)",
                      fontSize: "17px",
                      color: "#102D69",
                      fontFamily: "Arial",
                      fontWeight: "bold",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 0,
                    }}
                  >
                    {useMessages("Pax")}
                  </h5>
                  <div
                    style={{
                      gridColumn: "3",
                      borderBottom: "2px solid rgb(109, 109, 196)",
                      borderRight: "2px solid rgb(109, 109, 196)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h5
                      key={uniqueId()}
                      style={{
                        fontSize: "17px",
                        color: "#102D69",
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        marginBottom: 0,
                      }}
                    >
                      {useMessages("Tariff")}
                    </h5>
                  </div>
                  <div
                    className={
                      (hotel_available && "WrapperAvailableOptions") ||
                      // TODO: Проверить, бывают ли сейчас отели с вариантом совсем без цен, для которых нужны всего три (примерно) заголовка в таблице с ценами
                      /*(row.rowData.sum === 0 && "WrapperAvailableOptionsOnRequestWithoutPrice")
                        ||*/
                      "WrapperAvailableOptionsOnRequest"
                    }
                  >
                    <h5
                      style={{
                        fontSize: "17px",
                        color: "#102D69",
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        marginBottom: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#a3caf1",
                      }}
                    >
                      {useMessages("TotalCostShort")}
                    </h5>
                    {hotel_available && (
                      <h5
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#a3caf1",
                          fontSize: "17px",
                          color: "#102D69",
                          fontFamily: "Arial",
                          fontWeight: "bold",
                          marginBottom: 0,
                        }}
                      >
                        {useMessages("AvailabilityShort")}
                      </h5>
                    )}
                    <h5
                      style={{
                        // borderLeft: sum !== 0 && "2px solid rgb(109, 109, 196)",
                        fontSize: "17px",
                        color: "#102D69",
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        marginBottom: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderLeft: "2px solid rgb(109, 109, 196)",
                      }}
                    >
                      {useMessages("Book Now")}
                    </h5>
                  </div>
                </div>
              );
            }
            return (
              <div key={uniqueId()} className="DetailsNew">
                <h5
                  className="RoomTypeColumnNew RoomTypeCell"
                  style={{
                    gridRow: `1 / span ${row.techData.tariffsCount}`,
                    marginBottom: 0,
                  }}
                >
                  {(row.rowData.room_type === "0" && "Regular") ||
                    row.rowData.room_type}
                </h5>
                <h5
                  key={uniqueId()}
                  style={{
                    gridColumn: "2",
                    borderBottom: "2px solid rgb(109, 109, 196)",
                    borderRight: "2px solid rgb(109, 109, 196)",
                    borderLeft: "2px solid rgb(109, 109, 196)",
                    fontSize: "17px",
                    color: "#102D69",
                    fontFamily: "Arial",
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 0,
                  }}
                >
                  {
                    // ##### 25-02-18 Komarov: отображаем повторно иконку человечка (Pax), основываясь на числе (paxNum)
                    Array.from("1".repeat(row.rowData.pax)).map(() => {
                      return <Pax key={uniqueId()} />;
                    })
                  }
                </h5>
                <div
                  style={{
                    gridColumn: "3",
                    borderBottom: "2px solid rgb(109, 109, 196)",
                    borderRight: "2px solid rgb(109, 109, 196)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h5
                    key={uniqueId()}
                    style={{
                      fontSize: "17px",
                      color: "blue",
                      fontFamily: "Arial",
                      fontWeight: "bold",
                      marginBottom: 0,
                    }}
                  >
                    <span>{row.rowData.rat_name}</span>
                    <span
                      style={{
                        color: "darkred",
                        marginLeft: "5px",
                        fontStyle: "italic",
                      }}
                    >
                      {parseInt(row.techData.nrf) === parseInt("1")
                        ? "Non Refundable"
                        : null}
                    </span>
                  </h5>
                </div>
                <div
                  className={
                    (row.rowData.available_now && "WrapperAvailableOptions") ||
                    (row.rowData.sum === 0 &&
                      "WrapperAvailableOptionsOnRequestWithoutPrice") ||
                    "WrapperAvailableOptionsOnRequest"
                  }
                >
                  <AvailableOptions
                    sum={row.rowData.sum}
                    nrf={row.techData.nrf}
                    rooms={search_data.rooms}
                    room_id={row.techData.room_id}
                    room_name={row.rowData.room_type}
                    tariff_type_name={row.rowData.rat_name}
                    tariff_id={row.techData.tariff_id}
                    availability={parseInt(row.techData.allotm)}
                    // ##### 24-08-05 Komarov: TODO: Исправить код в соответствии с задачей убрать Промокод из URL
                    refpartner={company_id}
                    start={search_data.start}
                    end={search_data.end}
                    // ##### 24-04-05 Komarov: Так как в эту ветку кода попадают целиком отели, у которых нет доступных на продажу с аллотмента номеров, то здесь и указываем значение "available_now_hotel"
                    available_now={row.rowData.available_now}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
