// ##### 23-03-29 Komarov
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHotelRates } from "../../../../../Redux/actions";
import { LargeScreenRatesBlock } from "./LargeScreenRatesBlock";
import { SmallScreenRatesBlock } from "./SmallScreenRatesBlock";
// ##### 23-11-08 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
// ##### 24-06-10 Komarov
import { CupOfCoffee } from "../../../../Library/PageDevices/Animation/CupOfCoffee";

// ##### 23-09-14 Komarov
// import './RatesBlockCSS.css'

export const RatesBlock = ({ search_data }) => {
  const dispatch = useDispatch();
  // ##### 25-02-18 Komarov
  const hotelrates_loaded2 = useSelector(
    (state) => state.hotelrates.hotelrates_loaded2,
  );
  const hotels_avail_loaded = useSelector(
    (state) => state.hotels.hotels_avail_loaded,
  );
  const { user: currentUser } = useSelector((state) => state.auth);
  const userId = currentUser.user_id;
  // ##### 25-01-13 Komarov
  const locale = useSelector((state) => state.language);
  // ##### 23-11-07 Komarov
  const sale =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).sale;

  // ##### 24-03-01 Komarov
  const company_cipher = useSelector(
    (state) => state.whitelabel.company_cipher,
  );

  // ##### 24-05-24 Komarov
  const deviceType = useSelector((state) => state.deviceType);
  // ##### 24-08-05 Komarov
  const company_id = useSelector((state) => state.auth.user.company_id);
  const promocode = useSelector((state) => state.promocode.promocode);

  useEffect(() => {
    // ##### 23-06-30 Komarov: Убрал запрос цен в Смарте при отсутствии даты заезда в объекте search_data
    // TODO: Поставить тут запрос цен на новый action Smart
    // ##### 25-01-13 Komarov
    search_data.start &&
      dispatch(
        getHotelRates({
          search_data,
          userId,
          sale,
          company_cipher,
          deviceType,
          refpartner: company_id,
          promocode,
          locale,
        }),
      );
    // ##### 25-02-18 Komarov
  }, [
    search_data.start,
    search_data.end,
    search_data.adults,
    search_data.children,
    search_data.rooms,
    search_data.hotel_name,
    search_data.hotel_id,
    search_data.selected_currency,
    search_data.cityName,
    search_data.city_id,
    dispatch,
    userId,
    sale,
    company_cipher,
    deviceType,
  ]);

  // ##### 23-03-01 Komarov

  return (
    <div data-component="RatesBlock">
      {/* ##### 23-11-08 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        {/*##### 25-02-18 Komarov */}
        {(hotelrates_loaded2 && hotels_avail_loaded && (
          <LargeScreenRatesBlock />
        )) || <CupOfCoffee />}
      </Media>
      <Media lessThan="Laptop-1024-1440">
        {/* ##### 25-02-18 Komarov */}
        {(hotelrates_loaded2 && hotels_avail_loaded && (
          <SmallScreenRatesBlock />
        )) || <CupOfCoffee />}
      </Media>
    </div>
  );
};
