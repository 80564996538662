import axios from "../helpers/public.axios";

import {
  GET_CONTENT_REQ,
  GET_CONTENT_RES,
  GET_CONTENT_ERR,
} from "../constants";

//action creator
export const contentResponse = (res) => ({
  type: GET_CONTENT_RES,
  payload: res,
});

// console.log('[CONTENTRESPONCE]' , contentResponse)

// ##### 24-03-21 Komarov
export const getContent = (id, locale) => (dispatch) => {
  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/content.js:getContent");

  // console.log('GET_STATE:', getState())
  dispatch({ type: GET_CONTENT_REQ });

  // console.log('[GET_POSTS]' , id)
  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/content.js:getContent, url:",
      `${process.env.REACT_APP_SMART_URL}interface/content?id=${id}&language=${locale}`
    );
  axios
    .get(
      `${process.env.REACT_APP_SMART_URL}interface/content?id=${id}&language=${locale}`
    )
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      dispatch(contentResponse(await res.data));
      // console.log('[GET_CONTENT]:' , res.data)
    })

    .catch((err) => {
      dispatch({ type: GET_CONTENT_ERR, error: err });
    });
};
