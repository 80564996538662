// ##### 23-12-27 Komarov
import parse from "html-react-parser";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { login } from "../../../Redux/actions/auth";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../helpers/messages";

const required = (value) => {
  if (!value) {
    // ##### 24-08-05 Komarov: TODO: взять перевод из Смарта
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Login = (props) => {
  // ##### 24-04-23 Komarov
  const Username = useMessages("username");
  const Password = useMessages("password");
  const sign_in = useMessages("sign_in");
  const NoAccountQuestion = useMessages("NoAccountQuestion");
  const Register = useMessages("Register");
  const form = useRef();
  const checkBtn = useRef();

  const { text } = props;
  console.log("TEXT", props);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  // ##### 23-09-26 Komarov

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(username, password))
        .then(() => {
          props.history.push("/");
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  if (isLoggedIn) {
    // return <Redirect to="/profile" />;
    // TODO: Когда пользователь залогинен, переадресовать его на ту же страницу, на которой он был до этого
    return <Redirect to="/" />;
  }

  return (
    <div data-component="Login">
      <h3>{parse(text)}</h3>
      <div className="col-md-12">
        <div
          className="card card-container"
          style={{ backgroundColor: "#f7f7f7" }}
        >
          <img
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            alt="profile-img"
            className="profile-img-card"
          />

          <Form onSubmit={handleLogin} ref={form}>
            <div>
              <label
                // ##### 24-04-16 Komarov
                className="login-label"
                htmlFor="username"
              >
                {
                  // ##### 23-10-04 Komarov
                  Username
                }

                <sup>*</sup>
              </label>
              <Input
                type="text"
                className="form-control"
                name="username"
                value={username}
                onChange={onChangeUsername}
                validations={[required]}
              />
            </div>
            <div>
              <label
                // ##### 24-04-16 Komarov
                className="login-label"
                htmlFor="password"
              >
                {
                  // ##### 23-10-04 Komarov
                  Password
                }
                <sup>*</sup>
              </label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={password}
                onChange={onChangePassword}
                validations={[required]}
              />
            </div>
            <div>
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={loading}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm" />
                )}
                <span style={{ color: "white", fontWeight: "bold" }}>
                  {
                    // ##### 23-10-04 Komarov
                    sign_in
                  }
                </span>
              </button>
            </div>
            <div>
              {
                // ##### 23-10-04 Komarov
                NoAccountQuestion
              }
            </div>
            <div>
              <Link to={`/${locale}/sign_up`}>
                {
                  // ##### 23-10-04 Komarov
                  Register
                }
              </Link>
            </div>

            {message && (
              <div>
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
