import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
} from "../actions/types";

//
// const IS_NODE = typeof global === 'object' && '[object global]' === global.toString.call(global);
const IS_BROWSER =
  typeof window === "object" &&
  "[object Window]" === window.toString.call(window);

const notRegisteredUser = {
  user_id: 1426,
  company_id: 1361,
  company_name: "hotelsukraine.com.ua",
};

const user = IS_BROWSER ? JSON.parse(localStorage.getItem("user")) : undefined;

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: notRegisteredUser };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      // При успешном входе пользоватзеля в систему запоминаем его в localStorage
      localStorage.setItem("user", JSON.stringify(payload.user));
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: notRegisteredUser,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: notRegisteredUser,
      };
    default:
      return state;
  }
}
