// ##### 24-07-25 Komarov
import { SET_PROMOCODE_REQ, SET_PROMOCODE_RES } from "../constants";

export const promocodeResponse = (res) => ({
  type: SET_PROMOCODE_RES,
  payload: res,
});
// ##### 24-07-25 Komarov
export const setPromoCode = (code) => (dispatch) => {
  dispatch({ type: SET_PROMOCODE_REQ });

  dispatch(promocodeResponse(code));
};
