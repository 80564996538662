// ##### 24-09-09 Komarov
import React, { createContext, useMemo } from "react";
import { SmallScreenTopHotelsDiv } from "./SmallScreenTopHotelsDiv";

// ##### 24-09-09 Komarov
export const SmallScreenTopHotels = ({ TopHotelContents, GetHotelDetails }) => {
  const smallScreenTopHotelsContextValue = useMemo(
    () => ({
      TopHotelContents,
      GetHotelDetails,
    }),
    [
      // ##### 24-09-11 Komarov
      TopHotelContents,
      GetHotelDetails,
    ],
  );
  return (
    <SmallScreenTopHotelsContext.Provider
      value={smallScreenTopHotelsContextValue}
    >
      <SmallScreenTopHotelsDiv width="100vw" className="lessThanOrEqual_425" />
    </SmallScreenTopHotelsContext.Provider>
  );
}; // ##### 23-10-10 Komarov

export const SmallScreenTopHotelsContext = createContext();
