import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  SET_MESSAGE,
} from "./types";

import AuthService from "../services/auth.service";

// ##### 23-10-27 Komarov
export const register =
  (firstName, lastName, username, email, password, promocode, locale) =>
  (dispatch) => {
    return AuthService.register(
      firstName,
      lastName,
      username,
      email,
      password,
      promocode,
      locale,
    ).then(
      (response) => {
        dispatch({
          type: REGISTER_SUCCESS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        return Promise.resolve();
      },
      (error) => {
        // ##### 24-03-21 Komarov
        const message =
          error.response?.data?.message || error.message || error.toString();

        dispatch({
          type: REGISTER_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      },
    );
  };

// ##### 23-12-28 Komarov
export const loggedIn = (user) => (dispatch) => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload: { user },
  });
};

export const login = (username, password) => (dispatch) => {
  return AuthService.login(username, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      // ##### 24-03-21 Komarov
      const message =
        error.response?.data?.message || error.message || error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    },
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};
