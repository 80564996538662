import React from "react";
// ##### 24-05-10 Komarov
import { useMessages } from "../../../../../helpers/messages";

// ##### 23-12-11 Komarov
export const ClickableLayer_01_CityOrHotel_Alert = ({ width, height }) => {
  const ChooseYourHotel = useMessages("ChooseYourHotel");

  return (
    <div
      data-component="ClickableLayer_01_CityOrHotel_Alert"
      id="ClickableLayer_01_CityOrHotel_Alert"
      style={{
        gridColumn: "1",
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-around",
        width: width,
        height: height,
        border: "red",
        borderWidth: "0.2em",
        borderStyle: "solid",
        borderRadius: "1rem",
        fontFamily: "Arial",
        alignItems: "center",
        margin: "0.3vw",
      }}
    >
      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
          marginTop: "0.25em",
          color: "red",
        }}
      >
        {ChooseYourHotel}
      </div>
    </div>
  );
};
