// ##### 24-09-09 Komarov
import React, { useState } from "react";
import { IconHint } from "./iconHint";
// ##### 23-10-31 Komarov
import { Media } from "../../../helpers/MediaConfig";

const SvgTag = ({
  style = null,
  className = "",
  setIsHintShown,
  setY,
  // screenWidthClassName = ''
}) => {
  // ##### 23-10-17 Komarov: TODO: оригинальное условие: если нету цвета, то и подсказка не покажется :) Нужно разобраться в этом коде и переписать его правильно.
  const onMouseEnter = (e) => {
    setIsHintShown(true);
    setY(e.clientY + 10);
  };

  // ##### 24-08-16 Komarov
  const onMouseLeave = () => {
    setIsHintShown(false);
    setY(undefined);
  };

  return (
    <svg
      aria-hidden="true"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={style}
      focusable="false"
      data-prefix="fas"
      data-icon="phone-square"
      // ##### 23-10-31 Komarov
      className={`svg-inline--fa fa-phone-square fa-w-14 ${className}`}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="64 64 896 896"
    >
      <path
        fill="currentColor"
        d="M946.5 505L560.1 118.8l-25.9-25.9a31.5 31.5 0 00-44.4 0L77.5 505a63.9 63.9 0 00-18.8 46c.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8a63.6 63.6 0 0018.7-45.3c0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204zm217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z"
      />
    </svg>
  );
};

export const HomeIcon = ({ hintText }) => {
  const [isHintShown, setIsHintShown] = useState(false);
  const [y, setY] = useState(undefined);

  return (
    <div
      data-component="HomeIcon"
      style={{
        position: "relative",
        zIndex: 2001,
      }}
    >
      {/* ##### 23-10-31 Komarov */}
      <Media lessThan="Laptop-1024-1440">
        <SvgTag
          style={{ width: "7.5vw" }}
          className="HomeIconMobile"
          setIsHintShown={setIsHintShown}
          setY={setY}
        />
      </Media>
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <SvgTag
          style={{ height: "2.2vw" }}
          setIsHintShown={setIsHintShown}
          setY={setY}
        />
      </Media>
      <IconHint isHintShown={isHintShown} hintText={hintText} y={y} />
    </div>
  );
};
