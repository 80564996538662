export const TourRateGridTitles = (locale) => {
  // ##### 24-04-13 Komarov: TODO: Взять переводы из Смарта
  const obj = {
    en: [
      "Tour Name",
      "Tour Type",
      "Cost per person",
      "",
      "Amount of travellers",
      "",
      "Total Cost",
      "",
    ],
    uk: [
      "Назва Туру",
      "Тип Туру",
      "Вартість за особу",
      "",
      "К-сть осіб",
      "",
      "Загальна Вартість",
      "",
    ],
    ru: [
      "Название Тура",
      "Тип Тура",
      "Стоимость за чел.",
      "",
      "К-во чел.",
      "",
      "Общая Сумма",
      "",
    ],
    tr: [
      "Tur Ismi",
      "Tur Tipi",
      "Kişi Fiyatı",
      "",
      "Kişi Sayısı",
      "",
      "Toplam Fiyatı",
      "",
    ],
  };

  // ##### 24-03-21 Komarov
  for (const key in obj) {
    if (key === locale) return obj[key];
  }
  // console.log('AAA_KATE_KATE',obj)
};
