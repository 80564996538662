// ##### 24-05-21 Komarov
import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  // ##### 24-09-09 Komarov
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { ToursAutocomplete } from "../ToursAutocompleteBlock/ToursAutocomplete";
import { HotelsAutocomplete } from "../HotelsAutocompleteBlock/HotelsAutocomplete/HotelsAutocomplete";
import { Switcher, SwitcherItem } from "./Switcher";
import { Hotels } from "../../../Library/Icons/hotels";
import { getTogglerType } from "../../../../Redux/actions/tophotels";

// ##### 23-09-14 Komarov
// import './Search.css';
// import './SwitcherFront.css';
// import 'antd/dist/antd.css';
import config from "../../../../Redux/config";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages";

moment.locale(config.defaultLang);

// ##### 23-10-10 Komarov: TODO: параметры функции не передаются сюда, так есть ли в них смысл?..
// ##### 24-05-21 Komarov
const SwitcherTag = () => {
  const {
    toggler,
    align,
    hotels,
    // ##### 24-03-21 Komarov
    // tours
  } = useContext(LargeScreenSearchContext);
  // ##### 24-05-10 Komarov
  const deviceType = useSelector((state) => state.deviceType);
  // ##### 24-05-21 Komarov
  return (
    <Switcher name={"align"} changeHandler={toggler} active={align}>
      {/* ##### 23-04-01 Komarov */}
      <SwitcherItem value="HOTELS">
        {Hotels(deviceType)}
        <h4>
          {
            // ##### 23-10-04 Komarov
            hotels
          }
        </h4>
      </SwitcherItem>
      {/* ##### 24-01-12 Komarov */}
      {/* <SwitcherItem value='TOURS'><Tours deviceType={deviceType} /><h4>
      {
        // ##### 23-10-04 Komarov
        tours
      }
    </h4>
    </SwitcherItem> */}
    </Switcher>
  );
};

// ##### 24-05-21 Komarov
export const LargeScreenSearchContext = createContext();

// ##### 24-03-21 Komarov
export const LargeScreenSearch = ({
  formClass,
  datepickerClass,
  searchProps,
}) => {
  // ##### 24-04-23 Komarov
  const hotels = useMessages("Hotels");
  const tours = useMessages("Tours");

  const dispatch = useDispatch();
  // const [list , setList] = useState([]);
  // ##### 23-11-10 Komarov
  // const [generalValue, setGeneralValue] = useState('');
  const [align, setAlign] = useState("HOTELS");
  // const [generalList, setGeneralList] = useState([])

  useEffect(() => {
    dispatch(getTogglerType(align));
    // ##### 23-04-12 Komarov
  }, [align, dispatch]);

  const toggler = (me) => (_) => {
    setAlign(me);
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  // ##### 24-09-09 Komarov
  const largeScreenSearchContextValue = useMemo(() => ({
    toggler,
    align,
    hotels,
    tours,
  }), [
    // ##### 24-09-11 Komarov
    toggler,
    align,
    hotels,
    tours,
  ]); // value is cached by useMemo

  // ##### 24-05-21 Komarov
  return (
    <LargeScreenSearchContext.Provider
      value={largeScreenSearchContextValue}
    >
      <div
        data-component="LargeScreenSearch"
        // ##### 23-11-10 Komarov
        className="largeScreenSearch"
      // onClick={paxListOpen ? MakeVisible : null}
      >
        {/* ##### 23-11-02 Komarov */}
        {/* <div className='greaterThanOrEqual-tabletMaxWidth_960'>
        <SwitcherTag />
      </div> */}
        {/* <Media greaterThanOrEqual='Laptop-1024-1440'>
        <SwitcherTag />
      </Media> */}
        <SwitcherTag />

        <>
          {align === "TOURS" ? (
            <>
              <ToursAutocomplete
                formClass={formClass}
                onSubmit={onSubmit}
                datepickerClass={datepickerClass}
                // ##### 23-11-10 Komarov
                // GeneralListFunction={GeneralListFunction}
                searchProps={searchProps}
              />

              {/* ##### 23-11-10 Komarov  */}
              {/* <>
              {
                generalList.length > 0 && <GuestItem
                // title={generalValue}
                // list={generalList}
                />
              }
            </> */}
            </>
          ) : (
            <>
              <HotelsAutocomplete
                formClass={formClass}
                // ##### 24-09-10 Komarov
                // onSubmit={onSubmit}
                datepickerClass={datepickerClass}
                // ##### 23-11-10 Komarov
                // GeneralListFunction={GeneralListFunction}
                searchProps={searchProps}
              />
              {/* ##### 23-11-10 Komarov */}
              {/* <>
                {
                  generalList.length > 0 && <HotelItems
                  // title={generalValue}
                  // list={generalList}
                  />
                }
              </> */}
            </>
          )}
        </>
      </div>
    </LargeScreenSearchContext.Provider>
  );
};
