import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// import './index.css';
import App from "./App";
import store from "./Redux/redux/store";
import reportWebVitals from "./reportWebVitals";
// ##### 23-09-14 Komarov: Подключаем все CSS приложения
import "./css";
// ##### 23-10-18 Komarov
import { MediaContextProvider } from "./helpers/MediaConfig";

// ##### 24-07-26 Komarov: Удаляем страницы purePage из localStorage
function removeItemsByRegex(regex) {
  // Получаем все ключи из localStorage
  const keys = Object.keys(localStorage);

  // Итерируем по ключам и удаляем соответствующие элементы
  keys.forEach((key) => {
    if (regex.test(key)) {
      localStorage.removeItem(key);
    }
  });
}

// Пример использования:
const regex = /^\d+:\d+:[a-z]{2}$/; // То же регулярное выражение, что и в предыдущем примере
removeItemsByRegex(regex);

// ##### 24-03-21 Komarov
const isMarkupPresent = document.getElementById("root").hasChildNodes();

// ##### 23-10-18 Komarov
isMarkupPresent
  ? ReactDOM.hydrate(
      <React.StrictMode>
        <Provider store={store}>
          <MediaContextProvider>
            <App />
          </MediaContextProvider>
        </Provider>
      </React.StrictMode>,
      document.getElementById("root"),
    )
  : ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <MediaContextProvider>
            <App />
          </MediaContextProvider>
        </Provider>
      </React.StrictMode>,
      document.getElementById("root"),
    );

/* ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
); */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
