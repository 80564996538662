// ##### 25-02-15 Komarov
import { ContentBlock } from "./ContentBlock/ContentBlock";
import { InnerSearchBlock } from "./InnerSearchBlock/InnerSearchBlock";
import React from "react";
import { useSelector } from "react-redux";

export const HotelDetailsContent = (props) => {
  const hotelId = Object.keys(
    useSelector((state) => state.hotelcontent.hotelcontent3.data.hotels),
  )[0];
  const hotelTitle = useSelector(
    (state) => state.hotelcontent.hotelcontent3.data.hotels[hotelId].ho_title,
  );
  return (
    <>
      <h2>
        {/* ##### 25-02-14 Komarov: если у отеля нет перевода названия (title), то отображаем его системное имя (name) */}
        {hotelTitle || props.hotelName}, {props.cityName}
      </h2>

      <ContentBlock />

      <h3
        style={{
          fontFamily: "Arial",
          color: "#102D69",
          fontSize: "24px",
          fontWeight: "bold",
          marginTop: "3vh",
        }}
      >
        {
          // ##### 23-10-04 Komarov
          props.availability
        }
      </h3>

      <InnerSearchBlock
        search_data={props.searchData}
        hotelName={props.hotelName}
      />
    </>
  );
};
