import {
  GET_TOGGLER_TYPE_ERR,
  GET_TOGGLER_TYPE_REQ,
  GET_TOGGLER_TYPE_RES,
  GET_TOPHOTELS_ERR,
  GET_TOPHOTELS_REQ,
  GET_TOPHOTELS_RES,
} from "../constants";

const initState = {
  tophotels: [],
  tophotels_loaded: false,
  errors: [],

  toggler_type: "",
  toggler_loaded: false,
  toggler_errors: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_TOPHOTELS_REQ:
      return {
        ...state,
        tophotels_loaded: false,
      };

    case GET_TOPHOTELS_RES:
      return {
        ...state,
        tophotels_loaded: true,
        tophotels: action.payload,
      };

    case GET_TOPHOTELS_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    case GET_TOGGLER_TYPE_REQ:
      return {
        ...state,
        toggler_loaded: false,
      };

    case GET_TOGGLER_TYPE_RES:
      return {
        ...state,
        toggler_loaded: true,
        toggler_type: action.payload,
      };

    case GET_TOGGLER_TYPE_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        toggler_errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    default:
      return state;
  }
};

export default reducer;
