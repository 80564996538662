// ##### 24-09-09 Komarov
import React, { useContext, useEffect, createContext, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// ##### 23-03-29 Komarov
import { useLocation } from "react-router-dom";
// ##### 23-04-12 Komarov
import uniqueId from "lodash.uniqueid";
import { getGeneralHotels } from "../../../../Redux/actions/hotels";
// ##### 23-03-29 Komarov
import { getHotelCities } from "../../../../Redux/actions/hotelcities";
// ##### 24-06-14 Komarov
import { HotelContent } from "../../SecondPageofSearchModule/Hotels/HotelContent/HotelContent";
import { ValidateQuery } from "../../Helpers/helper";
import { HotelBookButton } from "../HotelBookButton/HotelBookButton";
import { LoadingMessage } from "../../../Library/PageDevices/LoadingMessage";
// ##### 23-09-26 Komarov
import { WhiteHotels } from "../../../Library/Icons/hotels";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages";
// ##### 23-11-08 Komarov
import { Media } from "../../../../helpers/MediaConfig";

// ##### 23-09-14 Komarov
// import './CityHotelsCSS.css'

// ##### 24-05-29 Komarov
const CityHotelsDiv = ({ className1, className2 }) => {
  const { filteredCities, citiesList, See_Availability, filteredLinkCityName } =
    useContext(CityHotelsContext);
  // ##### 24-05-29 Komarov: Берём название страницы исходя из текста ссылки в Footer, по которой кликнули.
  const footerPagesInfo = useSelector((state) => state.sitePageListSection);
  const [footerPageTitle, setFooterPageTitle] = useState();
  const location = useLocation();
  // ##### 24-05-31 Komarov
  const pageTitle = footerPagesInfo.find((pageInfo) =>
    `${location.pathname}${location.search}`.includes(pageInfo.urlPart)
  )?.title;
  useEffect(() => {
    // ##### 24-05-31 Komarov
    pageTitle && setFooterPageTitle(pageTitle);
  }, [pageTitle]);
  return (
    <div
      data-component="CityHotelsDiv"
      className={className1}
      // ##### 23-04-10 Komarov: Подвинул выше блок с сообщением о загрузке, чтобы он не накладывался на Footer
      // ##### 23-06-22 Komarov
      style={
        !filteredCities || filteredCities.length === 0
          ? { height: "250px" }
          : null
      }
    >
      {!citiesList || citiesList.length === 0 ? (
        // ##### 23-09-08 Komarov
        <LoadingMessage loadingMessageClass="RateLoading" />
      ) : (
        // ##### 24-05-31 Komarov
        /* !filteredCities || filteredCities.length === 0 ? (
        // ##### 23-09-08 Komarov
        <LoadingMessage
          loadingMessageClass="RateLoading"
          // ##### 23-09-01 Komarov
          smartResultFound="Hi!"
        />
      ) : */ <>
          <h2>{footerPageTitle && footerPageTitle.toUpperCase()}</h2>
          <>
            {/* ##### 24-05-31 Komarov */}
            {filteredCities && filteredCities.length > 0 ? (
              filteredCities.map((filtered_hotel) => {
                return (
                  // ##### 23-04-12 Komarov
                  <div
                    key={uniqueId()}
                    className={
                      // `${width > desktopMinWidth_1024 ? 'HotelContentWrapper' : 'HotelContentWrapperSmallScreen'}`
                      className2
                    }
                  >
                    <HotelContent hotel={filtered_hotel} />
                    <HotelBookButton
                      innerText={
                        // ##### 23-10-04 Komarov
                        See_Availability
                      }
                      hotel={filtered_hotel}
                      hotelsearch={location}
                    />
                  </div>
                );
              })
            ) : (
              // ##### 23-10-10 Komarov: TODO: Взять перевод из Смарта
              <h3>
                Sorry, we do not have hotels available in{" "}
                {filteredLinkCityName.toUpperCase()} at the moment
              </h3>
            )}
          </>
        </>
      )}
    </div>
  );
};

const CityHotelsContext = createContext();

export const CityHotels = () => {
  // ##### 24-04-23 Komarov
  const See_Availability = useMessages("See Availability");
  // ##### 24-03-21 Komarov
  const location = useLocation();

  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  // const [loaded,setLoaded] = useState(false)

  const search_details = ValidateQuery(location);
  // console.log('LOCATION_SEARCH',search_details)

  const filteredLinkCityName = search_details.name
    .substring(0, search_details.name.indexOf("-Hotels".toLowerCase()))
    .replace(/\//g, "");
  // console.log('TESTPATH',filteredLinkCityName)

  const dispatch = useDispatch();

  const citiesList = useSelector((state) => state.hotelcities.hotel_cities);
  // ##### 24-03-21 Komarov
  // const deviceType = useSelector(state => state.deviceType);

  useEffect(() => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/PageComponents/Footer/CityHotels.js:getGeneralHotels()"
      );
    dispatch(getGeneralHotels());
    //  ##### 23-04-12 Komarov
  }, [dispatch]);

  // console.log('HOTELLIST',general_smart_hotels)

  /* useEffect ( () => {
    dispatch (getHotelCities(locale))
  }) */

  // 22-08-22 Komarov (,[locale]) Добавил в массив зависимостей переменную locale, чтобы не было цикливания запросов
  useEffect(() => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/PageComponents/Footer/CityHotels.js:getHotelCities(locale)"
      );
    dispatch(getHotelCities(locale));
    // ##### 23-04-12 Komarov
  }, [locale, dispatch]);
  // console.log('CITIES',citiesList)

  // ##### 23-04-10 Komarov: убрал отображение отеля, у которого нет перевода описания
  const filteredCities = citiesList.filter((hotel) => {
    return (
      hotel.eng_city_name
        .toLowerCase()
        .includes(filteredLinkCityName.toLowerCase()) === true &&
      hotel.localized_hotel_name.trim().length > 0
    );
  });

  // ##### 24-09-09 Komarov
  const cityHotelsContextValue = useMemo(() => ({
    filteredCities,
    citiesList,
    See_Availability,
    filteredLinkCityName,
  }), [
    // ##### 24-09-11 Komarov
    filteredCities,
    citiesList,
    See_Availability,
    filteredLinkCityName,
  ]);

  return (
    <CityHotelsContext.Provider
      value={cityHotelsContextValue}
    >
      {/* ##### 23-11-08 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <CityHotelsDiv
          className1="HotelFooterPages"
          className2="HotelContentWrapper"
        />
      </Media>
      {/* ##### 23-11-13 Komarov */}
      <Media between={["Tablet-768-1024", "Laptop-1024-1440"]}>
        <CityHotelsDiv
          className1="HotelFooterPages"
          className2="HotelContentWrapperMediumScreen"
        />
      </Media>
      <Media lessThan="Tablet-768-1024">
        <CityHotelsDiv
          className1="HotelFooterPagesSmallScreen"
          className2="HotelContentWrapperSmallScreen"
        />
      </Media>
    </CityHotelsContext.Provider>
  );
};
