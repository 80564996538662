import axios from "../helpers/public.axios";

// ##### 25-03-28 Komarov
import {
  GET_ATTRACTIONS_ALL_ERR,
  GET_ATTRACTIONS_ALL_REQ,
  GET_ATTRACTIONS_ALL_RES,
  GET_ATTRACTIONS_TOP_ERR,
  GET_ATTRACTIONS_TOP_REQ,
  GET_ATTRACTIONS_TOP_RES,
  GET_CITIES_ERR,
  GET_CITIES_OVERVIEW_ALL_ERR,
  GET_CITIES_OVERVIEW_ALL_REQ,
  GET_CITIES_OVERVIEW_ALL_RES,
  GET_CITIES_OVERVIEW_TOP_ERR,
  GET_CITIES_OVERVIEW_TOP_REQ,
  GET_CITIES_OVERVIEW_TOP_RES,
  GET_CITIES_RES,
  GET_GENERAL_GEO_ERR,
  GET_GENERAL_GEO_REQ,
  GET_GENERAL_GEO_RES,
  GET_GEO_ERR,
  GET_GEO_REQ,
  GET_GEO_RES,
  GET_OCCUPIED_CITIES_ALL_ERR,
  GET_OCCUPIED_CITIES_ALL_REQ,
  GET_OCCUPIED_CITIES_ALL_RES,
  GET_OCCUPIED_CITIES_TOP_ERR,
  GET_OCCUPIED_CITIES_TOP_REQ,
  GET_OCCUPIED_CITIES_TOP_RES,
} from "../constants";
// ##### 25-02-26 Komarov
import { isServer } from "../../../server/helpers/browserDetector";
// ##### 25-01-09 Komarov
import { getPostObject, getUrl } from "../../helpers/crypto";
// ##### 25-02-26 Komarov
import { uniLog } from "../../../server/helpers/uniLog";
// ##### 25-03-28 Komarov
import {
  getSavingDataClient,
  postSavingDataClient,
} from "../../helpers/saveData";

export const geoResponse = (res) => ({
  type: GET_GEO_RES,
  payload: res,
});

// ##### 25-03-26 Komarov
export const getGeo = () => async (dispatch) => {
  dispatch({ type: GET_GEO_REQ });

  // ##### 25-01-09 Komarov
  const url = getUrl("classifier", "classifier=contract");

  // ##### 25-02-26 Komarov
  uniLog("cities.js", "axios:src/Redux/actions/cities.js:getGeo, url:", url);

  // ##### 25-03-26 Komarov
  await axios
    .get(url)
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      // ##### 23-03-28 Komarov: Заполняю объект cities.gen_locs на сервере заодно с cities.locs
      if (isServer) {
        // ##### 24-11-01 Komarov: Для получения generalHotels используется тот же запрос к Смарту, что и в данной функции, так что, если где-то вызываются последовательно эти две функции, достаточно вызова только этой. Если будем это использовать, то нужно поставить этот dispatch здесь без if.
        dispatch(generalGeoResponse(await res.data));
      }
      // ##### 24-11-01 Komarov
      const newArray1 = await res.data.map((b) => ({
        id: b.tour_id,
        name: b.tour_name,
      }));

      // ##### 24-11-01 Komarov
      const newArray2 = await res.data.map((q) => ({
        id: q.city_id,
        name: q.city_name,
      }));

      const newArray3 = newArray2.filter(
        (item, index, array) =>
          array.findIndex(
            (t) => t.name === item.name && parseInt(t.id) === parseInt(item.id),
          ) === index,
      );

      // console.log('[NEW ARRAY3] : ' , newArray3)
      dispatch(geoResponse([...newArray1, ...newArray3]));
    })
    .catch((err) => {
      dispatch({ type: GET_GEO_ERR, error: err });
    });
};

// ##### 24-08-05 Komarov
export const getCitiesOverview =
  ({
    user_id = 1426,
    refpartner,
    language,
    primary_objects_list = 1,
    section_name = "CITIES Overview",
  }) =>
  async (dispatch) => {
    const doWeGetTopCitiesOnly = !!primary_objects_list;

    dispatch({
      type:
        (doWeGetTopCitiesOnly && GET_CITIES_OVERVIEW_TOP_REQ) ||
        GET_CITIES_OVERVIEW_ALL_REQ,
    });

    const data = {
      section_name,
      primary_objects_list,
    };

    const postObject = getPostObject({
      action: "GetSitePageObjectListRQ",
      user_id,
      refpartner,
      language,
      data,
    });

    // ##### 25-02-26 Komarov
    uniLog(
      "cities.js",
      "axios:src/Redux/actions/cities.js:getCitiesOverview, url:",
      postObject.url,
    );

    // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
    await postSavingDataClient({
      postObject,
      typeRes:
        (doWeGetTopCitiesOnly && GET_CITIES_OVERVIEW_TOP_RES) ||
        GET_CITIES_OVERVIEW_ALL_RES,
      typeErr:
        (doWeGetTopCitiesOnly && GET_CITIES_OVERVIEW_TOP_ERR) ||
        GET_CITIES_OVERVIEW_ALL_ERR,
      dispatch,
    });
  };

// ##### 25-03-26 Komarov
export const getAttractionsOverview =
  ({
    user_id = 1426,
    refpartner,
    language,
    primary_objects_list = 1,
    section_name = "ATTRACTIONS",
  }) =>
  async (dispatch) => {
    const doWeGetTopAttractionsOnly = !!primary_objects_list;

    dispatch({
      type:
        (doWeGetTopAttractionsOnly && GET_ATTRACTIONS_TOP_REQ) ||
        GET_ATTRACTIONS_ALL_REQ,
    });

    const data = {
      section_name,
      primary_objects_list,
    };

    const postObject = getPostObject({
      action: "GetSitePageObjectListRQ",
      user_id,
      refpartner,
      language,
      data,
    });

    // ##### 25-02-26 Komarov
    uniLog(
      "cities.js",
      "axios:src/Redux/actions/cities.js:getAttractionsOverview, url:",
      postObject.url,
    );

    // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
    await postSavingDataClient({
      postObject,
      typeRes:
        (doWeGetTopAttractionsOnly && GET_ATTRACTIONS_TOP_RES) ||
        GET_ATTRACTIONS_ALL_RES,
      typeErr:
        (doWeGetTopAttractionsOnly && GET_ATTRACTIONS_TOP_ERR) ||
        GET_ATTRACTIONS_ALL_ERR,
      dispatch,
    });
  };

// ##### 24-09-05 Komarov
export const getOccupiedCities =
  ({
    user_id = 1426,
    refpartner,
    language,
    primary_objects_list = 1,
    section_name = "Occupied CITIES",
  }) =>
  async (dispatch) => {
    const doWeGetTopCitiesOnly = !!primary_objects_list;

    dispatch({
      type:
        (doWeGetTopCitiesOnly && GET_OCCUPIED_CITIES_TOP_REQ) ||
        GET_OCCUPIED_CITIES_ALL_REQ,
    });

    const data = {
      section_name,
      primary_objects_list,
    };

    const postObject = getPostObject({
      action: "GetSitePageObjectListRQ",
      user_id,
      refpartner,
      language,
      data,
    });

    // ##### 25-02-26 Komarov
    uniLog(
      "cities.js",
      "axios:src/Redux/actions/cities.js:getOccupiedCities, url:",
      postObject.url,
    );

    // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
    await postSavingDataClient({
      postObject,
      typeRes:
        (doWeGetTopCitiesOnly && GET_OCCUPIED_CITIES_TOP_RES) ||
        GET_OCCUPIED_CITIES_ALL_RES,
      typeErr:
        (doWeGetTopCitiesOnly && GET_OCCUPIED_CITIES_TOP_ERR) ||
        GET_OCCUPIED_CITIES_ALL_ERR,
      dispatch,
    });
  };

// ##### 23-08-15 Komarov
const generalGeoResponse = (res) => ({
  type: GET_GENERAL_GEO_RES,
  payload: res,
});

export const getGeneralGeo = () => async (dispatch) => {
  // ##### 23-03-06 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/cities.js:getGeneralGeo");

  dispatch({ type: GET_GENERAL_GEO_REQ });

  // ##### 25-01-09 Komarov
  const url = getUrl("classifier", "classifier=contract");
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/cities.js:getGeneralGeo, url:", url);

  // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
  await getSavingDataClient({
    smartUrl: url,
    typeRes: GET_GENERAL_GEO_RES,
    typeErr: GET_GENERAL_GEO_ERR,
    dispatch,
  });
};

// ##### 25-03-26 Komarov
export const getCities = () => async (dispatch) => {
  // ##### 23-03-06 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/cities.js:getCities");

  dispatch({ type: GET_GENERAL_GEO_REQ });

  // ##### 25-01-09 Komarov
  const url = getUrl("classifier", "classifier=city&priority=0");
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/cities.js:getCities, url:", url);

  // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
  await getSavingDataClient({
    smartUrl: url,
    typeRes: GET_CITIES_RES,
    typeErr: GET_CITIES_ERR,
    dispatch,
  });
};
