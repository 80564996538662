// ##### 24-09-09 Komarov
/* export const TravelServiceTypes = (locale) => {
  const obj = {
    en: [
      { name: "Accommodation", id: 1 },
      { name: "Transfers", id: 2 },
      // ##### 23-10-13 Komarov
      { name: "Meal", id: 3 },
      /* { name: "breakfast", id: 3 },
            { name: "lunch", id: 4 },
            { name: "dinner", id: 5 }, /
      { name: "Excursion", id: 6 },
      { name: "Guide-assistant", id: 7 },
      { name: "Flight tix", id: 8 },
      { name: "Train tix", id: 9 },
      { name: "Conference facilities", id: 10 },
      { name: "A/v equipment", id: 11 },
      { name: "Visa Support", id: 12 },
      { name: "Bus Transfer", id: 13 },
      { name: "River Cruise", id: 14 },
      { name: "Sea Cruise", id: 15 },
      { name: "Package Tour", id: 16 },
      { name: "Other Service", id: 17 },
      { name: "Charter", id: 18 },
      // Все типы сервисов из постановки задачи
      /**Air Flight, Visa Support, Excursion, Railway Travel, Bus Transfer, River Cruise, Sea Cruise, Package Tour, Other Service, Charter, Conference Service, Audio Video Equipment /
      // Типы сервисов из постановки задачи, которых здесь не было
      /**Visa Support, Bus Transfer, River Cruise, Sea Cruise, Package Tour, Other Service, Charter /
    ],
    uk: [
      { name: "Розміщення", id: 1 },
      { name: "Трансфери", id: 2 },
      // ##### 23-10-13 Komarov
      { name: "Харчування", id: 3 },
      /* { name: "сніданок", id: 3 },
            { name: "обід", id: 4 },
            { name: "вечеря", id: 5 }, /
      { name: "Екскурсії", id: 6 },
      { name: "Гід-перекладач", id: 7 },
      { name: "Авіа квитки", id: 8 },
      { name: "З/д квитки", id: 9 },
      { name: "Конференц обслуговування", id: 10 },
      { name: "Аудіо/відео обладнання", id: 11 },
      { name: "Візова підтримка", id: 12 },
      { name: "Автобусний трансфер", id: 13 },
      { name: "Річковий круїз", id: 14 },
      { name: "Морський круїз", id: 15 },
      { name: "Пакетний тур", id: 16 },
      { name: "Інші послуги", id: 17 },
      { name: "Чартер", id: 18 },
      // Все типы сервисов из постановки задачи
      /**Авіапереліт, Візова підтримка, Екскурсія, Залізничні перевезення, Автобусний трансфер, Річковий круїз, Морський круїз, Пакетний тур, Інші послуги, Чартер, Конференц-сервіс, Аудіо-відео обладнання /
      // Типы сервисов из постановки задачи, которых здесь не было
      /**Візова підтримка, Автобусний трансфер, Річковий круїз, Морський круїз, Пакетний тур, Інші послуги, Чартер /
    ],
    ru: [
      { name: "Размещение", id: 1 },
      { name: "Трансферы", id: 2 },
      // ##### 23-10-13 Komarov
      { name: "Питание", id: 3 },
      /* { name: "завтрак", id: 3 },
            { name: "обед", id: 4 },
            { name: "ужин", id: 5 }, /
      { name: "Экскурсия", id: 6 },
      { name: "Гид-переводчик", id: 7 },
      { name: "Авиа билеты", id: 8 },
      { name: "Ж/д билеты", id: 0 },
      { name: "Конференц обслуживание", id: 10 },
      { name: "Аудио/видео оборудование", id: 11 },
      { name: "Визовая поддержка", id: 12 },
      { name: "Автобусный трансфер", id: 13 },
      { name: "Речной круиз", id: 14 },
      { name: "Морской круиз", id: 15 },
      { name: "Пакетный тур", id: 16 },
      { name: "Прочие услуги", id: 17 },
      { name: "Чартер", id: 18 },
      // Все типы сервисов из постановки задачи
      /**Авиаперелет, Визовая поддержка, Экскурсия, Ж/д проезд, Автобусный трансфер, Речной круиз, Морской круиз, Пакетный тур, Прочие услуги, Чартер, Конференц-сервис, Аудио-видео оборудование /
      // Типы сервисов из постановки задачи, которых здесь не было
      /**Визовая поддержка, Автобусный трансфер, Речной круиз, Морской круиз, Пакетный тур, Прочие услуги, Чартер /
    ],
    tr: [
      { name: "Konaklama", id: 1 },
      { name: "Transferler", id: 2 },
      // ##### 23-10-13 Komarov
      { name: "Yiyecek", id: 3 },
      /* { name: "kahvaltı", id: 3 },
            { name: "öğle yemeği", id: 4 },
            { name: "akşam yemeği", id: 5 }, /
      { name: "Gezi", id: 6 },
      { name: "Rehber-asistan", id: 7 },
      { name: "Uçak biletleri", id: 8 },
      { name: "Tren biletleri", id: 9 },
      { name: "Konferans tesisleri", id: 10 },
      { name: "A/v ekipmanı", id: 11 },
      { name: "Vize Desteği", id: 12 },
      { name: "Otobüs Transferi", id: 13 },
      { name: "Nehir Gezisi", id: 14 },
      { name: "Deniz Gezisi", id: 15 },
      { name: "Paket Tur", id: 16 },
      { name: "Diğer Hizmetler", id: 17 },
      { name: "Charter", id: 18 },
      // Все типы сервисов из постановки задачи
      /**Uçak Bileti, Vize Desteği, Gezi, Demiryolu Seyahati, Otobüs Transferi, Nehir Gezisi, Deniz Gezisi, Paket Tur, Diğer Hizmetler, Charter, Konferans Hizmeti, Sesli Video Ekipmanı /
      // Типы сервисов из постановки задачи, которых здесь не было
      /**Vize Desteği, Otobüs Transferi, Nehir Gezisi, Deniz Gezisi, Paket Tur, Diğer Hizmetler, Charter /
    ],
  };

  // ##### 24-03-21 Komarov
  for (const key in obj) {
    if (key === locale) return obj[key];
  }
  // console.log('AAA_KATE_KATE',obj)
}; */

export const TravelServiceTypesObj = {
  en: {
    Accommodation: "Accommodation",
    Transfers: "Transfers",
    Meal: "Meal",
    /* breakfast: "breakfast",
    lunch: "lunch",
    dinner: "dinner", */
    Excursion: "Excursion",
    "Guide-assistant": "Guide-assistant",
    "Flight tix": "Flight tix",
    "Train tix": "Train tix",
    "Conference facilities": "Conference facilities",
    "A/v equipment": "A/v equipment",
    "Visa Support": "Visa Support",
    "Bus Transfer": "Bus Transfer",
    "River Cruise": "River Cruise",
    "Sea Cruise": "Sea Cruise",
    "Package Tour": "Package Tour",
    "Other Services": "Other Services",
    Charter: "Charter",
  },
  uk: {
    Accommodation: "Розміщення",
    Transfers: "Трансфери",
    Meal: "Харчування",
    /* breakfast: "сніданок",
    lunch: "обід",
    dinner: "вечеря", */
    Excursion: "Екскурсія",
    "Guide-assistant": "Гід-перекладач",
    "Flight tix": "Авіа квитки",
    "Train tix": "З/д квитки",
    "Conference facilities": "Конференц обслуговування",
    "A/v equipment": "Аудіо/відео обладнання",
    "Visa Support": "Візова підтримка",
    "Bus Transfer": "Автобусний трансфер",
    "River Cruise": "Річковий круїз",
    "Sea Cruise": "Морський круїз",
    "Package Tour": "Пакетний тур",
    "Other Services": "Інші послуги",
    Charter: "Чартер",
  },
  ru: {
    Accommodation: "Размещение",
    Transfers: "Трансферы",
    Meal: "Питание",
    /* breakfast: "завтрак",
    lunch: "обед",
    dinner: "ужин", */
    Excursion: "Экскурсия",
    "Guide-assistant": "Гид-переводчик",
    "Flight tix": "Авиа билеты",
    "Train tix": "Ж/д билеты",
    "Conference facilities": "Конференц обслуживание",
    "A/v equipment": "Аудио/видео оборудование",
    "Visa Support": "Визовая поддержка",
    "Bus Transfer": "Автобусный трансфер",
    "River Cruise": "Речной круиз",
    "Sea Cruise": "Морской круиз",
    "Package Tour": "Пакетный тур",
    "Other Services": "Прочие услуги",
    Charter: "Чартер",
  },
  tr: {
    Accommodation: "Konaklama",
    Transfers: "Transferler",
    Meal: "Yiyecek",
    /* breakfast: "kahvaltı",
    lunch: "öğle yemeği",
    dinner: "akşam yemeği", */
    Excursion: "Gezi",
    "Guide-assistant": "Rehber-asistan",
    "Flight tix": "Uçak biletleri",
    "Train tix": "Tren biletleri",
    "Conference facilities": "Konferans tesisleri",
    "A/v equipment": "A/v ekipmanı",
    "Visa Support": "Vize Desteği",
    "Bus Transfer": "Otobüs Transferi",
    "River Cruise": "Nehir Gezisi",
    "Sea Cruise": "Deniz Gezisi",
    "Package Tour": "Paket Tur",
    "Other Services": "Diğer Hizmetler",
    Charter: "Charter",
  },
};
