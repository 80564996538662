// ##### 23-03-29 Komarov
import {
  GET_HTL_SEARCH_REQ,
  GET_HTL_SEARCH_RES,
  GET_SEARCH_OCCUPANCY_REQ,
  GET_SEARCH_OCCUPANCY_RES,
} from "../constants";

//action creator
export const hotelsearchResponse = (res) => ({
  type: GET_HTL_SEARCH_RES,
  payload: res,
});

export const occupancyResponse = (res) => ({
  type: GET_SEARCH_OCCUPANCY_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
export const getHotelSearch = (param) => (dispatch) => {
  dispatch({ type: GET_HTL_SEARCH_REQ });

  dispatch(hotelsearchResponse(param));
};

//    ##### 24-03-21 Komarov
export const getOccupancySearch = (param) => (dispatch) => {
  dispatch({ type: GET_SEARCH_OCCUPANCY_REQ });

  dispatch(occupancyResponse(param));
};
