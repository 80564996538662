export const GuideTypes = (locale) => {
  const obj = {
    en: [
      "Arabic",
      "Chineese",
      "English",
      "French",
      "German",
      "Italian",
      "Polish",
      "Portugeese",
      "Spanish",
      "Turkish",
      "Ukrainian",
    ],
    uk: [
      "Англійська",
      "Арабська",
      "Іспанська",
      "Італьянська",
      "Кітайська",
      "Німецька",
      "Польська",
      "Португальська",
      "Турецька",
      "Французська",
      "Українська",
    ],
    ru: [
      "Английский",
      "Арабский",
      "Испанский",
      "Итальянский",
      "Китайский",
      "Немецкий",
      "Польский",
      "Португальский",
      "Турецкий",
      "Французский",
      "Украинский",
    ],
    tr: [
      "Ingilizce",
      "Arapça",
      "İspanyolca",
      "Italyanca",
      "Çinca",
      "Almanca",
      "Polonca",
      "Portekizce",
      "Turkçe",
      "Fransızca",
      "Ukraynaca",
    ],
  };

  // ##### 24-03-21 Komarov
  for (const key in obj) {
    if (key === locale) return obj[key];
  }
  // console.log('AAA_KATE_KATE',obj)
};
