// ##### 23-09-29 Komarov
import axios from "axios";
import React, { useEffect } from "react";
// ##### 25-01-01 Komarov
import { isBrowser } from "../../../../../server/helpers/browserDetector";

export const SendForm = ({ list }) => {
  // ##### 23-09-29 Komarov
  // const [supplierForm, setSupplierForm] = useState()

  useEffect(() => {
    const ActionRQ = {
      // ##### 24-03-21 Komarov
      data: {
        country: list.country,
        city: list.city,
        accomType: list.accomType,
        property_name: list.property_name,
        owner_name: list.owner_name,
        position: list.position,
        amountRooms: list.amountRooms,
        floorAmount: list.floorAmount,
        renYear: list.renYear,
        tel: list.tel,
        address: list.address,
        email: list.email,
        website: list.website,
        freeAccom: list.freeAccom,
        freeAccomDays: list.freeAccomDays,
        discAccom: list.discAccom,
        discAccomDays: list.discAccomDays,
        // ##### 23-10-11 Komarov
        comments: list.comments,
      },
    };

    // ##### 23-09-28 Komarov: исправил URL сайта на актуальный
    // Если установлена переменная, которая указывает отправлять почту с локального компьютера:
    // process.env.REACT_APP_SEND_EMAIL_FROM_LOCALHOST == "true"
    // то URL формируется, исходя из адреса локального приложения:
    // `${process.env.REACT_APP_URL}/supplier_form.php`
    // иначе - отправка сообщения на электронную почту происходит через сервер продуктивного сайта:
    // `https://arktur.ua/supplier_form.php`
    // ##### 23-09-29 Komarov
    // axios.post(process.env.REACT_APP_SEND_EMAIL_FROM_LOCALHOST == "true" &&
    //     `${process.env.REACT_APP_URL}/supplier_form.php`
    //     ||
    //     `https://arktur.ua/supplier_form.php`,
    //     JSON.stringify({ActionRQ}))
    // ##### 23-09-29 Komarov
    // axios.post(`${process.env.REACT_APP_URL}/${formName}`, JSON.stringify({ActionRQ}))
    axios.post(
      `${process.env.REACT_APP_URL}/supplier_form`,
      // ##### 25-01-01 Komarov
      JSON.stringify({ ActionRQ, arkturSiteB: isBrowser }),
    );
    // .then(response => setSupplierForm(response.data));
    // ##### 24-03-21 Komarov
  }, [
    list.country,
    list.city,
    list.accomType,
    list.property_name,
    list.owner_name,
    list.position,
    list.amountRooms,
    list.floorAmount,
    list.renYear,
    list.tel,
    list.address,
    list.email,
    list.website,
    list.freeAccom,
    list.freeAccomDays,
    list.discAccom,
    list.discAccomDays,
    list.comments,
  ]);

  // ##### 23-10-11 Komarov: TODO: Взять перевод из Смарта
  return (
    <h2 data-component="SendForm">
      Your application is sent. Our specialists will get back to you within 48
      hours
    </h2>
  );
};
