// ##### 24-07-24 Komarov
import { SET_COUNTRY } from "../constants";

// ##### 24-07-24 Komarov: TODO: временное решение, - пока для зранения названия Украины на разных языках. Нужно будет принимать значения названий стран ииз Смарта.
const initialState = {
  uk: 'Україна',
  en: 'Ukraine',
  ru: 'Украина',
};

// ##### 23-03-28 Komarov: Функция для добавления в Redux store поля country
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRY:
      return action.payload;
    default:
      return state;
  }
};
