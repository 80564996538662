// ##### 25-03-12 Komarov: функция для преобразования объекта autocomplete_data.data.list в массив вида, какого был массив smart_translated_hotels
export const makeItemsArray = (itemsListObject) => {
  const result = [];
  Object.keys(itemsListObject).forEach((cityHotelId) => {
    result.push({
      name: itemsListObject[cityHotelId].title,
      available_now: !!!parseInt(itemsListObject[cityHotelId].notavail),
      city_id: cityHotelId
        .toString()
        .slice(0, cityHotelId.toString().indexOf(":")),
      id: cityHotelId.toString().slice(cityHotelId.toString().indexOf(":") + 1),
      city: itemsListObject[cityHotelId].sign.toString(),
    });
  });
  return result;
};
