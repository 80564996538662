// ##### 25-02-05 Komarov
import { Alert, Input } from "antd";
import { markRequired } from "../../../../../helpers/markRequired";
import React from "react";

export function LabelTextArea(props) {
  const { TextArea } = Input;
  return (
    <>
      <span>
        {(props.required && markRequired(props.labelText)) || props.labelText}
      </span>
      <TextArea
        rows={1}
        style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
        onChange={props.onChange}
        value={props.value}
        // ##### 23-10-02 Komarov: если поле не заполнено, оно обводится красной полоской
        status={props.required && props.alertTrigger && "error"}
      />
      {/* ##### 23-10-02 Komarov: оповещение пользователя о необходимости заполнить это поле */}
      {props.required && props.alertTrigger && (
        <Alert
          message={props.message}
          type="error"
          showIcon
          closable
          onClose={props.onClose}
        />
      )}
    </>
  );
}
