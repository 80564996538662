import {
  GET_MAIN_PAGE_SSR_ERR,
  GET_MAIN_PAGE_SSR_REQ,
  GET_MAIN_PAGE_SSR_RES,
  GET_META_CHARSET_PAGE_SSR_ERR,
  GET_META_CHARSET_PAGE_SSR_REQ,
  GET_META_CHARSET_PAGE_SSR_RES,
  GET_META_DESCRIPTION_PAGE_SSR_ERR,
  GET_META_DESCRIPTION_PAGE_SSR_REQ,
  GET_META_DESCRIPTION_PAGE_SSR_RES,
  GET_META_KEYWORDS_PAGE_SSR_ERR,
  GET_META_KEYWORDS_PAGE_SSR_REQ,
  GET_META_KEYWORDS_PAGE_SSR_RES,
  GET_META_TITLE_PAGE_SSR_ERR,
  GET_META_TITLE_PAGE_SSR_REQ,
  GET_META_TITLE_PAGE_SSR_RES,
  GET_META_VIEWPORT_PAGE_SSR_ERR,
  GET_META_VIEWPORT_PAGE_SSR_REQ,
  GET_META_VIEWPORT_PAGE_SSR_RES,
  GET_PAGES_ERR,
  GET_PAGES_REQ,
  GET_PAGES_RES,
  GET_PRELOADED_CITY_PAGE_ERR,
  GET_PRELOADED_CITY_PAGE_REQ,
  GET_PRELOADED_CITY_PAGE_RES,
  GET_PRELOADED_PAGE_ERR,
  GET_PRELOADED_PAGE_REQ,
  GET_PRELOADED_PAGE_RES,
  GET_PURE_PAGE_ABOUT_US_SSR_ERR,
  GET_PURE_PAGE_ABOUT_US_SSR_REQ,
  GET_PURE_PAGE_ABOUT_US_SSR_RES,
  GET_PURE_PAGE_ERR,
  GET_PURE_PAGE_REQ,
  GET_PURE_PAGE_RES,
  GET_PURE_PAGES_SSR,
  GET_SLIDER_PAGE_SSR_ERR,
  GET_SLIDER_PAGE_SSR_REQ,
  GET_SLIDER_PAGE_SSR_RES,
  GET_WAR_PAGE_SSR_ERR,
  GET_WAR_PAGE_SSR_REQ,
  GET_WAR_PAGE_SSR_RES
} from "../constants";

const initState = {
  pages: [],
  pages_loaded: false,
  // ##### 24-03-21 Komarov

  purepage: [],
  purepage_loaded: false,

  // ##### 25-03-21 Komarov
  purepages: {},
  purepages_loaded: {},

  // ##### 25-03-31 Komarov
  preloadedpages: {},
  preloadedpages_loaded: {},

  // ##### 25-04-01 Komarov
  preloadedcitiespages: {},
  preloadedcitiespages_loaded: {},

  pageType: [],
  pageType_loaded: false,

  frontbody: [],
  frontbody_loaded: false,
  frontbody_errors: [],

  pageRegion: [],
  pageRegion_loaded: false,

  hotelPagesFooter: [],
  hotelPagesFooter_loaded: false,

  galleryPage: [],
  galleryPage_loaded: false,
  errors: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_PAGES_REQ:
      return {
        ...state,
        pages_loaded: false,
      };

    case GET_PAGES_RES:
      return {
        ...state,
        pages_loaded: true,
        pages: action.payload,
      };

    case GET_PAGES_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 25-03-21 Komarov
    case GET_PURE_PAGE_ABOUT_US_SSR_REQ:
      return {
        ...state,
        purepages_loaded: {
          ...state.purepages_loaded,
          2: true,
        },
      };

    // ##### 25-03-21 Komarov
    case GET_PURE_PAGE_ABOUT_US_SSR_RES:
      return {
        ...state,
        purepages: {
          ...state.purepages,
          2: action.payload,
        },
      };

    case GET_PURE_PAGE_ABOUT_US_SSR_ERR:
      return {
        ...state,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 25-03-21 Komarov
    case GET_SLIDER_PAGE_SSR_REQ:
      return {
        ...state,
        purepages_loaded: {
          ...state.purepages_loaded,
          199: true,
        },
      };

    case GET_SLIDER_PAGE_SSR_RES:
      return {
        ...state,
        purepages: {
          ...state.purepages,
          199: action.payload,
        },
      };

    case GET_SLIDER_PAGE_SSR_ERR:
      return {
        ...state,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 25-03-31 Komarov
    case GET_PRELOADED_PAGE_REQ:
      return {
        ...state,
        preloadedpages_loaded: {
          ...state.preloadedpages_loaded,
          [action.payload]: true,
        },
      };

    case GET_PRELOADED_PAGE_RES:
      return {
        ...state,
        preloadedpages: {
          ...state.preloadedpages,
          [action.payload.id]: action.payload,
        },
      };

    case GET_PRELOADED_PAGE_ERR:
      return {
        ...state,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 25-04-01 Komarov
    case GET_PRELOADED_CITY_PAGE_REQ:
      return {
        ...state,
        preloadedcitiespages_loaded: {
          ...state.preloadedcitiespages_loaded,
          [action.payload]: true,
        },
      };

    case GET_PRELOADED_CITY_PAGE_RES:
      return {
        ...state,
        preloadedcitiespages: {
          ...state.preloadedcitiespages,
          [action.payload.id]: action.payload,
        },
      };

    case GET_PRELOADED_CITY_PAGE_ERR:
      return {
        ...state,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 25-03-21 Komarov
    case GET_MAIN_PAGE_SSR_REQ:
      return {
        ...state,
        purepages_loaded: {
          ...state.purepages_loaded,
          209: true,
        },
      };

    case GET_MAIN_PAGE_SSR_RES:
      return {
        ...state,
        purepages: {
          ...state.purepages,
          209: action.payload,
        },
      };

    case GET_MAIN_PAGE_SSR_ERR:
      return {
        ...state,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 25-03-21 Komarov
    case GET_WAR_PAGE_SSR_REQ:
      return {
        ...state,
        purepages_loaded: {
          ...state.purepages_loaded,
          210: true,
        },
      };

    case GET_WAR_PAGE_SSR_RES:
      return {
        ...state,
        purepages: {
          ...state.purepages,
          210: action.payload,
        },
      };

    case GET_WAR_PAGE_SSR_ERR:
      return {
        ...state,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 25-03-21 Komarov
    case GET_META_CHARSET_PAGE_SSR_REQ:
      return {
        ...state,
        purepages_loaded: {
          ...state.purepages_loaded,
          219: true,
        },
      };

    case GET_META_CHARSET_PAGE_SSR_RES:
      return {
        ...state,
        purepages: {
          ...state.purepages,
          219: action.payload,
        },
      };

    case GET_META_CHARSET_PAGE_SSR_ERR:
      return {
        ...state,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 25-03-21 Komarov
    case GET_META_VIEWPORT_PAGE_SSR_REQ:
      return {
        ...state,
        purepages_loaded: {
          ...state.purepages_loaded,
          220: true,
        },
      };

    case GET_META_VIEWPORT_PAGE_SSR_RES:
      return {
        ...state,
        purepages: {
          ...state.purepages,
          220: action.payload,
        },
      };

    case GET_META_VIEWPORT_PAGE_SSR_ERR:
      return {
        ...state,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 25-03-21 Komarov
    case GET_META_TITLE_PAGE_SSR_REQ:
      return {
        ...state,
        purepages_loaded: {
          ...state.purepages_loaded,
          221: true,
        },
      };

    case GET_META_TITLE_PAGE_SSR_RES:
      return {
        ...state,
        purepages: {
          ...state.purepages,
          221: action.payload,
        },
      };

    case GET_META_TITLE_PAGE_SSR_ERR:
      return {
        ...state,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 25-03-21 Komarov
    case GET_META_KEYWORDS_PAGE_SSR_REQ:
      return {
        ...state,
        purepages_loaded: {
          ...state.purepages_loaded,
          304: true,
        },
      };

    case GET_META_KEYWORDS_PAGE_SSR_RES:
      return {
        ...state,
        purepages: {
          ...state.purepages,
          304: action.payload,
        },
      };

    case GET_META_KEYWORDS_PAGE_SSR_ERR:
      return {
        ...state,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 25-03-21 Komarov
    case GET_META_DESCRIPTION_PAGE_SSR_REQ:
      return {
        ...state,
        purepages_loaded: {
          ...state.purepages_loaded,
          305: true,
        },
      };

    case GET_META_DESCRIPTION_PAGE_SSR_RES:
      return {
        ...state,
        purepages: {
          ...state.purepages,
          305: action.payload,
        },
      };

    case GET_META_DESCRIPTION_PAGE_SSR_ERR:
      return {
        ...state,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 25-03-07 Komarov
    case GET_PURE_PAGE_REQ:
      return {
        ...state,
        purepage_loaded: false,
      };

    // ##### 25-03-07 Komarov
    case GET_PURE_PAGE_RES:
      // console.log('[PURE_PAGE_INFO] action.payload: ' , action.payload)
      return {
        ...state,
        purepage_loaded: true,
        purepage: action.payload,
      };

    // ##### 25-03-07 Komarov
    case GET_PURE_PAGE_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 25-03-18 Komarov
    case GET_PURE_PAGES_SSR:
      return {
        ...state,
        purePagesSsr: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
