// ##### 24-06-19 Komarov
import React from "react";
import { YearBuilder } from "../../../Library/Images/YearBuilder.js";

export const NotFound = () => {
  // ##### 24-06-17 Komarov: TODO: Взять перевод из Смарта
  return (
    <div data-component="NotFound">
      <h2>Not Found</h2>
      {/* ##### 23-08-15 Komarov */}
      {/* <YearAnimation/> */}
      <YearBuilder />
    </div>
  );
};
