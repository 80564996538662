import {
  GET_CONTENT_ERR,
  GET_CONTENT_REQ,
  GET_CONTENT_RES,
  GET_EN_TOUR_ERR,
  GET_EN_TOUR_REQ,
  GET_EN_TOUR_RES,
  GET_IMAGES_TOUR_ERR,
  GET_IMAGES_TOUR_REQ,
  GET_IMAGES_TOUR_RES,
  GET_TOURDETAILS_ERR,
  GET_TOURDETAILS_REQ,
  GET_TOURDETAILS_RES,
} from "../constants";

const initState = {
  touritems: [{}],
  touritems_loaded: false,
  touritems_errors: [],

  en_touritems: [],
  en_touritems_loaded: false,
  // ##### 24-03-21 Komarov
  // errors: [],

  images: [],
  images_loaded: false,
  // errors: [],

  content: [],
  content_loaded: false,
  errors: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_TOURDETAILS_REQ:
      return {
        ...state,
        touritems_loaded: false,
      };

    case GET_TOURDETAILS_RES:
      return {
        ...state,
        touritems_loaded: true,
        touritems: action.payload,
      };

    case GET_TOURDETAILS_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        touritems_errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    case GET_EN_TOUR_REQ:
      return {
        ...state,
        en_touritems_loaded: false,
      };

    case GET_EN_TOUR_RES:
      return {
        ...state,
        en_touritems_loaded: true,
        en_touritems: action.payload,
      };

    case GET_EN_TOUR_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    case GET_IMAGES_TOUR_REQ:
      return {
        ...state,
        images_loaded: false,
      };

    case GET_IMAGES_TOUR_RES:
      return {
        ...state,
        images_loaded: true,
        images: action.payload,
      };

    case GET_IMAGES_TOUR_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    case GET_CONTENT_REQ:
      return {
        ...state,
        content_loaded: false,
      };

    case GET_CONTENT_RES:
      return {
        ...state,
        content_loaded: true,
        content: action.payload,
      };

    case GET_CONTENT_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    default:
      return state;
  }
};

export default reducer;
