import React from "react";
// ##### 24-05-10 Komarov
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useMessages } from "../../../../../helpers/messages";
import { ValidateQuery } from "../../../Helpers/helper";

// ##### 24-02-20 Komarov

export const PaxValue = ({ fontSize }) => {
  // ##### 24-05-13 Komarov
  const counterAdultsOuter = useSelector(
    (state) => state.hotelsContext.counterAdultsOuter,
  );
  const counterChildOuter = useSelector(
    (state) => state.hotelsContext.counterChildOuter,
  );
  const counterRoomsOuter = useSelector(
    (state) => state.hotelsContext.counterRoomsOuter,
  );

  // ##### 24-05-10 Komarov
  const Adults = useMessages("Adults");
  const Children = useMessages("Children");
  const Rooms = useMessages("Rooms");

  const totalPaxRedux = useSelector((state) => state.paxchoice.pax);

  // ##### 24-05-10 Komarov
  const location = useLocation();
  // ##### 25-01-15 Komarov
  // const query = ValidateQuery(location);
  const shouldCypherUrlParams = useSelector(
    (state) => !!parseInt(state.siteCypherCodeData.data.value),
  );
  // ##### 25-02-28 Komarov
  const cypherKey = useSelector(
    (state) => state.siteCypherCodeData.data.description,
  );
  const query = ValidateQuery(location, shouldCypherUrlParams, cypherKey);

  return (
    <div
      data-component="PaxValue"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        fontSize,
      }}
    >
      {/* <Pax /> */}
      <div
        style={{
          paddingLeft: "1vw",
          /* fontSize: '14px',  */
          alignContent: "center",
        }}
      >
        <center>
          {/* ##### 24-05-10 Komarov */}
          {/* <b>{counterAdultsOuter}</b> { */}
          {
            <b>
              {totalPaxRedux?.adults || query?.adults || counterAdultsOuter}
            </b>
          }
          <span style={{ marginLeft: "0.3rem" }} />
          {
            // ##### 23-10-04 Komarov
            Adults
          }
          ,
        </center>
      </div>
      <div style={{ paddingLeft: "1vw" /* ,fontSize:'14px' */ }}>
        <center>
          {/* ##### 24-05-10 Komarov */}
          <b>
            {totalPaxRedux?.children || query?.children || counterChildOuter}
          </b>
          <span style={{ marginLeft: "0.3rem" }} />
          {
            // ##### 23-10-04 Komarov
            Children
          }
          ,
        </center>
      </div>
      <div style={{ paddingLeft: "1vw" /* ,fontSize:'14px' */ }}>
        <center>
          {/* ##### 24-05-10 Komarov */}
          <b>{totalPaxRedux?.rooms || query?.rooms || counterRoomsOuter}</b>
          <span style={{ marginLeft: "0.3rem" }} />
          {
            // ##### 23-10-04 Komarov
            Rooms
          }
        </center>
      </div>

      {/* ##### 23-03-30 Komarov */}
    </div>
  );
};
