import React from "react";
import { Media } from "../../../../../helpers/MediaConfig";
import { PaxValue } from "./PaxValue";

// @@@@@ 23-03-03 Komarov

export const FrontHotelPaxResult = ({ className }) => {
  return (
    <div className={className} data-component="FrontHotelPaxResult">
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <PaxValue fontSize="1.6vh" />
      </Media>
      <Media lessThan="Laptop-1024-1440">
        <PaxValue fontSize="1rem" />
      </Media>
    </div>
  );
};
