// ##### 25-02-05 Komarov
import React from "react";
import { useMessages } from "../../../../helpers/messages";

// ##### 24-09-09 Komarov
export const ButtonComponent = () => {
  // ##### 24-04-23 Komarov
  const ProceedToPayment = useMessages("ProceedToPayment");

  return (
    <button
      data-component="ButtonComponent"
      type="button"
      style={{
        backgroundColor: "#337ab7",
        color: "#fff",
        borderColor: "#2e6da4",
        border: "1px solid transparent",
        borderRadius: "4px",
        padding: "6px 12px",
        cursor: "pointer",
        fontSize: "25px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {
        // ##### 23-10-04 Komarov
        ProceedToPayment
      }
    </button>
  );
};
