// ##### 23-03-29 Komarov
import React from "react";
// ##### 23-09-14 Komarov
// import './SearchItems.css'
import moment from "moment";
import config from "../../../../Redux/config";
// ##### 24-07-25 Komarov
import { useSelector } from "react-redux";

moment.locale(config.defaultLang);

export const MinRate = ({ selection, datesArray, pickedCurrency }) => {
  // console.log('[SELECTION]' , selection, datesArray)

  // const currencies = useSelector(state=>state.currency.currencies)
  
  // ##### 24-07-25 Komarov
  const currenciesFromState = useSelector(state => state.currency.currencies);
  // ##### 24-03-21 Komarov
  const currencies = (currenciesFromState.length > 0 && currenciesFromState) || JSON.parse(localStorage.getItem("currency")).currencies;

  let exchangeRate;

  // ##### 24-03-21 Komarov
  for (const curr of currencies) {
    if (curr.name === pickedCurrency) exchangeRate = parseInt(curr.value);
  }

  // ##### 24-03-21 Komarov
  const minRateValue = datesArray
    .filter((item) => {
      if (selection === item.date) {
        return true;
      }
      return false;
    })
    .sort((a, b) => a.n_value - b.n_value);

  // console.log('[SORTED ARRAY] : ' , minRateValue[0])

  // ##### 24-04-13 Komarov: TODO: Взять перевод "Rates from" из Смарта
  return (
    <div className="minimumRate_details" data-component="MinRate">
      {/* ##### 24-04-13 Komarov */}
      {/* Rates from {pickedCurrency} {Math.round(minRateValue[0].price/exchangeRate)} */}
      Rates from {pickedCurrency}{" "}
      {parseFloat(minRateValue[0].price / exchangeRate).toFixed(2)}
    </div>
  );
};
