// ##### 23-10-19 Komarov
export default (...args) => {
  const deviceType = global.deviceType;
  // Get the current time in milliseconds
  const currentDate = new Date();

  // ##### 25-02-26 Komarov
  // Get the current time in microseconds using performance.now()
  function getCurrentTimeMicroseconds() {
    if (typeof performance !== "undefined" && performance.now) {
      return Math.round(performance.now() * 1000);
    } else if (typeof process !== "undefined" && process.hrtime) {
      // В Node.js, если performance.now недоступен (маловероятно),
      // используем process.hrtime
      const time = process.hrtime();
      return time[0] * 1000000 + time[1] / 1000;
    } else {
      // Если ни performance.now, ни process.hrtime недоступны,
      // используем Date.now (менее точно)
      return Date.now() * 1000;
    }
  }

  const currentTimeMicroseconds = getCurrentTimeMicroseconds();

  // Extract hours, minutes, seconds, and milliseconds from the Date object
  const hours = currentDate.getHours().toString().padStart(2, "0");
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");
  const seconds = currentDate.getSeconds().toString().padStart(2, "0");
  const milliseconds = currentDate
    .getMilliseconds()
    .toString()
    .padStart(3, "0");

  // Format microseconds to 6 digits
  const microseconds = currentTimeMicroseconds
    .toFixed(0)
    .toString()
    .padStart(6, "0");

  // Create the desired format
  const formattedTime = `${hours}:${minutes}:${seconds}:${milliseconds}:${microseconds}`;
  // ##### 23-04-12 Komarov
  if (!global.data) {
    global.data = {};
  }
  if (deviceType === "desktop") {
    global.data[JSON.stringify(formattedTime)] = [...args];
  }
};
