// ##### 25-03-27 Komarov
import React from "react";
import Slider from "react-slick";
import uniqueId from "lodash.uniqueid";
import { sliderFrequency } from "../../Context/config";
import { ArrowSlider } from "./ArrowSlider";
// ##### 25-04-03 Komarov
import AsynchronousImage from "../PageDevices/CartGallery/AsynchronousImage";

function SampleNextArrow(props) {
  const { onClick } = props;
  return <ArrowSlider direction="right" handleClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return <ArrowSlider direction="left" handleClick={onClick} />;
}

const SlickSlider = ({ images, className }) => {
  const settings = {
    dots: false,
    // ##### 25-04-03 Komarov
    lazyLoad: true,
    infinite: true,
    fade: true,
    waitForAnimate: false,
    autoplay: true,
    speed: 500,
    autoplaySpeed: sliderFrequency,
    swipeToSlide: true,
    cssEase: "linear",
    className: className,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,
  };
  return (
    <>
      <div className="slider-container">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div
              key={uniqueId()}
              data-component="Slide"
              style={{
                width: "100%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <AsynchronousImage
                emptyLoader
                key={uniqueId()}
                src={(process.env.REACT_APP_PROTOCOL || "https://") + image}
                alt={`Slide ${index}`}
                style={{
                  width: "100%",
                  objectFit: "cover",
                  height: "37vw",
                }}
              />
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default SlickSlider;
