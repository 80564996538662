// ##### 25-02-15 Komarov
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// ##### 25-02-15 Komarov
import {
  getHotelCities,
  getHotelContent,
  getHotelContent3,
  getHotelRates2,
  // ##### 25-02-18 Komarov
  getHotelsAvail,
  getHotelSearch,
  getPickedCurrencyResponse,
} from "../../../../Redux/actions";
import { ValidateQuery } from "../../Helpers/helper";
// ##### 24-06-14 Komarov
import { LoadingMessage } from "../../../Library/PageDevices/LoadingMessage";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages";
import { HotelDetailsContent } from "./HotelDetailsContent";

export const HotelDetails = () => {
  // ##### 24-05-31 Komarov
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // ##### 24-04-23 Komarov
  const Availability = useMessages("Availability");

  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  const location = useLocation();

  // ##### 24-12-27 Komarov
  const shouldCypherUrlParams = useSelector(
    (state) => !!parseInt(state.siteCypherCodeData.data.value),
  );
  // ##### 25-02-28 Komarov
  const cypherKey = useSelector(
    (state) => state.siteCypherCodeData.data.description,
  );
  const search_data = ValidateQuery(location, shouldCypherUrlParams, cypherKey);

  // console.log('[HOTEL_DETAILS_SEARCH]', search_data)

  const dispatch = useDispatch();
  const hotelcontents = useSelector((state) => state.hotelcontent.hotelcontent);

  dispatch(getPickedCurrencyResponse(search_data.selected_currency));

  // ##### 24-09-16 Komarov
  const { user: currentUser } = useSelector((state) => state.auth);
  const { user_id } = currentUser;

  // ##### 24-02-22 Komarov
  useEffect(() => {
    // ##### 23-03-06 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/ThirdPageofSearchModule/Hotels/HotelDetails.js:getHotelContent(search_data.hotel_id,locale)",
      );
    // ##### 24-09-16 Komarov
    dispatch(getHotelContent(search_data.hotel_id, locale, user_id));
    // ##### 23-04-12 Komarov
  }, [search_data.hotel_id, locale, dispatch]);

  useEffect(() => {
    // ##### 23-04-12 Komarov
    const rememberLink = (to) => {
      // ##### 24-06-27 Komarov
      localStorage.setItem("urlToBackToRooms", to);
    };
    // ##### 24-02-22 Komarov
    const strToRemove = `/${locale}`;
    rememberLink(
      location.pathname.substring(
        location.pathname.indexOf(strToRemove) + strToRemove.length,
      ) + location.search,
    );
    // ##### 23-04-12 Komarov
  }, [locale, dispatch, location.pathname, location.search]);

  // ##### 23-04-12 Komarov
  useEffect(() => {
    dispatch(getHotelSearch(search_data));
    // ##### 23-04-12 Komarov
  }, [search_data, dispatch]);

  useEffect(() => {
    // ##### 24-02-22 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/ThirdPageofSearchModule/Hotels/HotelDetails.js:getHotelCities(locale)",
      );
    dispatch(getHotelCities(locale));
    // ##### 23-04-12 Komarov
  }, [locale, dispatch]);

  // ##### 25-02-15 Komarov
  const company_id = useSelector((state) => state.auth.user.company_id);
  const promocode = useSelector((state) => state.promocode.promocode);
  const sale =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).sale;
  const company_cipher = useSelector(
    (state) => state.whitelabel.company_cipher,
  );
  const deviceType = useSelector((state) => state.deviceType);

  // ##### 25-02-18 Komarov
  const hotels_avail_loaded = useSelector(
    (state) => state.hotels.hotels_avail_loaded,
  );

  // ##### 25-02-21 Komarov
  useEffect(() => {
    !hotels_avail_loaded &&
      dispatch(
        getHotelsAvail({
          sale_id: (sale && sale) || null,
          promocode,
          deviceType,
          company_cipher: company_cipher || "",
          start: search_data.start, // date of arrival
          end: search_data.end, // date of departure
          city_id: search_data.city_id,
          // ##### 25-02-25 Komarov
          hotel_id: search_data.hotel_id,
          adults: search_data.adults,
          children: search_data.children,
          // ##### 25-03-11 Komarov
          // lower_cost_limit: 0.0, // lower cost limit of room in USD, NOT OBLIGATORY
          // upper_cost_limit: 200.0, // upper cost limit of room in USD, NOT OBLIGATORY
          quantity: search_data.rooms, // Quantity of rooms, 1 by default, NOT OBLIGATORY
          user_id: currentUser.user_id,
          refpartner: company_id,
          language: locale,
        }),
      );
  }, [
    currentUser.user_id,
    search_data.refpartner,
    search_data.start,
    search_data.end,
    search_data.city_id,
    // ##### 25-02-25 Komarov
    search_data.hotel_id,
    search_data.rooms,
    search_data.adults,
    search_data.children,
    sale,
    company_cipher,
  ]);

  // ##### 25-02-12 Komarov
  useEffect(() => {
    dispatch(
      getHotelContent3({
        // ##### 25-02-25 Komarov
        hotel_id: search_data.hotel_id,
        city_id: search_data.city_id,
        language: locale,
        user_id: currentUser.user_id,
        refpartner: company_id,
        sale,
        promocode,
        deviceType,
        company_cipher,
        content_size: 1,
      }),
    );
  }, [
    currentUser.user_id,
    search_data.refpartner,
    search_data.city_id,
    // ##### 25-02-25 Komarov
    search_data.hotel_id,
    sale,
    deviceType,
    company_cipher,
    // ##### 25-03-26 Komarov
    locale,
  ]);

  // ##### 25-02-20 Komarov
  useEffect(() => {
    dispatch(
      getHotelRates2({
        search_data,
        sale: (sale && sale) || null,
        userId: currentUser.user_id,
        promocode,
        deviceType,
        company_cipher: company_cipher || "",
        refpartner: company_id,
        locale,
      }),
    );
  }, [
    currentUser.user_id,
    search_data.refpartner,
    search_data.city_id,
    search_data.start,
    search_data.end,
    // ##### 25-02-25 Komarov
    search_data.hotel_id,
    sale,
    deviceType,
    company_cipher,
    search_data.adults,
    search_data.children,
    search_data.rooms,
  ]);

  // ##### 25-02-12 Komarov
  const cityName = search_data.cityName;
  const hotelName = decodeURIComponent(search_data.hotel_name);
  const hotelcontent_loaded3 = useSelector(
    (state) => state.hotelcontent.hotelcontent_loaded3,
  );

  // ##### 25-02-15 Komarov
  if (!hotelcontents || !hotelcontent_loaded3 || !hotels_avail_loaded) {
    return <LoadingMessage />;
  }

  // ##### 25-02-15 Komarov
  return (
    <div className="HotelDetailsWrapper" data-component="HotelDetails">
      <HotelDetailsContent
        hotelName={hotelName}
        cityName={cityName}
        hotelcontents={hotelcontents}
        searchData={search_data}
        availability={Availability}
      />
    </div>
  );
};
