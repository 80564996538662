// ##### 23-09-14 Komarov
import React from "react";

// ##### 24-04-23 Komarov
import { useMessages } from "../../../helpers/messages";
// ##### 23-09-14 Komarov
// import './ConfirmButton.css'

// ##### 24-04-24 Komarov
export const ConfirmButton = ({ AddContacts, clicked, available_now }) => {
  // ##### 24-04-24 Komarov
  // const Confirm_Details = useMessages("Confirm Details");
  const ToCart = useMessages("ToCart");
  const Cart = useMessages("Cart");
  const SendRequest = useMessages("SendRequest");
  const RequestSent = useMessages("RequestSent");

  const getButtonText = () => {
    if (available_now) {
      return clicked ? Cart : ToCart;
    }
    return clicked ? RequestSent : SendRequest;
  };

  return (
    <button
      data-component="ConfirmButton"
      type="submit"
      className={clicked === false ? "SubmitButton" : "NonActiveSubmit"}
      // ##### 24-05-01 Komarov
      onClick={clicked ? undefined : AddContacts}
    >
      {
        // ##### 24-04-24 Komarov
        // Confirm_Details
        getButtonText()
      }
    </button>
  );
};
