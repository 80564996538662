// ##### 25-02-21 Komarov
import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// ##### 25-02-21 Komarov
import { changeLang, getCurrency } from "../../../../../Redux/actions";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../../helpers/messages";
// ##### 23-10-24 Komarov
import { HeaderWrapperMain } from "./HeaderWrapperMain";

// ##### 23-10-10 Komarov
export const TopMenuContext = createContext();

// ##### 25-02-05 Komarov
export const Header = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.language);
  // ##### 24-07-25 Komarov
  const currencies = useSelector((state) => state.currency.currencies);

  // Думал, что язык работает и без этого запроса
  useEffect(() => {
    dispatch(changeLang(lang));
    // ##### 23-04-12 Komarov
  }, [lang, dispatch]);
  // Запрашиваем валюту из Смарта для сайта
  useEffect(() => {
    // ##### 24-07-25 Komarov
    (process.env.NODE_ENV === "development" && currencies.length > 0) ||
      console.log(
        "axios:src/Components/Pages/PageComponents/ResponsiveHeader/Header.js:getCurrency()",
      );
    currencies.length > 0 || dispatch(getCurrency());
    // ##### 23-04-12 Komarov
  }, [dispatch]);

  // ##### 24-06-20 Komarov
  const sitePages = Object.values(useMessages());
  let sitePagesObj = {};
  for (const sitePage of sitePages) {
    if (!sitePagesObj[sitePage.sitepage_region_name]) {
      sitePagesObj[sitePage.sitepage_region_name] = {};
    }
    if (
      !sitePagesObj[sitePage.sitepage_region_name][sitePage.sitepage_type_name]
    ) {
      sitePagesObj[sitePage.sitepage_region_name][sitePage.sitepage_type_name] =
        {
          sitepage_region_id: sitePage.sitepage_region_id,
          sitepage_region_name: sitePage.sitepage_region_name,
          sitepage_type_id: sitePage.sitepage_type_id,
          sitepage_type_name: sitePage.sitepage_type_name,
        };
    }
  }

  // ##### 25-02-21 Komarov
  const topMenuContextValue = useMemo(() => ({ sitePagesObj }), [sitePagesObj]);

  return (
    <TopMenuContext.Provider value={topMenuContextValue}>
      <HeaderWrapperMain />
    </TopMenuContext.Provider>
  );
};
