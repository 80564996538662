// ##### 25-02-05 Komarov
// import './LoadingMessage.css'
// ##### 24-09-09 Komarov
import parse from "html-react-parser";
import { useMessages } from "../../../helpers/messages";
import React from "react";

export const NoSearchResultMessageStart = ({ noTextMessage }) => {
  // ##### 24-09-23 Komarov
  const noSearchResultMessageStart = parse(
    useMessages("NoSearchResultMessageStart"),
  );
  return (
    <h3 className="LoadingText" data-component="LoadingMessage">
      {/* ##### 25-01-28 Komarov */}
      {noTextMessage ? null : noSearchResultMessageStart}
    </h3>
  );
};
