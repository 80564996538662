import { FETCH_USERS3 } from "../constants";

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_USERS3:
      // console.log('src/Redux/reducers/usersReducer3.js: ', action.payload.data);
      return action.payload.data;
    default:
      return state;
  }
};
