// ##### 24-06-12 Komarov
import React from "react";
// ##### 25-02-21 Komarov
import { useMessages } from "../../../../../helpers/messages";

export const HeaderH2 = ({ className, fontSize }) => {
  const ArkturFrontPageSloganMessage = useMessages(
    "ArkturFrontPageSloganMessage",
  );
  return (
    <h2
      data-component="HeaderH2"
      className={className}
      // ##### 23-09-23 Komarov
      style={{
        margin: "auto",
        color: "rgb(122,6,8)",
        fontSize: fontSize,
        fontFamily: "Arial",
        fontWeight: "bold",
      }}
    >
      {
        // ##### 23-10-04 Komarov
        ArkturFrontPageSloganMessage
      }{" "}
    </h2>
  );
};
