// ##### 25-02-05 Komarov
import React, { useMemo } from "react";
import { SwitcherButton } from "./SwitcherButton";
import { SwitcherItemContext } from "./Switcher";

export const SwitcherItem = (props) => {
  // ##### 23-03-29 Komarov
  const { children, active, changeHandler, buttonClassName } = props;
  const switcherItemContextValue = useMemo(
    () => ({
      changeHandler,
      children,
    }),
    [
      // ##### 24-09-11 Komarov
      changeHandler,
      children,
    ],
  );

  // ##### 23-11-02 Komarov
  return (
    <SwitcherItemContext.Provider value={switcherItemContextValue}>
      {/* ##### 23-06-27 Komarov */}
      <SwitcherButton className={buttonClassName + (active ? " active" : "")} />
    </SwitcherItemContext.Provider>
  );
};
