// ##### 25-02-05 Komarov
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ArkturDMClogoICO from "../../../Library/Images/ArkturDMClogoICO.ico";

export const FORGROUPS = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div data-component="FORGROUPS">
      <Helmet>
        <title>Arktur Groups</title>
        <link rel="shortcut icon" href={ArkturDMClogoICO} />
      </Helmet>
      <h2>For Groups</h2>
    </div>
  );
};
