// ##### 23-06-22 Komarov
import React from "react";

import coffeeGif from "./caffeine-rush-coffee-1.gif";
// ##### 23-09-14 Komarov
// import "./CupOfCoffee.css";

// ##### 25-04-01 Komarov
export const CupOfCoffee = ({ empty = false }) => {
  return empty && <></>
    ||
    <div className="pre-loader" data-component="CupOfCoffee">
      {/* <h1>Animated GIF Example</h1> */}
      <img src={coffeeGif} alt="Animated GIF" />
    </div>;
};
