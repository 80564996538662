// ##### 24-06-13 Komarov
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { Media } from "../../../../../helpers/MediaConfig.js";
import { setPaxChoiceDivOpened } from "../../../../../Redux/actions/index.js";
import { HotelsPaxChoiceContext } from "./HotelsPaxChoice.js";
import { PaxValue } from "./PaxValue.js";

// ##### 23-11-03 Komarov

export const FrontHotelPaxResult = ({ style = null }) => {
  const { deviceType, toggleContextPaxListOpen } = useContext(
    HotelsPaxChoiceContext
  );

  const dispatch = useDispatch();
  // ##### 24-05-16 Komarov
  const onPaxChoiceDivClick = (e) => {
    if (e.target.tagName === "BUTTON") {
      dispatch(setPaxChoiceDivOpened(false));
    } else {
      dispatch(setPaxChoiceDivOpened(true));
    }
    e.stopPropagation(); // Вызываем эту функцию, чтобы событие не отлавливалось дочерними компонентами, чтобы избежать его срабатвыания два раза подряд: и на div'е, и на целевом компоненте (в данном случае - Autocomplete).
  };
  return (
    <div
      data-component="FrontHotelPaxResult"
      className={deviceType === "desktop"
        ? "FrontHotelPaxResult"
        : "FrontHotelPaxResultSmallScreen"}
      // ##### 23-12-27 Komarov
      onClick={(e) => {
        toggleContextPaxListOpen(true);
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        // Trigger click action on Enter key press
        if (e.key === "Enter") {
          toggleContextPaxListOpen(true);
          e.stopPropagation();
        }
      }}
      // ##### 22-11-14 Komarov
      style={style}
    >
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <PaxValue
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            // ##### 24-03-12 Komarov
            alignItems: "center",
          }} />
      </Media>
      <Media lessThan="Laptop-1024-1440">
        <PaxValue
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            fontSize: "1rem",
          }} />
      </Media>
      {/* ##### 24-05-16 Komarov: Кнопка для закрытия div`а с содержимым PaxChoice */}
      <Media lessThan="Laptop-1024-1440">
        <button
          type="button"
          onClick={onPaxChoiceDivClick}
          style={{
            // minWidth: '90%',
            backgroundColor: "#005AEB",
            color: "white",
            // fontSize: '32px',
            fontFamily: "Arial, Helvetica, sans-serif",
            borderRadius: "1rem",
            // padding: "6.75px 33vw",
            padding: "6.75px 3vw",
            fontWeight: "bold",
            transform: "translateX(10px)",
          }}
        >
          OK
        </button>
      </Media>
    </div>
  );
};
