// ##### 25-02-05 Komarov
import React, { useEffect, useRef } from "react";
import { useWindowWidthAndHeight } from "../../Helpers/WindowResizeHook";
import OutsideClickHandler from "react-outside-click-handler";
import { LocalizationNavLink } from "../../../Library/Localization/LocalizationNavLink";
import uniqueId from "lodash.uniqueid";
import parse from "html-react-parser";

export const Requests = ({ pages, title, isWhiteLabelSet, companyCipher }) => {
  const requestsRef = useRef();
  const requestsItemsRef = useRef();
  // ##### 24-09-20 Komarov
  const [innerWidth] = useWindowWidthAndHeight();
  useEffect(() => {
    requestsItemsRef.current.style.left = `${requestsRef.current.offsetLeft}px`;
  }, [innerWidth]);

  const removeRequestsItems = () => {
    const requestsItemsclassList = requestsItemsRef.current.classList;
    if (!requestsItemsclassList.contains("DropDownMenuItemsHide")) {
      requestsItemsclassList.add("DropDownMenuItemsHide");
    }
  };

  const toggleRequestsItems = () => {
    requestsItemsRef.current.classList.toggle("DropDownMenuItemsHide");
  };

  return (
    <OutsideClickHandler onOutsideClick={removeRequestsItems}>
      <a
        ref={requestsRef}
        role="button"
        className="DropDownMenu"
        data-component="Requests"
        onClick={toggleRequestsItems}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            toggleRequestsItems();
          }
        }}
      >
        {title}
      </a>
      <div
        className="DropDownMenuItemsHide"
        data-component="DropDownMenuItems"
        ref={requestsItemsRef}
      >
        {pages.map((page) => {
          return (
            // ##### 24-03-04 Komarov
            <LocalizationNavLink
              to={
                (isWhiteLabelSet &&
                  `/ref${companyCipher}/sitepages/?name=${page.name}&id=${page.id}`) ||
                `/sitepages/?name=${page.name}&id=${page.id}`
              }
              key={uniqueId()}
              onClick={removeRequestsItems}
            >
              {/* ##### 25-03-26 Komarov */}
              {parse(page.title[0].text)}
            </LocalizationNavLink>
          );
        })}
      </div>
    </OutsideClickHandler>
  );
};
