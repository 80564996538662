// ##### 24-06-13 Komarov
import React, { useContext } from "react";
import { createPortal } from "react-dom";
import { useMessages } from "../../../../../helpers/messages";
import { AvailableNowSign } from "../../../../Library/Icons/availableNowSign";
import { OnRequestSign } from "../../../../Library/Icons/onRequestSign";
import { SearchContext } from "../../SearchResizersAndSwitchers/SearchFront";
// ##### 25-01-22 Komarov
// import parse from "html-react-parser";

// ##### 24-05-22 Komarov: компонент с элементом для отображения условных обозначений точек на иконках в элементах Autocomplete
export const HotelsCitiesAvailabilityHint = ({
  hotelsCitiesAvailabilityHintRef,
}) => {
  const { switcherRef } = useContext(SearchContext);
  const availableNow = useMessages("Available_Now");
  const onRequest = useMessages("Available_OnRequest");
  // const space = parse("&nbsp;");
  if (!switcherRef.current) return null;
  return createPortal(
    <div
      data-component="HotelsCitiesAvailabilityHint"
      className="hotels-cities-availability-hint"
      ref={hotelsCitiesAvailabilityHintRef}
    >
      <div style={{ display: "flex" }}>
        <AvailableNowSign /> &nbsp; {availableNow}
      </div>
      <div style={{ display: "flex" }}>
        <OnRequestSign /> &nbsp; {onRequest}
      </div>
    </div>,
    switcherRef.current,
  );
};
