// ##### 24-06-13 Komarov
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useContext } from "react";
import { useMessages } from "../../../../helpers/messages.js";
import { HotelsPaxChoiceContextPageDevices } from "./HotelsPaxChoicePageDevices.js";

// ##### 23-09-14 Komarov
// import './HotelsPaxChoiceCSS.css'
// import 'antd/dist/antd.css';
// ##### 23-11-08 Komarov
export const HotelsPaxChoiceGridPageDevices = ({ rowGap }) => {
  const {
    counterAdults,
    setCounterAdults,
    counterChild,
    setCounterChild,
    counterRooms,
    setCounterRooms,
  } = useContext(HotelsPaxChoiceContextPageDevices);

  // ##### 24-05-10 Komarov
  const Adults = useMessages("Adults");
  const Children = useMessages("Children");
  const Rooms = useMessages("Rooms");
  const twelve_plus_years = useMessages("twelve_plus_years");
  const two_eleven_years = useMessages("two_eleven_years");

  const add = () => {
    setCounterAdults(counterAdults + 1);
  };

  const deduct = () => {
    counterAdults > 0
      ? setCounterAdults(counterAdults - 1)
      : setCounterAdults(0);
  };

  const addChild = () => {
    // counterChild<2? setCounterChild(counterChild+1) : alert('This tour allows upto 2 Children')
    setCounterChild(counterChild + 1);
  };
  const deductChild = () => {
    counterChild > 0 ? setCounterChild(counterChild - 1) : setCounterChild(0);
  };

  const addRooms = () => {
    // counterInfant<1? setCounterInfant(counterInfant+1) : alert('This tour allows upto 1 Infant')
    setCounterRooms(counterRooms + 1);
  };

  const deductRooms = () => {
    counterRooms > 0 ? setCounterRooms(counterRooms - 1) : setCounterRooms(1);
  };

  // ##### 23-11-08 Komarov
  return (
    <div
      data-component="HotelsPaxChoiceGridPageDevices"
      // className={className}
      style={{
        display: "grid",
        gridTemplateRows: "repeat(3, 6vh)",
        //  ##### 22-11-14 Komarov
        rowGap,
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "25% 17% 16% 17% 25%",
        }}
      >
        <h4 style={{ fontSize: "14px", fontFamily: "Arial" }}>
          {
            // ##### 23-10-04 Komarov
            Adults
          }
        </h4>
        <MinusOutlined
          style={{ color: "white" }}
          className="MinusInner"
          onClick={deduct}
        />
        <h4 style={{ fontSize: "14px", fontFamily: "Arial" }}>
          {counterAdults}
        </h4>
        <PlusOutlined
          className="PlusInner"
          style={{ color: "white" }}
          onClick={add}
        />
        {/* ##### 23-07-06 Komarov */}
        {/* <h4 style={{ fontSize: '14px', fontFamily: 'Arial' }}>12+ years</h4> */}
        <h4 style={{ fontSize: "14px", fontFamily: "Arial" }}>
          {
            // ##### 23-10-04 Komarov
            twelve_plus_years
          }
        </h4>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "25% 17% 16% 17% 25%",
        }}
      >
        <h4 style={{ fontSize: "14px", fontFamily: "Arial" }}>
          {
            // ##### 23-10-04 Komarov
            Children
          }
        </h4>
        <MinusOutlined
          className="MinusInner"
          style={{ color: "white" }}
          onClick={deductChild}
        />
        <h4 style={{ fontSize: "14px", fontFamily: "Arial" }}>
          {counterChild}
        </h4>
        <PlusOutlined
          className="PlusInner"
          style={{ color: "white" }}
          onClick={addChild}
        />
        {/* ##### 23-07-06 Komarov */}
        {/* <h4 style={{ fontSize: '14px', fontFamily: 'Arial' }}>2-11 years</h4> */}
        <h4 style={{ fontSize: "14px", fontFamily: "Arial" }}>
          {
            // ##### 23-10-04 Komarov
            two_eleven_years
          }
        </h4>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "25% 17% 16% 17% 25%",
        }}
      >
        <h4 style={{ fontSize: "14px", fontFamily: "Arial" }}>
          {
            // ##### 23-10-04 Komarov
            Rooms
          }
        </h4>
        <MinusOutlined
          className="MinusInner"
          style={{ color: "white" }}
          onClick={deductRooms}
        />
        <h4 style={{ fontSize: "14px", fontFamily: "Arial" }}>
          {counterRooms}
        </h4>
        <PlusOutlined
          className="PlusInner"
          style={{ color: "white" }}
          onClick={addRooms}
        />
      </div>
    </div>
  );
};
