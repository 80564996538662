import React, { useEffect, useState } from "react";
// ##### 24-04-19 Komarov
import { useLocation } from "react-router-dom";
// ##### 24-07-25 Komarov
import { useDispatch, useSelector } from "react-redux";
import { BookButtonHotel } from "../BookButtonHotel";
// ##### 24-11-02 Komarov
import { getOccupancySearch } from "../../../../../Redux/actions";

import { Select } from "antd";
// ##### 24-04-05 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
// ##### 24-04-19 Komarov
import { ValidateQuery } from "../../../Helpers/helper";

// ##### 23-09-14 Komarov
// import './RatesBlockCSS.css'

export const AvailableOptions = (props) => {
  // ##### 24-04-17 Komarov
  const {
    rooms,
    room_id,
    room_name,
    tariff_type_name,
    sum,
    tariff_id,
    availability,
    occupancy,
    refpartner,
    // ##### 25-02-18 Komarov
    nrf,
    start,
    end,
    available_now,
  } = props;
  // ##### 24-04-19 Komarov
  let path;
  const location = useLocation();
  // ##### 25-01-15 Komarov
  const shouldCypherUrlParams = useSelector(
    (state) => !!parseInt(state.siteCypherCodeData.data.value),
  );
  // ##### 25-02-28 Komarov
  const cypherKey = useSelector(
    (state) => state.siteCypherCodeData.data.description,
  );
  if (location.search) {
    path = ValidateQuery(location, shouldCypherUrlParams, cypherKey);
  }
  // ##### 25-02-21 Komarov
  const hotelContractCurrencyId = useSelector(
    (state) =>
      state.hotels.hotels_avail.data.hotels[path.hotel_id].contr_currency_id,
  );
  const pickedCurrency = useSelector((state) => state.currency.pickedcurrency);
  const fromCurrencyRate = useSelector(
    (state) => state.currency.currencies,
  ).find(
    (currency) => parseInt(currency.id) === parseInt(hotelContractCurrencyId),
  ).value;
  const toCurrencyRate = useSelector((state) => state.currency.currencies).find(
    (currency) => pickedCurrency === currency.name,
  ).value;
  const exchangeRate = fromCurrencyRate / toCurrencyRate;
  const totalCost = sum * exchangeRate;

  const [selectedAvailability, setSelectedAvailability] = useState(
    parseInt(rooms, 10),
  );
  // ##### 25-02-18 Komarov
  const [totalsum, setTotalSum] = useState(totalCost);

  const { Option } = Select;

  const dispatch = useDispatch();

  const SelectRooms = (value) => {
    setSelectedAvailability(value);
    // ##### 25-02-18 Komarov
    setTotalSum(value * totalCost);
  };

  useEffect(() => {
    dispatch(getOccupancySearch(selectedAvailability, occupancy));
    // ##### 23-04-12 Komarov
  }, [dispatch, selectedAvailability, occupancy]);

  // ##### 24-03-21 Komarov
  const empty_array = [];

  for (let i = 1; empty_array.length < availability; i++) {
    empty_array.push(i);
  }

  // console.log('AVAILABILITY', selectedAvailability, empty_array)

  // ##### 24-11-02 Komarov
  const BookButton = ({ style }) => {
    return (
      <h5 style={style} data-component="BookButton">
        <BookButtonHotel
          selectedAvailability={selectedAvailability}
          room_id={room_id}
          room_name={room_name}
          // ##### 24-04-17 Komarov
          tariff_type_name={tariff_type_name}
          // ##### 25-02-19 Komarov
          totalsum={
            (!isNaN(totalsum) && Math.fround(totalsum).toFixed(2)) ||
            "No price was shown"
          }
          tariff_id={tariff_id}
          occupancy={occupancy}
          pickedCurrency={pickedCurrency}
          refpartner={refpartner}
          // ##### 25-02-18 Komarov
          nrf={nrf}
          start={start}
          end={end}
          available_now={available_now}
          // ##### 24-04-19 Komarov
          cityName={decodeURIComponent(path.cityName)}
        />
      </h5>
    );
  };

  const RoomPrice = () => (
    <h5
      data-component="RoomPrice"
      style={{
        fontSize: "17px",
        color: "#102D69",
        fontFamily: "Arial",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        // ##### 25-02-20 Komarov
        marginBottom: 0,
      }}
    >
      {pickedCurrency} {/*##### 25-02-18 Komarov*/}
      {Math.fround(totalsum).toFixed(2)}
    </h5>
  );

  const QuantityPicker = () => (
    <h5
      data-component="QuantityPicker"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        // ##### 25-02-20 Komarov
        marginBottom: 0,
      }}
    >
      <Select
        defaultValue={selectedAvailability}
        onChange={SelectRooms}
        bordered={true}
        size="middle"
      >
        <>
          {
            // ##### 24-03-21 Komarov
            empty_array?.map((item) => {
              return (
                <Option className="AvailableOptions" value={item} key={item}>
                  {item}
                </Option>
              );
            })
          }
        </>
      </Select>
    </h5>
  );

  const availableNowHotelButtonStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "middle",
    backgroundColor: "white",
    marginBottom: 0,
  };

  // ##### 24-09-09 Komarov
  return (
    <>
      {/*##### 25-02-18 Komarov */}
      {sum !== 0 && <RoomPrice />}

      {/* ##### 24-11-02 Komarov */}
      {!!available_now && <QuantityPicker />}

      {/* ##### 24-11-02 Komarov */}
      <Media lessThan="Laptop-1024-1440">
        <BookButton
          style={
            (!!available_now && availableNowHotelButtonStyle) || {
              margin: "1vw",
            }
          }
        />
      </Media>
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <BookButton
          // ##### 25-02-18 Komarov
          style={{
            borderLeft: sum !== 0 && "2px solid rgb(109, 109, 196)",
            // ##### 25-02-20 Komarov
            marginBottom: 0,
          }}
        />
      </Media>
    </>
  );
};
