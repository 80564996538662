import React from "react";
// ##### 23-11-03 Komarov
import { scrollWidth } from "../../Pages/Helpers/workWithScroll";

// ##### 23-11-02 Komarov
const Slide = ({ content }) => {
  return (
    <div
      data-component="Slide"
      style={{
        width: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <img
        style={{
          // ##### 23-11-03 Komarov
          // width: "100%",
          width: `calc(100% - ${scrollWidth}px)`,
          height: "50vw",
          objectFit: "cover",
        }}
        // ##### 24-03-21 Komarov: TODO: Взять значение атрибута alt из Смарта
        alt="Slide content"
        src={content}
      />
    </div>
  );
};

export default Slide;
