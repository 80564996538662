// ##### 24-06-13 Komarov
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
// ##### 23-03-29 Komarov
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
// ##### 24-01-05 Komarov: ConfigProvider - средство конфигурирования локали для календариков
import { ConfigProvider, DatePicker, Space } from "antd";
import Autocomplete from "react-autocomplete";
// ##### 24-01-05 Komarov: Оъбект с форматами дат соответственно локалям (обязательно названия языков в нём должны совпадать с названиями локалей в antd)
import dateFormats from "../../../../../helpers/dateFormats";
import { HotelsPaxChoice } from "../HotelsPaxChoice/HotelsPaxChoice";
import { PromoCode } from "../PromoCode";

// ##### 25-01-22 Komarov
import {
  changeLang,
  // ##### 25-03-14 Komarov
  getPages,
  // ##### 25-03-12 Komarov
  getAutocompleteData,
  setItemInfo,
} from "../../../../../Redux/actions";
import { PlaceHolderStrings } from "../../../../Library/Localization/placeholders";
import { ClearInput } from "../../../../Library/PageDevices/ClearInput";
// ##### 23-12-11 Komarov
import { Alert } from "antd";
import config from "../../../../../Redux/config";
import getIcon from "../helpers/getIcon";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../../helpers/messages";
// ##### 23-11-02 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
// ##### 24-02-02 Komarov
import OutsideClickHandler from "react-outside-click-handler";
// ##### 24-04-05 Komarov
import uniqueId from "lodash.uniqueid";
// ##### 24-04-17 Komarov
import { makeParamsString } from "../../../Helpers/helper";
// ##### 24-05-22 Komarov
import { HotelsCitiesAvailabilityHint } from "./HotelsCitiesAvailabilityHint";
// ##### 24-12-27 Komarov
import { encryptString } from "../../../../../helpers/crypto";
// ##### 25-03-11 Komarov
import { dateLocales } from "../../../../../helpers/locales";
// ##### 25-03-12 Komarov
import { makeItemsArray } from "../../../../../helpers/makeItemsArray";

moment.locale(config.defaultLang);

// ##### 23-11-10 Komarov
export const HotelsAutocomplete = ({
  formClass,
  datepickerClass,
  props,
  searchProps,
}) => {
  // ##### 24-05-21 Komarov
  const hotelsCitiesAvailabilityHintRef = useRef();

  // ##### 23-10-05 Komarov: Устанавливаем положение календарика с выбором даты в зависимости от положения прокрутки окна
  const [datePickerPlacement, setDatePickerPlacement] = useState("topLeft");

  // ##### 24-06-13 Komarov: Управление положением календарика на экране
  useEffect(() => {
    if (window.scrollY > 250) {
      setDatePickerPlacement("bottomLeft");
    } else setDatePickerPlacement("topLeft");
    // ##### 24-07-25 Komarov
  }, []);

  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  // console.log('REGEX',lettersOnlyString(searchProps.title))

  const [stayDates, setStayDates] = useState(
    searchProps ? [searchProps.start, searchProps.end] : [],
  );
  const [pickedStartDate, setPickedStartDate] = useState(!!searchProps);
  const [pickedEndDate, setPickedEndDate] = useState(!!searchProps);
  const [list, setList] = useState([]);
  // ##### 24-04-29 Komarov
  const [hotelsvalue, setHotelsValue] = useState(
    searchProps ? decodeURIComponent(searchProps.title) : "",
  );
  // ##### 24-04-05 Komarov
  const selectedItemInfoInitialState = undefined;
  const [selectedItemInfo, setSelectedItemInfo] = useState(
    selectedItemInfoInitialState,
  );
  const [open, setOpen] = useState(false);

  // ##### 24-05-21 Komarov
  useEffect(() => {
    open &&
      hotelsCitiesAvailabilityHintRef.current &&
      hotelsCitiesAvailabilityHintRef.current.classList.add("show");
    !open &&
      hotelsCitiesAvailabilityHintRef.current &&
      hotelsCitiesAvailabilityHintRef.current.classList.remove("show");
  }, [open]);
  const [paxListOpen, setPaxListOpen] = useState(false);
  const [crossVisible, setCrossVisible] = useState(false);
  const [pickedHotelValue, setPickedHotelValue] = useState(!!searchProps);
  // ##### 24-02-02 Komarov
  const [autocompleteFocused, setAutocompleteFocused] = useState(false);

  const history = useHistory();

  // ##### 24-02-22 Komarov
  const pickedcurrencyFromState = useSelector(
    (state) => state.currency.pickedcurrency,
  );
  // ##### 24-07-25 Komarov
  const pickedcurrency =
    pickedcurrencyFromState ||
    JSON.parse(localStorage.getItem("currency")).pickedcurrency;

  const dispatch = useDispatch();
  // ##### 25-03-12 Komarov
  const autocomplete_data = useSelector(
    (state) => state.hotels.autocomplete_data,
  );
  const autocomplete_data_loaded = useSelector(
    (state) => state.hotels.autocomplete_data_loaded,
  );
  // ##### 25-03-12 Komarov
  const autocomplete_data_list =
    autocomplete_data_loaded && makeItemsArray(autocomplete_data.data.list);
  // ##### 25-03-12 Komarov
  const totalPaxReduxAdults = useSelector(
    (state) => state.paxchoice.pax.adults,
  );
  const totalPaxReduxChildren = useSelector(
    (state) => state.paxchoice.pax.children,
  );
  const totalPaxReduxRooms = useSelector((state) => state.paxchoice.pax.rooms);
  const promoCode = useSelector((state) => state.promocode.promocode);
  // console.log('TOTALPAXREDUX',totalPaxRedux)

  useEffect(() => {
    dispatch(changeLang());
    // ##### 23-04-12 Komarov
  }, [dispatch]);

  // ##### 24-04-23 Komarov
  const pagesFromLocalStorage = useMessages();

  useEffect(
    () => {
      if (Object.values(pagesFromLocalStorage).length > 0) {
        return;
      }
      // ##### 23-04-17 Komarov
      console.log(
        "axios:src/Components/Pages/FirstPageofSearchModule/HotelsAutocompleteBlock/HotelsAutocomplete.js:getPages(lang)",
      );
      // ##### 23-12-27 Komarov
      dispatch(getPages(locale));
    },
    // ##### 24-01-05 Komarov
    [locale, dispatch, pagesFromLocalStorage],
  );

  const placeholder = PlaceHolderStrings();

  // console.log('TESTING', name.sort((a,b)=>a.city-b.city))

  // console.log('PLACEHOLDER',placeholder)

  // console.log('TOTALPAX', totalPaxRedux)

  const { RangePicker } = DatePicker;

  // ##### 25-03-12 Komarov
  const sale =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).sale;
  const promocode = useSelector((state) => state.promocode.promocode);
  const { user: currentUser } = useSelector((state) => state.auth);
  const company_id = useSelector((state) => state.auth.user.company_id);
  // ##### 25-03-26 Komarov
  useEffect(() => {
    const localeChanged =
      locale !== "undefined" && sessionStorage.getItem("language") !== locale;
    // ##### 25-03-12 Komarov
    if (autocomplete_data_loaded && !localeChanged) {
      return;
    }
    // ##### 24-02-02 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/FirstPageofSearchModule/HotelsAutocompleteBlock/HotelsAutocomplete.js:getAutocompleteData",
      );
    // ##### 25-03-12 Komarov
    dispatch(
      getAutocompleteData({
        sale_id: (sale && sale) || null,
        promocode,
        user_id: currentUser.user_id,
        refpartner: company_id,
        language: locale,
      }),
    );
    // ##### 25-03-26 Komarov
  }, [autocomplete_data_loaded, locale]);

  useEffect(() => {
    sessionStorage.setItem("language", locale);
  }, [locale]);

  // ##### 24-03-21 Komarov
  function onChange(_dates, dateStrings) {
    // ##### 24-05-27 Komarov
    if (dateStrings[0] && dateStrings[1]) {
      setStayDates(dateStrings);
      setPickedStartDate(true);
      setPickedEndDate(true);
      return;
    }
    setSearchClicked(false);
    setPickedStartDate(false);
    setPickedEndDate(false);
  }

  const optionChecker = (e) => {
    // console.log('[VALUE] : ', e.target.value)

    setHotelsValue(e.target.value);
    // ##### 23-04-12 Komarov
    if (hotelsvalue.length > 0) {
      setOpen(true);
    }
  };

  // ##### 24-04-05 Komarov
  useEffect(() => {
    dispatch(setItemInfo(selectedItemInfo));
  }, [selectedItemInfo, dispatch]);

  ////FOR DATEPICKER (RANGEDATEPICKER) TO DISABLE CHOICE OF DATES THAT ARE BEFORE TODAY

  const disabledDate = (current) => {
    return current < moment().subtract(1, "days");
  };
  // ##### 24-04-23 Komarov
  const chooseYourHotel =
    useMessages("ChooseYourHotel") || "Please choose your hotel";
  const pickUpYourDatesOfStay =
    useMessages("PickUpYourDatesOfStay") || "Please pick up your dates of stay";
  // ##### 23-10-04 Komarov
  const Search = useMessages("Search");

  // ##### 23-12-11 Komarov
  const [searchClicked, setSearchClicked] = useState(false);
  const [formFilled, setFormFilled] = useState(false);

  useEffect(() => {
    setFormFilled(
      pickedHotelValue !== false &&
        hotelsvalue !== "" &&
        pickedStartDate !== false &&
        pickedEndDate !== false,
    );
  }, [pickedHotelValue, hotelsvalue, pickedStartDate, pickedEndDate]);
  // ##### 24-03-01 Komarov
  const whitelabel = useSelector((state) => state.whitelabel);
  const isWhiteLabelSet = whitelabel.set;
  const company_cipher = whitelabel.company_cipher;

  // ##### 25-03-12 Komarov
  const shouldCypherUrlParams = useSelector(
    (state) => !!parseInt(state.siteCypherCodeData.data.value),
  );

  const addToList = () => {
    // ##### 23-12-11 Komarov
    setSearchClicked(true);

    if (!formFilled) {
      return;
    }
    // ##### 25-03-12 Komarov
    const hotel_id = localStorage.getItem("hotel_id");

    // ##### 25-03-12 Komarov
    const hotelNewList = {
      // id: filteredHotels[0].id,
      id: hotel_id,
      title: hotelsvalue,
      date: stayDates,
    };

    setList([...list, hotelNewList]);

    const params_string = makeParamsString({
      selected_currency:
        localStorage.getItem("current_currency") || pickedcurrency,
      title: hotelsvalue,
      start:
        (moment(stayDates[0], dateFormats[locale]).isValid() &&
          moment(stayDates[0], dateFormats[locale]).format(dateFormats.en)) ||
        moment(stayDates[0], dateFormats.en).format(dateFormats.en),
      end:
        (moment(stayDates[1], dateFormats[locale]).isValid() &&
          moment(stayDates[1], dateFormats[locale]).format(dateFormats.en)) ||
        moment(stayDates[1], dateFormats.en).format(dateFormats.en),
      // ##### 25-03-12 Komarov
      hotel_id:
        (!!parseInt(localStorage.getItem("isCitySelected")) && "0") || hotel_id,
      // city_id: filtered_hotelcity_id,
      city_id: localStorage.getItem("city_id"),
      adults: totalPaxReduxAdults,
      children: totalPaxReduxChildren,
      rooms: totalPaxReduxRooms,
    });

    // ##### 24-12-27 Komarov
    localStorage.setItem("selectedItemInfo", JSON.stringify(selectedItemInfo));

    const route_query = `?${shouldCypherUrlParams ? encryptString(params_string) : params_string}`;
    // ##### 24-02-29 Komarov: TODO: Убрать промокод из URL
    if (isWhiteLabelSet) {
      history.push(
        `/${locale}/ref${company_cipher}/search_results_hotels/${route_query}`,
        [...list, hotelNewList],
      );
    } else if (promoCode) {
      history.push(`/${locale}/search_results_hotels/${route_query}`, [
        ...list,
        hotelNewList,
      ]);
    } else {
      history.push(`/${locale}/search_results_hotels/${route_query}`, [
        ...list,
        hotelNewList,
      ]);
    }
  };

  // +++++
  const MakeVisible = (value) => {
    value === false || value === true
      ? setPaxListOpen(value)
      : setPaxListOpen(!paxListOpen);
  };

  const VisibleCross = () => {
    if (hotelsvalue !== "") {
      setCrossVisible(true);
    }
  };

  const NotVisibleCross = () => {
    setCrossVisible(false);
  };

  const clearInput = () => {
    // ##### 24-04-11 Komarov
    localStorage.removeItem("city_id");
    setHotelsValue("");
    // ##### 24-04-05 Komarov
    setSelectedItemInfo(selectedItemInfoInitialState);
    // ##### 24-05-27 Komarov
    setSearchClicked(false);
  };

  // ##### 24-02-02 Komarov
  const autocomplete = useRef();

  useEffect(() => {
    if (autocompleteFocused) {
      autocomplete.current.focus();
    }
  }, [autocompleteFocused]);

  return (
    <div data-component="HotelsAutocomplete">
      <form className={formClass} onSubmit={(e) => e.preventDefault()}>
        {/* ##### 23-12-11 Komarov */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <OutsideClickHandler
            onOutsideClick={() => {
              setAutocompleteFocused(false);
              if (hotelsvalue === "") {
                setPickedHotelValue(false);
              }
            }}
          >
            <div
              style={{
                gridColumn: "1",
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "space-around",
              }}
              onMouseEnter={VisibleCross}
              onMouseLeave={NotVisibleCross}
              // ##### 24-02-02 Komarov
              onClick={() => setAutocompleteFocused(true)}
              onKeyDown={(event) => {
                if (event.key === "Enter" || event.key === " ") {
                  setAutocompleteFocused(true);
                }
              }}
              id="HotelsAutocomplete"
            >
              {(autocompleteFocused || pickedHotelValue) && (
                <ClearInput
                  makevisible={crossVisible}
                  clearInput={clearInput}
                />
              )}

              {((autocompleteFocused || pickedHotelValue) &&
                autocomplete_data_loaded && (
                  <Autocomplete
                    {...props}
                    ref={autocomplete}
                    value={hotelsvalue}
                    inputProps={{
                      style: {
                        // width: `${width*0.8/4}px`,
                        height: "45px",
                        // ##### 23-03-29 Komarov
                        // fontFamily: 'Tahoma',
                        fontFamily: "Arial",
                        fontSize: "16px",
                        border: "none",
                        marginTop: "0.2vw",
                        textAlign: "center",
                        position: "relative",
                      },
                    }}
                    menuStyle={{
                      fontFamily: "Arial Narrow",
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "darkslategrey",
                      borderRadius: "3px",
                      boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                      background: "rgba(255, 255, 255)",
                      padding: "3px",
                      // ##### 24-06-28 Komarov
                      // overflow: "scroll",
                      overflowY: "auto",
                      maxHeight: "30vh",
                      zIndex: "2000",
                      border: "2px solid grey",
                      position: "absolute",
                      display: "block",
                      // left:'160px',
                      top: "609",
                      // left:'20%',
                      // top:'50%'
                    }}
                    // ##### 25-03-12 Komarov
                    items={autocomplete_data_list}
                    getItemValue={(item) => item.name}
                    onChange={optionChecker}
                    onSelect={(value, item) => {
                      localStorage.setItem("city_id", item.city_id);
                      // ##### 25-03-12 Komarov
                      localStorage.setItem("hotel_id", item.id);
                      localStorage.setItem("isCitySelected", item.city);
                      // ##### 25-03-11 Komarov
                      setHotelsValue(value);
                      setOpen(false);
                      setPickedHotelValue(true);
                      setSelectedItemInfo(item);
                    }}
                    renderItem={(item, highlighted) => (
                      <div
                        // ##### 24-04-05 Komarov
                        key={uniqueId()}
                        style={{
                          backgroundColor: highlighted
                            ? "lightblue"
                            : "transparent",
                        }}
                      >
                        {getIcon(item)} {item.name}{" "}
                        {item.city === 0 ? " city" : null}
                      </div>
                    )}
                    shouldItemRender={(item, value) =>
                      value !== ""
                        ? item.name.toLowerCase().includes(value.toLowerCase())
                        : null
                    }
                    open={open}
                    // ##### 24-03-21 Komarov
                    onMenuVisibilityChange={() => setOpen(false)}
                  />
                )) || (
                <div
                  style={{
                    height: "45px",
                    fontFamily: "Tahoma",
                    fontSize: "14px",
                    color: "rgb(75, 73, 73)",
                    border: "none",
                    marginTop: "0.2vw",
                    textAlign: "center",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {placeholder.placeHolderString}
                </div>
              )}
            </div>
          </OutsideClickHandler>
          {/* ##### 23-12-11 Komarov */}
          {searchClicked &&
            (pickedHotelValue === false || hotelsvalue === "") && (
              <Alert message={chooseYourHotel} type="error" />
            )}
        </div>

        {/* ##### 23-12-11 Komarov */}
        <div
          style={{
            gridColumn: "2",
            borderLeft: "0.5px solid grey",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
            // ##### 24-02-02 Komarov
            id="RangePicker"
          >
            {/* ##### 24-01-03 Komarov */}
            <Space direction="horizontal">
              {/* ##### 23-12-18 Komarov */}
              <ConfigProvider locale={dateLocales[locale]}>
                <RangePicker
                  size={"middle"}
                  disabledDate={disabledDate}
                  allowClear={true}
                  onChange={onChange}
                  defaultValue={
                    searchProps && [
                      moment(searchProps.start, dateFormats.en),
                      moment(searchProps.end, dateFormats.en),
                    ]
                  }
                  format={dateFormats[locale]}
                  bordered={false}
                  className={datepickerClass}
                  placeholder={[
                    placeholder.placeHolderStartDate,
                    placeholder.placeHolderEndDate,
                  ]}
                  allowEmpty={searchProps && [false, false]}
                  placement={datePickerPlacement}
                />
              </ConfigProvider>
            </Space>
          </div>
          {/* ##### 23-12-11 Komarov */}
          {searchClicked &&
            pickedStartDate === false &&
            pickedEndDate === false && (
              <Alert message={pickUpYourDatesOfStay} type="error" />
            )}
        </div>

        {/* ##### 23-11-02 Komarov */}
        <Media
          at="Tablet-768-1024"
          style={{
            gridColumn: "3",
            borderLeft: "0.5px solid grey",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <HotelsPaxChoice
            MakeVisible={MakeVisible}
            paxListOpen={paxListOpen}
            searchProps={searchProps}
            pages={useMessages}
          />
        </Media>
        <Media
          lessThan="Tablet-768-1024"
          style={{
            gridColumn: "3",
            borderLeft: "0.5px solid grey",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <HotelsPaxChoice
            MakeVisible={MakeVisible}
            paxListOpen={paxListOpen}
            searchProps={searchProps}
            pages={useMessages}
          />
        </Media>
        <Media
          greaterThan="Tablet-768-1024"
          style={{
            gridColumn: "3",
            borderLeft: "0.5px solid grey",
            display: "flex",
            alignItems: "center",
            // ##### 24-01-30 Komarov
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <HotelsPaxChoice
            MakeVisible={MakeVisible}
            paxListOpen={paxListOpen}
            searchProps={searchProps}
            pages={useMessages}
          />
        </Media>

        <div
          style={{
            gridColumn: "4",
            borderLeft: "0.5px solid grey",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // ##### 23-05-08 Komarov
            position: "relative",
          }}
        >
          {/* ##### 24-08-05 Komarov */}
          {/* TODO: Исправить код в соответствии с разницей понятий refpartner и promoCode */}
          <PromoCode /* refpartner={searchProps ? searchProps.refpartner : ""} */
          />
        </div>

        <button style={{ gridColumn: "5" }} type="submit" onClick={addToList}>
          {
            // ##### 23-10-04 Komarov
            Search
          }
        </button>
      </form>
      {/* ##### 24-05-21 Komarov */}
      <HotelsCitiesAvailabilityHint
        hotelsCitiesAvailabilityHintRef={hotelsCitiesAvailabilityHintRef}
      />
    </div>
  );
};
