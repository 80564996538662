// ##### 25-02-05 Komarov
import { useMessages } from "../../../../../../../helpers/messages";
import { PlaceHolderStrings } from "../../../../../Localization/placeholders";
import { useSelector } from "react-redux";
import uniqueId from "lodash.uniqueid";
import { Select } from "antd";
import { VehicleTypes } from "../../../../../StaticJsonData/VehicleTypes";
import React from "react";

export const TransfersComponent = ({ props }) => {
  const chooseTypeOfVehicle = useMessages("ChooseTypeOfVehicle");
  const { selectVehicleTypes, vehicleTypes, vehicleTypesInitialState } = props;
  const placeholder = PlaceHolderStrings();
  const locale = useSelector((state) => state.language);
  return (
    <div className="groupTravelblocks_2" key={uniqueId()}>
      <span>
        {/* ##### 23-09-07 Komarov */}
        {chooseTypeOfVehicle}
      </span>
      <Select
        onChange={selectVehicleTypes}
        bordered={true}
        style={{ minWidth: "5vw" }}
        key={uniqueId()}
        // ##### 24-03-21 Komarov
        value={
          (vehicleTypes === vehicleTypesInitialState &&
            placeholder.placeHolderSelect) ||
          vehicleTypes
        }
      >
        <>
          {
            // ##### 24-03-21 Komarov
            VehicleTypes(locale)?.map((item) => {
              return (
                <Option value={item} key={item}>
                  {item}
                </Option>
              );
            })
          }
        </>
      </Select>
    </div>
  );
};
