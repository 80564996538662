// ##### 25-02-05 Komarov
import React, { useContext } from "react";
import { BookButtonHotelContext } from "./BookButtonHotel";

export const BookButtonHotelButton = ({ className }) => {
  const {
    AddToBasket,
    Book_Now,
    // ##### 24-04-05 Komarov
    LeaveRequest,
    available_now,
  } = useContext(BookButtonHotelContext);
  // ##### 24-03-21 Komarov
  return (
    <button
      type="button"
      className={className}
      onClick={AddToBasket}
      data-component="BookButtonHotelButton"
    >
      {
        // ##### 24-04-05 Komarov
        (!!available_now && Book_Now) || LeaveRequest
      }
    </button>
  );
};
