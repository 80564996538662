import axios from "../helpers/public.axios";

import {
  // ##### 24-05-24 Komarov
  GET_HOTEL_CONTENT,
  GET_HOTEL_RATES_ERR,
  GET_HOTEL_RATES_REQ,
  GET_HOTEL_RATES_RES,
  // ##### 25-02-15 Komarov
  GET_HOTEL_RATES_ERR2,
  GET_HOTEL_RATES_REQ2,
  GET_HOTEL_RATES_RES2,
  // ##### 24-06-10 Komarov
  RESET_HOTEL_RATES,
} from "../constants";
// ##### 25-01-14 Komarov
import { getPostObject } from "../../helpers/crypto";
// ##### 25-03-28 Komarov
import { postSavingDataClient } from "../../helpers/saveData";

//action creator
export const hotelRatesResponse = (res) => ({
  type: GET_HOTEL_RATES_RES,
  payload: res,
});

// ##### 24-05-24 Komarov
export const hotelContent = (res) => ({
  type: GET_HOTEL_CONTENT,
  payload: res,
});

// ##### 24-06-10 Komarov
export const resetHotelrates = () => ({
  type: RESET_HOTEL_RATES,
});

// ##### 25-01-13 Komarov
export const getHotelRates2 =
  ({
    search_data,
    userId,
    sale,
    company_cipher,
    deviceType,
    refpartner,
    promocode,
    locale,
  }) =>
  async (dispatch) => {
    dispatch({ type: GET_HOTEL_RATES_REQ2 });
    const data = {
      sale_id: (sale && sale) || null,
      promocode,
      deviceType,
      company_cipher: company_cipher || "",
      start: search_data.start, // date of arrival
      end: search_data.end, // date of departure
      city_id: search_data.city_id,
      hotel_id: search_data.hotel_id,
      calculation_data: {
        adults: search_data.adults,
        children: search_data.children,
        // ##### 25-03-11 Komarov
        // lower_cost_limit: 0.0, // lower cost limit of room in USD, NOT OBLIGATORY
        // upper_cost_limit: 200.0, // upper cost limit of room in USD, NOT OBLIGATORY
        quantity: search_data.rooms, // Quantity of rooms, 1 by default, NOT OBLIGATORY
      },
    };

    const postObject = getPostObject({
      action: "GetHotelPriceAlphaRQ",
      user_id: userId,
      refpartner,
      language: locale,
      sale: (sale && sale) || null,
      data,
    });

    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/SecondPageofSearchModule/Hotels/HotelItems.js:useEffect, url:",
        // url
        postObject.url,
      );

    // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
    await postSavingDataClient({
      postObject,
      typeRes: GET_HOTEL_RATES_RES2,
      typeErr: GET_HOTEL_RATES_ERR2,
      dispatch,
    });
  };

// ##### 25-01-13 Komarov
export const getHotelRates =
  ({
    search_data,
    userId,
    sale,
    company_cipher,
    deviceType,
    refpartner,
    promocode,
    locale,
  }) =>
  async (dispatch) => {
    dispatch({ type: GET_HOTEL_RATES_REQ });
    const data = {
      promocode,
      // ##### 24-05-24 Komarov
      deviceType,
      // ##### 25-02-12 Komarov
      company_cipher: company_cipher || "",
      start: search_data.start, // date of arrival
      end: search_data.end, // date of departure
      city_id: search_data.city_id, // Id of city - can`t be equel to zero
      hotel_id: search_data.hotel_id, // Id of hotel: if hotel_id = 0, must return all hotels
      calculation_data: {
        adults: search_data.adults,
        children: search_data.children,
        // "child_discount" : search_data.c !== 0? 0.5: null,
        // ##### 25-03-11 Komarov
        // lower_cost_limit: 0.0, // lower cost limit of room in USD, NOT OBLIGATORY
        // upper_cost_limit: 200.0, // upper cost limit of room in USD, NOT OBLIGATORY
        quantity: search_data.rooms, // Quantity of rooms, 1 by default, NOT OBLIGATORY
      },
    };

    const postObject = getPostObject({
      action: "GetPriceHotelRQ",
      user_id: userId,
      refpartner,
      language: locale,
      sale: (sale && sale) || null,
      data,
    });

    // ##### 25-03-26 Komarov
    await axios
      .post(postObject.url, postObject.parameters)
      // ##### 24-11-01 Komarov
      .then(async (response) => {
        // ##### 24-11-01 Komarov
        if (
          typeof localStorage !== "undefined" &&
          (await response.data.data.city_id)
        ) {
          localStorage.setItem("city_id", await response.data.data.city_id);
        }

        // ##### 24-04-08 Komarov: ("костыль", - нужно, чтобы такой объект формировался в Смарте) Убирааем тарифы с повторяющимися типами из отелей, доступных под запрос (у которых нет объекта dates)
        const hotelsRates = [];
        // ##### 24-11-01 Komarov
        for (const hotelsRate of await response.data.data.hotels) {
          if (hotelsRate.dates) {
            hotelsRates.push(hotelsRate);
          } else {
            const room_rates = [];
            for (const roomRate of hotelsRate.room_rates) {
              const tariffsSet = {};
              for (const tariff of roomRate.tariffs) {
                tariffsSet[tariff.tariff_type_id] = tariff;
              }
              room_rates.push({
                ...roomRate,
                tariffsToShow: Object.values(tariffsSet),
              });
            }
            hotelsRates.push({
              ...hotelsRate,
              room_rates,
            });
          }
        }
        dispatch(hotelRatesResponse(hotelsRates));
        // ##### 24-11-01 Komarov
        dispatch(hotelContent(await response.data.data.hotels_content));
      })
      .catch((error) => {
        dispatch({ type: GET_HOTEL_RATES_ERR, error: error });
      });
  };
