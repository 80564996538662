// ##### 24-09-09 Komarov
import React, { useState, useEffect, createContext, useMemo } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
// ##### 23-09-14 Komarov
// import './SearchItems.css'
import { LoadingMessage } from "../../../../Library/PageDevices/LoadingMessage";
import moment from "moment";
// ##### 23-04-12 Komarov
import "moment/locale/uk";
import config from "../../../../../Redux/config";
// ##### 23-11-02 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
import { UlTag } from "./UlTag";

moment.locale(config.defaultLang);

// ##### 23-10-10 Komarov
export const ItemContentContext = createContext();

export const ItemContent = ({ tour }) => {
  // console.log('[TOUR]' , tour)

  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  const [result, setResult] = useState(false);

  useEffect(() => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/SecondPageofSearchModule/Tours/ItemContent.js:ItemContent"
      );
    // ##### 23-03-27 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/SecondPageofSearchModule/Tours/ItemContent.js:useEffect, url:",
        `${process.env.REACT_APP_SMART_URL}interface/content?id=${tour.tour_id}&language=${locale}`
      );
    axios
      .get(
        `${process.env.REACT_APP_SMART_URL}interface/content?id=${tour.tour_id}&language=${locale}`
      )
      // ##### 24-11-01 Komarov
      .then(async (res) => {
        setResult(await res.data);
      })

      .catch((error) => {
        setResult(undefined);
        // ##### 24-03-21 Komarov
        console.log("[axios error] : ", error);
      });
    //  ##### 23-04-12 Komarov
  }, [tour.tour_id, locale]);

  if (!result) {
    return (
      <div data-component="ItemContent">
        {" "}
        <LoadingMessage
          noTextMessage={true}
          loadingMessageClass={"contentLoadingWheel"}
        />
      </div>
    );
  }

  // ##### 24-09-11 Komarov
  const itemContentContextValue = useMemo(() => ({ result }), [result]);

  return (
    <ItemContentContext.Provider
      value={itemContentContextValue}
    >
      <div style={{ paddingRight: "2vw" }} data-component="ItemContent">
        {/* ##### 23-11-02 Komarov */}
        <Media greaterThanOrEqual="Laptop-1024-1440">
          <UlTag liSummaryWidth="50%" liImageWidth="50%" />
        </Media>
        <Media lessThan="Laptop-1024-1440">
          <UlTag liSummaryWidth="60%" liImageWidth="40%" />
        </Media>
      </div>
    </ItemContentContext.Provider>
  );
};
