// ##### 24-06-14 Komarov
import { CalendarOutlined } from "@ant-design/icons";
import { Select } from "antd";
import uniqueId from "lodash.uniqueid";
import moment from "moment";
import React, { useContext } from "react";
import { CartGallery } from "../../../../Library/PageDevices/CartGallery/CartGallery.js";
import { PaxChoice } from "../PaxChoice.js";
import { RateChoiceBlock } from "../RateChoiceBlock.js";
import { TourDetailsContext } from "./TourDetails.js";

// ##### 23-09-14 Komarov
// import './TourDetailsCSS.css'
// ##### 23-10-10 Komarov
export const DivTag = ({ className, width }) => {
  const {
    details,
    height,
    rateDetails,
    selectionDetails,
    search_data,
    choiceDetailsNew,
    MakeVisible,
    selectedPeriod,
    filteredDetails,
  } = useContext(TourDetailsContext);
  return (
    <div className={className} data-component="DivTag">
      {
        // ##### 24-03-21 Komarov
        details?.map((item) => {
          if (item.content_name === "Image") {
            // console.log('CARTGALLERY',item)
            return (
              // ##### 23-04-12 Komarov
              <div
                key={uniqueId()}
                className="GalleryTourDetails"
                style={{ height: `${height / 2}px` }}
              >
                <CartGallery photos={item} />
              </div>
            );
          }
        })
      }
      <div
        className="TourBookingDetails"
        // ##### 23-02-22 Komarov
        style={{
          width: width,
        }}
      >
        <h3>Tour Booking Details {rateDetails[0].duration}</h3>

        <div className="ChosenCalendar">
          <div style={{ textAlign: "right" }}>
            <CalendarOutlined
              style={{
                fontSize: "2vw",
                border: "2px solid white",
                color: "#102D69",
              }}
            />
          </div>

          <h4
            style={{
              color: "rgb(77, 75, 75)",
              fontSize: "17px",
              fontFamily: "Arial Narrow",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            Chosen date of travel :
          </h4>

          <h4 className="DateSelection">{selectionDetails}</h4>
        </div>

        <RateChoiceBlock
          //  totalPax={total}
          tour_id={search_data.tour_id}
          selectionDetails={selectionDetails}
          choiceDetailsNew={choiceDetailsNew}
        />

        <h3 className="TourUpdateBlock">Update your tour booking details</h3>

        <div className="TourBookingChoice">
          <div
            style={{
              gridColumn: "1",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <PaxChoice
              selectionDetails={selectionDetails}
              tour_id={search_data.tour_id}
              MakeVisible={MakeVisible}
              open={open}
              choiceDetailsNew={choiceDetailsNew}
            />
          </div>

          <div style={{ gridColumn: "2" }}>
            <h4>Available dates :</h4>
            <Select
              defaultValue={selectionDetails}
              onChange={selectedPeriod}
              bordered={true}
              size="medium"
            >
              <>
                {
                  // ##### 24-03-21 Komarov
                  filteredDetails?.map((filter) => {
                    return (
                      <Option
                        value={filter.date}
                        // ##### 23-04-12 Komarov
                        key={uniqueId()}
                      >
                        {filter.date} --{" "}
                        {moment(filter.date)
                          .add(rateDetails[0].duration, "days")
                          .format("YYYY-MM-DD")}{" "}
                        ({rateDetails[0].duration} days)
                      </Option>
                    );
                  })
                }
              </>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
};
