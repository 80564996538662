import React from "react";
import zero from "../Images/Numbers_small/uk/0-2-u.png";
import one from "../Images/Numbers_small/uk/1-3-u.png";
import two from "../Images/Numbers_small/uk/2-1-u.png";
import three from "../Images/Numbers_small/uk/3-4-u.png";
import four from "../Images/Numbers_small/uk/4-1-u.png";
import five from "../Images/Numbers_small/uk/5-4-u.png";
import six from "../Images/Numbers_small/uk/6-4-u.png";
import seven from "../Images/Numbers_small/uk/7-4-u.png";
import eight from "../Images/Numbers_small/uk/8-1-u.png";
import nine from "../Images/Numbers_small/uk/9-3-u.png";

// ##### 23-09-14 Komarov
// import '../PageDevices/Animation/yearanimationCSS.css';

export const YearBuilder = () => {
  const numbers = [zero, one, two, three, four, five, six, seven, eight, nine];

  const numbersAlts = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ];

  // ##### 24-03-21 Komarov
  const currentYear = new Date().getFullYear().toString();

  // ##### 24-06-17 Komarov
  return (
    <div className="svgContainer yearHeader">
      <img src={numbers[currentYear[0]]} alt={numbersAlts[currentYear[0]]} />
      <img src={numbers[currentYear[1]]} alt={numbersAlts[currentYear[1]]} />
      <img src={numbers[currentYear[2]]} alt={numbersAlts[currentYear[2]]} />
      <img src={numbers[currentYear[3]]} alt={numbersAlts[currentYear[3]]} />
    </div>
  );
};
