// ##### 24-09-09 Komarov
import React, { createContext, useMemo } from "react";
import { MediumScreenTopHotelsDiv } from "./MediumScreenTopHotelsDiv";

// ##### 24-09-09 Komarov
export const MediumScreenTopHotels = ({
  TopHotelContents,
  GetHotelDetails,
}) => {
  const mediumScreenTopHotelsContextValue = useMemo(
    () => ({
      TopHotelContents,
      GetHotelDetails,
    }),
    [
      // ##### 24-09-11 Komarov
      TopHotelContents,
      GetHotelDetails,
    ],
  );
  return (
    <MediumScreenTopHotelsContext.Provider
      value={mediumScreenTopHotelsContextValue}
    >
      <MediumScreenTopHotelsDiv
        // ##### 23-11-08 Komarov
        // width='29vw'
        width="45vw"
      />
    </MediumScreenTopHotelsContext.Provider>
  );
}; // ##### 23-10-10 Komarov

export const MediumScreenTopHotelsContext = createContext();
