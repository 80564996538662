// ##### 24-06-13 Komarov
import React from "react";
import { useSelector } from "react-redux";
import { Media } from "../../../../../helpers/MediaConfig.js";
import { PopUp } from "./PopUp.js";
import { FrontHotelPaxResult } from "./FrontHotelPaxResult.js";

// ##### 23-09-14 Komarov
// import './HotelsAutocompleteCSS.css'
// import 'antd/dist/antd.css';
// ##### 23-10-10 Komarov
export const HotelsPaxChoiceDiv = ({ className, style = null }) => {
  // ##### 24-05-10 Komarov
  const deviceType = useSelector((state) => state.deviceType);
  return (
    <div
      data-component="HotelsPaxChoiceDiv"
      // ##### 23-02-14 Komarov: TODO: условие установки className в этом, клиентском, файле (src/Components/Pages/FirstPageofSearchModule/HotelsAutocompleteBlock/HotelsPaxChoice.js) и в серверном (src/Components/Pages/FirstPageofSearchModule/HotelsAutocompleteBlock/HotelsPaxChoiceSsr.js) отличаются. Нужно проверить, какое правильное. Пока поставил с серверного файла
      className={className}
      // ##### 22-11-14 Komarov
      style={style}
    >
      {/* ##### 24-05-13 Komarov */}
      <div
        className={deviceType === "desktop"
          ? "FrontHotelPaxResult"
          : "FrontHotelPaxResultSmallScreen"}
      >
        <Media lessThan="Mobile-L-425-768">
          <FrontHotelPaxResult
            style={{
              border: "#e59700",
              borderStyle: "solid",
              borderRadius: "1rem",
              height: "37px",
            }} />
          {/* ##### 23-11-13 Komarov */}
          <PopUp
            style={{
              width: "72vw",
            }}
            // ##### 23-11-14 Komarov
            screenIsSmall />
        </Media>
        <Media between={["Mobile-L-425-768", "Laptop-1024-1440"]}>
          <FrontHotelPaxResult
            style={{
              border: "#e59700",
              borderStyle: "solid",
              borderRadius: "1rem",
              height: "37px",
            }} />
          {/* ##### 23-11-13 Komarov */}
          <PopUp
            style={{
              width: "72vw",
            }}
            screenIsSmall />
        </Media>
        <Media
          greaterThanOrEqual="Laptop-1024-1440"
          // ##### 24-01-30 Komarov
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <FrontHotelPaxResult />
          {/* ##### 23-11-13 Komarov */}
          <PopUp
            style={{
              width: null,
            }} />
        </Media>
      </div>
    </div>
  );
};
