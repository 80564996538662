// ##### 24-09-09 Komarov
import React, { createContext, useMemo } from "react";
// ##### 24-03-01 Komarov
// ##### 23-09-14 Komarov
// import './BookingForm.css'
// ##### 25-02-05 Komarov
import { useDispatch, useSelector } from "react-redux";
// ##### 23-03-29 Komarov
import moment from "moment";
import { useLocation } from "react-router-dom";

import { ValidateQuery } from "../Helpers/helper";
// ##### 25-01-22 Komarov
import { getPickedCurrencyResponse } from "../../../Redux/actions";
import config from "../../../Redux/config";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../helpers/messages";
// ##### 23-11-08 Komarov
import { Media } from "../../../helpers/MediaConfig";
// ##### 24-12-23 Komarov
import { encrypt } from "../../../helpers/crypto";
// ##### 25-02-05 Komarov
import { RegWrapperDiv } from "./RegWrapperDiv";

moment.locale(config.defaultLang);

// ##### 25-02-05 Komarov
export const BookingFormContext = createContext();

// ##### 24-05-31 Komarov
export const BookingForm = () => {
  // ##### 24-04-23 Komarov
  const BookingForm_Message_1 = useMessages("BookingForm Message_1");
  const CanxPolicySummary_2 = useMessages("CanxPolicySummary_2");
  const CanxPolicySummary = useMessages("CanxPolicySummary");
  const CanxPolicySummary_3 = useMessages("CanxPolicySummary_3");

  const location = useLocation();
  // ##### 23-03-29 Komarov
  const dispatch = useDispatch();

  const shouldCypherUrlParams = useSelector(
    (state) => !!parseInt(state.siteCypherCodeData.data.value),
  );
  // ##### 25-02-28 Komarov
  const cypherKey = useSelector(
    (state) => state.siteCypherCodeData.data.description,
  );
  const search_data = ValidateQuery(location, shouldCypherUrlParams, cypherKey);

  dispatch(getPickedCurrencyResponse(search_data.selected_currency));

  const lastLocation = location.pathname + location.search;
  localStorage.setItem("lastLocation", lastLocation);

  localStorage.setItem("search_data", encrypt(search_data));
  // ##### 24-03-01 Komarov
  const company_cipher = useSelector(
    (state) => state.whitelabel.company_cipher,
  );

  localStorage.setItem("cipherCompanyCipher", encrypt(company_cipher));

  const date_difference = moment(search_data.start).diff(
    moment(moment().format("YYYY-MM-DD")),
    "days",
  );

  let canx_deadline_date;

  if (
    parseInt(search_data.service_type_id) === parseInt("11") &&
    parseInt(search_data.nrf) === parseInt("0")
  ) {
    canx_deadline_date = moment(search_data.start)
      .subtract(7, "days")
      .calendar();
  } else if (
    parseInt(search_data.service_type_id) === parseInt("1") &&
    parseInt(search_data.nrf) === parseInt("0")
  ) {
    canx_deadline_date = moment(search_data.start)
      .subtract(1, "days")
      .calendar();
  } else if (parseInt(search_data.nrf) === parseInt("1")) {
    canx_deadline_date = moment(search_data.start).calendar();
  }

  // ##### 24-09-10 Komarov
  const bookingFormContextValue = useMemo(
    () => ({ search_data }),
    [search_data],
  ); // value is cached by useMemo

  return (
    <div className="FormWrapper" data-component="BookingForm">
      <h2>
        {
          // ##### 23-10-04 Komarov
          BookingForm_Message_1
        }
      </h2>
      <h3 className="HeadPolicies">
        <span>
          {parseInt(search_data.service_type_id) === parseInt("11") ? (
            date_difference < 7 ? (
              // ##### 23-10-06 Komarov: TODO: Взять перевод из Смарта
              <span>
                Your booking is for arrivals in less then 7 days. In case of
                cancellation, the fee will amount to 100% of the reservaton cost
              </span>
            ) : (
              <>
                <span style={{ marginRight: "5px" }}>
                  {
                    // ##### 23-10-04 Komarov
                    CanxPolicySummary_2
                  }
                  {/* ##### 23-11-09 Komarov */}
                  {/* <span style={{ marginLeft: '5px' }}>{moment(canx_deadline_date).format('YYYY-MM-DD')}</span> */}
                  <span style={{ marginLeft: "5px" }}>
                    {canx_deadline_date}
                  </span>
                </span>
                <span>
                  {
                    // ##### 23-10-04 Komarov
                    CanxPolicySummary
                  }
                </span>
              </>
            )
          ) : parseInt(search_data.service_type_id) === parseInt("1") &&
            parseInt(search_data.nrf) === parseInt("0") ? (
            <>
              <span style={{ marginRight: "5px" }}>
                {
                  // ##### 23-10-04 Komarov
                  CanxPolicySummary_2
                }
                {/* ##### 23-11-09 Komarov */}
                {/* <span style={{ marginLeft: '5px' }}>{moment(canx_deadline_date).format('YYYY-MM-DD')}</span> */}
                <span style={{ marginLeft: "5px" }}>{canx_deadline_date}</span>
              </span>
              <span>
                {
                  // ##### 23-10-04 Komarov
                  CanxPolicySummary
                }
              </span>
            </>
          ) : parseInt(search_data.service_type_id) === parseInt("1") &&
            parseInt(search_data.nrf) === parseInt("1") ? (
            <>
              <span style={{ marginRight: "5px" }}>
                {
                  // ##### 23-10-04 Komarov
                  CanxPolicySummary_2
                }
                {/* ##### 23-11-09 Komarov */}
                {/* <span style={{ marginLeft: '5px' }}>{moment(canx_deadline_date).format('YYYY-MM-DD')}</span> */}
                <span style={{ marginLeft: "5px" }}>{canx_deadline_date}</span>
              </span>
              <span>
                {
                  // ##### 23-10-04 Komarov
                  CanxPolicySummary_3
                }
              </span>
            </>
          ) : null}
        </span>
      </h3>
      {/* ##### 23-04-20 Komarov */}
      <BookingFormContext.Provider value={bookingFormContextValue}>
        {/* ##### 23-11-09 Komarov */}
        <Media greaterThanOrEqual="Laptop-1024-1440" className="RegWrapper">
          <RegWrapperDiv formClassName="myForm" cartClass="CartDetails" />
        </Media>
        <Media lessThan="Laptop-1024-1440" className="RegWrapperSmallScreen">
          <RegWrapperDiv
            formClassName="myFormSmallScreen"
            cartClass="CartDetailsSmallScreen"
          />
        </Media>
      </BookingFormContext.Provider>
    </div>
  );
};
