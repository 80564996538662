// ##### 24-05-13 Komarov
import React, { useRef } from "react";
// ##### 24-05-13 Komarov
import { CalendarHeader } from "./CalendarHeader";
import { WeekDays } from "./WeekDays";
import { YearsMonths } from "./YearsMonths";
import { OkButton } from "./OkButton";
// ##### 24-05-13 Komarov

// ##### 23-08-07 Komarov
export const HotelDatesComponent = () => {
  // ##### 24-01-19 Komarov: набор отмеченных дат заезда и выезда
  const markedDates = useRef(new Set());
  // ##### 24-01-22 Komarov: Ссылка на элемент CalendarHeader на странице
  const calendarHeader = useRef();

  // ##### 23-11-23 Komarov
  return (
    <div
      data-component="HotelDatesComponent"
      // ##### 24-05-15 Komarov
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        // ##### 24-06-10 Komarov
        // height: "inherit",
        // height: window.innerHeight,
      }}
      id="HotelDatesComponent"
    >
      <CalendarHeader
        markedDates={markedDates}
        calendarHeader={calendarHeader}
      />
      {/* ##### 24-01-15 Komarov: область отображения заголовка с Днями недели */}
      <WeekDays />
      {/* ##### 24-01-15 Komarov: область отображения календарей месяцев */}
      <YearsMonths markedDates={markedDates} calendarHeader={calendarHeader} />
      {/* ##### 24-05-16 Komarov: Кнопка OK для закрытия div`а с содержимым HotelDates */}
      {/* <OkButton markedDates={markedDates} /> */}
    </div>
  );
}; // ##### 23-11-23 Komarov
