// ##### 25-02-05 Komarov
import React from "react";
// ##### 23-10-18 Komarov
import { Media } from "../../../../helpers/MediaConfig";
// ##### 25-02-05 Komarov
import { CurrencySelect } from "./CurrencySelect";

// ##### 25-02-21 Komarov
export const CurrencySelectionTool = () => {
  return (
    <div style={{ marginRight: "1vw" }}>
      {/* ##### 23-11-02 Komarov */}
      <Media lessThan="Mobile-L-425-768">
        <CurrencySelect
          dropdownStyle={{
            scale: "none",
            transformOrigin: "top left",
          }}
        />
      </Media>
      <Media between={["Mobile-L-425-768", "Laptop-1024-1440"]}>
        <CurrencySelect
          dropdownStyle={{
            scale: "1.1",
            transformOrigin: "top left",
          }}
        />
      </Media>
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <CurrencySelect />
      </Media>
    </div>
  );
};
