// ##### 25-02-21 Komarov
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ValidateQuery } from "../../Helpers/helper";
import { Select } from "antd";
import {
  getCurrency,
  getPickedCurrencyResponse,
} from "../../../../Redux/actions";
import { OptionContent } from "./OptionContent";

export const CurrencySelect = ({ dropdownStyle = null }) => {
  // ##### 25-02-21 Komarov
  const deviceType = useSelector((state) => state.deviceType);

  // ##### 24-02-22 Komarov
  const location = useLocation();
  // ##### 25-01-15 Komarov
  const shouldCypherUrlParams = useSelector(
    (state) => !!parseInt(state.siteCypherCodeData.data.value),
  );
  // ##### 25-02-28 Komarov
  const cypherKey = useSelector(
    (state) => state.siteCypherCodeData.data.description,
  );
  const search_data = ValidateQuery(location, shouldCypherUrlParams, cypherKey);

  // ##### 22-08-29 Komarov: Добавил значение валюты по умолчанию, если оно отсутствует в path.selected_currency
  // ##### 25-02-21 Komarov
  const [currency, setCurrency] = useState(
    search_data.selected_currency || "UAH",
  );
  const { Option } = Select;
  // ##### 24-02-22 Komarov
  useEffect(() => {
    localStorage.setItem("current_currency", currency);
    // ##### 24-07-30 Komarov
    dispatch(getPickedCurrencyResponse(currency));
  }, [currency]);

  // ##### 24-07-25 Komarov
  const currenciesFromState = useSelector((state) => state.currency.currencies);
  // ##### 25-03-05 Komarov
  /* const currencies = (currenciesFromState.length > 0 && currenciesFromState)
    || JSON.parse(localStorage.getItem("currency")).currencies; */
  const currencies = currenciesFromState.length > 0 && currenciesFromState;

  // ##### 25-03-06 Komarov
  useEffect(() => {
    currencies.length > 0 || dispatch(getCurrency());
  }, [currencies.length]);

  // ##### 24-02-22 Komarov
  const dispatch = useDispatch();
  const history = useHistory();

  // ##### 23-02-14 Komarov
  const SelectCurrency = (value) => {
    setCurrency(value);
    // ##### 24-02-21 Komarov
    // history.push(`/${locale}/?selected_currency=${value}`)
    history.push(
      `${location.pathname}${location.search.replace(
        search_data.selected_currency,
        value,
      )}`,
    );
    // ##### 24-02-22 Komarov
    localStorage.setItem("current_currency", value);
    dispatch(getPickedCurrencyResponse(value));
  };

  return (
    <Select
      defaultValue={currency}
      dropdownStyle={dropdownStyle}
      onChange={SelectCurrency}
      bordered={true}
      // ##### 24-03-21 Komarov
      size={deviceType !== "tablet" ? "small" : "middle"}
    >
      <>
        {/* ##### 25-03-06 Komarov */}
        {currencies.length > 0 &&
          currencies.map((item) => {
            // ##### 22-11-14 Komarov
            return (
              <Option value={item.name} key={item.id}>
                <OptionContent item={item} />
              </Option>
            );
          })}
      </>
    </Select>
  );
};
