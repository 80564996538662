// ##### 25-03-06 Komarov
import {
  GET_LANG_REQ,
  GET_LANG_RES,
  GET_LOCALE_REQ,
  GET_LOCALE_RES,
} from "../constants";

export const changeLangResponce = (res) => ({
  type: GET_LOCALE_RES,
  payload: res,
});

export const changeLang = (locale) => (dispatch) => {
  dispatch({ type: GET_LOCALE_REQ });
  dispatch(changeLangResponce(locale));
};

export const langRepsonse = (res) => ({
  type: GET_LANG_RES,
  payload: res,
});

// ##### 24-03-21 Komarov
export const getLangResponse = (param) => (dispatch) => {
  dispatch({ type: GET_LANG_REQ });
  dispatch(langRepsonse(param));
};
