// ##### 25-03-11 Komarov
import en from "antd/es/locale/en_US";
import uk from "antd/es/locale/uk_UA";
import tr from "antd/es/locale/tr_TR";
import fr from "antd/es/locale/fr_FR";
import ru from "antd/es/locale/ru_RU";

export const dateLocales = {
  en,
  uk,
  tr,
  fr,
  ru,
};
