// ##### 23-09-14 Komarov
import React from "react";
// ##### 23-04-12 Komarov
import uniqueId from "lodash.uniqueid";
import { Pax } from "../../../../Library/Icons/pax";
import { AvailableOptions } from "./AvailableOptions";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../../helpers/messages";
// ##### 24-08-05 Komarov
import { useSelector } from "react-redux";

// ##### 24-11-02 Komarov
export const LargeScreenRatesBlock = ({
                                        hotelratesRatesBlock,
                                        search_data
                                      }) => {
  // ##### 24-04-13 Komarov
  const available_now_hotel = !!hotelratesRatesBlock[0].available_now;
  const available_now_hotel_dates_closed =
    !available_now_hotel && hotelratesRatesBlock[0].dates;

  // ##### 24-04-23 Komarov
  const hotelRateGridTitles = [
    useMessages("Room type"),
    useMessages("Pax"),
    useMessages("Tariff"),
    useMessages("TotalCostShort"),
    useMessages("AvailabilityShort"),
    useMessages("Book Now")
  ];
  const hotelOnRequestRateGridTitles = [
    useMessages("Room type"),
    useMessages("Tariff"),
    useMessages("LeaveRequest")
  ];
  const hotelOnRequestRatePricesGridTitles = [
    useMessages("Room type"),
    useMessages("Pax"),
    useMessages("Tariff"),
    useMessages("TotalCostShort"),
    useMessages("LeaveRequest")
  ];

  let tariff_policy;
  // ##### 24-03-21 Komarov
  const empty_array = [];
  // ##### 24-08-05 Komarov
  const company_id = useSelector(state => state.auth.user.company_id);

  // ##### 24-11-02 Komarov
  /*
  * Примеры:
  *
  * available_now_hotel:
  * http://localhost:9090/uk/hotel_details/?selected_currency=UAH&start=2024-11-03&end=2024-11-04&title=Nadiya%20Hotel&id=173&city_id=40&adults=1&children=0&rooms=1&hotel_name=Nadiya%20Hotel&hotel_id=173
  *
  * available_now_hotel_dates_closed:
  * http://localhost:9090/uk/hotel_details/?selected_currency=UAH&cityName=%D0%9A%D0%B8%D1%97%D0%B2&start=2024-12-25&end=2024-12-28&id=145&city_id=19&adults=1&children=0&rooms=1&hotel_name=Manufactura%20Hotel&hotel_id=145
  *
  * Иначе:
  * http://localhost:9090/uk/hotel_details/?selected_currency=UAH&cityName=%D0%90%D0%B4%D1%80%D1%96%D1%8F%20%D0%9A%D0%B8%D1%97%D0%B2&start=2024-11-27&end=2024-11-28&id=196&city_id=19&adults=1&children=0&rooms=1&hotel_name=Adria%20Hotel%20Kyiv&hotel_id=196*/

  // ##### 24-11-02 Komarov
  const RatesBlockHeader = () => {
    const tableTitles = (available_now_hotel && hotelRateGridTitles)
      || (available_now_hotel_dates_closed && hotelOnRequestRatePricesGridTitles)
      || hotelOnRequestRateGridTitles;

    const tableTitlesClassName = (available_now_hotel && "RatesGridWrapper")
      || (available_now_hotel_dates_closed && "RatesGridWrapperOnRequestPrices")
      || "RatesGridWrapperOnRequest";

    return <ul className={`${tableTitlesClassName} Header`}>
      {
        // ##### 24-03-21 Komarov
        tableTitles.map((title) => {
          return (
            // ##### 23-04-12 Komarov
            <li key={uniqueId()} className="RatesGridHeader">
              {title}
            </li>
          );
        })
      }
    </ul>;
  };

  // ##### 24-11-02 Komarov
  const RoomType = ({ room_rate }) => <h5 className="RoomTypeColumn">
    {/* ##### 24-01-25 Komarov */}
    {room_rate.room_name === "0"
      ? "Regular"
      : room_rate.room_name}
    {/* {item.room_id} */}
  </h5>;

  // ##### 24-11-02 Komarov
  const RatesOfAvailableNowHotel = ({ hotelrate }) => {
    return hotelrate.room_rates.map((room_rate) => {
      return hotelrate.dates.map(
        (hotelrate_date, indexRoomType) => {
          if (hotelrate_date.date === search_data.start) {
            return hotelrate_date.rooms.map(
              (hotelrate_date_room) => {
                if (
                  parseInt(hotelrate_date_room.room_id) ===
                  parseInt(room_rate.room_id)
                ) {
                  return (
                    // ##### 23-04-12 Komarov
                    <li key={uniqueId()} className="Details">
                      <RoomType room_rate={room_rate} />
                      <>
                        {hotelrate_date_room.tariffs.map(
                          (hotelrate_date_room_tariff) => {
                            // ##### 24-04-17 Komarov
                            const tariff_type_name =
                              room_rate.tariffs.find(
                                (tariff) =>
                                  tariff.tariff_id ===
                                  hotelrate_date_room_tariff.tariff_id
                              )?.tariff_type_name;
                            const available_now = !!parseInt(
                              hotelrate_date_room_tariff.available_now
                            );
                            return hotelrate_date_room_tariff.prices.map(
                              (
                                hotelrate_date_room_tariff_price,
                                indexPrices
                              ) => {
                                empty_array.length =
                                  hotelrate_date_room_tariff_price.pax;
                                // ##### 23-07-03 Komarov
                                return (
                                  <React.Fragment key={uniqueId()}>
                                    {/* ##### 23-07-03 Komarov */}
                                    <h5
                                      key={uniqueId()}
                                      style={{
                                        gridColumn: "2",
                                        borderBottom:
                                          "2px solid rgb(109, 109, 196)",
                                        borderRight:
                                          "2px solid rgb(109, 109, 196)",
                                        borderLeft:
                                          "2px solid rgb(109, 109, 196)",
                                        fontSize: "17px",
                                        color: "#102D69",
                                        fontFamily: "Arial",
                                        fontWeight: "bold",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                      }}
                                    >
                                      {
                                        // ##### 24-03-21 Komarov
                                        empty_array
                                          .fill(null)
                                          .map(() => {
                                            return (
                                              // ##### 23-04-12 Komarov
                                              <Pax
                                                key={uniqueId()}
                                              />
                                            );
                                          })
                                      }
                                    </h5>

                                    {/* ##### 23-07-03 Komarov */}
                                    <div
                                      style={{
                                        gridColumn: "3",
                                        borderBottom:
                                          "2px solid rgb(109, 109, 196)",
                                        borderRight:
                                          "2px solid rgb(109, 109, 196)",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                      }}
                                    >
                                      {
                                        // ##### 24-04-05 Komarov
                                        room_rate.tariffs?.map(
                                          (room_rate_tariff) => {
                                            if (
                                              parseInt(
                                                room_rate_tariff.tariff_id
                                              ) ===
                                              parseInt(
                                                hotelrate_date_room_tariff.tariff_id
                                              )
                                            ) {
                                              tariff_policy =
                                                room_rate_tariff.tariff_nrf;
                                              return (
                                                // ##### 23-04-12 Komarov
                                                <h5
                                                  key={uniqueId()}
                                                  style={{
                                                    fontSize:
                                                      "17px",
                                                    color: "blue",
                                                    fontFamily:
                                                      "Arial",
                                                    fontWeight:
                                                      "bold"
                                                  }}
                                                >
                                                  <span>
                                                    {
                                                      room_rate_tariff.tariff_type_name
                                                    }
                                                  </span>
                                                  <span
                                                    style={{
                                                      color:
                                                        "darkred",
                                                      marginLeft:
                                                        "5px",
                                                      fontStyle:
                                                        "italic"
                                                    }}
                                                  >
                                                    {parseInt(
                                                      room_rate_tariff.tariff_nrf
                                                    ) ===
                                                    parseInt("1")
                                                      ? "Non Refundable"
                                                      : null}
                                                  </span>
                                                </h5>
                                              );
                                            }
                                          }
                                        )
                                      }
                                      {/* ##### 23-07-03 Komarov */}
                                    </div>
                                    {/* ##### 23-07-03 Komarov */}
                                    <div
                                      key={uniqueId()}
                                      className={
                                        (available_now &&
                                          "WrapperAvailableOptions") ||
                                        "WrapperAvailableOptionsOnRequest"
                                      }
                                    >
                                      <AvailableOptions
                                        index={[
                                          indexRoomType,
                                          indexPrices
                                        ].join("")}
                                        currency={
                                          hotelrate.currency
                                        }
                                        contract_id={
                                          hotelrate.contract_id
                                        }
                                        rooms={search_data.rooms}
                                        room_id={room_rate.room_id}
                                        room_name={
                                          room_rate.room_name
                                        }
                                        room_type_id={
                                          room_rate.room_type_id
                                        }
                                        room_type_name={
                                          room_rate.room_type_name
                                        }
                                        // ##### 24-04-17 Komarov
                                        tariff_type_name={
                                          tariff_type_name
                                        }
                                        // room_subcategory_id={item.room_subcategory_id}
                                        // room_subcategory_name={item.room_subcategory_name}
                                        occupancy={
                                          hotelrate_date_room_tariff_price.pax
                                        }
                                        sum={
                                          hotelrate_date_room_tariff_price.sum
                                        }
                                        pickedCurrency={
                                          search_data.selected_currency
                                        }
                                        tariff_id={
                                          hotelrate_date_room_tariff.tariff_id
                                        }
                                        availability={
                                          hotelrate_date_room_tariff.availability
                                        }

                                        // ##### 24-08-05 Komarov: TODO: Исправить код в соответствии с задачей убрать Промокод из URL
                                        refpartner={
                                          /* search_data.refpartner
                                            ? search_data.refpartner
                                            : */ company_id
                                        }
                                        tariff_policy={
                                          tariff_policy
                                        }
                                        start={search_data.start}
                                        end={search_data.end}
                                        available_now={
                                          available_now
                                        }
                                        // ##### 24-04-13 Komarov
                                        available_now_hotel={
                                          available_now_hotel ||
                                          available_now_hotel_dates_closed
                                        }
                                      />
                                      {/* ##### 23-07-03 Komarov */}
                                    </div>
                                  </React.Fragment>
                                );
                              }
                            );
                          }
                        )}
                      </>
                    </li>
                  );
                }
              }
            );
          }
        }
      );
    });
  };

  // ##### 24-11-02 Komarov
  const RatesOfOnRequestHotel = ({ hotelrate }) => {
    return hotelrate.room_rates.map((room_rate) => {
      return (
        <li key={uniqueId()} className="DetailsOnRequest">
          <h5
            className="RoomTypeColumn"
            style={{
              gridRow: `1 / span ${room_rate.tariffsToShow.length}`
            }}
          >
            {room_rate.room_name === "0"
              ? "Regular"
              : room_rate.room_name}
          </h5>
          <>
            {room_rate.tariffsToShow.map((room_rate_tariff) => {
              // ##### 24-04-17 Komarov
              const tariff_type_name = room_rate.tariffs.find(
                (tariff) =>
                  tariff.tariff_id === room_rate_tariff.tariff_id
              )?.tariff_type_name;
              return (
                <React.Fragment key={uniqueId()}>
                  <div
                    style={{
                      gridColumn: "2",
                      borderBottom: "2px solid rgb(109, 109, 196)",
                      borderRight: "2px solid rgb(109, 109, 196)",
                      borderLeft: "2px solid rgb(109, 109, 196)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    {room_rate.tariffsToShow?.map((tariff) => {
                      if (
                        parseInt(tariff.tariff_id) ===
                        parseInt(room_rate_tariff.tariff_id)
                      ) {
                        tariff_policy = tariff.tariff_nrf;
                        return (
                          <h5
                            key={uniqueId()}
                            style={{
                              fontSize: "17px",
                              color: "blue",
                              fontFamily: "Arial",
                              fontWeight: "bold"
                            }}
                          >
                            <span>{tariff.tariff_type_name}</span>
                            <span
                              style={{
                                color: "darkred",
                                marginLeft: "5px",
                                fontStyle: "italic"
                              }}
                            >
                              {parseInt(tariff.tariff_nrf) ===
                              parseInt("1")
                                ? "Non Refundable"
                                : null}
                            </span>
                          </h5>
                        );
                      }
                    })}
                  </div>
                  <div
                    key={uniqueId()}
                    className="WrapperAvailableOptionsHotelOnRequest"
                  >
                    <AvailableOptions
                      contract_id={hotelrate.contract_id}
                      rooms={search_data.rooms}
                      room_id={room_rate.room_id}
                      room_name={room_rate.room_name}
                      room_type_id={room_rate.room_type_id}
                      room_type_name={room_rate.room_type_name}
                      // ##### 24-04-17 Komarov
                      tariff_type_name={tariff_type_name}
                      pickedCurrency={search_data.selected_currency}
                      tariff_id={room_rate_tariff.tariff_id}
                      availability={room_rate_tariff.availability}
                      // ##### 24-08-05 Komarov: TODO: Исправить код в соответствии с задачей убрать Промокод из URL
                      refpartner={company_id}
                      tariff_policy={tariff_policy}
                      start={search_data.start}
                      end={search_data.end}
                      // ##### 24-04-05 Komarov: Так как в эту ветку кода попадают целиком отели, у которых нет доступных на продажу с аллотмента номеров, то здесь и указываем значение "available_now_hotel"
                      available_now={available_now_hotel}
                      available_now_hotel={available_now_hotel}
                    />
                  </div>
                </React.Fragment>
              );
            })}
          </>
        </li>
      );
    });
  };

  // ##### 24-11-02 Komarov
  const RatesBlockContent = () => {
    return <ul className="GridUlDetails">
      {/* ##### 24-05-31 Komarov */}
      {
        hotelratesRatesBlock &&
        hotelratesRatesBlock.length > 0 &&
        parseInt(hotelratesRatesBlock[0].hotel_id) === parseInt(search_data.id) &&
        hotelratesRatesBlock.map((hotelrate) => {
          // ##### 24-04-05 Komarov
          if (hotelrate.dates) {
            return <RatesOfAvailableNowHotel hotelrate={hotelrate} />;
          } else {
            return <RatesOfOnRequestHotel hotelrate={hotelrate} />;
          }
        })
      }
    </ul>;
  };

  // ##### 24-11-02 Komarov
  return (
    // ##### 23-11-08 Komarov
    <div
      data-component="LargeScreenRatesBlock"
      // className={className}
    >
      {/* ##### 24-11-02 Komarov */}
      <RatesBlockHeader />
      <RatesBlockContent />
    </div>
  );
};
