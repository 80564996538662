// ##### 24-06-12 Komarov
import React from "react";
import { Media } from "../../../../../helpers/MediaConfig";
import { TopMenuRightDivPartForDesktop } from "./TopMenuRightDivPartForDesktop";
import { TopMenuRightDivPartForNotDesktop } from "./TopMenuRightDivPartForNotDesktop";

// @@@@@ 23-03-03 Komarov
export const TopMenuRightDiv = ({ className }) => (
  <div className={className} data-component="TopMenuRightDiv">
    {/* TODO: Вставить сюда перевод текста "LOG OUT" */}
    {/* TODO: Вставить сюда перевод текста "SIGN IN" */}

    {/* ##### 24-06-12 Komarov */}
    <Media lessThan="Laptop-1024-1440">
      <TopMenuRightDivPartForNotDesktop />
    </Media>
    <TopMenuRightDivPartForDesktop />
  </div>
);
