// ##### 25-02-05 Komarov
import { SpaceTag } from "./SpaceTag";
import React from "react";

export const TourMonthDiv = ({ transform }) => (
  <div style={{ transform }} data-component="TourMonthDiv">
    {/* ##### 23-11-21 Komarov */}
    <SpaceTag />
  </div>
);
