import {
  // ##### 25-03-14 Komarov
  GET_HOTEL_NAME_ERR,
  GET_HOTEL_NAME_REQ,
  GET_HOTEL_NAME_RES,
  // ##### 25-03-14 Komarov
  GET_HOTELS_OVERVIEW_ALL_ERR,
  GET_HOTELS_OVERVIEW_ALL_REQ,
  GET_HOTELS_OVERVIEW_ALL_RES,
  GET_HOTELS_OVERVIEW_TOP_ERR,
  GET_HOTELS_OVERVIEW_TOP_REQ,
  GET_HOTELS_OVERVIEW_TOP_RES,
  // ##### 25-03-14 Komarov
  GET_HOTELS_AVAIL_REQ,
  GET_HOTELS_AVAIL_RES,
  GET_HOTELS_AVAIL_ERR,
  // ##### 25-03-12 Komarov
  GET_AUTOCOMPLETE_DATA_REQ,
  GET_AUTOCOMPLETE_DATA_RES,
  GET_AUTOCOMPLETE_DATA_ERR,
  // ##### 25-02-21 Komarov
  RESET_HOTELS_AVAIL,
} from "../constants";

const initState = {
  // ##### 25-02-12 Komarov
  hotels_avail: [],
  hotels_avail_loaded: false,
  hotels_avail_errors: [],

  // ##### 25-03-12 Komarov
  autocomplete_data: null,
  autocomplete_data_loaded: false,
  autocomplete_data_errors: [],

  hotel_name: "",
  hotel_name_loaded: false,
  hotel_name_errors: [],

  // ##### 24-07-31 Komarov
  hotels_overview_top: [],
  hotels_overview_top_loaded: false,

  // ##### 24-07-31 Komarov
  hotels_overview_all: [],
  hotels_overview_all_loaded: false,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    // ##### 25-02-21 Komarov
    case RESET_HOTELS_AVAIL:
      return {
        ...state,
        hotels_avail_loaded: false,
        hotels_avail: initState.hotels_avail,
      };

    // ##### 24-07-31 Komarov
    case GET_HOTELS_OVERVIEW_ALL_REQ:
      return {
        ...state,
        hotels_overview_all_loaded: false,
      };

    case GET_HOTELS_OVERVIEW_ALL_RES:
      return {
        ...state,
        hotels_overview_all_loaded: true,
        hotels_overview_all: action.payload,
      };

    case GET_HOTELS_OVERVIEW_ALL_ERR:
      return {
        ...state,
        hotels_overview_all_loaded: false,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 24-07-31 Komarov
    case GET_HOTELS_OVERVIEW_TOP_REQ:
      return {
        ...state,
        hotels_overview_top_loaded: false,
      };

    case GET_HOTELS_OVERVIEW_TOP_RES:
      return {
        ...state,
        hotels_overview_top_loaded: true,
        hotels_overview_top: action.payload,
      };

    case GET_HOTELS_OVERVIEW_TOP_ERR:
      return {
        ...state,
        hotels_overview_top_loaded: false,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 25-02-12 Komarov
    case GET_HOTELS_AVAIL_REQ:
      return {
        ...state,
        hotels_avail_loaded: false,
      };

    case GET_HOTELS_AVAIL_RES:
      return {
        ...state,
        hotels_avail_loaded: true,
        hotels_avail: action.payload,
      };

    case GET_HOTELS_AVAIL_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        hotels_avail_errors: (state.errors && [
          ...state.errors,
          action.error,
        ]) || [action.error],
      };

    case GET_HOTEL_NAME_REQ:
      return {
        ...state,
        hotel_name_loaded: false,
      };

    case GET_HOTEL_NAME_RES:
      return {
        ...state,
        hotel_name_loaded: true,
        hotel_name: action.payload,
      };

    case GET_HOTEL_NAME_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        hotel_name_errors: (state.errors && [
          ...state.errors,
          action.error,
        ]) || [action.error],
      };

    case GET_AUTOCOMPLETE_DATA_REQ:
      return {
        ...state,
        autocomplete_data_loaded: false,
      };

    case GET_AUTOCOMPLETE_DATA_RES:
      return {
        ...state,
        autocomplete_data_loaded: true,
        autocomplete_data: action.payload,
      };

    case GET_AUTOCOMPLETE_DATA_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        autocomplete_data_errors: (state.errors && [
          ...state.errors,
          action.error,
        ]) || [action.error],
      };

    default:
      return state;
  }
};

export default reducer;
