// ##### 24-06-14 Komarov
import React, { useContext } from "react";
// ##### 24-08-10 Komarov
// import ArkturCollectionHotels from "../../../../Library/Images/ArkturCollectionHotels.jpg";
import { SmallScreenTopHotelsContext } from "./SmallScreenTopHotels";
// ##### 24-08-10 Komarov
import { TopHotelsTitle } from "../../ResponsiveHeader/Header/TopHotelsTitle";
// ##### 25-04-03 Komarov
import AsynchronousImage from "../../../../Library/PageDevices/CartGallery/AsynchronousImage";

export const SmallScreenTopHotelsDiv = ({ width, className = "" }) => {
  const { TopHotelContents, GetHotelDetails } = useContext(
    SmallScreenTopHotelsContext,
  );
  return (
    <div className={className} data-component="SmallScreenTopHotelsDiv">
      <div
        className={className}
        style={{
          padding: "19px",
          backgroundColor: "#005AEB",
          marginTop: "2vw",
          borderRadius: "5px",
        }}
      />
      <div className={className} style={{ textAlign: "center" }}>
        {/* ##### 24-03-18 Komarov: TODO: Брать перевод значения атрибута alt из Смарта */}
        {/* ##### 24-08-10 Komarov */}
        {/* <img
          src={ArkturCollectionHotels}
          alt="Arktur Collection Hotels"
          style={{ width }} /> */}
        <TopHotelsTitle fontSize="0.8em" />
      </div>

      <div className={className}>
        <ul
          style={{
            listStyle: "none",
          }}
        >
          {
            // ##### 24-03-21 Komarov
            TopHotelContents.length > 0 ? (
              TopHotelContents.map((hotel) => {
                if (hotel.main_photo.length > 0) {
                  return (
                    <li
                      key={hotel.hotel_id}
                      //  ##### 22-09-05 Komarov: исправил вход в конкретный отель
                      onClick={GetHotelDetails(hotel)}
                      // ##### 24-03-21 Komarov
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          GetHotelDetails(hotel);
                        }
                      }}
                    >
                      <div>
                        {
                          hotel.main_photo[0] ? (
                            <AsynchronousImage
                              emptyLoader
                              id={hotel.hotel_id}
                              // ##### 24-03-18 Komarov: TODO: Брать перевод значения атрибута alt из Смарта
                              alt={hotel.hotel_name}
                              className="SmallerTopToursImage"
                              style={{ width }}
                              src={
                                (process.env.REACT_APP_PROTOCOL || "https://") +
                                hotel.main_photo[0]
                              }
                            />
                          ) : null
                          // (<LoadingMessage itemWidth={`${width}px`}/>
                          // )
                        }
                      </div>
                      {hotel.main_photo[0] ? (
                        <div
                          className="SmallerTopToursTitle"
                          // ##### 23-03-08 Komarov
                          style={{ width, textAlign: "center" }}
                        >
                          <h4 id={hotel.hotel_id}>{hotel.hotel_name}</h4>
                        </div>
                      ) : null}
                    </li>
                  );
                }
              })
            ) : (
              <div>{null}</div>
            )
          }
        </ul>
      </div>
    </div>
  );
};
