// ##### 24-05-31 Komarov
import React from "react";
import { Route, Switch } from "react-router-dom";
import { HomePage } from "../../../Pages/PageComponents/HomePage/HomePage";
// ##### 25-02-05 Komarov
import { MenuPageGenerator } from "../../../Pages/PageComponents/MenuPageGenerator/MenuPageGenerator";
import { HOTELS } from "../../../Pages/PageComponents/MiddleMenuComponents/HOTELS";
import { CONTACTUS } from "../../../Pages/PageComponents/TopMenuComponents/CONTACTUS";

const Helmet = () => {
  return (
    <div data-component="Helmet">
      <h1>Helmet</h1>
      <Switch>
        <Route path="/" component={HomePage} />
        <Route path="/about" component={MenuPageGenerator} />
        <Route path="/contact_us" component={CONTACTUS} />
        <Route path="/hotels_in_ukraine" component={HOTELS} />
      </Switch>
    </div>
  );
};

export default Helmet;
