import React from "react";
// ##### 23-09-14 Komarov
// import './SliderCss.css'

// ##### 25-03-27 Komarov
export const ArrowSlider = ({ direction, handleClick }) => {
  // console.log('Hi Kate')

  return (
    // ##### 25-03-27 Komarov
    <div
      data-component="Arrow"
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          handleClick();
        }
      }}
    >
      {direction === "right" ? (
        <div className="prev">&#10094;</div>
      ) : (
        <div className="next">&#10095;</div>
      )}
    </div>
  );
};

//   export default Arrow;
// css={css`
// display: flex;
// position: absolute;
// ${direction === 'right' ? 'right: 50px' : 'left: 25px'};
// height: 50px;
// width: 50px;
// justify-content: center;
// background: white;
// border-radius: 50%;
// cursor: pointer;
// align-items: center;
// transition: transform ease-in 0.1s;
// &:hover {
//   transform: scale(1.1);
// }
// img {
//   transform: translateX(${direction === 'left' ? '-2' : '2'}px);
//   &:focus {
//     outline: 0;
//   }
// }
// `}
