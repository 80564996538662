// ##### 23-09-04 Komarov: Переменные для определения того, мы находимся в среде выполнения браузера или сервера (Node)
/*
Пример использования этой переменной в коде:
import { isBrowser } from './browserDetector';
...
if (isBrowser) {
	Логика для Клиентской части приложения
} else {
	Логика для Серверной части приложения
}
*/
export const isBrowser =
  typeof window === "object" &&
  "[object Window]" === window.toString.call(window);
export const isServer =
  typeof global === "object" &&
  "[object global]" === global.toString.call(global);
