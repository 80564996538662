// ##### 25-03-05 Komarov
import {
  GET_SITE_CYPHER_CODE_DATA_RES,
  GET_SITE_CYPHER_CODE_DATA_ERR,
} from "../constants";

const initState = {
  data: null,
  error: null,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_SITE_CYPHER_CODE_DATA_RES:
      return {
        ...state,
        data: action.payload.data,
      };

    case GET_SITE_CYPHER_CODE_DATA_ERR:
      return {
        ...state,
        data: action.payload.data,
      };

    default:
      return state;
  }
};

export default reducer;
