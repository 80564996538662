// ##### 24-06-14 Komarov
import React, { useContext } from "react";
import { Star } from "../../../../Library/Icons/star";
import uniqueId from "lodash.uniqueid";
import { HotelContentContext } from "./HotelContent";
// ##### 24-10-18 Komarov
import { HotelRatesContext } from "../HotelItems/UlTag";

// ##### 23-03-01 Komarov
export const UlTag = ({
  ulCLassName, liClassName, liImageClassName, divHotelNameStarRatingClassName, divHotelAddressClassName = null, width, height,
}) => {
  const { result, hotel, mainPhoto } = useContext(HotelContentContext);
  // ##### 24-10-18 Komarov
  const {
    hotelRooms, newobj2
  } = useContext(HotelRatesContext);
  console.log('newobj2', newobj2);
  return (
    <ul className={ulCLassName} data-component="UlTag">
      <>
        {result.length > 0 ? (
          result.map((trip) => {
            if (trip.content_name === "Title") {
              // ##### 24-03-21 Komarov
              return (
                <li
                  key={uniqueId()}
                  className={liClassName}
                  style={{ gridRow: "1", gridColumn: "1", order: 1 }}
                >
                  <div className={divHotelNameStarRatingClassName}>
                    <h3
                      style={{
                        // ##### 24-08-14 Komarov
                        // fontSize: "27px",
                        fontSize: "22px",
                        color: "#001959",
                        fontWeight: "bold",
                        marginRight: "2vw",
                      }}
                    >
                      {hotel.localized_hotel_name.toUpperCase()}
                    </h3>
                  </div>
                </li>
              );
            }

            // ##### 24-02-22 Komarov
            for (const key in trip) {
              if (key === "hotel_parameters") {
                // ##### 24-03-21 Komarov
                return (
                  <li
                    key={uniqueId()}
                    className={liClassName}
                    style={{ gridRow: "1", gridColumn: "2/3", order: 2 }}
                  >
                    {/* ##### 24-10-18 Komarov */}
                    <div style={{
                      display: 'flex', 
                      flexDirection: 'row',
                    }}>
                      <div className={divHotelNameStarRatingClassName}>
                        {trip[key].category &&
                          Array.from(trip[key].category).includes("*")
                          ? Array.from(trip[key].category).map(() => (
                            <span
                              key={uniqueId()}
                              style={{
                                marginLeft: "0.1vw",
                                marginRight: "0.1vw",
                              }}
                            >
                              <Star />
                            </span>
                          ))
                          : null}
                      </div>
                      {/* ##### 24-10-18 Komarov */}
                      {newobj2 && <>
                        {hotelRooms.length ? (
                          hotelRooms.map((item) => {
                            if (parseInt(item.room_id) === parseInt(newobj2.room_id)) {
                              // ##### 23-07-05 Komarov
                              return (
                                <h3
                                  key={uniqueId()}
                                  style={{
                                    fontSize: "14px",
                                    color: "#001959",
                                    fontWeight: "bold",
                                    gridColumn: "2",
                                    gridRow: "1",
                                  }}
                                >
                                  {/* ##### 24-01-25 Komarov */}
                                  {item.room_name === "0" ? "Regular" : item.room_name}
                                </h3>
                              );
                            }
                          })
                        ) : (
                          // ##### 24-01-25 Komarov: TODO: Брать перевод из Смарта
                          <h3>Your search returned no result</h3>
                        )}
                      </>}
                    </div>

                    {/* ##### 24-03-21 Komarov */}
                    <div className={divHotelAddressClassName}>
                      <span style={{ fontWeight: "bold" }}>
                        {hotel.localized_city_name.toUpperCase()}
                      </span>{" "}
                      - {trip[key].address}
                    </div>
                  </li>
                );
              }
            }
            if (trip.content_name === "Image") {
              // ##### 23-02-17 Komarov
              return (
                // ##### 23-11-13 Komarov
                <li
                  key={uniqueId()}
                  className={liImageClassName}
                  style={{ gridRow: "2", gridColumn: "1", order: 3 }}
                >
                  <img
                    // className='imageSearchrender'
                    style={{
                      // ##### 23-03-01 Komarov
                      width: width,
                      height: height,
                      borderRadius: "5px",
                    }}
                    // ##### 23-09-05 Komarov: если у отеля нет фотографии (это отмечено добавленным свойством trip.isNotOwnImage = true), устанавливаем фото-"заглушку"
                    src={(() => {
                      if (trip.isNotOwnImage) return trip.text[0];

                      return mainPhoto !== undefined
                        ? (process.env.REACT_APP_PROTOCOL || "https://") +
                        mainPhoto
                        : (process.env.REACT_APP_PROTOCOL || "https://") +
                        trip.text[2];
                    })()}
                    // ##### 24-02-22 Komarov
                    alt="Hotel" />
                </li>
              );
            }
          })
        ) : (
          <li className="Li_HotelContent">
            <div
              style={{
                display: "flex",
                flexDirect: "row",
              }}
            >
              <h3
                style={{
                  fontSize: "27px",
                  color: "#001959",
                  fontWeight: "bold",
                  marginRight: "2vw",
                }}
              >
                {hotel.hotel_name}
              </h3>
            </div>
          </li>
        )}
      </>
    </ul>
  );
};
