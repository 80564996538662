// ##### 25-02-05 Komarov
import React from "react";
import { Media } from "../../../../helpers/MediaConfig";
// ##### 25-02-21 Komarov
import { useSelector } from "react-redux";

export const OptionContent = ({ item }) => {
  // ##### 25-02-21 Komarov
  const deviceType = useSelector((state) => state.deviceType);
  // ##### 23-10-31 Komarov
  return (
    <>
      <Media lessThan="Laptop-1024-1440">
        {
          // ##### 24-03-21 Komarov
          deviceType === "tablet" ? (
            <span style={{ fontSize: "150%", lineHeight: "175%" }}>
              {item.name}
            </span>
          ) : (
            item.name
          )
        }
      </Media>
      <Media greaterThanOrEqual="Laptop-1024-1440">{item.name}</Media>
    </>
  );
};
