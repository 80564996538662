import React from "react";
import moment from "moment";
import dateFormats from "../../../../../helpers/dateFormats";
import { ValidateQuery } from "../../../Helpers/helper";
// ##### 24-05-17 Komarov
import { useLocation } from "react-router-dom";
// ##### 24-05-10 Komarov
import { PlaceHolderStrings } from "../../../../Library/Localization/placeholders";
// ##### 24-05-13 Komarov
import { useSelector, useDispatch } from "react-redux";
import { setHotelDatesDivOpened } from "../../../../../Redux/actions";

// ##### 23-08-07 Komarov
export const ClickableLayer_02_StartEndDates = ({ width, height }) => {
  // ##### 24-05-10 Komarov
  const locale = useSelector((state) => state.language);
  moment.locale(locale);
  const placeholder = PlaceHolderStrings();
  const stayStartDate = useSelector(
    (state) => state.hotelsContext.stayStartDate
  );
  const stayEndDate = useSelector((state) => state.hotelsContext.stayEndDate);

  // ##### 23-11-23 Komarov
  const query = ValidateQuery(useLocation());

  // ##### 24-05-13 Komarov
  const dispatch = useDispatch();
  // ##### 24-05-15 Komarov
  const onRangePickerDivClick = (e) => {
    dispatch(setHotelDatesDivOpened(true));
    e.stopPropagation(); // Вызываем эту функцию, чтобы событие не отлавливалось дочерними компонентами, чтобы избежать его срабатвыания два раза подряд: и на div'е, и на целевом компоненте (в данном случае - Autocomplete).
  };

  return (
    <div
      data-component="ClickableLayer_02_StartEndDates"
      style={{
        width,
        height,
        border: "#196fbb",
        borderWidth: "0.2em",
        borderStyle: "solid",
        borderRadius: "1rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        fontFamily: "Arial",
        alignItems: "center",
        margin: "0.3vw",
      }}
      id="clickableLayer_02_StartEndDates"
      onClick={(e) => onRangePickerDivClick(e)}
      onKeyDown={(e) => {
        // Trigger click action on Enter key press
        if (e.key === "Enter") {
          onRangePickerDivClick(e);
        }
      }}
    >
      {/* ##### 22-11-02 Komarov: надпись "Заїзд -> Виїзд" */}
      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
          // ##### 23-03-30 Komarov
          marginTop: "0.25em",
        }}
      >
        {/* ##### 22-11-02 Komarov: надпись "Заїзд" */}
        <div>
          {stayStartDate ||
            (query.start &&
              moment(query.start, dateFormats.en).format(
                dateFormats[locale]
              )) ||
            placeholder.placeHolderStartDate}
        </div>
        {/* ##### 22-11-02 Komarov: надпись "Заїзд -> Виїзд": стрелочка */}
        &nbsp;&nbsp;&mdash;&nbsp;&nbsp;
        {/* ##### 22-11-02 Komarov: надпись "Виїзд" */}
        <div>
          {stayEndDate ||
            (query.end &&
              moment(query.end, dateFormats.en).format(dateFormats[locale])) ||
            placeholder.placeHolderEndDate}
        </div>
      </div>
    </div>
  );
};
