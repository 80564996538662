import {
  GET_TOURCONTENTS_ERR,
  GET_TOURCONTENTS_REQ,
  GET_TOURCONTENTS_RES,
} from "../constants";
// ##### 25-01-10 Komarov
import { getUrl } from "../../helpers/crypto";
// ##### 25-03-28 Komarov
import { getSavingDataClient } from "../../helpers/saveData";

// console.log('[CONTENTRESPONCE]' , contentResponse)

// ##### 24-03-21 Komarov
export const getTourContents = (id, date) => async (dispatch) => {
  // ##### 23-03-06 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/tourcontents.js:getTourContents");

  dispatch({ type: GET_TOURCONTENTS_REQ });

  // ##### 25-01-10 Komarov
  const url = getUrl("sitechoice3new", `tour_id=${id}&date=${date}`);
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/tourcontents.js:getTourContents, url:",
      url,
    );

  // ##### 25-03-28 Komarov: запрашиваем у Сервера Сайта сохранение данных для запроса с текущими параметрами
  await getSavingDataClient({
    smartUrl: url,
    typeRes: GET_TOURCONTENTS_RES,
    typeErr: GET_TOURCONTENTS_ERR,
    dispatch,
  });
};
