// ##### 24-11-29 Komarov: Компонент для асинхронной подгрузки изображений
import React, { useEffect, useState } from "react";
import { CupOfCoffee } from "../Animation/CupOfCoffee";
// ##### 25-04-03 Komarov
import { CachedImage } from "../../../../helpers/saveData";
import { getDomain } from "../../../Pages/Helpers/urls";

// ##### 25-04-03 Komarov
function AsynchronousImage({
  src,
  shownImgRef,
  alt,
  style,
  onClick,
  onKeyUp,
  hotel_id,
  className,
  id,
  emptyLoader = false,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleLoad = () => {
    setIsLoading(false);
    setIsError(false);
  };

  const handleError = () => {
    // ##### 25-04-03 Komarov
    // Set a fallback image source:
    console.warn("There's no main image for hotel with id", hotel_id);
    setIsLoading(false);
    setIsError(true);
  };

  useEffect(() => {
    const img = new Image();
    img.src = src;

    img.onload = handleLoad;

    img.onerror = handleError;

    return () => {
      // Cleanup function: Remove event listeners to prevent memory leaks
      img.removeEventListener("load", handleLoad);
      img.removeEventListener("error", handleError);
    };
  }, [src]);
  // ##### 25-04-03 Komarov
  const currentWebProtocol = process.env.REACT_APP_PROTOCOL || "https://";

  // ##### 25-04-03 Komarov
  return (
    <>
      {isLoading && <CupOfCoffee empty={emptyLoader} />}
      {!isLoading && !isError && (
        <CachedImage
          src={src}
          shownImgRef={shownImgRef}
          alt={alt}
          className={className}
          style={style}
          onClick={onClick}
          onKeyUp={onKeyUp}
          id={id}
        />
      )}
      {/* ##### 25-04-03 Komarov */}
      {/*{isError && <p>Image failed to load.</p>}*/}
      {isError && (
        <CachedImage
          src={`${currentWebProtocol}${getDomain(process.env.REACT_APP_SMART_URL)}/images_symlink/hotel/0/Hotel_when_no_image.jpeg`}
          shownImgRef={shownImgRef}
          alt={alt}
          className={className}
          style={style}
          onClick={onClick}
          onKeyUp={onKeyUp}
          id={id}
        />
      )}
    </>
  );
}

export default AsynchronousImage;
