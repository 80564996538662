// ##### 24-09-09 Komarov
import React, { useState, useEffect, createContext, useMemo } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getGeneralGeo } from "../../../../../Redux/actions/cities";
import moment from "moment";
import { ValidateQuery } from "../../../Helpers/helper";
import { useWindowWidthAndHeight } from "../../../Helpers/WindowResizeHook";
import { LoadingMessage } from "../../../../Library/PageDevices/LoadingMessage";
import { getPickedCurrencyResponse } from "../../../../../Redux/actions/currency";
import config from "../../../../../Redux/config";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../../helpers/messages";
// ##### 23-11-02 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
import { UlTag } from "./UlTag";

moment.locale(config.defaultLang);

// ##### 23-10-10 Komarov
export const GuestItemContext = createContext();

// ##### 24-03-21 Komarov
export const GuestItem = () => {
  // ##### 24-05-31 Komarov
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  ///получаю с помощью своиства истории (history) из компонента search результаты поиска - массив с одним объектом.
  // ##### 24-03-21 Komarov
  const location = useLocation();
  // ##### 23-11-22 Komarov
  // let history = useHistory();
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  // ##### 24-03-21 Komarov
  const search_data = ValidateQuery(location);
  // console.log('GUEST ITEM LOCATION', location)

  // // const searchResults = search_data.query.replace(/_/g, " ")
  // console.log('[SEARCHRESULTS] : ' , searchResults , 'DATSENKO',search_data, search_data.title.replace(/_/g, " "))
  // console.log('DATSENKO',search_data)

  const dispatch = useDispatch();
  // ##### 24-07-25 Komarov
  const generalGeo = useSelector((state) => state.cities.gen_locs);

  // ##### 23-11-22 Komarov
  // const [rate, setRate] = useState([])
  const [rate, setRate] = useState(undefined);
  const [smartResultFound, setSmartResultFound] = useState(false);

  // console.log('[TEST]', test)

  ///получаю из смарта тур имя, тур айди, сити имя, сити айди
  useEffect(() => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/SecondPageofSearchModule/Tours/GuestItem.js:getGeneralGeo()"
      );
    // ##### 24-07-25 Komarov
    // dispatch (getGeneralGeo (locale));
    generalGeo.length > 0 ||
      dispatch(getGeneralGeo(locale));
    dispatch(getPickedCurrencyResponse(search_data.selected_currency));
    // ##### 23-04-12 Komarov
  }, [dispatch, locale, search_data.selected_currency]);

  // console.log('[GENERAL_GEO] , ' , generalGeo)///получаю из смарта тур имя, тур айди, сити имя, сити айди
  // ##### 23-03-29 Komarov
  const [width /* height */] = useWindowWidthAndHeight();

  ///используется непосредственно для вывода названий туров на странице.
  ///если айди города, который мне приходит первоначально от Саши Ефица (классификатор contracts) не находится в данных, пришедших в результате поиска клиентом,
  ///то в переменную filtered возвращается тур айди из классификатора contracts, равное айди, пришедшему от клиентского поиска
  ///иначе возвращается сити айди из классификатора contracts, равное айди из поиска

  // ##### 24-03-21 Komarov
  const filtered = generalGeo.filter((item) => {
    if (parseInt(item.city_id) === parseInt(search_data.id)) {
      return parseInt(item.city_id);
    }
    return parseInt(item.tour_id) === parseInt(search_data.id);
  });

  ///отфильтровала данные поиска, чтобы получить только тур айди поиска
  // ##### 24-03-21 Komarov
  const filtered_tour_id = filtered.map((item1) => item1.tour_id);

  const currentMonth = moment().format("YYYY-MM");
  const today = moment().format("YYYY-MM-DD");

  const { user: currentUser } = useSelector((state) => state.auth);
  // ##### 24-03-01 Komarov
  const company_cipher = useSelector(
    (state) => state.whitelabel.company_cipher
  );

  // ##### 24-03-21 Komarov
  useEffect(() => {
    const ActionRQ = {
      username: process.env.REACT_APP_SMART_USERNAME,
      password: process.env.REACT_APP_SMART_PASSWORD,
      // "user_id" :1426,
      user_id: currentUser.user_id,
      action: "GetPriceTourRQ",
      data: {
        // ##### 24-03-01 Komarov
        company_cipher: company_cipher || 0,
        city_id: search_data.city_id,
        date:
          currentMonth === search_data.date ? today : `${search_data.date}-01`,
        window: 30,
        tour_id: filtered_tour_id.length === 1 ? filtered_tour_id[0] : null, ///если в ответ при поиске пришёл массив из более 1 айди тура (что может быть при поиске клиентом по городу, а не по туру), то, так как Смарт не принимает массив, данный параметр при передаче данных игнорируется (равен null). Иначе этот параметр в одном экземпляре и он тогда передаётся в Смарт и участвует в фильтрации
      },
    };

    // axios.post((process.env.REACT_APP_PROTOCOL || 'https://') + 'hotels-ua.biz/interface/xmlsubj/', JSON.stringify({ActionRQ}))
    // ##### 23-11-22 Komarov
    !rate &&
      axios
        .post(
          `${process.env.REACT_APP_SMART_URL}interface/xmlsubj/`,
          JSON.stringify({ ActionRQ })
        )
        // axios.post('http://smartbooker/interface/xmlsubj/', JSON.stringify({ActionRQ}))
        // ##### 24-11-01 Komarov
        .then(async (res) => {
          if (await res.data.length === 0) {
            // ##### 23-11-22 Komarov TODO: Переделать код: изменить это значение, начиная со Смарта, чтобы в случае отсутствия результатов в выборке из БД возвращался результат более информативный (например, found: false). И такое же значение обрабатывать и использовать в коде сайта
            setSmartResultFound("Hi!");
          }
          setRate(await res.data);
        })
        .catch((error) => {
          setRate(undefined);
          // ##### 24-03-21 Komarov
          console.log("[axios error] : ", error);
        });
    // ##### 23-04-12 Komarov
  }, [
    currentUser.user_id,
    search_data.city_id,
    currentMonth,
    search_data.date,
    filtered_tour_id,
    today,
    // ##### 24-03-21 Komarov
    rate,
    filtered_tour_id[0],
  ]);

  //  ##### 24-04-23 Komarov
  const searchReturnedNoResults =
    useMessages("SearchReturnedNoResults") ||
    "Your Search returned no results. Please change your parameters and try once again.";
  //  ##### 24-04-23 Komarov
  const SearchResults = useMessages("SearchResults");

  // ##### 24-09-10 Komarov
  const guestItemContextValue = useMemo(() => ({
    filtered,
    rate,
    search_data,
    searchReturnedNoResults,
  }), [
    // ##### 24-09-11 Komarov
    filtered,
    rate,
    search_data,
    searchReturnedNoResults,
  ]);

  return (
    <GuestItemContext.Provider
      value={guestItemContextValue}
    >
      <div className="TourSearchrendering_Wrapper" data-component="GuestItem">
        <div>
          <h3
            style={{
              marginTop: "2vw",
              color: "#003057",
              fontFamily: "Arial",
              fontSize: "30px",
              fontWeight: "bold",
            }}
          >
            {
              // ##### 23-10-04 Komarov
              SearchResults
            }
          </h3>
        </div>

        <div>
          {
            // ##### 23-11-22 Komarov
            // !rate || rate.length === 0 ?
            !rate ? (
              <div>
                <LoadingMessage loadingMessageClass="RateLoading" />
              </div>
            ) : // ##### 23-11-22 Komarov
              rate.length === 0 ? (
                <LoadingMessage
                  loadingMessageClass="RateLoading"
                  smartResultFound={smartResultFound}
                />
              ) : (
                // ##### 23-03-01 Komarov
                <>
                  {/* ##### 23-11-02 Komarov */}
                  <Media greaterThanOrEqual="Laptop-1024-1440">
                    <UlTag
                      // ulCLassName='greaterThan-desktopMinWidth_1024 TourDescriptionUl'
                      ulCLassName="TourDescriptionUl"
                      width={width * 0.65}
                      // divClassName='greaterThan-desktopMinWidth_1024 TourDescriptionContent'
                      divClassName="TourDescriptionContent"
                    />
                  </Media>
                  {/* ##### 23-04-20 Komarov */}
                  <Media lessThan="Laptop-1024-1440">
                    <UlTag
                      // ulCLassName='lessThanOrEqual-desktopMinWidth_1024 TourDescriptionUlSmallScreen'
                      ulCLassName="TourDescriptionUlSmallScreen"
                      width={width * 0.9}
                      // divClassName='lessThanOrEqual-desktopMinWidth_1024 TourDescriptionContentSmallScreen'
                      divClassName="TourDescriptionContentSmallScreen"
                    />
                  </Media>
                </>
              )
          }
        </div>
      </div>
    </GuestItemContext.Provider>
  );
};
