// ##### 24-11-08 Komarov
import {
  GET_COMPANY_RES,
  GET_COMPANY_ERR,
  GET_COMPANY_NOT_FOUND
} from "../constants";

const initialState = {
  company_id: 1361,
  company_name: "hotelsukraine.com.ua",
  sale_id: 37,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_RES:
      console.log('action.payload', action.payload);
      const {
        company_id,
        company_name,
        sale_id
      } = action.payload.data;
      return {
        company_id,
        company_name,
        sale_id
      };

    case GET_COMPANY_NOT_FOUND:
      return {
        ...initialState
      };

    case GET_COMPANY_ERR:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

export default reducer;
