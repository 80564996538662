import { SET_CURRENT_URL } from "../constants";

export default (state = "/", action) => {
  // ##### 23-04-05 Komarov
  // process.env.NODE_ENV == "development" && console.log('src/Redux/reducers/setCurrentUrl.js:action', action);
  switch (action.type) {
    case SET_CURRENT_URL:
      return action.payload;
    default:
      return state;
  }
};
